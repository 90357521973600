import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const RASBconfig = {
  name: 'RASBconfig',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.RockwellAutomationSB_Expo,

};

export default RASBconfig;
