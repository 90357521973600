import { useQueryParam } from './use-query-param';

export function useIsPreviewView() {
  const query = useQueryParam();
  const isPreview = query.get('preview') === 'true';
  const shouldMockSpeakers = query.get('mockspeakers') === 'true';

  return {
    isPreview,
    shouldMockSpeakers,
  };
}
