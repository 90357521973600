import { IStreamsDisplayMode } from "@/components/PublishedStreamDisplay/types";

const tigerAnalytics = {
    name: 'tigerAnalytics',
    auth: {
      magicLinks: true,
    },
    zoneNames: {
      expo: 'games',
    },
    getDisplayMode: (numStreams, hasScreenshareStream) => {
      if (numStreams === 1) {
        return IStreamsDisplayMode.GRID;
      } else if (numStreams < 4) {
        return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
      } else {
        return IStreamsDisplayMode.SPOTLIGHT;
      }
    },
  };
  
  export default tigerAnalytics;