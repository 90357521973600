import { SAAS_INSIDER_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';

const saasInsider = {
  name: 'saasInsider',
  auth: {
    magicLinks: true,
  },

  boothConfig: {
    'e3949d96-d109-4a31-a3d0-efddaff28107': SAAS_INSIDER_PHOTOBOOTH,
    // local 
    '928548f3-6859-4807-887b-7e9aa9ec11ad': SAAS_INSIDER_PHOTOBOOTH,
  },
};
export default saasInsider;