export const virtualBackgroundMap = {
  '1f62f2fc-f78d-462b-8a9d-e883a0387c38': { // Test event id
    type: 'image',
    image: // Should be 1280 x 720
      'https://lbtfzh-online-events-dev-public.s3.ap-south-1.amazonaws.com/oak-park-zoom-main-library-1.jpeg',
  },
  '70afc348-b7b3-4a17-870a-546c47039c58': { //Staging test event: Virtual Background Test
    type: 'image',
    image:
      'https://lbtfzh-online-events-dev-public.s3.ap-south-1.amazonaws.com/oak-park-zoom-main-library-1.jpeg',
  },
  '6878eb05-fefd-44b1-b610-3f41c910fac0': { //Pre-prod test event id: VIRTUAL BACKGROUND TEST
    type: 'image',
    image:
      'https://lbtfzh-online-events-dev-public.s3.ap-south-1.amazonaws.com/oak-park-zoom-main-library-1.jpeg',
  },
  '6f3a5f3d-f69c-4e33-bd8c-06fe8ded6464': { //Prod Tally Digisabha 2022
    type: 'image',
    image: // Should be 1280 x 720
      'https://phoenixlive.imgix.net/1972c513-a9ff-4414-b0ed-fd11cbaa4076.jpg',
  },
  'dbdcd652-f274-4f69-ab24-26e124a88773': { //Prod - Nasdaq<>Zuddl (Sandbox)
    type: 'image',
    image:
      'https://phoenixlive.imgix.net/1ac66f61-9702-4c46-b1b6-f5950cd902ba.png',
  },
};
