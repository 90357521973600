import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const DisconnectedIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg className={fillClassName} width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={fillClassName} d="M13.7135 11.3565L12.5352 10.1782L13.7135 8.99984C14.0231 8.6903 14.2686 8.32283 14.4361 7.9184C14.6036 7.51397 14.6899 7.0805 14.6899 6.64275C14.6899 6.205 14.6036 5.77154 14.4361 5.36711C14.2686 4.96268 14.0231 4.59521 13.7135 4.28567C13.404 3.97613 13.0365 3.7306 12.6321 3.56308C12.2276 3.39556 11.7942 3.30933 11.3564 3.30933C10.9187 3.30933 10.4852 3.39556 10.0808 3.56308C9.67636 3.7306 9.30889 3.97613 8.99935 4.28567L7.82102 5.464L6.64268 4.28567L7.82102 3.10734C8.76127 2.18227 10.029 1.66622 11.348 1.67159C12.667 1.67696 13.9305 2.20332 14.8632 3.13601C15.7959 4.06871 16.3222 5.33217 16.3276 6.65119C16.333 7.9702 15.8169 9.23791 14.8918 10.1782L13.7135 11.3565ZM11.356 13.714L10.1777 14.8923C9.71478 15.3628 9.16331 15.737 8.55507 15.9933C7.94683 16.2496 7.29386 16.383 6.63383 16.3857C5.9738 16.3884 5.31977 16.2603 4.70946 16.009C4.09916 15.7577 3.54465 15.388 3.07794 14.9212C2.61122 14.4545 2.24153 13.9 1.99019 13.2897C1.73885 12.6794 1.61083 12.0254 1.61351 11.3654C1.6162 10.7053 1.74954 10.0524 2.00585 9.44412C2.26215 8.83588 2.63635 8.2844 3.10685 7.8215L4.28518 6.64317L5.46352 7.8215L4.28518 8.99984C3.97565 9.30937 3.73011 9.67685 3.56259 10.0813C3.39507 10.4857 3.30885 10.9192 3.30885 11.3569C3.30885 11.7947 3.39507 12.2281 3.56259 12.6326C3.73011 13.037 3.97565 13.4045 4.28518 13.714C4.59472 14.0235 4.96219 14.2691 5.36662 14.4366C5.77105 14.6041 6.20451 14.6903 6.64227 14.6903C7.08002 14.6903 7.51348 14.6041 7.91791 14.4366C8.32234 14.2691 8.68981 14.0235 8.99935 13.714L10.1777 12.5357L11.356 13.714ZM11.356 5.464L12.5352 6.64317L6.64268 12.5348L5.46352 11.3565L11.356 5.464ZM3.81185 0.910671L5.42185 0.479004L6.28435 3.69984L4.67518 4.1315L3.81185 0.910671ZM11.7143 14.3007L13.3235 13.869L14.1868 17.089L12.5768 17.5207L11.7143 14.3007ZM0.910182 3.81234L4.13018 4.67567L3.69852 6.28484L0.478516 5.42234L0.910182 3.81234ZM14.3002 11.7148L17.5202 12.5773L17.0885 14.1873L13.8685 13.324L14.3002 11.7148Z" />
    </svg>

  );
};

export default DisconnectedIcon;
