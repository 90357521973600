import { AOP_PHOTOBOOTH, ZUDDL_VO_PHOTOBOOTH2, AOP_PHOTOBOOTH2, AOP_PHOTOBOOTH3, ZWELLNESS_PHOTOBOOTH1, ZWELLNESS_PHOTOBOOTH2, ETP_PHOTOBOOTH, BRITANNIA_CLUB_ROYALE_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';
import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';

const aop = {
    name: 'aop',
    auth: {
      magicLinks: true,
    },
    getDisplayMode: (numStreams, hasScreenshareStream) => {
        if (hasScreenshareStream) {
          if (numStreams <= 3) {
            return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
          } else {
            return IStreamsDisplayMode.SPOTLIGHT;
          }
        }
    
        if (numStreams <= 3) {
          return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
        } else {
          return IStreamsDisplayMode.CONDENSED;
        }
      },
    boothConfig: {
      'da747c57-ec65-4b2c-856a-2a62b55420f1': ZUDDL_VO_PHOTOBOOTH2,
      'e222ba89-f89b-4737-a513-3137277be675': ZUDDL_VO_PHOTOBOOTH2,
      '81bcdbc6-2fe2-4150-a23f-c8d8ba8f918b': AOP_PHOTOBOOTH,
      'a809d487-78f7-4f3c-b20b-135bece6188f': AOP_PHOTOBOOTH2,
      'd5ecd535-e396-42cf-bbcf-a33cae7c4508': AOP_PHOTOBOOTH3,
      '7a9e9082-a72b-4478-bb19-f96c5643fd33': AOP_PHOTOBOOTH,
      '37c555a4-cf68-427e-abfc-711e9be7048e': AOP_PHOTOBOOTH2,
      'd0bc4e97-4777-4729-a1b8-d4d4766c6baa': AOP_PHOTOBOOTH3,
      '10a3f82f-b595-423e-b6da-0d85ececf867': AOP_PHOTOBOOTH,
      '056b3d5b-6b6b-4778-8511-73508d6702eb': AOP_PHOTOBOOTH2,
      '92b60f59-4b71-43c4-ac67-ef4e394db3c9': AOP_PHOTOBOOTH3,
      '70bc1d66-aa90-43bd-bee9-f9d865f50e6f': AOP_PHOTOBOOTH,
      '4382ab34-6424-46eb-8314-f382d48f0c3c': AOP_PHOTOBOOTH2,
      'b3b184ce-4dab-4c7e-b680-2475c246be09': AOP_PHOTOBOOTH3,
      'e468f5fa-5a73-4d9b-931b-2a4176b58141': AOP_PHOTOBOOTH,
      'dbf98d1f-7a66-4e35-8e76-4a0ec6f2543a': AOP_PHOTOBOOTH2,
      '6e7269d9-c569-477f-90bc-49ee2c02e170': AOP_PHOTOBOOTH3,
      '499ebb02-ff0f-4f34-a26e-1ebb1c1794b7': AOP_PHOTOBOOTH,
      'e4ff61f4-9e96-401b-afa4-18ba9aa56e82': AOP_PHOTOBOOTH2,
      'eac2de62-ede0-47c6-88f0-0c3a61f49b47': AOP_PHOTOBOOTH3,
      '478ccce8-950d-4b34-ae46-22ae5e4e791e': AOP_PHOTOBOOTH,
      'c0ccb8d2-0d94-4369-9931-b0f502ba8e12': AOP_PHOTOBOOTH2,
      '4fabd36d-56a5-4772-b5a7-cfc1c696c20f': AOP_PHOTOBOOTH3,
      '717b1979-d764-4870-b3b7-59b533844d24': ZWELLNESS_PHOTOBOOTH1,
      '2f7c9f9e-306a-4bbb-811e-15c4c92fa6e4': ZWELLNESS_PHOTOBOOTH2,
      '9a8a5e1e-a1ba-4030-a7f2-a9386750002c': ETP_PHOTOBOOTH,
      'ae3518cd-a449-461f-9b39-03be2e0dc4d0': BRITANNIA_CLUB_ROYALE_PHOTOBOOTH,
    },
    
  };


  export default aop;