import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const ExternalLinkLineIcon = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 14 } = props;
  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.35741 2.35693V3.85693H1.60742V12.1069H9.8574V8.35693H11.3574V12.8569C11.3574 13.0558 11.2784 13.2466 11.1377 13.3873C10.9971 13.5279 10.8063 13.6069 10.6074 13.6069H0.85742C0.658508 13.6069 0.467743 13.5279 0.327091 13.3873C0.186439 13.2466 0.107422 13.0558 0.107422 12.8569V3.10693C0.107422 2.90802 0.186439 2.71726 0.327091 2.5766C0.467743 2.43595 0.658508 2.35693 0.85742 2.35693H5.35741ZM13.6074 0.106934V6.10693H12.1074V2.66668L6.26266 8.51218L5.20216 7.45168L11.0461 1.60693H7.6074V0.106934H13.6074Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default ExternalLinkLineIcon;
