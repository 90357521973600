import { ADOBE_ALCHEMY_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';

const  adobeAlchemy = {
    name: 'adobeAlchemy',
    auth: {
      magicLinks: true,
    },
    boothConfig: {
        '3b9bb56f-f643-4192-a1ed-d9741a642c5d': ADOBE_ALCHEMY_PHOTOBOOTH,

        // local
        '928548f3-6859-4807-887b-7e9aa9ec11ad': ADOBE_ALCHEMY_PHOTOBOOTH,
    },
    
  };


  export default  adobeAlchemy;