import React from 'react';

const SmallRightCaret = () => (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.23883 10L6.23883 5L1.23883 0L0 1.23883L3.76117 5L0 8.76117L1.23883 10Z"
      fill="white"
    />
  </svg>
);
export default SmallRightCaret;
