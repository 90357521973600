import { useRef, useState, useLayoutEffect, useEffect } from "react";

const useImageDimensions = (deps, isMobile = false) => {
  const ref = useRef();
  const [dimensions, setDimensions] = useState({});

  const defaultWidth = isMobile ? 1080 : 1920;
  const defaultHeight = isMobile ? 1920 : 1080;

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      if (ref.current) {
        setDimensions({
          naturalWidth: ref.current.naturalWidth || defaultWidth,
          naturalHeight: ref.current.naturalHeight || defaultHeight,
        });
      }
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [ref.current]);

  useLayoutEffect(() => {
    if (ref.current) {
      setDimensions({
        naturalWidth: ref.current.naturalWidth || defaultWidth,
        naturalHeight: ref.current.naturalHeight || defaultHeight,
      });
    }
  }, [ref.current].concat(deps));

  return [ref, dimensions];
};

export default useImageDimensions;
