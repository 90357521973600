import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const VolumeOff = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.90751 14H1.66668C1.44566 14 1.2337 13.9122 1.07742 13.7559C0.921141 13.5997 0.833344 13.3877 0.833344 13.1667V8.16668C0.833344 7.94567 0.921141 7.73371 1.07742 7.57743C1.2337 7.42115 1.44566 7.33335 1.66668 7.33335H4.90751L9.31918 3.72335C9.38022 3.67331 9.45422 3.64163 9.53257 3.63201C9.61091 3.62239 9.69038 3.63523 9.76171 3.66902C9.83305 3.70281 9.89332 3.75616 9.93551 3.82288C9.97769 3.88959 10.0001 3.96692 10 4.04585V17.2875C10.0001 17.3665 9.97769 17.4438 9.93551 17.5105C9.89332 17.5772 9.83305 17.6306 9.76171 17.6644C9.69038 17.6981 9.61091 17.711 9.53257 17.7014C9.45422 17.6917 9.38022 17.6601 9.31918 17.61L4.90751 14Z"
        className={fillClassName}
      />
      <path
        d="M19.9583 13.6134L17.0117 10.6667L19.9583 7.72002L18.78 6.54169L15.8333 9.48835L12.8867 6.54169L11.7083 7.72002L14.655 10.6667L11.7083 13.6134L12.8867 14.7917L15.8333 11.845L18.78 14.7917L19.9583 13.6134Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default VolumeOff;
