import React from 'react';
// styles + types
import { IIconProps } from '../types';

const SuccessCircleFilled = (props: IIconProps) => {
  const { size = 20 } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2307_145505)">
        <circle cx="10.5" cy="10.5" r="7.5" fill="white" />
      </g>
      <path
        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9.003 14L16.073 6.929L14.659 5.515L9.003 11.172L6.174 8.343L4.76 9.757L9.003 14Z"
        fill="#11AB47"
      />
      <defs>
        <filter
          id="filter0_b_2307_145505"
          x="-17"
          y="-17"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="10" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2307_145505"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2307_145505"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default SuccessCircleFilled;
