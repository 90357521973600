import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const RaisedHandIcon = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 50 50"
      style={{
        enableBackground:'new 0 0 50 50'
      }}
    >
      <g>
        <path
          className={fillClassName}
          d="M37.9,12.7c0-1.2-1.1-2.3-2.3-2.3s-2.3,1.1-2.3,2.3v10.1c0,1.2,1.1,2.3,2.3,2.3c0.7,0,1.3-0.3,1.7-0.7
          c0,0,0,0,0.1-0.1c0.3-0.4,0.5-0.9,0.5-1.4V12.7z"
        />
        <path
          className={fillClassName}
          d="M13.7,26c1.2,0,2.3-1.1,2.3-2.3v-1v-1.9c0-1.2-1.1-2.3-2.3-2.3s-2.3,1.1-2.3,2.3v2.9c0,0.5,0.1,0.9,0.4,1.3
          C12.2,25.6,12.9,26,13.7,26z"
        />
        <path
          className={fillClassName}
          d="M21.2,26c0.4,0,0.7-0.1,1-0.3c0.1-0.5,0.2-0.9,0.4-1.4c0.2-0.5,0.5-0.9,0.9-1.2v-0.4v-1.9
          c0-1.2-1.1-2.3-2.3-2.3c-1.2,0-2.3,1.1-2.3,2.3v1.9v1C18.9,24.9,20,26,21.2,26z"
        />
        <path
          className={fillClassName}
          d="M29.3,22.7l1.7,0.8v-0.8v-1.9c0-1.2-1.1-2.3-2.3-2.3c-1.2,0-2.3,1.1-2.3,2.3v1.1c0.7,0,1.4,0.1,2,0.4
            L29.3,22.7z"
        />
        <path
          className={fillClassName}
          d="M31.3,25.9l-3.7-1.7c-0.4-0.2-1.5-0.4-2.2,0c0,0-0.5,0.2-0.9,0.8c-0.3,0.5-0.5,1.3-0.4,1.8
          c0.2,0.7,0.6,1.3,1.3,1.6l5.2,2.5c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.3,0.1,0.6,0,0.8c-0.2,0.4-0.5,0.6-0.9,0.6c-0.1,0-0.3,0-0.4-0.1
          l-5.2-2.5c-1.1-0.5-1.8-1.4-2.2-2.4C22,27.9,21.6,28,21.1,28c-1.6,0-3-0.9-3.7-2.2c-0.7,1.3-2.1,2.2-3.8,2.2
          c-0.7,0-1.3-0.2-1.9-0.5v1.9c0,7.1,5.7,12.8,12.8,12.8s12.8-5.7,12.8-12.8v-0.7L31.3,25.9z"
        />
      </g>
    </svg>
  );
};

export default RaisedHandIcon;
