import { makeSelectStudioIdByStageId } from '@/models/event';
import { useLocation, useParams } from 'react-router-dom';
import { useMemoizedSelector } from './use-memoized-selector';

const useIsPageEmbedded = () => {
  const { stageId } = useParams<{ stageId: string }>();
  const location = useLocation();
  const stageStudioId = useMemoizedSelector(
    makeSelectStudioIdByStageId,
    stageId,
  );
  const isStudioStage = !!stageStudioId;

  if (isStudioStage) {
    if (location.pathname.includes('embed')) {
      return true;
    }
  }

  return false;
};

export default useIsPageEmbedded;
