import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';

const dsciaissConfig = {
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      return IStreamsDisplayMode.SPOTLIGHT;
    }

    switch (numStreams) {
      case 1:
      case 2:
        return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
      default:
        return IStreamsDisplayMode.GRID;
    }
  },
  containerPadding: {
    leftPercentage: 16.5,
    rightPercentage: 16.5,
    topPercentage: 3,
    bottomPercentage: 21.2,
  },
};

export default dsciaissConfig;
