import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const GridLayout = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9107 2.375C15.0954 2.375 15.2726 2.44837 15.4032 2.57898C15.5338 2.70958 15.6072 2.88672 15.6072 3.07143V14.2143C15.6072 14.399 15.5338 14.5761 15.4032 14.7067C15.2726 14.8373 15.0954 14.9107 14.9107 14.9107H2.37502C2.19031 14.9107 2.01317 14.8373 1.88257 14.7067C1.75196 14.5761 1.67859 14.399 1.67859 14.2143V3.07143C1.67859 2.88672 1.75196 2.70958 1.88257 2.57898C2.01317 2.44837 2.19031 2.375 2.37502 2.375H14.9107ZM7.94645 9.33929H3.07145V13.5179H7.94645V9.33929ZM14.2143 9.33929H9.3393V13.5179H14.2143V9.33929ZM7.94645 3.76786H3.07145V7.94643H7.94645V3.76786ZM14.2143 3.76786H9.3393V7.94643H14.2143V3.76786Z" fill="white"/>
    </svg>
    
  );
};

export default GridLayout;
