import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const RoomIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 14"
      xmlns="http://www.w3.org/2000/svg"
      className={fillClassName}
    >
      <path
        d="M10.8333 12.3665H2.16667C2.02301 12.3665 1.88523 12.3095 1.78365 12.2079C1.68207 12.1063 1.625 11.9685 1.625 11.8249V2.07487C1.625 1.93121 1.68207 1.79344 1.78365 1.69185C1.88523 1.59027 2.02301 1.5332 2.16667 1.5332H10.8333C10.977 1.5332 11.1148 1.59027 11.2164 1.69185C11.3179 1.79344 11.375 1.93121 11.375 2.07487V11.8249C11.375 11.9685 11.3179 12.1063 11.2164 12.2079C11.1148 12.3095 10.977 12.3665 10.8333 12.3665ZM10.2917 11.2832V2.61654H2.70833V11.2832H10.2917ZM4.33333 4.24154H8.66667V5.32487H4.33333V4.24154ZM4.33333 6.4082H8.66667V7.49154H4.33333V6.4082ZM4.33333 8.57487H8.66667V9.6582H4.33333V8.57487Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default RoomIcon;
