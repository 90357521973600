import { IEventCustomConfigType } from '@/custom-config/types';

const acxiomss: IEventCustomConfigType = {
  name: 'acxiomss',
  zoneNames: {
    lobby: 'Home',
    schedule: 'Agenda',
    stages: 'Sessions',
  },
};
export default acxiomss;
