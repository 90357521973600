import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const PollIcon = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 50 50"
      style={{
        enableBackground:'new 0 0 50 50',
      }}
    >
      <g>
        <path
          className={fillClassName}
          d="M18.5,39.7h-5.2c-0.6,0-1-0.4-1-1V22c0-0.6,0.4-1,1-1h5.2c0.6,0,1,0.4,1,1v16.7C19.5,39.3,19.1,39.7,18.5,39.7
          z"
        />
        <path
          className={fillClassName}
          d="M37.3,39.7H32c-0.6,0-1-0.4-1-1V27c0-0.6,0.4-1,1-1h5.2c0.6,0,1,0.4,1,1v11.7C38.3,39.3,37.8,39.7,37.3,39.7z"
        />
        <path
          className={fillClassName}
          d="M27.9,39.7h-5.2c-0.6,0-1-0.4-1-1V11.2c0-0.6,0.4-1,1-1h5.2c0.6,0,1,0.4,1,1v27.5
          C28.9,39.3,28.4,39.7,27.9,39.7z"
        />
      </g>
    </svg>
  );
};

export default PollIcon;
