export const VJ_STREAM_IDS = [
  // 503, 500, 5741, 5742, 5743, 5744, 5745, 671636,
  989830,
  989829,
  989828,
  989827,
  989826,
  989820,
  989819,
  989818,
  989817,
  989816,
  1052480,
  1052479,
  1052478,
  1052477,
  1052476,
  1052485,
  1052484,
  1052483,
  1052482,
  1052481,
  1047565,
  1047564,
  1047563,
  1047562,
  1047561,
  1047575,
  1047574,
  1047573,
  1047572,
  1047571,
  1047545,
  1047544,
  1047543,
  1047542,
  1047541,
  1047540,
  1047539,
  1047538,
  1047537,
  1047536,
  1047570,
  1047569,
  1047568,
  1047567,
  1047566,
  1047550,
  1047549,
  1047548,
  1047547,
  1047546,
  1047555,
  1047554,
  1047553,
  1047552,
  1047551,
  1047535,
  1047534,
  1047533,
  1047532,
  1047531,
  1047560,
  1047559,
  1047558,
  1047557,
  1047556,
  1057381,
  1057382,
  1057383,
  1057384,
  1057385,
  1057376,
  1057377,
  1057378,
  1057379,
  1057380,
  1060320,
  1060319,
  1060318,
  1060317,
  1060316,
  1060325,
  1060324,
  1060323,
  1060322,
  1060321,
  1080435,
  1080434,
  1080433,
  1080432,
  1080431,
  1080425,
  1080424,
  1080423,
  1080422,
  1080421,
  1081215,
  1081214,
  1081213,
  1081212,
  1081211,
  1081220,
  1081219,
  1081218,
  1081217,
  1081216,
  1084656,
  1084657,
  1084658,
  1084659,
  1084660,
  1084661,
  1084662,
  1084663,
  1084664,
  1084665,
  1086785,
  1086784,
  1086783,
  1086782,
  1086781,
  1086790,
  1086789,
  1086788,
  1086787,
  1086786,
  1089371,
  1089372,
  1089373,
  1089374,
  1089375,
  1089366,
  1089367,
  1089368,
  1089369,
  1089370,
  1089711,
  1089712,
  1089713,
  1089714,
  1089715,
  1089721,
  1089722,
  1089723,
  1089724,
  1089725,
  1089716,
  1089717,
  1089718,
  1089719,
  1089720,
  1090845,
  1090844,
  1090843,
  1090842,
  1090841,
  1091560,
  1091559,
  1091558,
  1091557,
  1091556,
  1120320,
  1120319,
  1120318,
  1120317,
  1120316,
  1120315,
  1120314,
  1120313,
  1120312,
  1120311,
  1125405,
  1125404,
  1125403,
  1125402,
  1125401,
  1125400,
  1125399,
  1125398,
  1125397,
  1125396,
  1125410,
  1125409,
  1125408,
  1125407,
  1125406,
  1134610,
  1134609,
  1134608,
  1134607,
  1134606,
  1134535,
  1134534,
  1134533,
  1134532,
  1134531,
  1134545,
  1134544,
  1134543,
  1134542,
  1134541,
  1134540,
  1134539,
  1134538,
  1134537,
  1134536,
  1134530,
  1134529,
  1134528,
  1134527,
  1134526,
  1200875,
  1200874,
  1200873,
  1200872,
  1200871,
  1200950,
  1200949,
  1200948,
  1200947,
  1200946,
  1200880,
  1200879,
  1200878,
  1200877,
  1200876,
  1348295,
  1348294,
  1348293,
  1348292,
  1348291,
  1348265,
  1348264,
  1348263,
  1348262,
  1348261,
  1375801,
  1375802,
  1375803,
  1375804,
  1375805,
  1375796,
  1375797,
  1375798,
  1375799,
  1375800,
  1382076,
  1382077,
  1382078,
  1382079,
  1382080,
  1382081,
  1382082,
  1382083,
  1382084,
  1382085,
  1391756,
  1391757,
  1391758,
  1391759,
  1391760,
  1391771,
  1391772,
  1391773,
  1391774,
  1391775,
  1391761,
  1391762,
  1391763,
  1391764,
  1391765,
  1402286,
  1402287,
  1402288,
  1402289,
  1402290,
  1402281,
  1402282,
  1402283,
  1402284,
  1402285,
  1698303,
  1698304,
  1698305,
  1698306,
  1698307,
  1926833,
  1926832,
  1926831,
  1926830,
  1926829,
  1926838,
  1926837,
  1926836,
  1926835,
  1926834,
  1967110,
  1967109,
  1967108,
  1967107,
  1967106,
  1971603,
  1971602,
  1971601,
  1971600,
  1971599,
  2018433,
  2018432,
  2018431,
  2018430,
  2018429,
  2019403,
  2019402,
  2019401,
  2019400,
  2019399,
  2019393,
  2019392,
  2019391,
  2019390,
  2019389,
  2019423,
  2019422,
  2019421,
  2019420,
  2019419,
  2019428,
  2019427,
  2019426,
  2019425,
  2019424,
  2018488,
  2018487,
  2018486,
  2018485,
  2018484,
  2024139,
  2024138,
  2024137,
  2024136,
  2024135,
  2026280,
  2026279,
  2026278,
  2026277,
  2026276,
  2027829,
  2027828,
  2027827,
  2027826,
  2027825,
  2028788,
  2028787,
  2028786,
  2028785,
  2028784,
  2037142,
  2037141,
  2037140,
  2037139,
  2037138,
  2149907,
  2149906,
  2149905,
  2149904,
  2149903,
  2149947,
  2149946,
  2149945,
  2149944,
  2149943,
  2150002,
  2150001,
  2150000,
  2149999,
  2149998,
  2190127,
  2190126,
  2190125,
  2190124,
  2190123,
  2568776,
  2568775,
  2568774,
  2568773,
  2568772,
  2568781,
  2568780,
  2568779,
  2568778,
  2568777,
  2568636,
  2568635,
  2568634,
  2568633,
  2568632,
  2568641,
  2568640,
  2568639,
  2568638,
  2568637,
  3094217,
  3094218,
  3094219,
  3094220,
  3094221,
  3097879,
  3097880,
  3097881,
  3097882,
  3097883,
  3097658,
  3097659,
  3097660,
  3097661,
  3097662,
  3097663,
  3097664,
  3097665,
  3097666,
  3097667,
  3097670,
  3097671,
  3097672,
  3097673,
  3097674,
  3097675,
  3097676,
  3097677,
  3097678,
  3097679,
  3097680,
  3097681,
  3097682,
  3097683,
  3097684,
  3097685,
  3097686,
  3097687,
  3097688,
  3097689,
  3127669,
  3127670,
  3127671,
  3127672,
  3127673,
  3191411,
  3191410,
  3191409,
  3191408,
  3191407,
  3191416,
  3191415,
  3191414,
  3191413,
  3191412,
  3816643,
  3816642,
  3816641,
  3816640,
  3816639,
  3816644,
  3816645,
  3816646,
  3816647,
  3816648,
  3764170,
  3764171,
  3764172,
  3764173,
  3764174,
  3760747,
  3760748,
  3760749,
  3760750,
  3760751,
  3783962,
  3783963,
  3783964,
  3783965,
  3783966,
  3783967,
  3783968,
  3783969,
  3783970,
  3783971,
  3783972,
  3783973,
  3783974,
  3783975,
  3783976,
  3783977,
  3783978,
  3783979,
  3783980,
  3783981,
  3783982,
  3783983,
  3783984,
  3783985,
  3783986,
  3783987,
  3783988,
  3783989,
  3783990,
  3783991,
  3783992,
  3783993,
  3783994,
  3783995,
  3783996,
  3783997,
  3783998,
  3783999,
  3784000,
  3784001,
  3784002,
  3784003,
  3784004,
  3784005,
  3784006,
  3784007,
  3784008,
  3784009,
  3784010,
  3784011,
  3784017,
  3784018,
  3784019,
  3784020,
  3784021,
  3784022,
  3784023,
  3784024,
  3784025,
  3784026,
  3784027,
  3784028,
  3784029,
  3784030,
  3784031,
  3784032,
  3784033,
  3784034,
  3784035,
  3784036,
  3784037,
  3784038,
  3784039,
  3784040,
  3784041,
  3784042,
  3784043,
  3784044,
  3784045,
  3784046,
  3784047,
  3784048,
  3784049,
  3784050,
  3784051,
  3784052,
  3784053,
  3784054,
  3784055,
  3784056,
  3784057,
  3784058,
  3784059,
  3784060,
  3784061,
  3784062,
  3784063,
  3784064,
  3784065,
  3784066,
  3760752,
  3760753,
  3760754,
  3760755,
  3760756,
  3834267,
  3834268,
  3834269,
  3834270,
  3834271,
  3834272,
  3834273,
  3834274,
  3834275,
  3834276,
  3834798,
  3834799,
  3834800,
  3834801,
  3834802,
  3841867,
  3841866,
  3841865,
  3841864,
  3841863,
  3841872,
  3841871,
  3841870,
  3841869,
  3841868,
  3843939,
  3843940,
  3843941,
  3843942,
  3843943,
  3843944,
  3843945,
  3843946,
  3843947,
  3843948,
  4026869,
  4026870,
  4026871,
  4026872,
  4026873,
  4026874,
  4026875,
  4026876,
  4026877,
  4026878,
  4026879,
  4026880,
  4026881,
  4026882,
  4026883,
  4026884,
  4026885,
  4026886,
  4026887,
  4026888,
  2221039,
  2221038,
  2221037,
  2221036,
  2221035,
  2221025,
  2221024,
  2221023,
  2221022,
  2221021,
  4035306,
  4035305,
  4035304,
  4035303,
  4035302,
  4035316,
  4035315,
  4035314,
  4035313,
  4035312,
  4032772,
  4032771,
  4032770,
  4032769,
  4032768,
  4142927,
  4142928,
  4142929,
  4142930,
  4142931,
  4142922,
  4142923,
  4142924,
  4142925,
  4142926,
  4108839,
  4108840,
  4108841,
  4108842,
  4108843,
  4108854,
  4108855,
  4108856,
  4108857,
  4108858,
  4153601,
  4153602,
  4153603,
  4153604,
  4153605,
  4163151,
  4163152,
  4163153,
  4163154,
  4163155,
  4163156,
  4163157,
  4163158,
  4163159,
  4163160,
  4163120,
  4163119,
  4163118,
  4163117,
  4163116,
  4242504,
  4242505,
  4242506,
  4242507,
  4242508,
  4252925,
  4252926,
  4252927,
  4252928,
  4252929,
  4252910,
  4252911,
  4252912,
  4252913,
  4252914,
  4433053,
  4433054,
  4433055,
  4433056,
  4433057,
  4433058,
  4433059,
  4433060,
  4433061,
  4433062,
  4513254,
  4513255,
  4513256,
  4513257,
  4513258,
  4455198,
  4455199,
  4455200,
  4455201,
  4455202,
  4455203,
  4455204,
  4455205,
  4455206,
  4455207,
  4682292,
  4682293,
  4682294,
  4682295,
  4682296,
  4703615,
  4703616,
  4703617,
  4703618,
  4703619,
  4703620,
  4703621,
  4703622,
  4703623,
  4703624,
  4841181,
  4841182,
  4841183,
  4841184,
  4841185,
  4841186,
  4841187,
  4841188,
  4841189,
  4841190,
  4961006,
  4961007,
  4961008,
  4961009,
  4961010,
  4961016,
  4961017,
  4961018,
  4961019,
  4961020,
  4966439,
  4966440,
  4966441,
  4966442,
  4966443,
  4966444,
  4966445,
  4966446,
  4966447,
  4966448,
  5104145,
  5104146,
  5104147,
  5104148,
  5104149,
  5104150,
  5104151,
  5104152,
  5104153,
  5104154,
  5125854,
  5125855,
  5125856,
  5125857,
  5125858,
  5125859,
  5125860,
  5125861,
  5125862,
  5125863,
  5125864,
  5125865,
  5125866,
  5125867,
  5125868,
  5126515,
  5126516,
  5126517,
  5126518,
  5126519,
  5126520,
  5126521,
  5126522,
  5126523,
  5126524,
  5126092,
  5126093,
  5126094,
  5126095,
  5126096,
  5126097,
  5126098,
  5126099,
  5126100,
  5126101,
  5469166,
  5469167,
  5469168,
  5469169,
  5469170,
  5469171,
  5469172,
  5469173,
  5469174,
  5469175,
  5790878,
  5790879,
  5790880,
  5790881,
  5790882,
  5790883,
  5790884,
  5790885,
  5790886,
  5790887,
  5834050,
  5834051,
  5834052,
  5834053,
  5834054,
  5834055,
  5834056,
  5834057,
  5834058,
  5834059,
  5971272,
  5971273,
  5971274,
  5971275,
  5971276,
  5971277,
  5971278,
  5971279,
  5971280,
  5971281,
  6018287,
  6018288,
  6018289,
  6018290,
  6018291,
  6121630,
  6121631,
  6121632,
  6121633,
  6121634,
  6133561,
  6133562,
  6133563,
  6133564,
  6133565,
  6133611,
  6133612,
  6133613,
  6133614,
  6133615,
  6134401,
  6134402,
  6134403,
  6134404,
  6134405,
  6200724,
  6200725,
  6200726,
  6200727,
  6200728,
  6200729,
  6200730,
  6200731,
  6200732,
  6200733,
];

export const HIGH_RES_STREAM_IDS = [
  3710246,
  3710245,
  3710244,
  3710243,
  3710242,
  3710235,
  3710234,
  3710233,
  3710232,
  3710231,
  5711058,
  5711057,
  5711056,
  5711055,
  5711054,
  5706485,
  5706484,
  5706483,
  5706482,
  5706481,
  5963818,
  5963819,
  5963820,
  5963821,
  5963822,
  5963828,
  5963829,
  5963830, 
  5963831,
  5963832,
  6258371,
  6258372,
  6258373,
  6258374,
  6258375,
  6258376,
  6258377,
  6258378,
  6258379,
  6258380,

];

export const VJ_15FPS = [
  5849197,
  5849196,
  5849195,
  5849194,
  5849193,
  5849177,
  5849176,
  5849175,
  5849174,
  5849173,
  5849187,
  5849186,
  5849185,
  5849184,
  5849183
];

export const _30FPS_USER_STREAM_EVENT_IDS: string[] = [
  // Pre-prod
  '397c7927-c826-4ec3-8b0f-9dca4a7e65f9',

  // Prod
  // Sprinto
  'eec0f15b-30b9-4437-8daa-86b99fda6634',
  '7d036d69-a7c5-49f9-94fc-a50cb9c2dbe4',
];

export const _DIAL_IN_UIDS = [
  // Master
  1552883,
  // Expensify Prod
  5706603,
  5706604,
  5706605,
  5706606,
  5706607,
  5705925,
  5705924,
  5705923,
  5705922,
  5705921,
  5981742,
  5981743,
  5981744,
  5981745,
  5981746,
  6004520,
  6004521,
  6004522,
  6004523,
  6004524,
  6258700,
  6258701,
  6258702,
  6258703,
  6258704,
  6260241,
  6260242,
  6260243,
  6260244,
  6260245,


  
  // Local Vedha Test
  5973968,
  5973969,
  5973970,
  5973971,
  5973972,
  5973973,
  5973974,
  5973975,
  5973976,
  5973977,
];
