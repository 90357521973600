import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const MapPinUserFill = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className={fillClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 10.25C3.75 6.125 6.5 2 12.0007 2C17.5 2 20.25 6.125 20.25 10.25C20.25 14.375 12.0007 24 12.0007 24C12.0007 24 3.75 14.375 3.75 10.25ZM9.25 10.25C9.25 11.7688 10.4812 13 12 13C13.5188 12.9999 14.7499 11.7688 14.75 10.25C14.75 8.73122 13.5188 7.5 12 7.5C10.4812 7.5 9.25 8.73122 9.25 10.25Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default MapPinUserFill;
