import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Filter = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 14 } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.71855 12.3848V9.0923L13.0414 3.76943C13.3107 3.5002 13.4621 3.13476 13.4621 2.75353V0.897636C13.4621 0.500611 13.1412 0.179688 12.7442 0.179688H1.25701C0.859986 0.179688 0.539062 0.500611 0.539062 0.897636V2.75353C0.539062 3.13476 0.69055 3.5002 0.95978 3.76943L6.28265 9.0923V13.8207L7.71855 12.3848Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default Filter;
