import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const BarChartFilled = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 12 } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size + 1}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 7.00016H2.66667V13.0002H0V7.00016ZM9.33333 4.3335H12V13.0002H9.33333V4.3335ZM4.66667 0.333496H7.33333V13.0002H4.66667V0.333496Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default BarChartFilled;
