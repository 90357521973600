import { CHANGE_HEALTHCARE_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';

const changeHealthcare = {
    name: 'changeHealthcare',
    auth: {
      magicLinks: true,
    },
    boothConfig: {
      '09f774ec-2b84-46a0-a0a8-3fd56f4975c3': CHANGE_HEALTHCARE_PHOTOBOOTH,
      // local
      '928548f3-6859-4807-887b-7e9aa9ec11ad': CHANGE_HEALTHCARE_PHOTOBOOTH,
    },
    
  };


  export default changeHealthcare;