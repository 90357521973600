import { BOMBALIVE_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';

const  bombaLive = {
    name: ' bombaLive',
    auth: {
      magicLinks: true,
    },
    boothConfig: {
        'afed3f52-f82b-48a5-80b7-bc59a6456766': BOMBALIVE_PHOTOBOOTH,

        // local
        '928548f3-6859-4807-887b-7e9aa9ec11ad': BOMBALIVE_PHOTOBOOTH,
    },
    
  };


  export default  bombaLive;