import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';
import { DSCI_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';

const dsci = {
  name: 'dsci',
  auth: {
    magicLinks: true,
  },
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      if (numStreams <= 3) {
        return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
      } else {
        return IStreamsDisplayMode.SPOTLIGHT;
      }
    }

    if (numStreams <= 3) {
      return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
    } else {
      return IStreamsDisplayMode.CONDENSED;
    }
  },

  boothConfig: {
    '6bc03552-fab6-42e2-b3e2-cdff643a02dd': DSCI_PHOTOBOOTH,

    // local
    '928548f3-6859-4807-887b-7e9aa9ec11ad': DSCI_PHOTOBOOTH,
  },

};
export default dsci;
