import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Wifi = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 34 } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size - 6}
      viewBox="0 0 35 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.126539 6.79852C4.89107 2.86927 10.863 0.699964 17.0386 0.655206C23.4658 0.599117 29.3877 2.79302 34.0552 6.50243L31.2584 10.0265C27.2313 6.82229 22.2239 5.1021 17.0778 5.15504C11.723 5.20177 6.81957 7.11663 2.98447 10.2732L0.126539 6.79852ZM4.88878 12.5922C8.33004 9.75404 12.6435 8.18716 17.104 8.15492C21.7448 8.11442 26.0218 9.69866 29.3948 12.3768L26.598 15.9024C23.913 13.766 20.5743 12.6192 17.1433 12.6548C13.5734 12.6859 10.3054 13.9625 7.74671 16.0669L4.88877 12.5907L4.88878 12.5922ZM9.65252 18.3858C11.7701 16.6391 14.4245 15.6747 17.1695 15.6546C20.0254 15.6297 22.6575 16.6043 24.7329 18.2527L21.9361 21.7783C20.5935 20.7102 18.9242 20.1368 17.2087 20.1545C15.4932 20.1668 13.8342 20.7692 12.5104 21.8605L9.65251 18.3843L9.65252 18.3858ZM14.4163 24.1795C15.2101 23.5241 16.2055 23.1621 17.2349 23.1544C18.3059 23.145 19.2931 23.5114 20.071 24.1286L17.2742 27.6542L14.4162 24.178L14.4163 24.1795Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default Wifi;
