import { useState, useEffect } from 'react';
import api from '@/api';
import { ITicketTypeBasicInfo } from '@/models/event/types';

const useGetAllTicketTypes = (eventId: string) => {
  const [eventTicketTypeList, setEventTicketTypeList] = useState<
    ITicketTypeBasicInfo[] | []
  >([]);
  useEffect(() => {
    if (!eventId) {
      return;
    }
    api.ticketType.getAllTicketTypesByEventId(eventId).then(res => {
      if (res && res.data) {
        setEventTicketTypeList(res.data);
      }
    });
  }, [eventId]);

  return eventTicketTypeList;
};

export default useGetAllTicketTypes;
