import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const indiaMobileCongressNew = {
  name: 'IndiaMobileCongress',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.INDIAMOBILECONGRESS_EXPO_OUTER1,

};

export default indiaMobileCongressNew;
