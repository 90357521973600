import React, { useRef, useState } from 'react';
// components
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import Loader from '../../Loader';
import IconTextButton from '../../buttons/IconTextButton';
// styles
import classnames from 'classnames';
import styles from './styles.module.scss';
import { IUIIcons } from '@/types';
import ReactPlayerWrapper from './ReactPlayerWrapper';
import AutoplayClickOverlay from '../../AutoplayClickOverlay';
import { useUnmount } from 'react-use';

const { VOLUME_OFF, VOLUME_ON } = IUIIcons;

const Video = ({
  // general
  showLoader = false,
  className,
  styleClass,
  deletable,
  onDelete,
  resizeProps,
  playsinline,
  // Props for React Player
  url = '',
  autoplay = true,
  controls = false,
  loop = false,
  muted = true,
  startTime,
  onPlay,
  onPause,
  onEnded,
  onLoad,
  showAutoplayOverlay,
  onUnmount,
  seekToEndWhenEnded,
  toggleVolume,
  shouldTrack,
  trackingMetadata = {},
  showTimer = false,
  overrideFileConfig = {},
}) => {
  const [ready, setReady] = useState(false);
  const reactPlayerWrapperRef = useRef();

  const [manualMute, setManualMute] = useState();
  const [playAfterMute, setPlayAfterMute] = useState();
  const toggleManualMute = e => {
    setPlayAfterMute(true);
    setManualMute(false);
    reactPlayerWrapperRef?.current?.handleUnmute();
    muted && toggleVolume && toggleVolume();
  };

  const handleErrorFallbackLight = (e, data, _hls, _Hls) => {
    console.error(
      'debugVideo > handleErrorFallbackLight > ',
      JSON.stringify(e),
    );
    if (e === 'hlsError') {
      const { type, details, fatal } = data;
      console.error('debugVideo > hlsError 1 > ', type, details, fatal);
      if (fatal) {
        switch (type) {
          case _Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            console.error(
              'debugVideo > hlsError 2 > fatal network error encountered, try to recover',
              e,
            );
            _hls.startLoad();
            break;
          case _Hls.ErrorTypes.MEDIA_ERROR:
            console.error(
              'debugVideo > hlsError 3 > fatal media error encountered, try to recover',
              e,
            );
            _hls.recoverMediaError();
            break;
          default:
            // cannot recover
            console.error(
              'debugVideo > hlsError 4 > fatal error, could not recover',
              e,
            );
            break;
        }
      }
    } else if (e instanceof DOMException) {
      console.error('debugVideo > handleErrorFallbackLight > DOMException ', e);
      setPlayAfterMute(false);
      setManualMute(true);
    }
  };

  const [showVideo, setShowVideo] = useState(false);

  const handleOnLoad = e => {
    if (!e) return;
    e.preventDefault();
    onLoad && onLoad();
  };

  const handleReady = () => {
    setReady(true);
  };

  const handleVideoDelete = () => {
    onDelete();
    setShowVideo(!showVideo);
  };

  useUnmount(() => onUnmount && onUnmount());

  const videoElement = (
    <div
      className={classnames(
        className,
        styles.playerContainer,
        styles[styleClass],
      )}
    >
      {!ready && showLoader && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
      <div
        className={classnames(styles.videoContainer, {
          [styles.displayNone]: !ready,
        })}
      >
        {manualMute && showAutoplayOverlay && (
          <AutoplayClickOverlay onClick={toggleManualMute} />
        )}
        {manualMute && !showAutoplayOverlay && (
          <div className={styles.volumeControl}>
            <IconTextButton
              key="micButton"
              activeIcon={VOLUME_OFF}
              defaultIcon={VOLUME_ON}
              activeLabel="Tap to Unmute"
              defaultLabel="Tap to Unmute"
              onClick={toggleManualMute}
              showDefault={!manualMute}
              type={IButtonTypes.PRIMARY_SQUARE_SMALL}
            />
          </div>
        )}
        <ReactPlayerWrapper
          ref={reactPlayerWrapperRef}
          url={url}
          loop={loop}
          controls={controls}
          muted={manualMute !== undefined ? manualMute || muted : muted}
          autoplay={manualMute ? playAfterMute : autoplay}
          startTime={startTime}
          playsinline={playsinline}
          onPlay={onPlay}
          onPause={onPause}
          onEnded={onEnded}
          onReady={handleReady}
          onLoad={handleOnLoad}
          onError={handleErrorFallbackLight}
          seekToEndWhenEnded={seekToEndWhenEnded}
          shouldTrack={shouldTrack}
          trackingMetadata={trackingMetadata}
          showTimer={showTimer}
          overrideFileConfig={overrideFileConfig}
        />
      </div>
    </div>
  );

  if (showVideo) {
    return (
      <div
        className={styles[styleClass]}
        style={resizeProps && { height: resizeProps.height / 10 + 'vh' }}
      >
        {deletable ? (
          <Button
            label="Upload New Video"
            type="button"
            styleClass={IButtonTypes.NO_BG_BLACK_SQUARE}
            onClick={handleVideoDelete}
            className={styles.videoDeleteButton}
          />
        ) : null}
        {videoElement}
      </div>
    );
  }

  return <>{videoElement}</>;
};

export default Video;
