import { GoogleSB_PHOTOBOOTH1,
  GoogleSB_PHOTOBOOTH2,
  GoogleSB_Expo,
 } from '@/components/custom/layouts/ManualLayout/config';

const GoogleSBconfig = {
  name: 'GoogleSBonfig',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: GoogleSB_Expo,
  boothConfig: {
    'a778f49e-1505-4ded-b68f-968bd2ef2f07': GoogleSB_PHOTOBOOTH1,
    'e1926254-29ba-47ad-856c-a7d2bbe3a9b9': GoogleSB_PHOTOBOOTH2,
 },

};

export default GoogleSBconfig;
