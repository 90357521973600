import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const MicOff = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.922 7.77062C24.794 7.64262 24.538 7.51462 24.346 7.51462C24.154 7.51462 23.962 7.57862 23.77 7.77062L20.378 11.1626L13.018 18.5866L11.802 19.8026L10.586 21.0186L8.41004 23.1946C8.09004 23.5146 8.09004 24.0266 8.41004 24.3466C8.60204 24.4746 8.79404 24.6026 8.98604 24.6026C9.17804 24.6026 9.37004 24.5386 9.56204 24.3466L12.826 21.0826L13.85 20.0586L20.506 13.4666L24.986 8.98662C25.242 8.60262 25.242 8.09062 24.922 7.77062Z"
        fill="#FF4C4C"
      />
      <path
        d="M20.4421 7.89862C20.4421 7.38662 20.0581 7.00262 19.5461 7.00262H13.9141C13.4021 7.00262 13.0181 7.38662 13.0181 7.89862V16.7306L20.4421 9.30662V7.89862Z"
        className={fillClassName}
      />
      <path
        d="M20.4421 19.0986V15.1946L15.6421 19.9946H19.5461C20.0581 19.9946 20.4421 19.6106 20.4421 19.0986Z"
        className={fillClassName}
      />
      <path
        d="M11.738 18.0746V15.6426C11.738 15.1946 11.354 14.8106 10.906 14.8106C10.458 14.8106 10.074 15.1946 10.074 15.6426V19.2906C10.074 19.4186 10.074 19.5466 10.074 19.6106L11.738 18.0746Z"
        className={fillClassName}
      />
      <path
        d="M22.5541 14.8106C22.1061 14.8106 21.7221 15.1946 21.7221 15.6426V19.2906C21.7221 20.3786 20.826 21.2106 19.738 21.2106H14.362L12.826 22.7466C13.082 22.8106 13.3381 22.8106 13.5941 22.8106H15.8341V25.4986C15.8341 25.9466 16.218 26.3306 16.666 26.3306C17.114 26.3306 17.498 25.9466 17.498 25.4986V22.8106H19.738C21.722 22.8106 23.3221 21.2106 23.3221 19.2906V15.6426C23.3221 15.1946 22.9381 14.8106 22.5541 14.8106Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default MicOff;
