import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const StopStream = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 15.5C3.35775 15.5 0 12.1423 0 8C0 3.85775 3.35775 0.5 7.5 0.5C11.6423 0.5 15 3.85775 15 8C15 12.1423 11.6423 15.5 7.5 15.5ZM7.5 14C9.0913 14 10.6174 13.3679 11.7426 12.2426C12.8679 11.1174 13.5 9.5913 13.5 8C13.5 6.4087 12.8679 4.88258 11.7426 3.75736C10.6174 2.63214 9.0913 2 7.5 2C5.9087 2 4.38258 2.63214 3.25736 3.75736C2.13214 4.88258 1.5 6.4087 1.5 8C1.5 9.5913 2.13214 11.1174 3.25736 12.2426C4.38258 13.3679 5.9087 14 7.5 14ZM5.25 5.75H9.75V10.25H5.25V5.75Z" fill="white" />
    </svg>
  );
};

export default StopStream;