import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const Veeco1config = {
  name: 'Veeco1config',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.Veeco1_Expo,


};

export default Veeco1config;

