import React, { useState } from 'react';
import classnames from 'classnames';
// components
import Label from '@/components/ui/content/Label';
import FormValidationErrorTooltip from '@/components/ui/FormValidationErrorTooltip';
// styles + types
import styles from './styles.module.scss';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';

const NumberField = props => {
  const {
    error,
    isLastElement,
    label,
    onChange,
    onClick,
    placeholder,
    readOnly,
    styleClass = '',
    submitFailed,
    value,
    widthStyles = '',
    className = '',
    validate,
    tooltip,
    // Setting the default, max limit of number fields as 50 for now
    maxLength = 50,
  } = props;

  const [showMaxLengthExceededError, setShowMaxLengthExceededError] = useState(
    false,
  );

  const [numberFieldValue, setNumberFieldValue] = useState() as any;

  const validationError = (() => {
    if (error && submitFailed === undefined) {
      // with out Form Section
      return true;
    }
    if (error && submitFailed === true) {
      // with Form Section
      return true;
    }
    return false;
  })();

  // set up necessary style classes
  const fullContainerWidth = `${styles[widthStyles]}`;
  const fullContainerPositioning = `${styles.container}`;
  const marginRightClass = `${styles.addMarginRight}`;
  const containerWithValidationMessage = classnames(
    fullContainerWidth,
    fullContainerPositioning,
    { [marginRightClass]: !isLastElement }
  );

  const validationErrorBorder = `${styles.validationError}`;
  const baseClass = 'inputField';
  const inputFieldBaseClass = classnames(
    `${styles[
    label ?
      `${baseClass}`
      : `${baseClass}NoLabel`
    ]}`,
    { [validationErrorBorder]: validationError },
  );
  const styleClassExists = !!styleClass;
  const inputFieldClass = classnames(
    { [inputFieldBaseClass]: !styleClassExists },
    { [`${styles[styleClass]}`]: styleClassExists },
    { [className]: className }
  );

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  function stripNonDigitCharacters(inputValue) {
    return inputValue.replace(/[^0-9]/g, '').replace(/\s/g, '');
  }

  const handleChangeInput = event => {
    setNumberFieldValue(event.target.value);
    const inputValue = event.target.value;
    // Strip any input that is not a digit
    if (!isNumeric(inputValue)) {
      event.target.value = stripNonDigitCharacters(inputValue);
    }

    // Restrict input to maxLength
    if (inputValue.length >= event.target.maxLength) {
      event.target.value = inputValue.slice(0, event.target.maxLength);
      setShowMaxLengthExceededError(true);
    } else {
      setShowMaxLengthExceededError(false);
    }
  };

  const handleOnKeyUp = event => {
    // Detect if anything was pasted into the field; strip all non-digit characters
    const charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey && charCode === 'v') {
      event.target.value = stripNonDigitCharacters(event.target.value);
    }
  };

  // showing validation error works without inner div around textarea
  // because label is positioned absolutely
  return (
    <div className={containerWithValidationMessage}>
      <div className={styles.titleLabelContainer}>
        {
          label && label.length ? (
            <Label
              value={
                validate
                  ? `${label}*`
                  : label
              }
              styleClass="fieldLabelTitleText"
            />
          ) : null
        }
        {/* if placeholder and no label, we use placeholder as both placeholder and label */}
        {
          !label && placeholder &&
          <Label
            value={placeholder}
            styleClass={numberFieldValue && numberFieldValue.length ? 'fieldLabelTitleTextFocus' : 'fieldLabelTitleTextWithoutFocus'}
          />
        }
        {
          label && label.length && tooltip ? (
            <TooltipDiv dataFor={`tooltip_input_number_${label}`} tooltipText={tooltip} />
          ) : null
        }
      </div>
      <input
        className={inputFieldClass}
        defaultValue={value}
        disabled={readOnly}
        onBlur={onChange}
        onClick={onClick}
        onChange={handleChangeInput}
        onKeyUp={handleOnKeyUp}
        placeholder={label ? placeholder : ''}
        tabIndex={0}
        type="text"
        maxLength={maxLength}
      />
      {showMaxLengthExceededError && !validationError && (
        <FormValidationErrorTooltip value="Max Character Limit Reached!" />
      )}
      {validationError && !showMaxLengthExceededError && <FormValidationErrorTooltip value={error} />}
    </div>
  );
};

export default React.memo(NumberField);
