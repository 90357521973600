import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const DoubleTickIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 16 9" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.73463 5.67336L8.67596 6.61469L14.32 0.970689L15.2626 1.91336L8.67596 8.50002L4.43329 4.25736L5.37596 3.31469L6.79263 4.73136L7.73463 5.67269V5.67336ZM7.73596 3.78802L11.0373 0.486023L11.9773 1.42602L8.67596 4.72802L7.73596 3.78802ZM5.85129 7.55802L4.90929 8.50002L0.666626 4.25736L1.60929 3.31469L2.55129 4.25669L2.55063 4.25736L5.85129 7.55802Z" className={fillClassName} />
</svg>


  );
};

export default DoubleTickIcon;
