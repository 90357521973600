import React, { useState } from 'react';
import classnames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
// components
import DatePicker from 'react-datepicker';
import Label from '@/components/ui/content/Label';
// styles + types
import styles from './styles.module.scss';
import { IDateTimeFieldProps } from './types';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';

const DateTimeField = (props: IDateTimeFieldProps) => {
  // manually set focus state shadow on field
  const [showShadow, setShowShadow] = useState(false);
  // set default date to 6:30 PM today if it doesn't exist
  const today = new Date();

  let { value: fieldValue = new Date(today.setHours(18, 0, 0, 0)) } = props;

  const {
    error,
    isLastElement,
    label,
    onChange,
    readOnly,
    submitFailed,
    timeIntervals = 30,
    widthStyles = '',
    validate
  } = props;
  const validationError = error && submitFailed;

  // datepicker needs a date to work properly
  if (typeof fieldValue === 'string') {
    fieldValue = Date.parse(fieldValue);
  }

  // backend needs a string with timezone
  // convert before saving to form state
  const handleOnChange = (value) => {
    if (typeof value !== 'string') {
      value = new Date(value);
    }
    onChange(value);
    setShowShadow(false);
  }

  const handleContainerClick = (parentOnClick) => {
    parentOnClick();
    setShowShadow(true);
  }

  const handleContainerBlur = (parentOnBlur) => {
    parentOnBlur();
    setShowShadow(false);
  }

  // set up necessary style classes
  const fullContainerWidth = `${styles[widthStyles]}`;
  const marginRightClass = `${styles.addMarginRight}`;
  const showFocusShadowClass = `${styles.focusShadow}`;
  const containerWithValidationMessage = classnames(
    fullContainerWidth,
    { [marginRightClass]: !isLastElement },
    { [showFocusShadowClass]: showShadow }
  );

  const innerContainer = `${styles.innerContainer}`;
  const validationErrorBorder = `${styles.validationError}`;
  const innerContainerWithValidationBorder = classnames(
    innerContainer,
    { [validationErrorBorder]: validationError },
  );

  const customComponentContainer = `${styles.customComponentContainer}`;
  const CustomField = allAttrs => (
    <div
      className={customComponentContainer}
      onClick={() => handleContainerClick(allAttrs.onClick)}
      onBlur={() => handleContainerBlur(allAttrs.onBlur)}
    >
      <div className={innerContainerWithValidationBorder}>
        {label && label.length ? (
          <Label
            value={
              validate
                ? `${label}*`
                : label
            }
            styleClass="dateField"
          />
        ) : null}

        {allAttrs.value}
      </div>
      {validationError && <FormValidationErrorTooltip value={error} />}
    </div>
  );

  return (
    <div className={containerWithValidationMessage}>
      <DatePicker
        customInput={<CustomField />}
        dateFormat="MMMM dd, yyyy h:mm aa"
        onChange={handleOnChange}
        readOnly={readOnly}
        selected={fieldValue}
        showTimeSelect
        timeCaption="Time"
        timeFormat="HH:mm"
        timeIntervals={timeIntervals}
        withPortal={true}
        minDate={new Date(props && props.initialState)}
        maxDate={new Date(props && props.eventEndTime)}
      />
    </div>
  );
};

export default DateTimeField;
