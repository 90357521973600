// components
import DateTimeField from '@/components/ui/fields/DateTimeField';
import FileUploadField from '@/components/ui/fields/FileUploadField';
import ImageUploadField from '@/components/ui/fields/ImageUploadFieldNew';
import InputField from '@/components/ui/fields/InputField';
import InputListField from '@/components/ui/fields/InputListField';
import PasswordField from '@/components/ui/fields/PasswordField';
import PasswordFieldNew from '@/components/ui/fields/PasswordFieldNew';

import SelectField from '@/components/ui/fields/SelectField';
import TextAreaField from '@/components/ui/fields/TextAreaField';
import NumberField from '@/components/ui/fields/NumberField';
import VideoUploadFieldNew from '@/components/ui/fields/VideoUploadFieldNew';
import NewInputField from '@/components/ui/NewFormComponents/InputField';
import NewSelectField from '@/components/ui/NewFormComponents/SelectField';
import ColorPickerField from '@/components/ui/NewFormComponents/ColorPickerField';
import NewImageUploadField from '@/components/ui/NewFormComponents/ImageUploadField';
import MultiSelectField from '@/components/ui/fields/MultiSelectField';
import LandingPageInput from '@/components/ui/NewFormComponents/LandingPageInput';
import NewTextAreaField from '@/components/ui/NewFormComponents/TextAreaField';
import { DateTimePickerField } from '../../NewFormComponents';
import DatePickerField from '../../NewFormComponents/DatePickerField';
import CheckBoxesField from '@/components/ui/NewFormComponents/CheckBoxesField';

// types
import { IFormFieldTypes } from '@/types/form';
const {
  DATE,
  DATETIME,
  FILE,
  IMAGE,
  INPUT_LIST,
  INPUT,
  MULTISELECT,
  CHECKBOXES,
  PASSWORD,
  SELECT,
  TEXTAREA,
  NUMBER,
  NEW_IMAGE_UPLOAD_FIELD,
  VIDEO,
  NEW_INPUT,
  COLOR_PICKER_FIELD,
  NEW_PASSWORD,
  LANDING_PAGE_INPUT,
  NEW_SELECT,
  NEW_TEXTAREA,
} = IFormFieldTypes;

export const COMPONENT_MAP = {
  [DATETIME]: DateTimeField,
  [DATE]: DateTimePickerField,
  [FILE]: FileUploadField,
  [IMAGE]: ImageUploadField,
  [INPUT_LIST]: InputListField,
  [INPUT]: InputField,
  [PASSWORD]: PasswordField,
  // [MULTISELECT]: SelectField,
  [SELECT]: SelectField,
  [TEXTAREA]: TextAreaField,
  [NUMBER]: NumberField,
  [VIDEO]: VideoUploadFieldNew,
  [NEW_INPUT]: NewInputField,
  [COLOR_PICKER_FIELD]: ColorPickerField,
  [NEW_IMAGE_UPLOAD_FIELD]: NewImageUploadField,
  [MULTISELECT]: MultiSelectField,
  [CHECKBOXES]: CheckBoxesField,
  [NEW_PASSWORD]: PasswordFieldNew,
  [LANDING_PAGE_INPUT]: LandingPageInput,
  [NEW_SELECT]: NewSelectField,
  [NEW_TEXTAREA]: NewTextAreaField,
};
