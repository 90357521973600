// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__crossButton___2LTBC{position:absolute;top:0;right:4px;cursor:pointer;z-index:1}.styles-module__gifContainer___A8Fx3{position:relative;display:flex;justify-content:center;align-items:center;height:100%;width:100%;padding:12px}.styles-module__gif___35gOr{z-index:1;max-width:93%;max-height:98%}.styles-module__gifStillImage___2EIxt{filter:blur(16px);background-position:center;background-repeat:no-repeat;background-size:cover;height:100%;width:100%;position:absolute}\n", ""]);
// Exports
exports.locals = {
	"crossButton": "styles-module__crossButton___2LTBC",
	"gifContainer": "styles-module__gifContainer___A8Fx3",
	"gif": "styles-module__gif___35gOr",
	"gifStillImage": "styles-module__gifStillImage___2EIxt"
};
module.exports = exports;
