import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const neomsb = {
  name: 'neomsb',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.NeomSB_Expo,

};

export default neomsb;