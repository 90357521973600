import { useEffect, useRef } from 'react';

const stopTouchMove = e => {
  e.stopPropagation();
};

const removeBodyScroll = () => {
  document.body.style.overflow = 'hidden';
  document.addEventListener('touchmove', stopTouchMove, { passive: false });
};

const resetBodyScroll = (initialOverflowValue: string) => {
  document.body.style.overflow = initialOverflowValue;
  document.removeEventListener('touchmove', stopTouchMove);
};

const useLockBodyScroll = isOpen => {
  const initialOverflowValue = useRef(document.body.style.overflow);

  useEffect(() => {
    if (!isOpen) {
      return undefined;
    }
    removeBodyScroll();
    return () => resetBodyScroll(initialOverflowValue.current);
  }, [isOpen]);

  return null;
};

export default useLockBodyScroll;
