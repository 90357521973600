const isTieConEvent = (eventId, path) => {
  if (
    [
      // Unstoppable India | TiEcon Delhi-NCR 2022
      '57e39d4e-3231-470b-b423-0bc0adb7ad8c',
      // Karthik Local Test Event
      '6b0adbd9-bdb2-486a-be2a-331026559896',
    ].includes(eventId) &&
    path.includes('/p/a/event/')
  ) {
    return true;
  }
  return false;
};

export default isTieConEvent;
