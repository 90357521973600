import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const FileList = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 11 11" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
<path d="M9.83333 10.9168H1.16667C1.02301 10.9168 0.885233 10.8598 0.78365 10.7582C0.682068 10.6566 0.625 10.5188 0.625 10.3752V0.625163C0.625 0.481504 0.682068 0.343729 0.78365 0.242147C0.885233 0.140564 1.02301 0.0834961 1.16667 0.0834961H9.83333C9.97699 0.0834961 10.1148 0.140564 10.2164 0.242147C10.3179 0.343729 10.375 0.481504 10.375 0.625163V10.3752C10.375 10.5188 10.3179 10.6566 10.2164 10.7582C10.1148 10.8598 9.97699 10.9168 9.83333 10.9168ZM9.29167 9.8335V1.16683H1.70833V9.8335H9.29167ZM3.33333 2.79183H7.66667V3.87516H3.33333V2.79183ZM3.33333 4.9585H7.66667V6.04183H3.33333V4.9585ZM3.33333 7.12516H7.66667V8.2085H3.33333V7.12516Z" className={fillClassName}/>
</svg>

  );
};

export default FileList;
