import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const SandboxMSconfig = {
  name: 'SandboxMSconfig',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.SandboxMS_Expo,
  boothConfig: {
    'c998ef7c-26dd-433c-b128-b52ac4237041': ManualLayoutConfig.SandboxMS_PHOTOBOOTH,
 },


};

export default SandboxMSconfig;
