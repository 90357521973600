/* eslint-disable no-restricted-syntax */
import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  makeSelectPendingSegmentListByEventId,
  makeSelectEventById,
} from '@/models/event';
import { SessionBroadcastStatus } from '@/components/PublishedStreamDisplay/SessionSelectBar/types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { convertInAnotherTimeZone } from '@/utils/helpers';

export const useComputeRecordingSessionsList = () => {
  const dispatch = useDispatch();
  const { eventId = '', stageId = '' } = useParams<{
    eventId: string;
    stageId: string;
  }>();
  const selectSegmentListByEventId = useMemo(
    () => makeSelectPendingSegmentListByEventId(eventId),
    [],
  );
  const event = useMemoizedSelector(makeSelectEventById, eventId);
  const segments = useSelector(selectSegmentListByEventId);
  const [pendingSessions, setPendingSessions] = useState([]) as any;

  useEffect(() => {
    dispatch({
      type: 'event/getEventPendingSegmentList',
      payload: {
        eventId,
      },
    });
  }, [eventId]);

  useEffect(() => {
    if (!segments || !event) return;
    let currentStageSessions = segments.filter(
      session =>
        session.refId === stageId &&
        !session.hiddenSegment &&
        session.broadcastStatus !== SessionBroadcastStatus.COMPLETED,
    );
    if (!event?.enableScheduleSync) {
      // set current running session only if schedule sync off.
      currentStageSessions = currentStageSessions.filter(
        session =>
          session.broadcastStatus === SessionBroadcastStatus.STARTED ||
          session.broadcastStatus === SessionBroadcastStatus.PAUSED,
      );
    }
    const pendingSessionsList = [...currentStageSessions].filter(
      session => !session.isDummy,
    );
    setPendingSessions(pendingSessionsList);

    // find current running with status is started.
    let currentRunningSession = null;
    for (const segment of currentStageSessions) {
      if (
        segment.broadcastStatus === SessionBroadcastStatus.PAUSED ||
        segment.broadcastStatus === SessionBroadcastStatus.STARTED
      ) {
        currentRunningSession = segment;
        break;
      }
    }

    // find first pending session if not match with all above conditions
    if (!currentRunningSession) {
      for (let a = 0; a < currentStageSessions.length; a += 1) {
        const sessionStartDateTime = convertInAnotherTimeZone(currentStageSessions[a].startDateTime, event.tz).getTime();
        if (
          currentStageSessions[a].broadcastStatus ===
            SessionBroadcastStatus.PENDING &&
          moment(convertInAnotherTimeZone(new Date(), event.tz)).format('YYYY/DD/MMM') ===
            moment(sessionStartDateTime).format('YYYY/DD/MMM')
        ) {
          currentRunningSession = currentStageSessions[a];
          break;
        }
      }
    }
    dispatch({
      type: 'event/setRecordingSession',
      payload: {
        stageId,
        sessionObj: currentRunningSession,
      },
    });
  }, [segments, event]);

  return { pendingSessions };
};
