import { NACD_GALA_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';
import { IEventCustomConfigType } from '@/custom-config/types';

const nacdgala: IEventCustomConfigType = {
  name: 'nacdgala',
  zoneNames: {
    stages: 'Main Stage',
    expo: 'Booths',
  },
  boothConfig: {
    // production
    '5d4e7c55-038d-407d-8830-fc5365843a16': NACD_GALA_PHOTOBOOTH,
  },
};
export default nacdgala;
