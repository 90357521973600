import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Asterisk = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 9 10" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M5.04157 0.125V4.06129L8.45083 2.09342L8.99249 3.03158L5.58378 4.99946L8.99249 6.96842L8.45083 7.90658L5.04157 5.93817V9.875H3.95824V5.93817L0.548991 7.90658L0.00732422 6.96842L3.41603 5L0.00732422 3.03158L0.548991 2.09342L3.95824 4.06129V0.125H5.04157Z" className={fillClassName} />
    </svg>
  );
};

export default Asterisk;
