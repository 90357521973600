import React from 'react';

const GridIcon = ({ size, onClick }) => {
  return (
    <svg onClick={onClick} width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="4.50818" height="4.60309" fill="#B5B5B5" />
      <rect x="6.49219" width="4.50818" height="4.60309" fill="#B5B5B5" />
      <rect x="12.9844" width="4.50818" height="4.60309" fill="#B5B5B5" />
      <rect y="6.44409" width="4.50818" height="4.60309" fill="#B5B5B5" />
      <rect x="6.49219" y="6.44409" width="4.50818" height="4.60309" fill="#B5B5B5" />
      <rect x="12.9844" y="6.44409" width="4.50818" height="4.60309" fill="#B5B5B5" />
      <rect y="12.8887" width="4.50818" height="4.60309" fill="#B5B5B5" />
      <rect x="6.49219" y="12.8887" width="4.50818" height="4.60309" fill="#B5B5B5" />
      <rect x="12.9844" y="12.8887" width="4.50818" height="4.60309" fill="#B5B5B5" />
    </svg>
  );
};

export default GridIcon;
