import { useParams } from 'react-router-dom';
import api from '@/api';
import { useDispatch } from 'react-redux';

export const useRecording = ({ channelId }) => {
  const { eventId = '' } = useParams<{ eventId: string }>();
  const dispatch = useDispatch();

  const refreshPendingSegmentsList = () => {
    dispatch({
      type: 'event/getEventPendingSegmentList',
      payload: {
        eventId,
      },
    });
  };

  const showErrorMessage = error => {
    if (error?.response?.data?.message === 'OTHER_SESSION_RUNNING') {
      dispatch({
        type: 'global/addDangerToast',
        payload: {
          description: 'Another session is already running in this stage',
        },
      });
      return;
    }
    if (error?.response?.data?.message === 'SESSION_ALREADY_STARTED') {
      dispatch({
        type: 'global/addDangerToast',
        payload: { description: 'Session already started' },
      });
      return;
    }
    dispatch({
      type: 'global/addDangerToast',
      payload: { description: 'Unable to start/resume session' },
    });
  };

  const startRecordingWithDummySession = () => {
    api.recording
      .startRecordingWithDummySession(channelId)
      .then(() => {
        refreshPendingSegmentsList();
      })
      .catch(error => {
        showErrorMessage(error);
      });
  };

  const startRecording = (sessionId: string) => {
    api.recording
      .startRecording(channelId, sessionId)
      .then(() => {
        refreshPendingSegmentsList();
      })
      .catch(error => {
        showErrorMessage(error);
      });
  };

  const pauseRecording = (sessionId: string) => {
    api.recording
      .pauseRecording(channelId, sessionId)
      .then(() => {
        refreshPendingSegmentsList();
      })
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Unable to pause session' },
        });
      });
  };

  const resumeRecording = (sessionId: string) => {
    api.recording
      .resumeRecording(channelId, sessionId)
      .then(() => {
        refreshPendingSegmentsList();
      })
      .catch(error => {
        showErrorMessage(error);
      });
  };

  const stopRecording = (sessionId: string) => {
    api.recording
      .stopRecording(channelId, sessionId)
      .then(() => {
        refreshPendingSegmentsList();
      })
      .catch(() => {
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Unable to stop session' },
        });
      });
  };

  const startDryRun = (stageId: string) => {
    api.stage.startDryRun(stageId).catch(() => {
      dispatch({
        type: 'global/addDangerToast',
        payload: { description: 'Unable to start dry run' },
      });
    });
  };

  const stopDryRun = (stageId: string) => {
    api.stage.stopDryRun(stageId).catch(() => {
      dispatch({
        type: 'global/addDangerToast',
        payload: { description: 'Unable to stop dry run' },
      });
    });
  };

  return {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    startRecordingWithDummySession,
    startDryRun,
    stopDryRun,
  };
};
