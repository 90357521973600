import { useState, useEffect } from 'react';
import api from '@/api';
import { HostChannelEvent } from '@/types/messaging';

const useStageSettingsChanges = ({ eventId, stageId, hostChannel }) => {
  const [stageSessionSettings, setStageSessionSettings] = useState(null) as any;
  useEffect(() => {
    if (!stageId || !eventId) return;
    api.stage.getStageSettingsDetails(eventId, stageId).then(res => {
      setStageSessionSettings(res.data);
    });
  }, [stageId, eventId]);

  useEffect(() => {
    if (!hostChannel) {
      return;
    }

    const stageSettingsUpdateListener = data => {
      setStageSessionSettings(JSON.parse(data));
    };

    hostChannel.bind(
      HostChannelEvent.STAGE_SETTINGS_UPDATE,
      stageSettingsUpdateListener,
    );

    // eslint-disable-next-line consistent-return
    return () => {
      hostChannel.unbind(
        HostChannelEvent.STAGE_SETTINGS_UPDATE,
        stageSettingsUpdateListener,
      );
    };
  }, [hostChannel]);

  return {
    stageSessionSettings,
  };
};

export default useStageSettingsChanges;
