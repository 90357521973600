import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const SandboxMS2config = {
  name: 'SandboxMS2config',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.SandboxMS2_Expo,
  boothConfig: {
    'eda6997d-0680-4fdd-ba6a-f895849ab4c0': ManualLayoutConfig.SandboxMS_PHOTOBOOTH,
 },


};

export default SandboxMS2config;
