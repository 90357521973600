import {
  ZUDDL_VO_PHOTOBOOTH2,
  ETP_PHOTOBOOTH,
  CIPLA_PUBLICATIONGALLERY_BOOTH9,
} from '@/components/custom/layouts/ManualLayout/config';

const sandbox1 = {
  name: 'zuddlPhotoBooth',
  auth: {
    magicLinks: true,
  },
  boothConfig: {
    '282b7e74-c8e4-4389-a6e9-a6398ec477ed': ZUDDL_VO_PHOTOBOOTH2,
    'f8d21bfe-9f09-429c-b938-2ea148eb2096': ETP_PHOTOBOOTH,
    'b219b17b-5c41-4bd0-852f-e36adbcbbe80': CIPLA_PUBLICATIONGALLERY_BOOTH9,
    'bc3d5727-4a21-4e97-a991-a22e841c7bfd': CIPLA_PUBLICATIONGALLERY_BOOTH9,
  },
};
export default sandbox1;
