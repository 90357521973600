import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const ComputerLine = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      className={fillClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00002 7H9.00002V1.5H1.00002V7ZM5.50002 8V9H7.50002V10H2.50002V9H4.50002V8H0.49602C0.430363 7.99961 0.365429 7.98626 0.304939 7.96073C0.24445 7.93519 0.189594 7.89797 0.143516 7.8512C0.0974383 7.80442 0.0610445 7.74901 0.0364207 7.68815C0.0117969 7.62728 -0.000572906 7.56216 2.0373e-05 7.4965V1.0035C2.0373e-05 0.7255 0.22752 0.5 0.49602 0.5H9.50402C9.77802 0.5 10 0.7245 10 1.0035V7.4965C10 7.7745 9.77252 8 9.50402 8H5.50002Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default ComputerLine;
