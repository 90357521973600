import { makeSelectIsOrganizer, makeSelectIsRoomOwner } from '@/models/event';
import { useMemoizedSelector } from '../use-memoized-selector';

const useIsOrganizerOrRoomOwner = (
  eventId: string,
  discussionTableId: string,
) => {
  const isOrganizer = useMemoizedSelector(makeSelectIsOrganizer, eventId);
  const isRoomOwner = useMemoizedSelector(
    makeSelectIsRoomOwner,
    discussionTableId,
  );

  return isOrganizer || isRoomOwner;
};

export default useIsOrganizerOrRoomOwner;
