import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const UploadFilledIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 20"
      className={fillClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99993 19.9813C5.63398 19.8764 4.3361 19.3426 3.29159 18.4561C2.24709 17.5696 1.50937 16.3758 1.18379 15.0451C0.858208 13.7144 0.961403 12.3148 1.47864 11.0462C1.99588 9.77766 2.90071 8.70494 4.06393 7.98125C4.31145 6.05134 5.25373 4.27776 6.71445 2.99241C8.17517 1.70706 10.0542 0.998047 11.9999 0.998047C13.9457 0.998047 15.8247 1.70706 17.2854 2.99241C18.7461 4.27776 19.6884 6.05134 19.9359 7.98125C21.0992 8.70494 22.004 9.77766 22.5212 11.0462C23.0385 12.3148 23.1417 13.7144 22.8161 15.0451C22.4905 16.3758 21.7528 17.5696 20.7083 18.4561C19.6638 19.3426 18.3659 19.8764 16.9999 19.9813V20.0003H6.99993V19.9813ZM12.9999 12.0003H15.9999L11.9999 7.00025L7.99993 12.0003H10.9999V16.0003H12.9999V12.0003Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default UploadFilledIcon;
