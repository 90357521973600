import {
  AOP_PHOTOBOOTH,
  ZUDDL_VO_PHOTOBOOTH2,
  AOP_PHOTOBOOTH2,
  AOP_PHOTOBOOTH3,
  SHIFT_PHOTOBOOTH,
  MYTHOLOGY_SHOW_PRESS_CONFERENCE_PHOTOBOOTH,
  TESCO_PHOTOBOOTH,
  AISS2021_PHOTOBOOTH,
  ZUDDL_PHOTOBOOTH10,
  PSAWARDS_PHOTOBOOTH,
  ROCHE_PHOTOBOOTH,
  DELOITTESB_PHOTOBOOTH,
  UBERSB_PHOTOBOOTH,
  BACARDISB_PHOTOBOOTH,
  CHANGE_HEALTHCARE_PHOTOBOOTH,
  MeeshoSB_PHOTOBOOTH,
  UECE_PHOTOBOOTH,
  VODAFONESB_PHOTOBOOTH,
  REDHATSB_PHOTOBOOTH,
  Cipla2022_PHOTOBOOTH,
  ManchesterSB_PHOTOBOOTH,
  AnnualEventSB_PHOTOBOOTH,
  NewHireSB_PHOTOBOOTH,
  RidersMusicFestivalSB_PHOTOBOOTH,
  SalesMeetSB_PHOTOBOOTH,
  TallyDigiSabha_PHOTOBOOTH,
  RealMeB1_PHOTOBOOTH,
  RealMeB2_PHOTOBOOTH,
  LuvPalooza_PHOTOBOOTH,
  DeliciousExperiencesSB_PHOTOBOOTH,
  OTRGlobal1_PHOTOBOOTH,
  UnileverSB_PHOTOBOOTH,
  AccentureSB_PHOTOBOOTH,
  DUSIPM1_PHOTOBOOTH,
  DUSIPM2_PHOTOBOOTH,
  DUSIPM3_PHOTOBOOTH,
  DUSIPM4_PHOTOBOOTH,
  DUSIPM5_PHOTOBOOTH,
  Adobe2022_PHOTOBOOTH,
  DubaiHolding_PHOTOBOOTH,
  Fractal1729_PHOTOBOOTH,
  Lambdatest_PHOTOBOOTH,
  Cyberfrat_PHOTOBOOTH,
  Legasis_PHOTOBOOTH,
  Fractal1729_2_PHOTOBOOTH,
  Fractal1729_3_PHOTOBOOTH,
  Legasis2_PHOTOBOOTH,
  FractalGWR_1_PHOTOBOOTH,
  FractalGWR_2_PHOTOBOOTH,
  CH_FamilyDay_PHOTOBOOTH,
  PolygonSB_PHOTOBOOTH,
  ChargebeeSB_PHOTOBOOTH,
  Zuddl_HR_PHOTOBOOTH,
  SHRMSB_PHOTOBOOTH,
  EYSB_PHOTOBOOTH,
  TinymagiqSB_PHOTOBOOTH,
  SenseHawkSB_PHOTOBOOTH,
  PolygonSB2_PHOTOBOOTH,
  Bayer_Global_PHOTOBOOTH,
  CONNECTSB_PHOTOBOOTH,
  MinimalismTechSB_PHOTOBOOTH,
  ServiceNow_PHOTOBOOTH,
  ElevateUC22_PHOTOBOOTH,
  AIDEASB_PHOTOBOOTH,
  IMEX_PHOTOBOOTH,
  GT8th_PHOTOBOOTH,
  Chargefly_PHOTOBOOTH,
  Everafter_PHOTOBOOTH,
  QS_PHOTOBOOTH,
  AFCONS_PHOTOBOOTH,
  PROPEL2023_PHOTOBOOTH,
  HUX24_PHOTOBOOTH,
  FINALTEMP_PHOTOBOOTH,
  ROCKETLANE_PHOTOBOOTH1,
  QS_PHOTOBOOTH1,
  QS_PHOTOBOOTH2,
  POSTSCRIPT_1, 
  //SURAJ_MASTER_PHOTOBOOTH,
  
} from '@/components/custom/layouts/ManualLayout/config';

const zuddlPhotoBooth = {
  name: 'zuddlPhotoBooth',
  auth: {
    magicLinks: true,
  },
  boothConfig: {
    '94688ec2-08fc-4446-b813-b1f8931e6b05': CHANGE_HEALTHCARE_PHOTOBOOTH,
    'da747c57-ec65-4b2c-856a-2a62b55420f1': ZUDDL_VO_PHOTOBOOTH2,
    'e222ba89-f89b-4737-a513-3137277be675': ZUDDL_VO_PHOTOBOOTH2,
    '81bcdbc6-2fe2-4150-a23f-c8d8ba8f918b': AOP_PHOTOBOOTH,
    'a809d487-78f7-4f3c-b20b-135bece6188f': AOP_PHOTOBOOTH2,
    'd5ecd535-e396-42cf-bbcf-a33cae7c4508': AOP_PHOTOBOOTH3,
    'f4fc6575-5abc-4187-97e1-377f4827d409': SHIFT_PHOTOBOOTH,
    '725fed32-9d9b-4fd3-ac58-56e81d020b3d': MYTHOLOGY_SHOW_PRESS_CONFERENCE_PHOTOBOOTH,
    '6d0dc72a-fa71-4635-b8d2-9c98cd20213b': TESCO_PHOTOBOOTH,
    '2e63dabd-81a8-4eff-be1d-bfa8e76beb96': AISS2021_PHOTOBOOTH,
    'f6248e96-40d4-4dcd-9b65-3ff054bcd926': Zuddl_HR_PHOTOBOOTH,
    '937790d0-69c2-4cd9-beac-867f497e5d7f': ZUDDL_PHOTOBOOTH10,
    'a3c3ef02-43c2-40e8-b1b3-f04c67900df1': ZUDDL_PHOTOBOOTH10,
    '177cb885-b426-435c-ba58-f73c95a28484': PSAWARDS_PHOTOBOOTH,
    'f3a56806-be79-4c28-862a-ae4e3fa1ed23': ROCHE_PHOTOBOOTH,
    '98c5f761-fc73-4bb3-ba09-4d98e8210192': PSAWARDS_PHOTOBOOTH,
    '87d5ac13-8b03-4586-b025-f9db4d5e31ad': PSAWARDS_PHOTOBOOTH,
    '0f56e56c-c437-4e26-a1c3-c47112ee3571': PSAWARDS_PHOTOBOOTH,
    '24dbef02-2203-4105-a2f7-fdd710f9744e': DELOITTESB_PHOTOBOOTH,
    'aa765ad4-7129-4588-8e76-d3f813b50a95': UBERSB_PHOTOBOOTH,
    '7c0864e1-dab4-4fd8-b83d-f626dfa2529f': BACARDISB_PHOTOBOOTH,
    '3a02405e-d994-4ac9-92bc-c6faf2185983': ManchesterSB_PHOTOBOOTH,
    'e5d7f1b2-bf21-4758-a6f7-10519b3cddbc': AnnualEventSB_PHOTOBOOTH,
    'ae577963-c298-4b49-91f8-2762ee5ee7cd': NewHireSB_PHOTOBOOTH,
    '06b8b1fa-4b73-4656-b9fc-067f6d955592': RidersMusicFestivalSB_PHOTOBOOTH,
    '9c3f2ec3-f022-42b4-98bd-b25f3c2a63ce': SalesMeetSB_PHOTOBOOTH,
    '68fd3b9f-e983-49e1-a050-00bec2328f7e': TallyDigiSabha_PHOTOBOOTH,
    '671568a5-dbb7-4c68-ba10-b48b4b9f4b2c': AnnualEventSB_PHOTOBOOTH,
    '75fd29d6-320e-4700-ab8f-294f34c38337': RealMeB1_PHOTOBOOTH,
    '11f1e8fb-1ec0-470b-8c83-3e6dd50f2748': RealMeB2_PHOTOBOOTH,
    'c02f6fab-0cc5-4e4b-9bcd-53480362c737': LuvPalooza_PHOTOBOOTH,
    '4316ded7-0297-4876-82d2-74bc569c672b': DeliciousExperiencesSB_PHOTOBOOTH,
    '6707a8c1-91b0-4d79-a1f2-40b10baf801e': OTRGlobal1_PHOTOBOOTH,
    'b08d8c57-cfec-4387-b599-1ffdc4ca5729': UnileverSB_PHOTOBOOTH,
    '9b0acb7b-81c1-4efa-ab42-99966a9424b8': AccentureSB_PHOTOBOOTH,
    '18507c26-f21b-4bfd-857e-7c0d8ad7f48c': DUSIPM1_PHOTOBOOTH,
    'f2ad3564-165a-4aaf-932c-b46dc282a778': DUSIPM2_PHOTOBOOTH,
    'd88159f3-ee22-4771-985e-59723bc96ca0': DUSIPM3_PHOTOBOOTH,
    'fde57f9b-1869-4c57-b332-56f6baf799a9': DUSIPM4_PHOTOBOOTH,
    'd4f70c60-94b6-4acc-a020-394018affc69': DUSIPM5_PHOTOBOOTH,
    '2fc4ff1f-2e68-4745-b16f-bc78d25c40f4': Adobe2022_PHOTOBOOTH,
    '0e4f3017-8428-43cb-87a8-7d475c94731d': DubaiHolding_PHOTOBOOTH,
    '510c68a9-f7bf-4093-82a8-3cdf14d937d1': Fractal1729_PHOTOBOOTH,
    '584a87a8-fbbb-4bc5-9986-24743f7e831f': Lambdatest_PHOTOBOOTH,
    'e3d7e631-de61-4cb7-a5d6-31095d6303f6': Cyberfrat_PHOTOBOOTH,
    'f4669a96-9541-42c4-afc0-c484c72e3b67': Legasis_PHOTOBOOTH,
    '12b196c2-0a76-4562-82b6-bb88904e0a10': Fractal1729_2_PHOTOBOOTH,
    'c60053e9-e5f4-4fa1-98d6-88b0fe96377e': Fractal1729_3_PHOTOBOOTH,
    'c2e42c06-60c0-424c-920a-6f0848c6faba': Legasis2_PHOTOBOOTH,
    '24bc7f29-ab2d-4f8d-9831-13b36a411e0b': FractalGWR_1_PHOTOBOOTH,
    'f8c68e14-35f1-4507-b5e1-315038a92f22': FractalGWR_2_PHOTOBOOTH,
    '2f2a09dc-54be-4475-8790-babe12c21d25': CH_FamilyDay_PHOTOBOOTH,
    '1885d796-619e-45fe-9777-e76b6933a65e': PolygonSB_PHOTOBOOTH,
    '9078588e-1065-484a-899a-5e35e00960e6': ChargebeeSB_PHOTOBOOTH,
    'dbb21b28-1dfc-45a2-89ed-8e5760d453ab': SHRMSB_PHOTOBOOTH,
    '8be1c9fb-1d93-42b5-97c0-1d03f8171033': EYSB_PHOTOBOOTH,
    '446b5f06-fe3f-4ada-812d-2dd0a5738b0a': TinymagiqSB_PHOTOBOOTH,
    'e2f77d31-e4fa-42b6-a7d3-b8731982e5a0': SenseHawkSB_PHOTOBOOTH,
    '14245e14-e5fb-4406-9b50-05a0ab843b63': PolygonSB2_PHOTOBOOTH,
    'd0eea882-6a69-429d-9c5a-d3dba9fca771': Bayer_Global_PHOTOBOOTH,
    '87c32b64-7335-42b6-9caf-e7fa49c0e8d1': CONNECTSB_PHOTOBOOTH,
    'c99dca3a-537a-4caf-a3e9-c57300af2335': MinimalismTechSB_PHOTOBOOTH,
    'a9371421-f116-4478-aa5d-f43b95ec5ee8': MinimalismTechSB_PHOTOBOOTH,
    '715bdf31-b8ab-4ad0-b667-dd2c72e13e5d': MinimalismTechSB_PHOTOBOOTH,
    '0e0b63a4-e46b-4e9b-b569-5a21a3153298': ServiceNow_PHOTOBOOTH,
    '893a47ea-a96d-412c-92fb-80f02288c73a': ElevateUC22_PHOTOBOOTH,
    '401a38f7-8eb1-4560-8968-eda72f39ffcd': AIDEASB_PHOTOBOOTH,
    '7b8bbfb2-ecf1-429c-882d-151c17d2fc57': GT8th_PHOTOBOOTH,
    '817d99d3-bd4e-4d2a-af97-9b8a46d78353': Chargefly_PHOTOBOOTH,
    'e9368841-5dcd-4cc7-a4e9-62e56a7b7f6a': PROPEL2023_PHOTOBOOTH,
    'acc0a147-ddf7-4468-baad-b39a54197460': HUX24_PHOTOBOOTH,
    '62e3599e-062f-41e2-967b-2fbb70baf759': Everafter_PHOTOBOOTH,
    '10da2883-95db-467c-919f-dc5e6641adf4': QS_PHOTOBOOTH,
    '5eb474c4-cd61-48ab-b7a4-f713e0e04772': AFCONS_PHOTOBOOTH,
    'e406a44f-ab82-4b2f-a626-8f30f61216cf':FINALTEMP_PHOTOBOOTH,
    'b7a5bd1b-4107-467c-9eb8-4d6a73fb549f' : ROCKETLANE_PHOTOBOOTH1,
    'd18f1c5a-97f2-4a83-b78c-f3368c810527' : QS_PHOTOBOOTH1,
    'bc1e69d2-b88a-46b3-bfbe-2a2cbc48df34' : QS_PHOTOBOOTH2,
    'dc540cee-0367-4d24-8c9f-6b05a9dd0431' : POSTSCRIPT_1,
    
    // staging
    '876178f8-310b-4fbe-9490-67de2ca01b0b': PSAWARDS_PHOTOBOOTH,
    'fbfc84de-14ab-4f21-a2bf-0a6cc690119a': MeeshoSB_PHOTOBOOTH,
    '18c3dca3-716f-44a3-a557-33ad7a15ae3d': UECE_PHOTOBOOTH,
    '5d75ac68-a1ae-4ae2-9c4d-e1dafed1c053': VODAFONESB_PHOTOBOOTH,
    '6c863230-76c3-4451-8466-169611a49d75': REDHATSB_PHOTOBOOTH,
    '7a2ed14b-dc00-4735-9fa8-3277c47bfb93': Cipla2022_PHOTOBOOTH,
    'ed720ed7-71cc-45f3-a1f4-f6aa992bd05e': IMEX_PHOTOBOOTH,
    // local
    'bc3d5727-4a21-4e97-a991-a22e841c7bfd': AISS2021_PHOTOBOOTH,
    '6be50c8c-38e7-49ba-b043-b495ad599a8a': MYTHOLOGY_SHOW_PRESS_CONFERENCE_PHOTOBOOTH,
    // suraj
    //'0f988d41-c18f-4ea1-921d-f35b675e9183':SURAJ_MASTER_PHOTOBOOTH,
  },

};

export default zuddlPhotoBooth;
