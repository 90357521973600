import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const reveneraExpo = {
  name: 'reveneraExpo',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.Revenera_Expo,
  boothConfig: {
 },


};

export default reveneraExpo;
