import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const ipc22 = {
  name: 'ipc22',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.GTIPCAug22_Expo,
  boothConfig: {
    '16fe1a35-210c-4c72-8d58-9be2051d72be': ManualLayoutConfig.GTIPCAug22_PHOTOBOOTH,
 },


};

export default ipc22;
