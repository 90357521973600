import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const UploadIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 23 23" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 11.086L15.743 15.328L14.328 16.743L12.5 14.915V20.5H10.5V14.913L8.67202 16.743L7.25702 15.328L11.5 11.086ZM11.5 0.5C13.217 0.500082 14.8741 1.13111 16.1562 2.27312C17.4383 3.41512 18.2561 4.98846 18.454 6.694C19.6983 7.03332 20.7837 7.7991 21.5207 8.85753C22.2576 9.91595 22.5992 11.1997 22.4856 12.4844C22.3721 13.7691 21.8107 14.973 20.8996 15.8858C19.9884 16.7986 18.7855 17.3622 17.501 17.478V15.464C17.9612 15.3983 18.4037 15.2416 18.8026 15.003C19.2015 14.7644 19.549 14.4487 19.8246 14.0744C20.1002 13.7001 20.2985 13.2746 20.4079 12.8228C20.5173 12.3711 20.5356 11.902 20.4617 11.4431C20.3879 10.9841 20.2233 10.5445 19.9777 10.1498C19.7321 9.75519 19.4104 9.41342 19.0312 9.14447C18.6521 8.87552 18.2232 8.68479 17.7695 8.58341C17.3159 8.48203 16.8466 8.47203 16.389 8.554C16.5456 7.82489 16.5372 7.06997 16.3642 6.34455C16.1913 5.61913 15.8583 4.94158 15.3896 4.36153C14.9209 3.78147 14.3284 3.31361 13.6555 2.99219C12.9826 2.67078 12.2463 2.50397 11.5005 2.50397C10.7548 2.50397 10.0185 2.67078 9.34554 2.99219C8.67262 3.31361 8.08011 3.78147 7.61142 4.36153C7.14273 4.94158 6.80972 5.61913 6.6368 6.34455C6.46387 7.06997 6.45541 7.82489 6.61202 8.554C5.69967 8.38267 4.75663 8.58079 3.99036 9.10476C3.22409 9.62874 2.69735 10.4357 2.52602 11.348C2.35469 12.2603 2.5528 13.2034 3.07678 13.9697C3.60075 14.7359 4.40767 15.2627 5.32002 15.434L5.50002 15.464V17.478C4.21547 17.3623 3.01242 16.7989 2.10115 15.8862C1.18988 14.9735 0.628343 13.7696 0.51469 12.4848C0.401037 11.2001 0.742496 9.91628 1.47939 8.85777C2.21627 7.79926 3.30172 7.03339 4.54602 6.694C4.74372 4.98838 5.56142 3.41491 6.8436 2.27287C8.12577 1.13082 9.78297 0.499863 11.5 0.5Z" className={fillClassName} />
    </svg>
  );
};

export default UploadIcon;
