import React from 'react';
import { IGif } from '@giphy/js-types';
import styles from './styles.module.scss';
import CrossCircleIcon from '@/components/ui/new-icons/CrossCircle';

type IPreviewGif = {
  gif: IGif;
  onClose: () => void;
};

const PreviewGif = ({ gif, onClose }: IPreviewGif) => (
  <div className={styles.gifContainer}>
    <div
      className={styles.gifStillImage}
      style={{
        backgroundImage: `url(${gif.images.downsized_still.url})`,
      }}
    />
    <video
      src={gif.images.original.mp4}
      playsInline
      autoPlay
      muted
      loop
      preload="metadata"
      className={styles.gif}
    />
    <div className={styles.crossButton} onClick={onClose} aria-hidden>
      <CrossCircleIcon size={20} />
    </div>
  </div>
);

export default PreviewGif;
