import { WidgetTypes } from '@/components/custom/widgets/Widget/types';
import { PhotoboothLayerTypes } from '@/components/custom/widgets/PhotoboothWidget/types';
import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';

export enum IStageLayoutTemplate {
  TEMPLATE_1 = 1,
  TEMPLATE_2 = 2,
  TEMPLATE_3 = 3,
  TEMPLATE_4 = 4,
  // NOTE- the template5.ts file is the same getDisplayMode logic as template1.
  TEMPLATE_6 = 6,
  TEMPLATE_8 = 8,
  TEMPLATE_9 = 9,
}

export interface IStageLayoutTemplateType {
  getDisplayMode: (
    numStreams: number,
    hasScreenshareStream: boolean,
  ) => IStreamsDisplayMode;
}

export interface IEventCustomConfigType {
  name: string;
  auth?: {
    magicLinks: boolean;
  };
  showStageButton?: boolean;
  getDisplayMode?: (
    numStreams: number,
    hasScreenshareStream: boolean,
  ) => IStreamsDisplayMode;
  zoneNames?: {
    lobby?: string;
    schedule?: string;
    stages?: string;
    expo?: string;
    discussions?: string;
  };
  includeZones?: string[];
  boothConfig?: BoothConfigObjectType;
}

interface BoothConfigObjectType {
  [key: string]: IBoothConfigType;
}

export interface IBoothConfigType {
  type: string;
  bgWidth: string;
  bgHeight: string;
  zone: string;
  widgets: {
    type: WidgetTypes;
    x: number;
    y: number;
    width: string;
    height: string;
    config: {
      layers: {
        type: PhotoboothLayerTypes;
        src?: string;
        baseWidth?: number;
        baseHeight?: number;
        offsetTop?: number;
        offsetLeft?: number;
      }[];
    };
  }[];
}

export interface IExpoConfigType {
  type: string;
  backgroundType: string;
  backgroundUrl: string;
  bgWidth: number;
  bgHeight: number;
  widgets: {
    type: WidgetTypes;
    x: number;
    y: number;
    width: number;
    height: number;
    config: {
      type: string;
      buttonType?: string;
      withinZone?: string;
      buttonLinkType?: string;
      zone?: string;
      controls?: boolean;
      src?: string;
      muted?: boolean;
      loop?: boolean;
      redirectURL?: string;
    };
  }[];
}
