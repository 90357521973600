import React, { useState, useRef } from 'react';
import MultiSelect from 'react-multi-select-component';
import classnames from 'classnames';
// components
import Label from '../../content/Label';
// styles + types
import styles from './styles.module.scss';
import { ISelectFieldOptionsArray } from '@/types/selectField';
import { ISelectFieldProps } from './types';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';
// helpers
import {
  generateSelectFieldOptionsFromStringArray,
  removeAsteriskFromLabel,
} from '@/utils/helpers';
import { useLocation, useParams } from 'react-router-dom';
import isTieConEvent from '@/hooks/is-tiecon-event';

const MultiSelectField = (props: ISelectFieldProps) => {
  const [showShadow, setShowShadow] = useState(false);
  const fieldRef = useRef();
  const {
    data,
    error,
    isLastElement,
    label,
    onChange,
    readOnly,
    submitFailed,
    value,
    widthStyles = '',
    validate,
    tooltip,
    placeholder,
    selectStyleClass,
    labelStyleClass,
    titleLabelContainerClass,
    isTicketMappingField = false,
    isMandatory = false,
    isSponsorLandingPage = false,
  } = props;

  let selectFieldOptions = data as ISelectFieldOptionsArray;
  let selectedOption = [] as any;

  const { eventId = '' } = useParams();
  const location = useLocation();

  const validationError = submitFailed && error;

  if (data && typeof data[0] === 'string') {
    selectFieldOptions = generateSelectFieldOptionsFromStringArray(data);
  }

  if (value) {
    if (Array.isArray(value)) {
      selectedOption = value;
    } else {
      if (selectFieldOptions.length > 0) {
        selectedOption = [selectFieldOptions[0]];
      }
    }
  } else if (!isTicketMappingField) {
    if (selectFieldOptions.length > 0 && selectStyleClass !== "landingPageSelect") {
      selectedOption = [selectFieldOptions[0]];
    }
  }

  const handleOnChange = event => {
    if (event.length > 1) {
      selectedOption = event.filter(item => !item.hidden);
      onChange && onChange(event.filter(item => !item.hidden));
    } else {
      selectedOption = event;
      onChange && onChange(event);
    }
  };

  const isTieconEvent = isTieConEvent(eventId, location.pathname);

  // set up necessary style classes
  const fullContainerWidth = `${styles[widthStyles]}`;
  const marginRightClass = `${styles.addMarginRight}`;
  const containerWithValidationMessage = classnames(fullContainerWidth, {
    [marginRightClass]: !isLastElement,
    [styles.maxWidth]: isTieconEvent,
  });

  const fullContainerPositioning = `${styles.container}`;
  const validationErrorBorder = `${styles.validationError}`;
  const showFocusShadowClass = `${styles.focusShadow}`;
  const innerContainerWithValidationBorder = classnames(
    fullContainerPositioning,
    { [validationErrorBorder]: !isSponsorLandingPage && validationError },
    { [showFocusShadowClass]: showShadow },
  );

  const selectFieldClass = `${styles.selectField}`;

  // Forcefully add focus on the field since it's a div
  const handleContainerClick = () => !showShadow && setShowShadow(true);
  const handleContainerBlur = () => showShadow && setShowShadow(false);
  const customValueRenderer = (selected, _options) => {
    if (selected[0]?.label) {
      return selected.length > 1 ? (
        <>
          <div className={styles.valueHolder}>{selected[0]?.label}</div>
          <div className={styles.countHolder}> +{selected.length - 1}</div>
        </>
      ) : (
        <span className={styles.unselectedStyle}>{selected[0]?.label}</span>
      );
    } else {
      return (
        <div className={styles.placeHolder}>
          {removeAsteriskFromLabel(placeholder)}
        </div>
      );
    }
  };

  return (
    <div
      className={containerWithValidationMessage}
      onClick={handleContainerClick}
      onBlur={handleContainerBlur}
      tabIndex={0}
    >
      <div className={innerContainerWithValidationBorder}>
        <div
          className={classnames(styles.titleLabelContainer, {
            [styles.maxWidth]: isTieconEvent,
            [styles.ticketMappingLabelWidth]: titleLabelContainerClass,
            [styles.titleLabelContainerSponsorLandingPage]: isSponsorLandingPage,
          })}
        >
          {label && label.length && (
            <Label
              value={
                validate
                  ? `${removeAsteriskFromLabel(label)}`
                  : removeAsteriskFromLabel(label)
              }
              hasTooltip={isTieconEvent || tooltip}
              styleClass={
                labelStyleClass || isSponsorLandingPage
                  ? 'fieldSelectLabelTitleTextSponsorLandingPage'
                  : isTieconEvent
                  ? 'fieldLabelTitleTextMaxWidth100'
                  : 'fieldLabelTitleText'
              }
              wrapperClass={isTieconEvent && 'tieconWrapperClass'}
              hideTooltip={isTicketMappingField}
              isMandatory={validate || isMandatory}
              isSponsorLandingPage={isSponsorLandingPage}
            />
          )}
          {/* if placeholder and no label, we use placeholder as both placeholder and label */}
          {!label && placeholder && (
            <Label
              value={removeAsteriskFromLabel(placeholder)}
              styleClass={
                isSponsorLandingPage
                  ? 'fieldSelectLabelTitleTextSponsorLandingPage'
                  : value && value.length
                  ? 'fieldSelectLabelTitleTextFocus'
                  : 'fieldSelectLabelTitleTextWithoutFocus'
              }
              isMandatory={validate || isMandatory}
              isSponsorLandingPage={isSponsorLandingPage}
            />
          )}
          {label && label.length && tooltip ? (
            <TooltipDiv
              dataFor={`tooltip_select_${label}`}
              tooltipText={tooltip}
            />
          ) : null}
        </div>
        <MultiSelect
          ref={fieldRef}
          className={classnames(selectFieldClass, {
            [selectStyleClass]: selectStyleClass,
            ['inputFieldError']: validationError,
            ['sponsorLandingPage']: isSponsorLandingPage,
          })}
          defaultValue={selectedOption}
          options={data}
          value={selectedOption}
          onChange={handleOnChange}
          placeholder={isSponsorLandingPage ? removeAsteriskFromLabel(placeholder) : placeholder}
          disabled={readOnly}
          hasSelectAll={false}
          valueRenderer={customValueRenderer}
          /* On clear, the placeholder was being reset to "Select...", So overriding the default to same as the placeholder. */
          overrideStrings={{
            selectSomeItems: removeAsteriskFromLabel(placeholder),
          }}
        />
      </div>
      {validationError && <FormValidationErrorTooltip value={error} />}
    </div>
  );
};

export default MultiSelectField;
