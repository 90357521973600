import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Image = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 11 11" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M1.70833 4.5125L2.79167 3.42917L5.77083 6.40833L7.66667 4.5125L9.29167 6.1375V1.20833H1.70833V4.5125ZM1.70833 6.04488V8.79167H3.3875L5.00492 7.17479L2.79167 4.96154L1.70833 6.04488ZM4.91988 8.79167H9.29167V7.66988L7.66667 6.04488L4.91988 8.79167ZM1.16667 0.125H9.83333C9.97699 0.125 10.1148 0.182068 10.2164 0.28365C10.3179 0.385233 10.375 0.523008 10.375 0.666667V9.33333C10.375 9.47699 10.3179 9.61477 10.2164 9.71635C10.1148 9.81793 9.97699 9.875 9.83333 9.875H1.16667C1.02301 9.875 0.885233 9.81793 0.78365 9.71635C0.682068 9.61477 0.625 9.47699 0.625 9.33333V0.666667C0.625 0.523008 0.682068 0.385233 0.78365 0.28365C0.885233 0.182068 1.02301 0.125 1.16667 0.125ZM7.39583 3.91667C7.18035 3.91667 6.97368 3.83106 6.82131 3.67869C6.66894 3.52632 6.58333 3.31966 6.58333 3.10417C6.58333 2.88868 6.66894 2.68202 6.82131 2.52964C6.97368 2.37727 7.18035 2.29167 7.39583 2.29167C7.61132 2.29167 7.81798 2.37727 7.97036 2.52964C8.12273 2.68202 8.20833 2.88868 8.20833 3.10417C8.20833 3.31966 8.12273 3.52632 7.97036 3.67869C7.81798 3.83106 7.61132 3.91667 7.39583 3.91667Z" className={fillClassName} />
    </svg>
  );
};

export default Image;
