import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const BroadcastStop = (props: IIconProps) => {
  const { color = IContentColors.DANGER, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      className={fillClassName}
    >
      <path
        d="M1.00033 0.166748H11.0003C11.2213 0.166748 11.4333 0.254546 11.5896 0.410826C11.7459 0.567106 11.8337 0.779068 11.8337 1.00008V11.0001C11.8337 11.2211 11.7459 11.4331 11.5896 11.5893C11.4333 11.7456 11.2213 11.8334 11.0003 11.8334H1.00033C0.779312 11.8334 0.56735 11.7456 0.41107 11.5893C0.25479 11.4331 0.166992 11.2211 0.166992 11.0001V1.00008C0.166992 0.779068 0.25479 0.567106 0.41107 0.410826C0.56735 0.254546 0.779312 0.166748 1.00033 0.166748Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default BroadcastStop;
