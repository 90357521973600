import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const PinVerticalIcon = (props: IIconProps) => {
  const { color = IContentColors.WHITE } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      className={fillClassName}
      width="9"
      height="12"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 0.375V1.625H8.125V5.375L9.375 7.25V8.5H5.625V12.875H4.375V8.5H0.625V7.25L1.875 5.375V1.625H1.25V0.375H8.75Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default PinVerticalIcon;
