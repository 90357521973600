import React from 'react';
// styles + types
import { IIconProps } from '../types';

const AlertCircleFilled = (props: IIconProps) => {
  const { size = 20 } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20Z"
        fill="#F0B429"
      />
      <path
        d="M8.78192 4.78816H11.2181L10.9991 12.6386H8.99542L8.78192 4.78816ZM9.99726 16.1423C9.29652 16.1423 8.68885 15.5566 8.68338 14.8285C8.68885 14.1113 9.29652 13.5255 9.99726 13.5255C10.7199 13.5255 11.3166 14.1113 11.3111 14.8285C11.3166 15.5566 10.7199 16.1423 9.99726 16.1423Z"
        fill="#222222"
      />
    </svg>
  );
};

export default AlertCircleFilled;
