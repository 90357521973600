import { TIK_TOK_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';
import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';

const tikTok = {
  name: 'tikTok',
  auth: {
    magicLinks: true,
  },
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      if (numStreams <= 3) {
        return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
      } else {
        return IStreamsDisplayMode.SPOTLIGHT;
      }
    }

    if (numStreams === 1) {
      return IStreamsDisplayMode.CENTER_STAGE;
    } else if (numStreams >= 2 && numStreams <= 3) {
      return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
    } else {
      return IStreamsDisplayMode.GRID;
    }
  },
  boothConfig: {
    '11ac9964-bdbc-4f9f-b307-061e67f0dc66': TIK_TOK_PHOTOBOOTH,
    // local
    '928548f3-6859-4807-887b-7e9aa9ec11ad': TIK_TOK_PHOTOBOOTH,
  },
};
export default tikTok;