import { secondsToHms } from '@/utils/helpers';
import React from 'react';
import styles from './styles.module.scss';

const VideoTimerView = ({ duration, playedSeconds }) => (
  <>
    {duration && (
      <div className={styles.timerView}>
        {secondsToHms(playedSeconds)} / {secondsToHms(duration)}
      </div>
    )}
  </>
);

export default VideoTimerView;
