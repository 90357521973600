import React, { useState, useCallback, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { isIOS, isSafari, isAndroid, isMobileSafari } from 'react-device-detect'

// styles + types
import styles from './styles.module.scss';
import { IChatInputFieldProps, IChatInputLabelProps, IChatInputLabelProps } from './types';
import { trim } from 'lodash';
import IconButton, {
  IIconButtonTypes,
  IUIIcons,
} from '@/components/ui/buttons/IconButton';
import {
  EMOJI_MEDIUM_SIZE,
  SMALL_ICON_SIZE,
  STANDARD_ICON_BUTTON_SIZE,
} from '@/styles/iconSizes';
import { TooltipTypes } from '../../buttons/IconButton/types';
import { IGif } from '@giphy/js-types';
import PreviewGif from './PreviewGif';
import { MEDIA_TYPE } from './config';
import CloseCircleFilled from '../../new-icons/CloseCircleFilled';
import { IContentColors } from '../../ExpandableContent';
import loadable from '@/utils/loadable';
import Loader from '../../Loader';
import { IChatBoxParent } from '@/components/chat/AttendeeMessage/types';

const PickerLoader = (
  <div className={styles.pickerLoader}>
    <Loader />
  </div>
);

const EmojiPicker = loadable(() => import('../../EmojiPicker'), {
  fallback: PickerLoader,
});

const GifPicker = loadable(() => import('../../GifPicker'), {
  fallback: PickerLoader,
});

const ChatInputLabel = ({ text }: IChatInputLabelProps) => (
  <div className={styles.chatInputLabel}>{text}</div>
);

const { SEND, EMOJI, GIF } = IUIIcons;

const ChatInputField = (props: IChatInputFieldProps) => {
  const {
    onMessageSubmit,
    placeholder = 'Type text here...',
    readonly = false,
    showSendIcon = true,
    showProfanityWarning,
    setShowProfanityWarning,
    hasEmojiPicker,
    showEmojiPicker,
    setShowEmojiPicker,
    hasGifPicker,
    showGifPicker,
    setShowGifPicker,
    isBottomFixed = false,
    label,
    parent,
  } = props;

  const [message, setMessageText] = useState('');
  const [gif, setGif] = useState<IGif | null>(null);
  const [showMediaPreview, setShowMediaPreview] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  
  const inputRef = useRef<HTMLInputElement>(null);
  const chatInputFieldContainerRef = useRef<HTMLDivElement>(null)
  const gifPickerContainerRef = useRef<HTMLDivElement>(null)
  const emojiPickerContainerRef = useRef<HTMLDivElement>(null)

  const isAnyPickerShown = showEmojiPicker || showGifPicker;
  const isInboxChat = parent === IChatBoxParent.INBOX
  const isInboxChatInAndroid = isAndroid && isInboxChat
  const isBoothOwnerWidgetChat = parent === IChatBoxParent.BOOTH_OWNER_WIDGET

  React.useEffect(() => {
    const visualViewportHandler = () => {
      const visualViewportHeight = Number(window?.visualViewport && window?.visualViewport?.height)
      const windowViewportHeight = window.innerHeight
      
      if(isIOS || isSafari){
        const viewportDifference = windowViewportHeight - visualViewportHeight
        
        if(!!chatInputFieldContainerRef && !!chatInputFieldContainerRef.current){
          let extraOffset = 56

          if(isAnyPickerShown){
            if(isBottomFixed || isMobile){
              extraOffset = 200
            }else if(!isBottomFixed){
              if(isMobile){
                extraOffset = 200
              }else{
                extraOffset = 200 + 56
              }
            }
          }else if(!isAnyPickerShown && isBottomFixed){
            extraOffset = 56
          }else if(isMobile){
            extraOffset = 0
          }
          chatInputFieldContainerRef.current.style.bottom = `${((viewportDifference) + extraOffset) || 0}px`
        }
        if(!!gifPickerContainerRef.current || !!emojiPickerContainerRef.current){
          let extraOffset = 56

          if(isBottomFixed || isMobile){
            extraOffset = 0
          }

          if(!!gifPickerContainerRef.current && showGifPicker){
            gifPickerContainerRef.current.style.bottom = `${((viewportDifference) + extraOffset) || 0}px`
          }

          if(!!emojiPickerContainerRef.current && showEmojiPicker){
            emojiPickerContainerRef.current.style.bottom = `${((viewportDifference) + extraOffset) || 0}px`
          }
        }
      }
    }
    visualViewportHandler()
    
    window?.visualViewport?.addEventListener('resize', visualViewportHandler);
    window?.visualViewport?.addEventListener('scroll', visualViewportHandler);

    return () => {
      window?.visualViewport?.removeEventListener('resize', visualViewportHandler);
      window?.visualViewport?.removeEventListener('scroll', visualViewportHandler);
    }
  },[
      isIOS,
      isSafari,
      isAnyPickerShown, 
      isMobile, 
      isBottomFixed,
      chatInputFieldContainerRef.current,
      gifPickerContainerRef.current,
      emojiPickerContainerRef.current
    ]
  )

  const handleMessageSubmit = useCallback(() => {
    const messageCopy = message?.trim();
    const messageIsEmpty =
      !messageCopy || (messageCopy && messageCopy.length === 0);

    if (gif) {
      onMessageSubmit(gif.id, MEDIA_TYPE.gif);
      setGif(null);
    }
    if (!messageIsEmpty) {
      setMessageText('');
      onMessageSubmit(messageCopy, MEDIA_TYPE.text);
    }
  }, [message, gif]);

  const handleChatInput = text => {
    setMessageText(text);
    if (setShowProfanityWarning) {
      setShowProfanityWarning(false);
    }
  };

  const handleOnChangeInput = event => {
    handleChatInput(event.target.value);
  };

  const handleEmojiInput = emoji => {
    const inputEle = inputRef.current;
    if (!inputEle) {
      return;
    }
    const emojiPos = inputEle.selectionStart ?? message.length;
    const newInput = `${message.substr(0, emojiPos)}${
      emoji.native
    }${message.substr(emojiPos)}`;
    handleChatInput(newInput);
    setTimeout(() => {
      const newCaretPos = emojiPos + emoji.native.length;
      inputEle.setSelectionRange(newCaretPos, newCaretPos);
    }, 0);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      if (trim(message).length === 0) return;
      handleMessageSubmit();
    }
  };

  return (
    <>
      {label && <ChatInputLabel text={label} />}
      <div
        ref={chatInputFieldContainerRef}
        className={classnames(styles.chatInputFieldContainer, {
          [styles.gifSelectedInputStyles]: gif,
          [styles.chatInputEmojiGifContainer]:
            !isBottomFixed && isAnyPickerShown,
          [styles.bottomInputContainer]: isBottomFixed && !isAnyPickerShown,
          [styles.bottomChatInputEmojiGifContainer]:
            isBottomFixed && isAnyPickerShown && !isInboxChatInAndroid,
          [styles.bottomChatInputEmojiGifContainerAndroid]:
            isBottomFixed && isAnyPickerShown && isInboxChatInAndroid,
          [styles.chatInputFieldContainerAndroid]: isInboxChatInAndroid,
          [styles.chatInputEmojiGifContainerOneToOneChat]: !isBottomFixed && isAnyPickerShown && isInboxChat,
          [styles.chatInputFieldContainerInBoothOwnerWidget]: isBoothOwnerWidgetChat,
          [styles.chatInputFieldContainerInBoothOwnerWidgetForIosMobileSafari]: isBoothOwnerWidgetChat && isMobileSafari && isIOS,
        })}
      >
        <input
          className={styles.chatInputField}
          disabled={readonly}
          onChange={handleOnChangeInput}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
          type="text"
          value={message}
          ref={inputRef}
        />
        <div className={styles.chatButtonsContainer}>
          {gif && (
            <>
              <div className={`${styles.gifContainer}`}>
                <img
                  alt={gif.title}
                  src={gif.images.downsized.url}
                  onClick={() => setShowMediaPreview(true)}
                  aria-hidden
                  className={styles.selectedGif}
                />
                <div
                  className={styles.closeButton}
                  onClick={() => {
                    setGif(null);
                    setShowMediaPreview(false);
                  }}
                  role="button"
                  tabIndex={0}
                  aria-hidden
                >
                  <CloseCircleFilled
                    size={SMALL_ICON_SIZE}
                    color={IContentColors.NEUTRAL_MID_2}
                  />
                </div>
              </div>
            </>
          )}
          <div
            className={classnames(styles.chatButtonsRightContainer, {
              [styles.chatButtonsAlignment]: gif,
            })}
          >
            {hasEmojiPicker ? (
              <div className={styles.emojiContainer}>
                <IconButton
                  activeIcon={EMOJI}
                  defaultIcon={EMOJI}
                  iconSize={STANDARD_ICON_BUTTON_SIZE}
                  type={
                    showEmojiPicker
                      ? IIconButtonTypes.ROUND_DARK_TRANSPARENT_WHITE
                      : IIconButtonTypes.ONLY_ICON_GREY_BLACK
                  }
                  tooltip={TooltipTypes.none}
                  className={classnames('elementAsButton', styles.sendButton, styles.emojiButton, {
                    [styles.sendButtonMobileSafari]: isMobile,
                  })}
                  onClick={() => {
                    if (!showEmojiPicker) {
                      setShowGifPicker(false);
                    }
                    setShowMediaPreview(false);
                    setShowEmojiPicker(!showEmojiPicker);
                  }}
                  style={{ border: 'none' }}
                  triggerTooltip={false}
                  showDefault
                  hideAfterSeconds={2}
                  tooltipText='emoji picker'
                />
                {showEmojiPicker ? (
                  <div
                    className={classnames(styles.emojiGifIndicator, {
                      [styles.gifPreviewIndicator]: gif,
                    })}
                  />
                ) : null}
              </div>
            ) : null}
            {hasGifPicker ? (
              <div className={styles.emojiContainer}>
                <IconButton
                  activeIcon={GIF}
                  defaultIcon={GIF}
                  iconSize={STANDARD_ICON_BUTTON_SIZE}
                  type={
                    showGifPicker
                      ? IIconButtonTypes.ROUND_DARK_TRANSPARENT_WHITE
                      : IIconButtonTypes.ONLY_ICON_GREY_BLACK
                  }
                  tooltip={TooltipTypes.none}
                  className={classnames(
                    styles.sendButton,
                    styles.emojiButton,
                    styles.gifButton,
                    {
                      [styles.sendButtonMobileSafari]: isMobile,
                    },
                  )}
                  onClick={() => {
                    if (!showGifPicker) {
                      setShowEmojiPicker(false);
                    }
                    setShowMediaPreview(false);
                    setShowGifPicker(!showGifPicker);
                  }}
                  style={{ border: 'none' }}
                  triggerTooltip={false}
                  showDefault
                  hideAfterSeconds={2}
                  tooltipText='GIF picker'
                />
                {showGifPicker && !showMediaPreview ? (
                  <div
                    className={classnames(styles.emojiGifIndicator, {
                      [styles.gifPreviewIndicator]: gif,
                    })}
                  />
                ) : null}
              </div>
            ) : null}
            {showSendIcon && (
              <IconButton
                activeIcon={SEND}
                defaultIcon={SEND}
                iconSize={EMOJI_MEDIUM_SIZE}
                type={IIconButtonTypes.ONLY_ICON_GREY_BLACK}
                tooltip={TooltipTypes.top}
                className={classnames(styles.sendButton, {
                  [styles.sendButtonMobileSafari]: isMobile,
                })}
                onClick={() => !readonly && handleMessageSubmit()}
                style={{ border: 'none' }}
                triggerTooltip={showProfanityWarning}
                showDefault
                hideAfterSeconds={2}
                dataTestId="chat-input-send-button"
                tooltipText='send message'
              />
            )}
          </div>
        </div>
      </div>
      {isAnyPickerShown && (
        <div>
          {showEmojiPicker ? (
            <div
              className={classnames(styles.emojiGifPickerContainer, {
                [styles.bottomEmojiGifPickerContainer]: isBottomFixed,
              })}
              tabIndex={0}
              aria-label="emoji container"
            >
              <EmojiPicker onEmojiSelect={handleEmojiInput} />
            </div>
          ) : null}
          {showGifPicker ? (
            <div
              className={classnames(styles.emojiGifPickerContainer, {
                [styles.bottomEmojiGifPickerContainer]: isBottomFixed,
              })}
              tabIndex={0}
              aria-label="gif container"
            >
              <GifPicker onSelect={newGif => setGif(newGif)} />
              {showMediaPreview && gif && (
                <div tabIndex={0}
                aria-label="gif container" className={styles.previewGifContainer}>
                  <PreviewGif
                    gif={gif}
                    onClose={() => setShowMediaPreview(false)}
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default React.memo(ChatInputField);