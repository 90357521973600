import React, { useState } from 'react';
import classnames from 'classnames';
// components
import { Eye, EyeOff } from 'react-feather';
import Label from '@/components/ui/content/Label';
// styles + types
import { STANDARD_ICON_BUTTON_SIZE } from '@/styles/iconSizes';
import styles from './styles.module.scss';
import { IPasswordFieldProps } from './types';
import { IContentColors } from '@/types';
import { getCssVar } from '@/utils/cssVars';
import FormValidationErrorTooltipNew from '../../FormValidationErrorTooltipNew';
import FormPasswordHintTooltip from '../../FormPasswordHintTooltip';

const PasswordField = (props: IPasswordFieldProps) => {
  const [showText, setShowText] = useState(false);
  const [showShadow, setShowShadow] = useState(false);
  const [passwordHintMode, setPasswordHintMode] = useState(false);
  const [fieldValue, setFieldValue] = useState('') as any;
  const {
    error,
    isLastElement,
    label,
    onChange,
    onClick,
    placeholder,
    readOnly,
    submitFailed,
    value,
    widthStyles = '',
    passwordType,
    hasTooltip = false,
    styleClass = '',
    dataTestId = ''
  } = props;

  const validationError = submitFailed && error;

  // set up necessary style classes
  const fullContainerWidth = styles[widthStyles];
  const marginRightClass = styles.addMarginRight;
  const containerWithValidationMessage = classnames(fullContainerWidth, {
    [marginRightClass]: !isLastElement,
  });

  const innerContainer = styles.container;
  const validationErrorBorder = styles.validationError;
  const showFocusShadowClass = styles.focusShadow;
  const innerContainerWithValidationBorder = classnames(
    innerContainer,
    styles[styleClass],
    { [validationErrorBorder]: validationError },
    { [showFocusShadowClass]: showShadow },
  );

  const baseClass = 'passwordField';
  const inputFieldClass = styles[label ? baseClass : `${baseClass}NoLabel`];

  // Forcefully add focus on the field since it's a div
  const handleContainerClick = () => !showShadow && setShowShadow(true);
  const handleContainerBlur = () => showShadow && setShowShadow(false);

  const handleIconClick = () => setShowText(!showText);

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      return false;
    }
    return true;
  };

  // Add icon settings + determine the correct icon to show
  const iconColor = IContentColors.NEUTRAL_MID_2;
  const size = STANDARD_ICON_BUTTON_SIZE;
  const EyeIcon = showText ? (
    <Eye color={getCssVar(iconColor)} size={size} onClick={handleIconClick} />
  ) : (
    <EyeOff
      color={getCssVar(iconColor)}
      size={size}
      onClick={handleIconClick}
    />
  );
  return (
    <div className={styles.formContainer}>
      {validationError && <FormValidationErrorTooltipNew value={error} />}
      <div
        className={containerWithValidationMessage}
        onClick={handleContainerClick}
        onBlur={handleContainerBlur}
      >
        <div className={innerContainerWithValidationBorder}>
          {label && label.length ? (
            <Label value={label} styleClass="inputField" hasTooltip={hasTooltip} />
          ) : null}
          {
            !label && placeholder &&
            <Label
              value={placeholder}
              hasTooltip={hasTooltip}
              styleClass={value && value.length ? 'fieldPasswordLabelTitleTextFocus' : 'fieldPasswordLabelTitleTextWithoutFocus'}
              className={styles.passwordFieldLabel}

            />
          }
          <input
            className={inputFieldClass}
            defaultValue={value}
            disabled={readOnly}
            onChange={(event) => { onChange(event); setFieldValue(event.target.value) }}
            onClick={onClick}
            onKeyPress={handleKeyPress}
            onFocus={() => setPasswordHintMode(true)}
            onBlur={() => setPasswordHintMode(false)}
            placeholder={label ? placeholder : ''}
            type={showText ? 'text' : 'password'}
            data-private
            data-testid={dataTestId}
          />
          <div className={styles.passwordVisibilityToggleContainer}>
            {EyeIcon}
          </div>
        </div>
      </div>
      {passwordType && passwordHintMode && <FormPasswordHintTooltip value={fieldValue} />}
    </div>
  );
};

export default React.memo(PasswordField);
