import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Heart = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 14 } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size - 1}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00076 1.39673C9.76251 -0.185015 12.485 -0.132515 14.1823 1.56773C15.8788 3.26873 15.9373 5.97773 14.3593 7.74473L7.99925 14.1137L1.64075 7.74473C0.0627545 5.97773 0.122005 3.26423 1.81775 1.56773C3.5165 -0.130265 6.23376 -0.187265 8.00076 1.39673V1.39673Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default Heart;
