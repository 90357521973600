import React from 'react';
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Globe = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 32 32',
      }}
    >
      <path
        d="M6.40039 16.9655H11.6845C11.8496 20.0208 12.8234 22.9774 14.5064 25.5327C12.3983 25.2016 10.4594 24.1812 8.99276 22.6311C7.52613 21.0811 6.61449 19.0887 6.40039 16.9655ZM6.40039 15.036C6.61449 12.9128 7.52613 10.9204 8.99276 9.37032C10.4594 7.82025 12.3983 6.79988 14.5064 6.46875C12.8234 9.02407 11.8496 11.9807 11.6845 15.036H6.40039ZM25.5994 15.036H20.3153C20.1503 11.9807 19.1764 9.02407 17.4934 6.46875C19.6015 6.79988 21.5404 7.82025 23.0071 9.37032C24.4737 10.9204 25.3853 12.9128 25.5994 15.036ZM25.5994 16.9655C25.3853 19.0887 24.4737 21.0811 23.0071 22.6311C21.5404 24.1812 19.6015 25.2016 17.4934 25.5327C19.1764 22.9774 20.1503 20.0208 20.3153 16.9655H25.6004H25.5994ZM13.6169 16.9655H18.3829C18.2241 19.5699 17.4039 22.0908 15.9999 24.2901C14.5959 22.0908 13.7757 19.5699 13.6169 16.9655ZM13.6169 15.036C13.7757 12.4316 14.5959 9.91065 15.9999 7.71138C17.4039 9.91065 18.2241 12.4316 18.3829 15.036H13.6169Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default Globe;
