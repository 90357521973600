import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const UserTalking = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={fillClassName}
    >
      <path
        d="M0.541687 12.4168C0.541687 11.2675 0.998233 10.1653 1.81089 9.35268C2.62355 8.54002 3.72575 8.08347 4.87502 8.08347C6.02429 8.08347 7.12649 8.54002 7.93915 9.35268C8.75181 10.1653 9.20835 11.2675 9.20835 12.4168H0.541687ZM4.87502 7.5418C3.0794 7.5418 1.62502 6.08743 1.62502 4.2918C1.62502 2.49618 3.0794 1.0418 4.87502 1.0418C6.67064 1.0418 8.12502 2.49618 8.12502 4.2918C8.12502 6.08743 6.67064 7.5418 4.87502 7.5418ZM9.88327 2.2248C10.1538 2.88031 10.2926 3.58267 10.2917 4.2918C10.2926 5.00094 10.1538 5.7033 9.88327 6.3588L8.97489 5.69797C9.1299 5.24537 9.20879 4.77021 9.20835 4.2918C9.20896 3.81343 9.13025 3.33827 8.97544 2.88564L9.88327 2.2248ZM11.6719 0.924805C12.1909 1.97121 12.4601 3.12375 12.4584 4.2918C12.4584 5.50135 12.1751 6.6448 11.6719 7.6588L10.7808 7.01097C11.1736 6.15837 11.3764 5.23055 11.375 4.2918C11.375 3.32114 11.1621 2.39976 10.7808 1.57264L11.6719 0.924805Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default UserTalking;