import {
  ServiceNow_PHOTOBOOTH,
  ManualLayoutConfig,
} from '@/components/custom/layouts/ManualLayout/config';

const serviceNow = {
  name: 'serviceNow',
  auth: {
    magicLinks: true,
  },
  expoConfig: ManualLayoutConfig.serviceNow_Expo,
  boothConfig: {
    '0e0b63a4-e46b-4e9b-b569-5a21a3153298': ServiceNow_PHOTOBOOTH,
  },
};

export default serviceNow;
