import React, { useRef, useEffect, useState, useContext } from 'react';
// component
import TabMenuOption from './TabMenuOption';
// styles
import styles from './styles.module.scss';
// types
import { ITabMenuOptionsProps } from './types';
import SmallRightCaret from '@/components/ui/new-icons/SmallRightCaret';
import classnames from 'classnames';
import { ScheduleMeetingRoomContext } from '@/utils/helpers';

const TabMenuOptions = (props: ITabMenuOptionsProps) => {
  const {
    menuType,
    onClick,
    options,
    selectedOptionIndex,
    isPublishedPollTab,
    hasBanner,
    isWebinar,
  } = props;
  const topTabRef = useRef<HTMLDivElement>(null);
  const [showIndicator, setShowIndicator] = useState(0);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);
  const { isMyMeetingRoomsModalView } = useContext(ScheduleMeetingRoomContext);

  const slide = shift => {
    if (!topTabRef || !topTabRef.current) return;

    topTabRef.current.scrollLeft += shift;

    if (shift < 1) {
      setscrollX(0);
    } else {
      setscrollX(scrollX + shift);
    }
    if (
      topTabRef.current?.scrollWidth - topTabRef.current?.scrollLeft === topTabRef.current?.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const onScroll = () => {
    if (!topTabRef || !topTabRef?.current) return;

    setscrollX(topTabRef.current?.scrollLeft);
    if (
      topTabRef.current?.scrollWidth - topTabRef.current?.scrollLeft === topTabRef.current?.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const handleClick = e => {
    if (menuType === 'primaryIconSidePanel' && topTabRef && e === 0) {
      setscrollX(0);
      return;
    }
    if (
      menuType === 'primaryIconSidePanel' &&
      topTabRef &&
      e === options.length - 1
    ) {
      slide(+20);
      return;
    }
    if (
      menuType === 'primaryIconSidePanel' &&
      topTabRef &&
      e > options.length / 2 &&
      e !== 0
    ) {
      slide(+60);
      return;
    }
    if (
      menuType === 'primaryIconSidePanel' &&
      topTabRef &&
      e < options.length / 2 &&
      e !== options.length - 1
    ) {
      slide(-60);
      return;
    }
    onClick(e);
  };

  useEffect(() => {
    if (
      menuType === 'primaryIconSidePanel' &&
      options[selectedOptionIndex] &&
      topTabRef
    ) {
      if (selectedOptionIndex === 0) {
        slide(-500);
        return;
      }
      if (selectedOptionIndex < options.length / 2 + 1) {
        slide('+50%');
      }
    }
  }, [selectedOptionIndex]);

  return (
    <>
      <div
        className={classnames(styles[`${menuType}OptionsWrapper`], {
          [styles.primaryIconSidePanelOptionsWrapperWebinar]:
            isWebinar && menuType === 'primaryIconSidePanel',
          [styles.hasCTABanner]: hasBanner,
        })}
      >
        {scrollX !== 0 && options.length > 5 && (
          <div className={styles.indicatorLeftWrapper}>
            <button
              type="button"
              className={styles.prevIcon}
              onClick={() => slide(-60)}
              aria-label="see more tab"
              title="see more tab"
            >
              <div className={styles.iconWrapper}>
                {showIndicator ? (
                  <span className={styles.unreadIndicator}></span>
                ) : (
                  ''
                )}
                <SmallRightCaret />
              </div>
            </button>
          </div>
        )}
        <div
          className={classnames(styles[`${menuType}OptionsContainer`], {
            [styles.lessthan5options]: options.length <= 5,
            [styles.modalPrimaryFillOptionsContainer]: isMyMeetingRoomsModalView,
            [styles.hasCTABanner]: hasBanner,
          })}
          ref={topTabRef}
          onScroll={onScroll}
          tabIndex={0}
          role="tablist"
        >
          {options.map((tabOption, index) => {
            const { id, content, textColor, badgeContent } = tabOption;
            const active = index === selectedOptionIndex;
            return (
              <TabMenuOption
                key={id}
                active={active}
                id={id}
                index={index}
                menuType={menuType}
                onClick={handleClick}
                textColor={textColor}
                isPublishedPollTab={isPublishedPollTab}
              >
                {content}
                {badgeContent ? (
                  <span className={styles.redBadge}>{badgeContent}</span>
                ) : null}
              </TabMenuOption>
            );
          })}
        </div>
        {!scrolEnd && options.length > 5 && (
          <div className={styles.indicatorRightWrapper}>
            <button
              type="button"
              className={styles.nextIcon}
              onClick={() => slide(+60)}
              aria-label="show more tabs"
            >
              <div className={styles.iconWrapper}>
                {showIndicator ? (
                  <span className={styles.unreadIndicator}></span>
                ) : (
                  ''
                )}
                <SmallRightCaret />
              </div>
            </button>
          </div>
        )}
      </div>
      {menuType === 'primaryIconSidePanel' && <div className={styles.ramp} />}
    </>
  );
};

export default TabMenuOptions;
