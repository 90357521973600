import React from 'react';
// styles + types
import { IIconProps } from '../types';

const PdfFileDynamicIcon = (props: IIconProps) => {
  const { size, active = false } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3572 0.000650883L34.6072 11.2507L34.6072 39.6667C34.6072 40.9553 33.5625 42 32.2738 42H2.44051C1.15184 42 0.107178 40.9553 0.107178 39.6667V2.33333C0.107178 1.04467 1.15185 0 2.44051 0H23.3627L23.3572 0.000650883Z"
        fill="#F0ECEC"
      />
      <path
        d="M23.3572 8.91667V0L34.6072 11.25H25.6905C24.4018 11.25 23.3572 10.2053 23.3572 8.91667Z"
        fill="#ABABAB"
      />
      {active && (
        <path d="M34.1515 17.25L25.2424 11.25H34.1515V17.25Z" fill="#E4DFDF" />
      )}
      <rect
        x="15.1072"
        y="21"
        width="25.5"
        height="13.5"
        rx="2.33333"
        fill={active ? 'url(#paint0_linear)' : '#ABABAB'}
      />
      <path
        d="M19.9822 30.5H21.5617V28.7727H22.4367C23.7634 28.7727 24.6526 27.9915 24.6526 26.7386C24.6526 25.5 23.7862 24.6818 22.4935 24.6818H19.9822V30.5ZM21.5617 27.5455V25.9432H22.1185C22.6725 25.9432 23.0049 26.2386 23.0049 26.7386C23.0049 27.2358 22.6725 27.5455 22.1185 27.5455H21.5617ZM27.8708 30.5C29.6435 30.5 30.7685 29.4091 30.7685 27.5909C30.7685 25.7727 29.6435 24.6818 27.8481 24.6818H25.6322V30.5H27.8708ZM27.2117 29.1591V26.0227H27.7685C28.6776 26.0227 29.1776 26.375 29.1776 27.5909C29.1776 28.8068 28.6776 29.1591 27.814 29.1591H27.2117ZM31.8994 30.5H33.4789V28.2273H35.7403V26.9545H33.4789V25.9545H35.9903V24.6818H31.8994V30.5Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="27.4697"
          y1="21"
          x2="27.4697"
          y2="34.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF792D" />
          <stop offset="1" stopColor="#B65824" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PdfFileDynamicIcon;
