import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';
import { IStageLayoutTemplateType } from '@/custom-config/types';

const template4: IStageLayoutTemplateType = {
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (numStreams >= 3) {
      return IStreamsDisplayMode.GRID;
    }
    return IStreamsDisplayMode.CENTER_STAGE;
  },
};
export default template4;
