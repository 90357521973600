import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';
import { IStageLayoutTemplateType } from '@/custom-config/types';

// NOTE - this is exactly the same as template1.ts so we are not using this...
const template5: IStageLayoutTemplateType = {
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      if (numStreams <= 3) {
        return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
      } else {
        return IStreamsDisplayMode.SPOTLIGHT;
      }
    }

    if (numStreams <= 3) {
      return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
    } else {
      return IStreamsDisplayMode.CONDENSED;
    }
  },
};
export default template5;
