import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Browser = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 30 } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2886 30.1542C7.00438 30.2265 0.230537 23.5699 0.158242 15.2857C0.0859468 7.0015 6.74259 0.227652 15.0268 0.155357C23.311 0.0830619 30.0848 6.7397 30.1571 15.0239C30.2294 23.3081 23.5728 30.0819 15.2886 30.1542ZM11.8232 26.6848C10.3159 23.5588 9.42903 20.1703 9.21149 16.7067L3.26421 16.7586C3.57623 19.0643 4.55116 21.2298 6.07057 22.9919C7.58997 24.7541 9.58844 26.037 11.8232 26.6848ZM12.2159 16.6805C12.4743 20.3369 13.5497 23.7641 15.2591 26.7823C16.9604 23.6542 17.9398 20.1849 18.1256 16.6289L12.2159 16.6805ZM27.0773 16.5508L21.13 16.6027C20.973 20.0695 20.1453 23.473 18.6929 26.6249C20.916 25.9381 22.8917 24.6205 24.3802 22.8322C25.8686 21.0438 26.8056 18.8616 27.0773 16.5508ZM3.23803 13.7587L9.18531 13.7068C9.34237 10.24 10.17 6.83652 11.6224 3.6847C9.39937 4.37144 7.4236 5.68905 5.93518 7.47742C4.44675 9.26579 3.50976 11.4479 3.23803 13.7587ZM12.1912 13.6806L18.098 13.6291C17.8506 10.077 16.8113 6.62533 15.0562 3.52723C13.355 6.65537 12.3755 10.1247 12.1897 13.6806L12.1912 13.6806ZM18.4922 3.62475C19.9994 6.75074 20.8863 10.1393 21.1039 13.6028L27.0511 13.5509C26.7391 11.2452 25.7642 9.07975 24.2448 7.31763C22.7254 5.55551 20.7269 4.27259 18.4922 3.62475Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default Browser;
