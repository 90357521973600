import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const ScreenShareOff = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.518 21.632L11.598 25.152C11.342 25.536 11.662 26.048 12.174 26.048H16.59H21.006C21.454 26.048 21.774 25.536 21.582 25.152L19.726 21.632C19.598 21.44 19.406 21.312 19.15 21.312H14.094C13.838 21.312 13.646 21.44 13.518 21.632Z"
        className={fillClassName}
      />
      <path
        d="M19.4699 15.424C19.2139 15.68 18.7659 15.68 18.5099 15.424L17.3579 14.208V18.112C17.3579 18.496 17.0379 18.816 16.6539 18.816C16.2699 18.816 15.9499 18.496 15.9499 18.112V14.208L14.7979 15.36C14.5419 15.616 14.0939 15.68 13.8379 15.36C13.5179 15.104 13.5179 14.656 13.7739 14.4L16.1419 11.904C16.2059 11.84 16.2699 11.84 16.3339 11.776C16.3979 11.776 16.5259 11.712 16.5899 11.712C16.6539 11.712 16.7819 11.712 16.8459 11.776L16.9099 11.84C16.9739 11.84 16.9739 11.904 17.0379 11.904L19.4059 14.4C19.7899 14.72 19.7899 15.168 19.4699 15.424Z"
        className={fillClassName}
      />
      <path
        d="M27.2139 7.168H6.09394C5.58194 7.168 5.19794 7.552 5.19794 8.064V22.4C5.19794 22.912 5.58194 23.296 6.09394 23.296H27.2139C27.7259 23.296 28.1099 22.912 28.1099 22.4V8.064C28.1099 7.552 27.7259 7.168 27.2139 7.168ZM16.6539 20.16C13.9019 20.16 11.7259 17.984 11.7259 15.232C11.7259 12.48 13.9019 10.304 16.6539 10.304C19.4059 10.304 21.5819 12.48 21.5819 15.232C21.5819 17.984 19.4059 20.16 16.6539 20.16Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default ScreenShareOff;
