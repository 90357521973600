import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const DownArrowIcon = (props: IIconProps) => {
  const { color = IContentColors.NEUTRAL_CONTRAST_3, size = 18 } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      className={fillClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00054 9.879L12.713 6.1665L13.7735 7.227L9.00054 12L4.22754 7.227L5.28804 6.1665L9.00054 9.879Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default DownArrowIcon;
