import { IContentColors } from '@/types';
import React from 'react';
// styles + types
import { IIconProps } from '../types';
import styles from '../styles.module.scss';

const CloseCircleFilled = (props: IIconProps) => {
  const { color = IContentColors.NEUTRAL_BASE_2, size = 24, className, onClick } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M11.3668 0.643765C17.2891 0.643766 22.0898 5.44447 22.0898 11.3668C22.0898 17.2891 17.2891 22.0898 11.3668 22.0898C5.44447 22.0898 0.643761 17.2891 0.643762 11.3668C0.643762 5.44447 5.44447 0.643765 11.3668 0.643765ZM11.3668 12.883L14.3993 15.9166L15.9166 14.3993L12.883 11.3668L15.9166 8.33433L14.3993 6.81702L11.3668 9.85057L8.33433 6.81702L6.81702 8.33433L9.85057 11.3668L6.81702 14.3993L8.33433 15.9166L11.3668 12.883Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default CloseCircleFilled;
