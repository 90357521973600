import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const VirtualBackgroundOn = (props: IIconProps) => {
  const { color = IContentColors.NEUTRAL_CONTRAST_4, size = 24 } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.1 8.875V11L11.1 3H8.975L3.1 8.875ZM3 4.725V6.85L6.85 3H4.725L3 4.725ZM15.6375 5.8875C15.8292 6.0625 16.0083 6.24167 16.175 6.425L19.6 3H17.475L15.05 5.425C15.25 5.55833 15.4458 5.7125 15.6375 5.8875ZM7.425 13.05L5.5 14.975C5.86667 14.8083 6.23333 14.65 6.6 14.5C6.96667 14.35 7.34167 14.2167 7.725 14.1L8.2 13.975C8.05 13.8417 7.9125 13.7 7.7875 13.55C7.6625 13.4 7.54167 13.2333 7.425 13.05ZM17.2875 8.5125C17.3625 8.80417 17.4167 9.10833 17.45 9.425L21 5.875V3.725L17 7.725C17.1167 7.95833 17.2125 8.22083 17.2875 8.5125ZM13.225 3L11.725 4.5H11.875H12C12.2667 4.5 12.5458 4.52083 12.8375 4.5625C13.1292 4.60417 13.3833 4.66667 13.6 4.75L15.35 3H13.225ZM3 13.25V15.375L6.75 11.6C6.66667 11.4167 6.60417 11.175 6.5625 10.875C6.52083 10.575 6.5 10.2833 6.5 10V9.8875V9.75L3 13.25ZM19.125 15.225C19.3583 15.3583 19.5667 15.5 19.75 15.65L21 14.4V12.275L18.4 14.875C18.65 14.975 18.8917 15.0917 19.125 15.225ZM16.3125 14.1375C16.4875 14.1958 16.6667 14.25 16.85 14.3L21 10.15V8L17.175 11.825C17.0417 12.1583 16.8542 12.5333 16.6125 12.95C16.3708 13.3667 16.0917 13.7083 15.775 13.975C15.9583 14.025 16.1375 14.0792 16.3125 14.1375ZM9.175 12.825C9.95833 13.6083 10.9 14 12 14C13.1 14 14.0417 13.6083 14.825 12.825C15.6083 12.0417 16 11.1 16 10C16 8.9 15.6083 7.95833 14.825 7.175C14.0417 6.39167 13.1 6 12 6C10.9 6 9.95833 6.39167 9.175 7.175C8.39167 7.95833 8 8.9 8 10C8 11.1 8.39167 12.0417 9.175 12.825ZM19 21H18.2222H17.4444H6.55556H5.77778H5V20.2105C5 17.1639 7.44222 14.6842 10.4444 14.6842H13.5556C16.557 14.6842 19 17.1639 19 20.2105V21Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default VirtualBackgroundOn;
