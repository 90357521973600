import React from 'react';
import classnames from 'classnames';
// components
import InputField from '@/components/ui/fields/InputField';
import { Search } from 'react-feather';
// types
import { ISearchFieldProps } from './types';
import { IFormFieldTypes } from '@/types/form';
import { IContentColors } from '@/types';
// styles
import { STANDARD_ICON_BUTTON_SIZE } from '@/styles/iconSizes';
import styles from './styles.module.scss';
import { getCssVar } from '@/utils/cssVars';

const SearchField = (props: ISearchFieldProps) => {
  const {
    handleSearchQueryChange,
    searchQuery,
    placeHolder,
    id,
    className,
    inputStyle,
    forceShowPlaceholder = false,
  } = props;
  return (
    <div className={classnames(styles.searchField, className)} id={id}>
      <Search
        size={STANDARD_ICON_BUTTON_SIZE}
        color={getCssVar(IContentColors.NEUTRAL_CONTRAST_2)}
      />
      <InputField
        fieldType={IFormFieldTypes.INPUT}
        onChange={handleSearchQueryChange}
        styleClass={inputStyle ? `${inputStyle}` : 'searchField'}
        value={searchQuery}
        widthStyles="flexGrow2"
        placeholder={placeHolder}
        forceShowPlaceholder={forceShowPlaceholder}
      />
    </div>
  );
};

export default SearchField;
