import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const VirtualBackgroundOff = (props: IIconProps) => {
  const { color = IContentColors.NEUTRAL_CONTRAST_4, size = 24 } = props;
  const fillClassName = styles[`fill-${color}`];
  const strokeClassName = styles[`stroke-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6842 14.6842L19 21L18.2222 21H17.4444H6.55556H5.77778H5V20.2105C5 17.1639 7.44222 14.6842 10.4444 14.6842H12.6842ZM16.4131 14.1704L16.0045 13.7619C16.2283 13.5336 16.431 13.2629 16.6125 12.95C16.8542 12.5333 17.0417 12.1583 17.175 11.825L21 8V10.15L16.85 14.3C16.7016 14.2595 16.556 14.2163 16.4131 14.1704ZM14.9441 12.7014L9.29855 7.05591C10.0567 6.35197 10.9572 6 12 6C13.1 6 14.0417 6.39167 14.825 7.175C15.6083 7.95833 16 8.9 16 10C16 11.0428 15.648 11.9432 14.9441 12.7014ZM8 10C8 11.1 8.39167 12.0417 9.175 12.825C9.95833 13.6083 10.9 14 12 14L8 10ZM8.17132 5.92868L11.1 3H8.975L7.10882 4.86618L8.17132 5.92868ZM4.9875 6.9875L6.05 8.05L3.1 11V8.875L4.9875 6.9875ZM6.04632 3.80368L6.85 3H5.24264L6.04632 3.80368ZM3 5L3.925 5.925L3 6.85L3 5ZM16.175 6.425C16.0083 6.24167 15.8292 6.0625 15.6375 5.8875C15.4458 5.7125 15.25 5.55833 15.05 5.425L17.475 3H19.6L16.175 6.425ZM5.5 14.975L7.425 13.05C7.54167 13.2333 7.6625 13.4 7.7875 13.55C7.9125 13.7 8.05 13.8417 8.2 13.975L7.725 14.1C7.34167 14.2167 6.96667 14.35 6.6 14.5C6.23333 14.65 5.86667 14.8083 5.5 14.975ZM17.45 9.425C17.4167 9.10833 17.3625 8.80417 17.2875 8.5125C17.2125 8.22083 17.1167 7.95833 17 7.725L21 3.725V5.875L17.45 9.425ZM11.725 4.5L13.225 3H15.35L13.6 4.75C13.3833 4.66667 13.1292 4.60417 12.8375 4.5625C12.5458 4.52083 12.2667 4.5 12 4.5H11.875H11.725ZM3 15.375V13.25L6.5 9.75V9.8875V10C6.5 10.2833 6.52083 10.575 6.5625 10.875C6.60417 11.175 6.66667 11.4167 6.75 11.6L3 15.375ZM19.75 15.65C19.5667 15.5 19.3583 15.3583 19.125 15.225C18.8917 15.0917 18.65 14.975 18.4 14.875L21 12.275V14.4L19.75 15.65Z"
        className={fillClassName}
      />
      <path
        d="M4 2.80005L21 19.8"
        strokeWidth="1.5"
        strokeLinecap="square"
        className={strokeClassName}
      />
    </svg>
  );
};

export default VirtualBackgroundOff;
