import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const UserPlusIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path className={fillClassName} d="M5.90411 7.50353V8.64901C5.40806 8.47363 4.87716 8.41984 4.35601 8.49215C3.83486 8.56446 3.33867 8.76076 2.90911 9.06457C2.47954 9.36838 2.12914 9.77083 1.88734 10.2381C1.64554 10.7054 1.51939 11.2239 1.51949 11.75L0.42334 11.7495C0.42317 11.0802 0.57621 10.4198 0.870734 9.81882C1.16526 9.21785 1.59345 8.69228 2.1225 8.28237C2.65155 7.87246 3.26741 7.58911 3.92289 7.45401C4.57838 7.31892 5.2561 7.33567 5.90411 7.50299V7.50353ZM4.80796 6.81734C2.99108 6.81734 1.51949 5.34575 1.51949 3.52888C1.51949 1.712 2.99108 0.240417 4.80796 0.240417C6.62483 0.240417 8.09642 1.712 8.09642 3.52888C8.09642 5.34575 6.62483 6.81734 4.80796 6.81734ZM4.80796 5.72119C6.01921 5.72119 7.00026 4.74013 7.00026 3.52888C7.00026 2.31763 6.01921 1.33657 4.80796 1.33657C3.59671 1.33657 2.61565 2.31763 2.61565 3.52888C2.61565 4.74013 3.59671 5.72119 4.80796 5.72119ZM8.09642 9.00965V7.36542H9.19257V9.00965H10.8368V10.1058H9.19257V11.75H8.09642V10.1058H6.45219V9.00965H8.09642Z" />
    </svg>
  );
};

export default UserPlusIcon;
