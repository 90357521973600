import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const ChatIcon = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 50 50"
      style={{
        enableBackground: 'new 0 0 50 50',
      }}
    >
      <g>
        <path
          className={fillClassName}
          d="M25.2,9.8c-8.3,0-15.1,6.7-15.1,15v13.6c0,0.8,0.6,1.4,1.4,1.4h13.7c4.1,0,7.9-1.7,10.6-4.4
          c2.7-2.7,4.4-6.5,4.4-10.6C40.2,16.5,33.5,9.8,25.2,9.8z M18,27.5c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
          S19,27.5,18,27.5z M25.2,27.5c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8S26.2,27.5,25.2,27.5z M32.4,27.5
          c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8S33.4,27.5,32.4,27.5z"
        />
      </g>
    </svg>
  );
};

export default ChatIcon;
