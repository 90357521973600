import React from 'react';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const CrossCircleIcon = props => {
  const {
    color = IContentColors.SILVER_CHALICE,
    circleColor = IContentColors.NEUTRAL_BASE_3,
    size = 20,
  } = props;

  const fillClassName = styles[`fill-${color}`];
  const fillCircleClassName = styles[`fill-${circleColor}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" className={fillCircleClassName} />
      <path
        d="M9.99999 9.05732L13.3 5.75732L14.2427 6.69999L10.9427 9.99999L14.2427 13.3L13.3 14.2427L9.99999 10.9427L6.69999 14.2427L5.75732 13.3L9.05732 9.99999L5.75732 6.69999L6.69999 5.75732L9.99999 9.05732Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default CrossCircleIcon;
