import React from 'react';
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Extension = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 32 32',
      }}
    >
      <path
        d="M20.8207 25.0984H17.0004V23.1395C17.001 22.9583 16.9649 22.7789 16.8942 22.6121C16.8236 22.4452 16.7199 22.2944 16.5894 22.1688C16.2607 21.8448 15.7824 21.6627 15.2793 21.6699C14.8317 21.6742 14.3999 21.8361 14.06 22.1272C13.8371 22.3244 13.5718 22.6655 13.5718 23.1738V25.0984H9.78412C9.15213 25.0978 8.54622 24.8464 8.09933 24.3995C7.65245 23.9527 7.40109 23.3468 7.40041 22.7148V18.9271H9.35941C9.75198 18.9271 10.1338 18.7592 10.4347 18.4557C10.6021 18.287 10.7344 18.0869 10.8242 17.8668C10.9139 17.6468 10.9592 17.4111 10.9575 17.1735C10.9447 16.2654 10.1973 15.4987 9.32512 15.4987H7.40041V11.6699C7.39917 11.3578 7.46133 11.0488 7.5831 10.7615C7.70488 10.4742 7.88374 10.2146 8.10884 9.99849C8.55838 9.56489 9.15954 9.32406 9.78412 9.32736H12.4944V8.70681C12.4944 8.33386 12.5687 7.96465 12.7129 7.62072C12.8572 7.27679 13.0685 6.96503 13.3345 6.70364C13.6005 6.44225 13.9159 6.23646 14.2623 6.09829C14.6088 5.96012 14.9792 5.89233 15.3521 5.89887C16.8731 5.92544 18.1104 7.20084 18.1104 8.74152V9.32736H20.8207C21.1304 9.32404 21.4376 9.38258 21.7243 9.49955C22.0111 9.61653 22.2716 9.78958 22.4906 10.0086C22.7096 10.2276 22.8827 10.4881 22.9996 10.7748C23.1166 11.0616 23.1752 11.3688 23.1718 11.6785V14.3887H23.7573C25.325 14.3887 26.6004 15.6315 26.6004 17.1589C26.6004 18.7279 25.3408 20.0046 23.7924 20.0046H23.1718V22.7148C23.1718 24.0292 22.1171 25.0984 20.8207 25.0984Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default Extension;
