import React from 'react';
import LogRocket from 'logrocket';
// components
import Modal from '@/components/ui/modals/Modal';
// styles + types
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import Label from '@/components/ui/content/Label';
import { IModalTypes } from '@/components/ui/modals/Modal/types';
import styles from './styles.module.scss';
import { RefreshCcw } from 'react-feather';
import { STANDARD_ICON_BUTTON_SIZE } from '@/styles/iconSizes';

class ErrorBoundaryModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
        LogRocket.captureException(error);
    }

    render() {
        if (!this.state.hasError) {
            return this.props.children;
        }
        return(
            <Modal
                title="It's all good!"
                styleClass={IModalTypes.NEUTRAL_LIGHTEST}
                className={styles.errorBoundaryModel}
                noHeader={true}
                noBodyPadding={true}
                autoTrigger = {true}
                render={({ setShow }) => (
                    <div>
                        <header className={styles.modalHeaderTitle}>
                                It's all good!
                        </header>
                        <div className={styles.errorBoundaryBody}>
                            <div className={styles.errorTagLine}>
                             Just <b>refresh this page</b> to continue attending the event!
                            </div>
                            <div
                                className={styles.refreshButton}
                                onClick={() => location.reload()}
                            >
                                <Button styleClass={IButtonTypes.NEW_DANGER} type="button">
                                <Label
                                    value="Refresh Now"
                                    className={styles.hoverLabel}
                                    styleClass="buttonSecondaryWithIcon"
                                />
                                &nbsp; &nbsp;
                                <RefreshCcw
                                    size={STANDARD_ICON_BUTTON_SIZE}
                                    className={styles['stroke-primary']}
                                />
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            />
        );
    }
}

export default ErrorBoundaryModal;