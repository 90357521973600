import { IEventCustomConfigType } from './types';

const umdETP: IEventCustomConfigType = {
  name: 'umdETP',
  auth: {
    magicLinks: true,
  },
  zoneNames: {
    stages: 'Meeting',
  },
};

export default umdETP;
