import { TOY_INSIDER_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';

const toyInsider = {
  name: 'toyInsider',
  auth: {
    magicLinks: true,
  },

  boothConfig: {
    'becde025-4a0d-4c47-8b9f-d7f15495e96b': TOY_INSIDER_PHOTOBOOTH,
    'e87c3d04-ff09-43d5-a662-864315f7df83': TOY_INSIDER_PHOTOBOOTH,
    // local
    '928548f3-6859-4807-887b-7e9aa9ec11ad': TOY_INSIDER_PHOTOBOOTH,
  },
};
export default toyInsider;