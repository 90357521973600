import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const MenuMoreVerticalDots = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      className={fillClassName}
      viewBox="0 0 4 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'rotate(90deg)' }}
    >
      <circle cx="1.92029" cy="1.5" r="1.5" fill={color}></circle>
      <circle cx="1.92029" cy="5.5" r="1.5" fill={color}></circle>
      <circle cx="1.92029" cy="9.5" r="1.5" fill={color}></circle>
    </svg>
  );
};

export default MenuMoreVerticalDots;
