import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const OTICONNEXT = {
  name: 'OTICONNEXT',
  zoneNames: {
    expo: 'Zone',
  },
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.OTICONNEXT_2022,

};

export default OTICONNEXT;
