import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const MicOn = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.978 19.392H19.674C20.186 19.392 20.57 19.008 20.57 18.496V7.29599C20.57 6.78399 20.186 6.39999 19.674 6.39999H13.978C13.466 6.39999 13.082 6.78399 13.082 7.29599V18.496C13.082 19.008 13.53 19.392 13.978 19.392Z"
        className={fillClassName}
      />
      <path
        d="M22.618 14.208C22.17 14.208 21.786 14.592 21.786 15.04V18.688C21.786 19.776 20.89 20.608 19.802 20.608H13.722C12.634 20.608 11.738 19.712 11.738 18.688V15.04C11.738 14.592 11.354 14.208 10.906 14.208C10.458 14.208 10.074 14.592 10.074 15.04V18.688C10.074 20.672 11.674 22.208 13.658 22.208H16.026V24.896C16.026 25.344 16.41 25.728 16.858 25.728C17.306 25.728 17.69 25.344 17.69 24.896V22.208H19.866C21.85 22.208 23.45 20.608 23.45 18.688V15.04C23.45 14.592 23.066 14.208 22.618 14.208Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default MicOn;
