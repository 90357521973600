import React from 'react';
// types
import { ITabMenuContentsProps } from './types';

const TabMenuContents = (props: ITabMenuContentsProps) => {
  const { children, selectedOptionIndex } = props;

  const modifiedChildren = React.Children.map(
    children || null,
    (child, index) => {
      if (selectedOptionIndex === index) {
        return child || null;
      }
      return null;
    },
  );

  return modifiedChildren.find(child => child !== null) || null;
};

export default TabMenuContents;
