import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Stop = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 10 10" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0.75H10C10.1989 0.75 10.3897 0.829018 10.5303 0.96967C10.671 1.11032 10.75 1.30109 10.75 1.5V10.5C10.75 10.6989 10.671 10.8897 10.5303 11.0303C10.3897 11.171 10.1989 11.25 10 11.25H1C0.801088 11.25 0.610322 11.171 0.46967 11.0303C0.329018 10.8897 0.25 10.6989 0.25 10.5V1.5C0.25 1.30109 0.329018 1.11032 0.46967 0.96967C0.610322 0.829018 0.801088 0.75 1 0.75Z" className={fillClassName} />
    </svg>
  );
};

export default Stop;
