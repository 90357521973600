import React from 'react';
// styles + types
import { IIconProps } from '../types';
import styles from '../styles.module.scss';

const ScreenshareOffDynamicIcon = (props: IIconProps) => {
  const { color, size, active = false } = props;

  const fillClassNames = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 49 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6276 30.6448L13.5597 38.1026C13.0173 38.9162 13.6953 40.0009 14.78 40.0009H24.1362H33.4924C34.4415 40.0009 35.1195 38.9162 34.7127 38.1026L30.7804 30.6448C30.5092 30.238 30.1024 29.9668 29.5601 29.9668H18.8479C18.3055 29.9668 17.8988 30.238 17.6276 30.6448Z"
        fill="#F0ECEC"
      />
      {active && (
        <rect
          x="13.0003"
          y="5.82031"
          width="22.2463"
          height="22.2463"
          rx="3.91193"
          fill="url(#paint0_linear)"
        />
      )}
      <path
        d="M30.2379 17.4915C29.6955 18.0339 28.7463 18.0339 28.204 17.4915L25.7632 14.9152V23.1866C25.7632 24.0002 25.0852 24.6782 24.2717 24.6782C23.4581 24.6782 22.7801 24.0002 22.7801 23.1866V14.9152L20.3394 17.356C19.797 17.8983 18.8478 18.0339 18.3054 17.356C17.6274 16.8136 17.6274 15.8644 18.1698 15.322L23.1869 10.0337C23.3225 9.89815 23.4581 9.89815 23.5937 9.76255C23.7293 9.76255 24.0005 9.62695 24.1361 9.62695C24.2717 9.62695 24.5429 9.62695 24.6784 9.76255L24.814 9.89815C24.9496 9.89815 24.9496 10.0337 25.0852 10.0337L30.1023 15.322C30.9159 16 30.9159 16.9492 30.2379 17.4915Z"
        fill="#F0ECEC"
      />
      <path
        d="M46.6452 0H1.89835C0.813579 0 0 0.813579 0 1.89835V32.272C0 33.3567 0.813579 34.1703 1.89835 34.1703H46.6452C47.73 34.1703 48.5435 33.3567 48.5435 32.272V1.89835C48.5435 0.813579 47.73 0 46.6452 0ZM24.2718 27.5261C18.4411 27.5261 13.8308 22.9158 13.8308 17.0852C13.8308 11.2545 18.4411 6.64423 24.2718 6.64423C30.1024 6.64423 34.7127 11.2545 34.7127 17.0852C34.7127 22.9158 30.1024 27.5261 24.2718 27.5261Z"
        fill="#F0ECEC"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="24.1234"
          y1="5.82031"
          x2="24.1234"
          y2="28.0666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF792D" />
          <stop offset="1" stopColor="#B65824" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ScreenshareOffDynamicIcon;
