import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const VideoOff = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.784 9.69063C6.272 9.69063 5.888 10.0746 5.888 10.5866V21.6586L17.856 9.69063H6.784V9.69063Z"
        className={fillClassName}
      />
      <path
        d="M19.584 18.8426C19.584 18.8426 19.648 13.7226 19.648 13.6586L9.59998 23.7066H19.712C20.16 23.7066 20.608 23.3226 20.608 22.8106V20.5066L20.48 20.4426C19.904 20.0586 19.584 19.4826 19.584 18.8426Z"
        className={fillClassName}
      />
      <path
        d="M25.664 11.2266C25.536 11.2266 21.184 13.7226 21.184 13.7226C20.992 13.8506 20.864 14.0426 20.864 14.2346V18.8426C20.864 19.0346 20.928 19.2266 21.12 19.3546C21.12 19.3546 25.152 22.1066 25.664 22.1706C25.984 22.2346 26.304 21.9146 26.304 21.5306V11.8666C26.304 11.4826 25.984 11.1626 25.664 11.2266Z"
        className={fillClassName}
      />
      <path
        d="M21.504 8.92263C21.376 8.79463 21.184 8.66663 20.992 8.66663C20.8 8.66663 20.608 8.73063 20.48 8.92263L19.712 9.69063L10.56 18.7786L8.95998 20.3786L6.01597 23.3226L5.95198 23.3866C5.63198 23.7066 5.63198 24.1546 5.95198 24.4746C6.07998 24.6026 6.27198 24.7306 6.46398 24.7306C6.65598 24.7306 6.84797 24.6666 6.97597 24.4746L7.67998 23.7706L10.368 21.0826L11.072 20.3786L20.48 10.9706L21.376 10.0746C21.76 9.69063 21.76 9.17863 21.504 8.92263Z"
        fill="#FF4C4C"
      />
    </svg>
  );
};

export default VideoOff;
