import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const QuestionIcon = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 50 50"
      style={{
        enableBackground: 'new 0 0 50 50',
      }}>
      <g>
        <g>
          <g>
            <path
              className={fillClassName}
              d="M32.7,29.7c-0.7,0-1.2,0-1.8-0.1c-2.9-0.4-5.6-2-7.3-4.4c-1.8-2.5-2.4-5.5-1.9-8.4c0.1-0.8,0.3-1.5,0.6-2.1
              c-1.2-0.1-2.4,0.1-3.6,0.3c-5.5,1.3-9.3,6.3-9.4,12v11.5c0,0.6,0.5,1.1,1.1,1.1h11.1c0.7,0,1.4,0,2.1-0.1
              c5.2-0.8,9.3-4.8,10.3-9.8H32.7z M14.6,31c-0.9,0-1.6-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7c1,0,1.7,0.8,1.7,1.7
              C16.2,30.3,15.4,31,14.6,31z M21.1,31c-0.9,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7c1,0,1.7,0.8,1.7,1.7C22.8,30.3,22,31,21.1,31z
              "
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className={fillClassName}
              d="M34.6,10c-0.7-0.1-1.4-0.2-2.1-0.2c-3.5,0-6.7,2-8.1,5.1c-0.3,0.7-0.5,1.4-0.7,2.2
              c-0.9,5.1,2.6,9.8,7.5,10.5c0.5,0.1,1,0.1,1.5,0.1h7.7c0.6,0,1.1-0.5,1.1-1.1v-7.9C41.5,14.8,38.4,10.8,34.6,10z M32.8,24.5
              c-0.6,0-1-0.5-1-1c0-0.6,0.5-1.1,1-1.1s1,0.5,1,1.1C33.8,24,33.4,24.5,32.8,24.5z M35.5,18.8l-1,0.5c-0.6,0.3-0.8,0.6-0.8,1.1V21
              h-1.8v-1.2c0.1-0.9,0.5-1.5,1.3-1.8l1.1-0.5c0.6-0.3,1-0.7,1-1.2c0-0.8-0.7-1.3-1.9-1.3c-1.1,0-1.9,0.4-2.1,1.1
              c-0.1,0.3-0.4,0.6-0.7,0.6h-0.2c-0.6,0-1-0.6-0.8-1.1c0.5-1.4,2-2.3,4-2.3c2.2,0,3.7,1.1,3.7,3C37.3,17.5,36.5,18.3,35.5,18.8z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default QuestionIcon;
