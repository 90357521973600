import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const BroadcastResume = (props: IIconProps) => {
  const { color = IContentColors.WARNING, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 11 12"
      xmlns="http://www.w3.org/2000/svg"
      className={fillClassName}
    >
      <path
        d="M1.45998 0.53262L10.2167 5.64095C10.2794 5.67769 10.3315 5.73021 10.3676 5.79329C10.4038 5.85637 10.4228 5.92782 10.4228 6.00054C10.4228 6.07325 10.4038 6.1447 10.3676 6.20778C10.3315 6.27087 10.2794 6.32338 10.2167 6.36012L1.45998 11.4685C1.39654 11.5055 1.32444 11.5251 1.25098 11.5252C1.17752 11.5254 1.10533 11.5062 1.04171 11.4695C0.978086 11.4327 0.925302 11.3799 0.888706 11.3162C0.852109 11.2525 0.833001 11.1802 0.833317 11.1068V0.89262C0.833295 0.819306 0.852618 0.747284 0.889334 0.683826C0.926051 0.620369 0.978861 0.567722 1.04243 0.531203C1.106 0.494683 1.17808 0.475584 1.2514 0.475832C1.32471 0.476081 1.39666 0.49567 1.45998 0.53262Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default BroadcastResume;
