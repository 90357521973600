import { ADOBE_TRANSCEND_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';
import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';
import { IEventCustomConfigType } from '@/custom-config/types';

const ngis: IEventCustomConfigType = {
  name: 'ngis',
  auth: {
    magicLinks: true,
  },
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      if (numStreams <= 3) {
        return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
      } else {
        return IStreamsDisplayMode.SPOTLIGHT;
      }
    }

    if (numStreams <= 3) {
      return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
    } else {
      return IStreamsDisplayMode.CONDENSED;
    }
  },
  boothConfig: {
    'bc852652-da78-4454-97e5-c17b9a76b9d0': ADOBE_TRANSCEND_PHOTOBOOTH,
  },
};
export default ngis;
