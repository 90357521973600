import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const worldbankSB = {
  name: 'worldbankSB',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.WORLDBANK_EXPO_OUTER1,

};

export default worldbankSB;

