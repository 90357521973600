import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const BroadcastPause = (props: IIconProps) => {
  const { color = IContentColors.WARNING, size } = props;
  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      className={fillClassName}
    >
      <path
        d="M7.49967 0.833333C7.49967 0.61232 7.58747 0.400358 7.74375 0.244078C7.90003 0.0877975 8.11199 0 8.33301 0C8.55402 0 8.76598 0.0877975 8.92226 0.244078C9.07854 0.400358 9.16634 0.61232 9.16634 0.833333V9.16667C9.16634 9.38768 9.07854 9.59964 8.92226 9.75592C8.76598 9.9122 8.55402 10 8.33301 10C8.11199 10 7.90003 9.9122 7.74375 9.75592C7.58747 9.59964 7.49967 9.38768 7.49967 9.16667V0.833333ZM0.833008 0.833333C0.833008 0.61232 0.920805 0.400358 1.07709 0.244078C1.23337 0.0877975 1.44533 0 1.66634 0C1.88735 0 2.09932 0.0877975 2.2556 0.244078C2.41188 0.400358 2.49967 0.61232 2.49967 0.833333V9.16667C2.49967 9.38768 2.41188 9.59964 2.2556 9.75592C2.09932 9.9122 1.88735 10 1.66634 10C1.44533 10 1.23337 9.9122 1.07709 9.75592C0.920805 9.59964 0.833008 9.38768 0.833008 9.16667V0.833333Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default BroadcastPause;
