import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const ImageAdd = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M10 6.49988V7.99988H11.5V8.99988H10V10.4999H9V8.99988H7.5V7.99988H9V6.49988H10ZM10.004 0.499878C10.278 0.499878 10.5 0.722378 10.5 0.996378V5.49988H9.5V1.49988H1.5V8.49938L6.5 3.49988L8 4.99988V6.41438L6.5 4.91438L2.9135 8.49988H6.5V9.49988H0.996C0.864407 9.49975 0.738248 9.44738 0.645245 9.35428C0.552241 9.26118 0.5 9.13497 0.5 9.00338V0.996378C0.500915 0.865067 0.55345 0.739387 0.646256 0.646487C0.739063 0.553587 0.86469 0.500925 0.996 0.499878H10.004ZM3.5 2.49988C3.76522 2.49988 4.01957 2.60523 4.20711 2.79277C4.39464 2.98031 4.5 3.23466 4.5 3.49988C4.5 3.76509 4.39464 4.01945 4.20711 4.20698C4.01957 4.39452 3.76522 4.49988 3.5 4.49988C3.23478 4.49988 2.98043 4.39452 2.79289 4.20698C2.60536 4.01945 2.5 3.76509 2.5 3.49988C2.5 3.23466 2.60536 2.98031 2.79289 2.79277C2.98043 2.60523 3.23478 2.49988 3.5 2.49988Z" className={fillClassName} />
    </svg>
  );
};

export default ImageAdd;
