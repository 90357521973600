import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';
import { AZ_GIVE_PHOTOBOOTH1 } from '@/components/custom/layouts/ManualLayout/config';
import { IEventCustomConfigType } from '@/custom-config/types';

const aznet: IEventCustomConfigType = {
  name: 'aznet',
  auth: {
    magicLinks: true,
  },
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (numStreams === 1) {
      return IStreamsDisplayMode.GRID;
    } else if (numStreams < 4) {
      return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
    } else {
      return IStreamsDisplayMode.SPOTLIGHT;
    }
  },
  boothConfig: {
    // Production booth ID
    'f6610124-ebe0-45e7-8f65-c863f2554996': AZ_GIVE_PHOTOBOOTH1,
  },
};

export default aznet;
