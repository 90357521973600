import React, { useEffect, useState } from 'react';
import { IImageCarouselProps } from './types';
import Image from '../Image';

const ImageCarousel = (props: IImageCarouselProps) => {
  const { src = [] } = props;
  const [imageSrc, setImageSrc] = useState();

  useEffect(() => {
    if (src.length <= 0) {
      return;
    }

    let currentIndex = 0;
    setImageSrc(src[0]);
    const imageInterval = setInterval(() => {
      currentIndex = currentIndex + 1;
      const arrayIdx = currentIndex % src.length;
      setImageSrc(src[arrayIdx]);
    }, 3000);

    return () => {
      clearInterval(imageInterval);
    }
  }, []);

  if (!imageSrc) {
    return <></>;
  }

  const imageProps = {
    ...props,
    src: imageSrc
  };
  return <Image {...imageProps} />
};

export default ImageCarousel;
