import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';

const llegoogle = {
    name: 'llegoogle',
    zoneNames: {
        expo:'PhotoOp',
        networking: 'Speed Dating'
      },
      getDisplayMode: (numStreams, hasScreenshareStream) => {
        if (hasScreenshareStream) {
          if (numStreams <= 3) {
            return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
          } else {
            return IStreamsDisplayMode.SPOTLIGHT;
          }
        }
    
        if (numStreams <= 3) {
          return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
        } else {
          return IStreamsDisplayMode.CONDENSED;
        }
      },
    };
    export default llegoogle;