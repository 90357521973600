import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const satairSB = {
  name: 'satairSB',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.SATAIRSB_EXPO,
  boothConfig: {
    'da820fe6-c333-4d0d-8e58-a3c981b094fa': ManualLayoutConfig.SATAIRSB_PHOTOBOOTH,
 },


};

export default satairSB;
