import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import {  X } from 'react-feather';
// styles + types
import styles from './styles.module.scss';
import useOutsideClick from '@/hooks/use-outside-click';
import Button, {
  IButtonTypes,
  IButtonMargins,
} from '@/components/ui/buttons/Button';
const PromptsModal = ({
  autoTrigger=false,
  onShowChange,
  title,
  disableClose=false,
  subTitle,
  trigger,
  id,
  onModalOutSideClick,
  handleCloseManually = false,
  modalCloseIconColor = '#cdcdcd',
  rootId,
  primaryButtonProps={},
  secondaryButtonProps,
}: any) => {
  const [show, setShow] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (autoTrigger) {
      setShow(true);
    }
  }, [autoTrigger]);

  useEffect(() => {
    onShowChange && onShowChange(show);
  }, [show]);

  useEffect(() => {
    if (show && modalRef.current) {
      modalRef.current.focus();
    }
  }, [show, modalRef?.current]);

  const ref = useRef();
  useOutsideClick(ref, () => {
    onModalOutSideClick && onModalOutSideClick();
  });

  const modalRoot = rootId
    ? document.getElementById(rootId)
    : document.getElementById('modal') || document.getElementById('app-modal');

  return (
    <>
      {trigger && trigger({ setShow })}
      {show &&
        modalRoot &&
        createPortal(
          <div id={id} className={styles.overlay}>
            <div
              className={
                styles.modal}
              ref={ref}
            >
              <div tabIndex={1}
              aria-label='dialog box'
              ref={modalRef}
               className ={styles.container}>
                {!disableClose &&
              <button
                  className={styles.modalClose}
                  type="button"
                  onClick={() => {
                    !handleCloseManually && setShow(false);
                  }}
                  tabIndex={1}
                  aria-label="close popup"
                  title="close popup"
                >
                  <X size={24} color={modalCloseIconColor} />
                </button>}
              <header
                className={
                  styles.modalHeader
                }
                tabIndex={1}
                aria-label={title}
              >
                {title}
                <div tabIndex={1} aria-label={`${subTitle} subtitle`} className={styles.subTitle}>
                  {
                    subTitle.split("$n").map((item: string, key: number) => {
                      return <div key={key}>{item}</div>
                    })
                  }
                
                </div>
              </header>
              <div className={styles.buttonBody}>
                {secondaryButtonProps &&
                <Button
                tabIndex={1}
                {...secondaryButtonProps}
                onClick={() => {
                  if(secondaryButtonProps.onClick){
                    secondaryButtonProps.onClick({setShow});
                  }
                  else{
                    setShow(false);
                  }
                }}
              />
              }
                <Button
                tabIndex={1}
                {...primaryButtonProps}
                onClick={() => {
                  if(primaryButtonProps.onClick){
                    primaryButtonProps.onClick({setShow});
                  }
                  else{
                    setShow(false);
                  }
                }}
              />
              </div>
            </div>
            </div>
          </div>,
          modalRoot,
        )}
    </>
  );
};

export default PromptsModal;