import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const TwitterNegative = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 18 16" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M5.66262 15.313C12.4534 15.313 16.1687 9.68558 16.1687 4.80694C16.1687 4.64874 16.1652 4.48702 16.1582 4.32882C16.8809 3.80615 17.5046 3.15874 18 2.41702C17.3269 2.7165 16.6122 2.91209 15.8804 2.9971C16.651 2.53523 17.2279 1.80965 17.5043 0.954874C16.7794 1.38447 15.9867 1.68751 15.1601 1.85101C14.6031 1.25922 13.8667 0.867387 13.0648 0.736088C12.2628 0.604789 11.4399 0.741337 10.7233 1.12462C10.0067 1.50791 9.43635 2.11658 9.10039 2.85653C8.76442 3.59649 8.68157 4.42651 8.86465 5.21827C7.39687 5.14462 5.96095 4.76333 4.64999 4.09912C3.33902 3.43492 2.18227 2.50263 1.25473 1.36269C0.7833 2.17548 0.639042 3.13728 0.851273 4.05262C1.0635 4.96795 1.6163 5.76813 2.3973 6.29054C1.81097 6.27192 1.23748 6.11406 0.724219 5.82999V5.87569C0.723693 6.72866 1.01857 7.55549 1.55873 8.21563C2.09889 8.87576 2.85099 9.32847 3.68719 9.49679C3.14404 9.6454 2.57399 9.66705 2.02113 9.56007C2.25709 10.2936 2.71618 10.9352 3.33433 11.3953C3.95248 11.8554 4.69884 12.111 5.46926 12.1265C4.16132 13.1539 2.54563 13.7111 0.882422 13.7085C0.587465 13.7081 0.292799 13.69 0 13.6544C1.68964 14.7384 3.65515 15.3141 5.66262 15.313Z" className={fillClassName}/>
    </svg>
  );
};

export default TwitterNegative;
