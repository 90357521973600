import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import eventAPI from '@/api/event';
import videoAPI from '@/api/video';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as UpChunk from '@mux/upchunk';
import { IVideoStatusType } from '@/types/video';
import { getVideoMuxUrl } from '@/utils/helpers';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

const getPdfPageCount = async (file: File): Promise<number> => {
  const contentBuffer = await readFileAsync(file);
  return new Promise((resolve, reject) => {
    const typedArray = new Uint8Array(contentBuffer as ArrayBufferLike);
    const loadingTask = pdfjs.getDocument(typedArray);
    loadingTask.promise
      .then(pdf => {
        resolve(pdf.numPages);
      })
      .catch(err => reject(err));
  });
};

export enum IUploadType {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  PDF_FILE = 'pdfFile',
  ALL = 'all',
}

export enum IUploadSizeLimit {
  ONE_MB = 1024,
  FIVE_MB = 5120,
  TEN_MB = 10240,
  FIFTEEN_MB = 15360,
  TWENTY_MB = 20480,
}

export enum IUploadFileType {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  GIF = 'image/gif',
  MP4 = 'video/mp4',
  MOV = 'video/mov',
  PDF = 'application/pdf',
}

interface useUploadProps {
  type: IUploadType,
  fileType: any,
  maxFileSize: any,
  minWidth: any,
  maxWidth: any,
  minHeight: any,
  maxHeight: any,
}
export interface IUploadMetadata {
  totalPages?: number;
}
interface useUploadReturn {
  startUpload: () => void;
  fileName: string;
  setFileName: (name: string) => void;
  isUploading: boolean;
  readyToDisplay: boolean;
  errorUploading: boolean;
  errorMessage: string;
  resetEverything: () => void;
  videoUploadingStatusText: string;
  setVideoUploadingStatusText: (name: string) => void;
  metadata: IUploadMetadata;
}

const useUploader = ({
  type,
  fileType = (() => {
    if (type === IUploadType.IMAGE) {
      return 'jpg,jpeg,png';
    }
    if (type === IUploadType.VIDEO) {
      return 'mp4';
    }
    if (type === IUploadType.PDF_FILE) {
      return IUploadFileType.PDF;
    }
    return '';
  })(),
  maxFileSize = (() => {
    if (type === IUploadType.IMAGE) {
      return 4;
    }
    if (type === IUploadType.VIDEO) {
      return 6000;
    }
    if (type === IUploadType.PDF_FILE) {
      return 100;
    }
    return 0;
  })(),
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
}: useUploadProps): useUploadReturn => {
  const [fileName, setFileName] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [readyToDisplay, setReadyToDisplay] = useState(false);
  const [errorUploading, setErrorUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [videoId, setVideoId] = useState('');
  const [videoStatus, setVideoStatus] = useState('');
  const [upChunkStatus, setUpChunkStatus] = useState('');
  const [videoUploadingStatusText, setVideoUploadingStatusText] = useState('Upload Video');
  const [metadata, setMetadata] = useState<IUploadMetadata>({});

  const resetEverything = () => {
    setFileName('');
    setIsUploading(false);
    setReadyToDisplay(false);
    setVideoId('');
    setVideoStatus('');
    setUpChunkStatus('');
    setErrorMessage(null);
    setErrorUploading(false);
    setMetadata({});
  }

  const getBackendImageUploadPermission = async (fileName: string) => {
    const { data } = await eventAPI.getImageUploadUrl(fileName);
    return data;
  };

  const uploadFileToS3 = async (s3Url: string, file: any) => {
    const s3UploadResponse = await eventAPI.uploadS3Image(s3Url, file);
    return s3UploadResponse;
  }

  const getMuxUrl = async () => {
    var response = await videoAPI.getMuxUrl(eventId).catch(function (error) {
      return null;
    });
    if (!response) {
      return null;
    }
    return response.data;
  }

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>, tempInput: HTMLInputElement) => {
    setIsUploading(true);
    e.preventDefault();
    const fileUUID = uuidv4();
    const targetFiles = e.target.files;
    if (targetFiles) {
      // extract file and get its properties
      const chosenFile = targetFiles[0];
      const { name: chosenFileName } = chosenFile;
      const lastDotIndex = chosenFileName.lastIndexOf('.');
      const fileExtension = chosenFileName.substring(lastDotIndex + 1);
      let fileTypes = fileType.split(',');

      if (tempInput.accept !== 'video/*') {

        // update the file with the new uuid name
        const fileName = `${fileUUID}.${fileExtension}`;
        const blob = chosenFile.slice(0, chosenFile.size, chosenFile.type);
        const renamedFile = new File([blob], fileName, { type: chosenFile.type });

        // get the pre-signed request from the backend
        const { presignedPutUrl: s3UploadUrl } = await getBackendImageUploadPermission(fileName);

        if (type === IUploadType.IMAGE) {

          if (!fileTypes.includes(fileExtension.toLocaleLowerCase().trim())) {
            dispatch({ type: 'global/addDangerToast', payload: { description: 'Please select a valid Image file.' } });
            setIsUploading(false);
            setErrorMessage('Please select a valid Image file.');
            return;
          }
          if ((chosenFile.size / Math.pow(1024, 2)) > maxFileSize) {
            dispatch({ type: 'global/addDangerToast', payload: { description: `Please select image file below ${maxFileSize} MB.` } });
            setIsUploading(false);
            setErrorMessage(`Please select image file below ${maxFileSize} MB.`);
            return
          }
          var reader = new FileReader();
          reader.readAsDataURL(chosenFile);
          reader.onload = async function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = async function () {
              var height = this.height;
              var width = this.width;

              if (maxWidth || maxHeight || minWidth || minHeight) {
                if (minWidth && width < minWidth) {
                  errorMessage
                  dispatch({ type: 'global/addDangerToast', payload: { description: `File minimum width should be ${minWidth} pixels` } });
                  setErrorMessage(`File minimum width should be ${minWidth} pixels`);
                  setIsUploading(false);
                  return;
                }
                if (minHeight && height < minHeight) {
                  dispatch({ type: 'global/addDangerToast', payload: { description: `File minimum height should be ${minHeight} pixels` } });
                  setErrorMessage(`File minimum height should be ${minHeight} pixels`)
                  setIsUploading(false);
                  return;
                }
                if (maxWidth && width > maxWidth) {
                  dispatch({ type: 'global/addDangerToast', payload: { description: `File maximum width should be ${maxWidth} pixels` } });
                  setErrorMessage(`File maximum width should be ${maxWidth} pixels`);
                  setIsUploading(false);
                  return;
                }
                if (maxHeight && height > maxHeight) {
                  dispatch({ type: 'global/addDangerToast', payload: { description: `File maximum height should be ${maxHeight} pixels` } });
                  setErrorMessage(`File maximum height should be ${maxHeight} pixels`);
                  setIsUploading(false);
                  return;
                }
              }
              const uploadedS3File = await uploadFileToS3(s3UploadUrl, renamedFile);
              if (uploadedS3File) {
                setFileName(fileName);
                setReadyToDisplay(true);
                // onChange(fileName);
              } else {
                console.log("Oops there's an error when uploading file to s3. :(")
                setErrorUploading(true);
                setErrorMessage("There's an error when uploading file");
                setReadyToDisplay(false);
              }
              setIsUploading(false);
              tempInput.remove();
            };
          }
        } else {
          if (maxFileSize && chosenFile.size / 1024 ** 2 > maxFileSize) {
            dispatch({
              type: 'global/addDangerToast',
              payload: {
                description: `Please select a file with size less than ${maxFileSize} MB.`,
              },
            });
            setIsUploading(false);
            setErrorMessage(
              `Please select a file with size less than ${maxFileSize} MB.`,
            );
            setErrorUploading(true);
            return;
          }
          if (type === IUploadType.PDF_FILE) {
            try {
              const totalPages = await getPdfPageCount(renamedFile);
              if (totalPages <= 0) {
                throw new Error('Total pages in pdf should be greater than 0');
              }
              setMetadata({ ...metadata, totalPages });
            } catch (err) {
              console.error('Error getting pdf page count', err);
              setErrorMessage("There's an error when uploading file");
              setErrorUploading(true);
              setReadyToDisplay(false);
              setIsUploading(false);
              tempInput.remove();
              return;
            }
          }
          // upload the file to s3
          const uploadedS3File = await uploadFileToS3(s3UploadUrl, renamedFile);
          if (uploadedS3File) {
            setFileName(fileName);
            setReadyToDisplay(true);
            // onChange(fileName);
          } else {
            console.log("Oops there's an error when uploading file to s3. :(")
            setErrorMessage("There's an error when uploading file");
            setErrorUploading(true);
            setReadyToDisplay(false);
          }
          setIsUploading(false);
          tempInput.remove();
        }
      } else {
        //below code for video upload
        if (!fileTypes.includes(fileExtension.toLocaleLowerCase().trim())) {
          dispatch({ type: 'global/addDangerToast', payload: { description: 'Please select a valid video file.' } });
          setErrorMessage('Please select a valid video file.');
          setIsUploading(false);
          return;
        }

        if ((chosenFile.size / Math.pow(1024, 2)) > maxFileSize) {
          let fileSizeFormat = `${maxFileSize} MB`;
          if(maxFileSize >= 1000){
            fileSizeFormat = maxFileSize/1000 + ' GB';
          }
          dispatch({ type: 'global/addDangerToast', payload: { description: `Please select video file below ${fileSizeFormat}` } });
          setErrorMessage(`Please select video file below ${fileSizeFormat}`);
          setIsUploading(false);
          return
        }
        var video = document.createElement('video');
        video.onloadedmetadata = async () => {
          let videoWidth = video.videoWidth;
          let videoHeight = video.videoHeight;

          if (minWidth && videoWidth < minWidth) {
            const message = `Video frame minimum width should be ${minWidth} pixels`
            dispatch({ type: 'global/addDangerToast', payload: { description: message } });
            setErrorMessage(message);
            setIsUploading(false);
            return;
          }
          if (minHeight && videoHeight < minHeight) {
            const message = `Video frame minimum height should be ${minHeight} pixels`;
            dispatch({ type: 'global/addDangerToast', payload: { description: message } });
            setErrorMessage(message);
            setIsUploading(false);
            return;
          }
          if (maxWidth && videoWidth > maxWidth) {
            const message = `Video frame maximum width should be ${maxWidth} pixels`;
            dispatch({ type: 'global/addDangerToast', payload: { description: message } });
            setErrorMessage(message);
            setIsUploading(false);
            return;
          }
          if (maxHeight && videoHeight > maxHeight) {
            const message = `Video frame maximum height should be ${maxHeight} pixels`;
            dispatch({ type: 'global/addDangerToast', payload: { description: message } });
            setErrorMessage(message);
            setIsUploading(false);
            return;
          }

          setVideoUploadingStatusText("Uploading Video (0%)");
          const uploadResponse = await getMuxUrl();
          if (!uploadResponse) {
            setErrorUploading(true);
            setReadyToDisplay(false);
            setIsUploading(false);
            tempInput.remove();
            return;
          }
          setVideoId(uploadResponse.video_id);
          setVideoStatus(IVideoStatusType.UPLOADING);
          setUpChunkStatus('uploading');
          const upload = UpChunk.createUpload({
            endpoint: uploadResponse.mux_upload_url,
            file: targetFiles[0],
            chunkSize: 5120, // Uploads the file in ~5mb chunks
          });

          // subscribe to events
          upload.on('error', err => {
            console.error('💥 🙀', err.detail);
            setErrorUploading(true);
            setReadyToDisplay(false);
          });

          upload.on('progress', progress => {
            console.log(`So far we've uploaded ${progress.detail}% of this file.`);
            setVideoUploadingStatusText(parseInt(progress.detail) < 100 ? `Uploading Video (${parseInt(progress.detail)}%)` : 'Processing your Video...');
            setUpChunkStatus(parseInt(progress.detail) < 100 ? 'progress' : 'success');
          });

          upload.on('success', () => {
            console.log("Wrap it up, we're done here. 👋");
            setVideoUploadingStatusText("Processing your Video...");
            setUpChunkStatus('success');

            const getVideoStatus = async () => {
              const { data } = await videoAPI.getVideoById(uploadResponse.video_id);
              setVideoStatus(data.status);
              if (data.status === IVideoStatusType.READY) {
                setFileName(getVideoMuxUrl(data.muxPlaybackId));
                setReadyToDisplay(true);
                return true;
              }
              if (data.status === IVideoStatusType.ERRORED || data.status === IVideoStatusType.CANCELLED) {
                setErrorUploading(true);
                setReadyToDisplay(false);
                return true;
              }
              return false;
            }

            const asyncInterval = async (callback, ms) => {
              return new Promise((resolve, reject) => {
                const interval = setInterval(async () => {
                  if (await callback()) {
                    resolve();
                    clearInterval(interval);
                    setIsUploading(false);
                    tempInput.remove();
                  }
                }, ms);
              });
            }

            const checkVideoStatus = async () => {
              try {
                await asyncInterval(getVideoStatus, 5 * 1000);
              } catch (e) {
                console.log('error handling');
              }
              console.log("Done!");
            }
            checkVideoStatus();
          });
          return;

        }
        video.onerror = () => {
          dispatch({ type: 'global/addDangerToast', payload: { description: 'Error while loading video.' } });
          setIsUploading(false);
          return;
        };
        video.src = URL.createObjectURL(chosenFile);
      }
    }
  }

  const startUpload = () => {
    const tempInput = document.createElement('input');
    tempInput.type = 'file';
    tempInput.accept = (function () {
      if (type === IUploadType.IMAGE) {
        return 'image/*';
      }
      if (type === IUploadType.VIDEO) {
        return 'video/*';
      }
      if (type === IUploadType.AUDIO) {
        return 'audio/*';
      }
      if (type === IUploadType.PDF_FILE) {
        return IUploadFileType.PDF;
      }
      return '*/*'
    })();
    tempInput.click();
    tempInput.onchange = e => handleFileInput(e, tempInput);
  }

  return {
    startUpload,
    fileName,
    setFileName,
    isUploading,
    readyToDisplay,
    errorUploading,
    errorMessage,
    resetEverything,
    videoUploadingStatusText,
    setVideoUploadingStatusText,
    metadata,
  }
}

export default useUploader;
