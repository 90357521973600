import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const TimerFill = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00001 0.333984C10.68 0.333984 13.6667 3.32065 13.6667 7.00065C13.6667 10.6807 10.68 13.6673 7.00001 13.6673C3.32001 13.6673 0.333344 10.6807 0.333344 7.00065C0.333344 3.32065 3.32001 0.333984 7.00001 0.333984ZM9.35734 3.70065L6.05734 7.00065L7.00001 7.94332L10.3 4.64332L9.35734 3.70065Z" className={fillClassName}/>
    </svg>
  );
};

export default TimerFill;
