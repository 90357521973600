import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const UserSpeakingOutlined = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 13 13" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
<path d="M0.541504 11.9163C0.541504 10.767 0.99805 9.66484 1.81071 8.85219C2.62337 8.03953 3.72557 7.58298 4.87484 7.58298C6.02411 7.58298 7.12631 8.03953 7.93897 8.85219C8.75162 9.66484 9.20817 10.767 9.20817 11.9163H8.12484C8.12484 11.0544 7.78243 10.2277 7.17293 9.61822C6.56344 9.00873 5.73679 8.66632 4.87484 8.66632C4.01288 8.66632 3.18623 9.00873 2.57674 9.61822C1.96725 10.2277 1.62484 11.0544 1.62484 11.9163H0.541504ZM4.87484 7.04132C3.07921 7.04132 1.62484 5.58694 1.62484 3.79132C1.62484 1.99569 3.07921 0.541316 4.87484 0.541316C6.67046 0.541316 8.12484 1.99569 8.12484 3.79132C8.12484 5.58694 6.67046 7.04132 4.87484 7.04132ZM4.87484 5.95798C6.07192 5.95798 7.0415 4.9884 7.0415 3.79132C7.0415 2.59423 6.07192 1.62465 4.87484 1.62465C3.67775 1.62465 2.70817 2.59423 2.70817 3.79132C2.70817 4.9884 3.67775 5.95798 4.87484 5.95798ZM11.6717 0.424316C12.1907 1.47072 12.4599 2.62326 12.4582 3.79132C12.4582 5.00086 12.1749 6.14432 11.6717 7.15832L10.7806 6.51048C11.1734 5.65788 11.3762 4.73006 11.3748 3.79132C11.3748 2.82065 11.162 1.89927 10.7806 1.07215L11.6717 0.424316ZM9.88309 1.72432C10.1536 2.37982 10.2924 3.08218 10.2915 3.79132C10.2924 4.50045 10.1536 5.20281 9.88309 5.85832L8.97471 5.19748C9.12971 4.74489 9.2086 4.26972 9.20817 3.79132C9.20878 3.31294 9.13007 2.83778 8.97525 2.38515L9.88309 1.72432Z" className={fillClassName}/>
</svg>

  );
};

export default UserSpeakingOutlined;
