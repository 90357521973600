import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Message = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 13 13" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
<path d="M2.76525 10.0833L0.166504 12.125V1.33333C0.166504 1.17862 0.227962 1.03025 0.337358 0.920854C0.446754 0.811458 0.595128 0.75 0.749837 0.75H11.2498C11.4045 0.75 11.5529 0.811458 11.6623 0.920854C11.7717 1.03025 11.8332 1.17862 11.8332 1.33333V9.5C11.8332 9.65471 11.7717 9.80308 11.6623 9.91248C11.5529 10.0219 11.4045 10.0833 11.2498 10.0833H2.76525ZM2.36159 8.91667H10.6665V1.91667H1.33317V9.72458L2.36159 8.91667ZM5.4165 4.83333H6.58317V6H5.4165V4.83333ZM3.08317 4.83333H4.24984V6H3.08317V4.83333ZM7.74984 4.83333H8.9165V6H7.74984V4.83333Z" className={fillClassName}/>
</svg>

  );
};

export default Message;
