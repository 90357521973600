import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const LinkAlt = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg 
    width={size} 
    height={size} 
    viewBox="0 0 48 48" 
    className={fillClassName}
    xmlns="http://www.w3.org/2000/svg"
    >
<path d="M8.44401 39.556C10.394 41.506 12.954 42.48 15.514 42.48C18.076 42.478 20.638 41.506 22.586 39.556L28.242 33.898L25.414 31.07L19.758 36.728C17.42 39.062 13.614 39.066 11.272 36.728C8.93401 34.388 8.93401 30.582 11.272 28.242L16.93 22.586L14.102 19.758L8.44401 25.414C4.54601 29.312 4.54601 35.658 8.44401 39.556ZM39.556 22.586C43.452 18.688 43.452 12.342 39.556 8.44401C35.656 4.54401 29.31 4.54801 25.414 8.44401L19.758 14.102L22.586 16.93L28.242 11.272C30.582 8.93801 34.388 8.93401 36.728 11.272C39.066 13.612 39.066 17.418 36.728 19.758L31.07 25.414L33.898 28.242L39.556 22.586Z" className={fillClassName}/>
<path d="M16.9274 33.9008L14.099 31.0723L31.0713 14.1006L33.8996 16.9291L16.9274 33.9008Z" className={fillClassName}/>
</svg>
  )
};
export default LinkAlt;