import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const Veeco2config = {
  name: 'Veeco2config',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.Veeco2_Expo,


};

export default Veeco2config;

