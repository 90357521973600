export enum IModalTypes {
  NEUTRAL_LIGHTEST = 'neutralLightest',
  NEUTRAL_LIGHT = 'neutralLight',
  NEUTRAL_MEDIUM = 'neutralMedium',
  FEEDBACK = 'feedback',
  EVENT_FEED = 'eventFeedModal',
  DATE_TIME_PICKER = 'dateTimePickerModal',
  SLOTAVAILABILITY = 'slotAvailability',
  CHROME_WARNING = 'chromeWarningModal',
  STREAM_PREVIEW = 'streamPreviewModal',
  STREAM_PREVIEW_ERROR = 'streamPreviewModalError',
  LOGIN_LIMIT = 'loginLimitModal',
}
