import { IStreamsDisplayMode } from "@/components/PublishedStreamDisplay/types";

const plpsvrp = {
  name: 'plpsvrp',
  auth: {
    magicLinks: true,
  },
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      if (numStreams <= 2) {
        return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
      }
      return IStreamsDisplayMode.SPOTLIGHT;
    }
    if (numStreams === 1) {
      return IStreamsDisplayMode.CENTER_STAGE;
    } else if (numStreams > 1 && numStreams <= 6) {
      return IStreamsDisplayMode.GRID;
    } else {
      return IStreamsDisplayMode.TWO_ROW_SPOTLIGHT;
    }
  },
};

export default plpsvrp;