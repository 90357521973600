import { IEventCustomConfigType } from './types';

const foodBuy: IEventCustomConfigType = {
  name: 'foodBuy',
  zoneNames: {
    schedule: 'Agenda',
    stages: 'Theater',
  },
};
export default foodBuy;
