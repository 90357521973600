import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const EndCall = (props: IIconProps) => {
  const { color = IContentColors.DANGER, size } = props;
  const fillClassName = styles[`fill-${color}`];
  return (
      <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.20023 11.5904L5.90584 11.5905C6.38722 11.5906 6.84888 11.3993 7.18927 11.059C7.52965 10.7186 7.72087 10.2569 7.72086 9.77553L7.7209 6.70259C10.4633 5.58051 13.5367 5.58052 16.2791 6.70263L16.2792 9.77536C16.2791 10.7778 17.0917 11.5904 18.0941 11.5905L21.7997 11.5906C22.281 11.5906 22.7427 11.3994 23.0831 11.059C23.4235 10.7186 23.6147 10.257 23.6147 9.7756L23.6147 8.01363C23.6083 6.18441 22.692 4.47834 21.1705 3.46278C15.6222 -0.255263 8.37794 -0.255284 2.82953 3.46273C1.30811 4.47832 0.391767 6.18436 0.385169 8.01358L0.385278 9.77545C0.385236 10.2568 0.576439 10.7185 0.916817 11.0589C1.2572 11.3993 1.71886 11.5905 2.20023 11.5904Z" fill="white" />
      </svg>

  );
};

export default EndCall;
