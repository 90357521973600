import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const FileIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];
  const strokeClassName = styles[`stroke-${color}`];

  const strokeStyles = {
    fill:'none',
    strokeWidth:2.5,
    strokeLinecap:'round',
    strokeLinejoin:'round',
    strokeMiterlimit:10
  };

  return (
    <svg 
    version="1.1" 
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    x="0px" 
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 50 50" 
    className={fillClassName}
    style={{
      enableBackground: 'new 0 0 50 50',
    }}>
    <g>
      <g>
        <g>
          <path className={fillClassName} d="M23,10.8v8.5c0,0.2-0.2,0.4-0.4,0.4h-9.5c-0.4,0-0.5-0.4-0.3-0.6l9.5-8.5C22.6,10.3,23,10.5,23,10.8z"/>
        </g>
        <path className={fillClassName} d="M37.4,11.1v15.5c-0.9-0.3-1.9-0.5-2.9-0.5c-4.9,0-8.9,4-8.9,8.9c0,1.4,0.3,2.7,0.9,3.9H13.4
          c-0.7,0-1.2-0.5-1.2-1.1V21.4c0.2,0.2,0.3,0.2,0.9,0.2h9.5c1.3,0,2.4-1.1,2.4-2.4v-8.5c0-0.5,0-0.6-0.2-0.7h11.4
          C36.9,10,37.4,10.5,37.4,11.1z"/>
      </g>
      <g>
        <g>
          <polyline className={strokeClassName} style={strokeStyles} points="33.5,29.7 33.5,38.9 33.5,39.9 32.6,38.9 29.3,35.4 			"/>
        </g>
        <g>
          <polyline className={strokeClassName} style={strokeStyles} points="37.7,35.4 37.4,35.7 34.4,38.9 33.5,39.9 			"/>
        </g>
      </g>
    </g>
    </svg>
  );
};

export default FileIcon;
