import { Dispatch, SetStateAction, useEffect } from 'react';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectVirtualBgByRefId } from '@/models/event';
import { virtualBackgroundMap } from '@/config/virtualBackground';
import { IStageParent } from '@/components/Stage/types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isChrome, isDesktop, isEdgeChromium } from 'react-device-detect';

const useVirtualBackground = (
  eventId: string,
  localClient,
  isEnabled: boolean,
  setIsVirtualBgEnabled: Dispatch<SetStateAction<boolean>>,
  parent: IStageParent,
) => {
  const { discussionTableId = '' } = useParams<{ discussionTableId: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    // Don't reinitialize when viewing from rooms
    if (parent === IStageParent.LIVE_STAGE && discussionTableId) return;
    dispatch({
      type: 'event/getVirtualBackground',
      payload: { eventId },
    });
  }, []);

  const virtualBgConfig = useMemoizedSelector(
    makeSelectVirtualBgByRefId,
    eventId,
  );

  const toggle = () => {
    setIsVirtualBgEnabled(!isEnabled);
  };

  const enable = () => {
    setIsVirtualBgEnabled(true);
  };

  useEffect(() => {
    if (!localClient) {
      return;
    }
    let virtualBackground = virtualBackgroundMap[eventId] || virtualBgConfig;
    if (!virtualBackground) {
      return;
    }

    (async () => {
      if (!isEnabled) {
        await localClient.removeBackground();
        return;
      }
      if (parent === IStageParent.NETWORKING) {
        virtualBackground = { ...virtualBackground, type: 'blur', bgValue: 2 };
      }

      if (virtualBackground.type?.toLowerCase() === 'image') {
        await localClient.setBackgroundImage(
          virtualBackground.image || virtualBackground.bgValue,
        );
      } else if (virtualBackground.type?.toLowerCase() === 'color') {
        await localClient.setBackgroundColor(
          virtualBackground.color || virtualBackground.bgValue,
        );
      } else if (virtualBackground.type?.toLowerCase() === 'blur') {
        await localClient.setBackgroundBlur(
          virtualBackground.blur || virtualBackground.bgValue,
        );
      }
    })();
  }, [eventId, localClient, isEnabled, parent, virtualBgConfig]);

  return {
    isEnabled,
    toggle,
    enable,
  };
};

const useShowVirtualBgSetting = () => {
  const { eventId = '' } = useParams<{ eventId: string }>();
  const savedSettings = useMemoizedSelector(
    makeSelectVirtualBgByRefId,
    eventId,
  );

  const virtualBgSettings = virtualBackgroundMap[eventId] || savedSettings;

  const showVirtualBgSetting =
    virtualBgSettings && isDesktop && (isChrome || isEdgeChromium);

  return { showVirtualBgSetting, virtualBgSettings };
};

export { useVirtualBackground, useShowVirtualBgSetting };
