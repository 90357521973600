import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const neomrenewed = {
  name: 'neomrenewed',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.NeomRenewed_Expo,

};

export default neomrenewed;