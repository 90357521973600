import React from 'react';
// styles + types
import styles from './styles.module.scss';
import classnames from 'classnames';
import { Info } from 'react-feather';
import ReactTooltip from 'react-tooltip';

const TooltipDiv = (props) => {
  const {
    className,
    dataFor,
    tooltipText,
    tooltipPlace
  } = props;

  const baseClass = classnames(
    `${styles['alertIcon']}`,
    { [className]: className },
  );

  return (
    <>
        <Info size={18} className={baseClass} data-tip data-for={dataFor} />
        <ReactTooltip className={styles.tootipContainer} id={dataFor} place={tooltipPlace ? tooltipPlace : 'top'} effect="solid">
          {tooltipText}
        </ReactTooltip>
    </>
   
  );
};

export default TooltipDiv;
