import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const StartStream = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 15 16" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
    <path d="M3.087 14.0653C2.13002 13.3699 1.35136 12.4577 0.814794 11.4035C0.278224 10.3493 -0.000995615 9.18293 2.66743e-06 8C2.66743e-06 3.85775 3.35775 0.5 7.5 0.5C11.6423 0.5 15 3.85775 15 8C15.001 9.18293 14.7218 10.3493 14.1852 11.4035C13.6486 12.4577 12.87 13.3699 11.913 14.0653L11.1518 12.761C12.1465 11.9979 12.8773 10.9424 13.2414 9.7427C13.6055 8.54298 13.5847 7.25935 13.1817 6.0721C12.7788 4.88486 12.0142 3.85365 10.9951 3.12332C9.976 2.393 8.75375 2.00025 7.5 2.00025C6.24625 2.00025 5.024 2.393 4.00493 3.12332C2.98585 3.85365 2.22116 4.88486 1.81826 6.0721C1.41535 7.25935 1.39449 8.54298 1.75859 9.7427C2.12268 10.9424 2.85346 11.9979 3.84825 12.761L3.087 14.0653ZM4.6125 11.4515C3.90377 10.8586 3.39476 10.0618 3.15462 9.16953C2.91448 8.27723 2.95485 7.33263 3.27026 6.46408C3.58567 5.59552 4.16082 4.84512 4.91756 4.31483C5.6743 3.78454 6.57595 3.50007 7.5 3.50007C8.42405 3.50007 9.3257 3.78454 10.0824 4.31483C10.8392 4.84512 11.4143 5.59552 11.7297 6.46408C12.0452 7.33263 12.0855 8.27723 11.8454 9.16953C11.6052 10.0618 11.0962 10.8586 10.3875 11.4515L9.615 10.127C10.0363 9.70816 10.3238 9.17364 10.441 8.59121C10.5582 8.00878 10.4999 7.40467 10.2733 6.85545C10.0468 6.30623 9.6623 5.83664 9.16857 5.50621C8.67484 5.17578 8.09411 4.99938 7.5 4.99938C6.9059 4.99938 6.32517 5.17578 5.83144 5.50621C5.3377 5.83664 4.9532 6.30623 4.72666 6.85545C4.50012 7.40467 4.44176 8.00878 4.55896 8.59121C4.67616 9.17364 4.96366 9.70816 5.385 10.127L4.6125 11.4515ZM6.75 8.75H8.25V15.5H6.75V8.75Z" className={fillClassName}/>
    </svg>
  );
};

export default StartStream;