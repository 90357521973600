import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Options = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
      <svg width="12" height="4" viewBox="0 0 12 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.00002 0.666748C5.26669 0.666748 4.66669 1.26675 4.66669 2.00008C4.66669 2.73341 5.26669 3.33341 6.00002 3.33341C6.73335 3.33341 7.33335 2.73341 7.33335 2.00008C7.33335 1.26675 6.73335 0.666748 6.00002 0.666748ZM10 0.666748C9.26669 0.666748 8.66669 1.26675 8.66669 2.00008C8.66669 2.73341 9.26669 3.33341 10 3.33341C10.7334 3.33341 11.3334 2.73341 11.3334 2.00008C11.3334 1.26675 10.7334 0.666748 10 0.666748ZM2.00002 0.666748C1.26669 0.666748 0.666687 1.26675 0.666687 2.00008C0.666687 2.73341 1.26669 3.33341 2.00002 3.33341C2.73335 3.33341 3.33335 2.73341 3.33335 2.00008C3.33335 1.26675 2.73335 0.666748 2.00002 0.666748Z" fill="white" />
      </svg>
  );
};

export default Options;
