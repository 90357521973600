import { useEffect, useRef } from "react"

const useWindowUnloadEffect = (handlerFn, callOnCleanup, deps = []) => {
  const cb = useRef();
  cb.current = handlerFn;

  useEffect(() => {
    const handler = () => cb.current();
    window.addEventListener('beforeunload', handler);

    return () => {
      if (callOnCleanup) {
        handler();
      }
      window.removeEventListener('beforeunload', handler);
    };

  }, [cb].concat(deps));
}

export default useWindowUnloadEffect;
