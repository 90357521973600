import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const bpm2022 = {
  name: 'bpm2022',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.BPM2022_Expo,
  boothConfig: {
    '5394a0b2-95d6-4c7f-afc6-bd5f2f58adda': ManualLayoutConfig.BPM2022_PHOTOBOOTH,
 },


};

export default bpm2022;
