import React from 'react';
import classnames from 'classnames';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const RightArrowIcon = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  const handleClick = () => {
    console.log('VEDHA HANDLE CLICK');
  };

  return (
    <svg className={styles.clickPassthrough} onClick={handleClick} width={size} height={size} viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={styles.clickPassthrough} d="M0.416504 4H6.24984" stroke="white" stroke-width="0.833333" stroke-linecap="round" stroke-linejoin="round" />
      <path className={styles.clickPassthrough} d="M3.3335 1.0835L6.25016 4.00016L3.3335 6.91683" stroke="white" stroke-width="0.833333" stroke-linecap="round" stroke-linejoin="round" />
      <path className={styles.clickPassthrough} d="M8.3335 1.0835L8.3335 6.91683" stroke="white" stroke-width="0.833333" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default RightArrowIcon;
