import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const UserSpeaking = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 11.0001C0.5 9.93922 0.921427 8.92181 1.67157 8.17166C2.42172 7.42152 3.43913 7.00009 4.5 7.00009C5.56087 7.00009 6.57828 7.42152 7.32843 8.17166C8.07857 8.92181 8.5 9.93922 8.5 11.0001H0.5ZM4.5 6.50009C2.8425 6.50009 1.5 5.15759 1.5 3.50009C1.5 1.84259 2.8425 0.50009 4.5 0.50009C6.1575 0.50009 7.5 1.84259 7.5 3.50009C7.5 5.15759 6.1575 6.50009 4.5 6.50009ZM9.123 1.59209C9.37272 2.19717 9.50082 2.84551 9.5 3.50009C9.50082 4.15467 9.37272 4.80301 9.123 5.40809L8.2845 4.79809C8.42758 4.38031 8.5004 3.94169 8.5 3.50009C8.50056 3.05851 8.42791 2.6199 8.285 2.20209L9.123 1.59209ZM10.774 0.39209C11.2531 1.358 11.5016 2.42189 11.5 3.50009C11.5 4.61659 11.2385 5.67209 10.774 6.60809L9.9515 6.01009C10.3141 5.22307 10.5013 4.36662 10.5 3.50009C10.5 2.60409 10.3035 1.75359 9.9515 0.99009L10.774 0.39209Z" className={fillClassName}/>
</svg>

  );
};

export default UserSpeaking;
