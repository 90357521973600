import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const Bayerconfig = {
  name: 'Bayerconfig',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.Bayer_Expo,

};

export default Bayerconfig;
