import { selectRecapdLanguagesList } from '@/models/global';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useSyncWords = ({
  recapdEventId,
  recapdEventToken,
  isCaptionSupport = false,
}) => {
  const dispatch = useDispatch();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const recapdLanguagesList = useSelector(selectRecapdLanguagesList);

  const updateRecapdLanguagesList = () => {
    const languagesList: { id: string; value: string }[] = [];
    try {
      const list = Recapd.getAvailableLanguages();
      Object.keys(list).forEach(function (key, index) {
        languagesList.push({ id: key, value: list[key] });
      });
    } catch (err) {
      console.error('recapd updateRecapdLanguagesList error > ', err);
    }
    languagesList.splice(0, 0, { id: 'OFF', value: 'OFF' });
    // languagesList.splice(0, 0, { id: 'ON', value: 'ON' });
    dispatch({
      type: 'global/updateRecapdLanguagesList',
      payload: languagesList,
    });
  };

  const handleReceivers = () => {
    try {
      Recapd.register(Recapd.getEventKeys().onReceiveSettings, function (data) {
        updateRecapdLanguagesList();
      });
      Recapd.register(Recapd.getEventKeys().onReceiveUpdatedSettings, function (
        data,
      ) {
        updateRecapdLanguagesList();
      });
    } catch (err) {
      console.error('recapd handleReceivers error > ', err);
    }
  };

  const initRecapd = () => {
    const mainScript = document.getElementById('fw-js-jsonp');
    if (mainScript) {
      return;
    }
    const recapdOptionScript = document.createElement('script');
    recapdOptionScript.type = 'text/javascript';
    recapdOptionScript.id = 'fw-js-options';
    recapdOptionScript.async = true;
    const eventTokenString =
      !recapdEventToken || recapdEventToken.trim().length === 0
        ? ''
        : `,'eventToken': '${recapdEventToken}'`;
    recapdOptionScript.innerHTML = `var recapdOptions = {'isCollapsed':false,'eventID':${recapdEventId},'isFullscreenModeEnabled':false, 'isIcons':false ${eventTokenString}};`;
    document.body.appendChild(recapdOptionScript);

    const recapdMinJsScript = document.createElement('script');
    recapdMinJsScript.type = 'text/javascript';
    recapdMinJsScript.async = true;
    recapdMinJsScript.id = 'fw-js-jsonp';
    recapdMinJsScript.src =
      'https://live.syncwords.com/js/events/swl-widget.js';
    document.body.appendChild(recapdMinJsScript);
    recapdMinJsScript.onload = () => {
      handleReceivers();
      timeoutRef.current = setTimeout(() =>
        initRecapdStage(recapdEventId, recapdEventToken), 3000);
    };
  };

  useEffect(() => () => timeoutRef.current && clearTimeout(timeoutRef.current), [])

  const initRecapdStage = (_recapdEventId, _recapdEventToken) => {
    console.log('recapd update settings', _recapdEventId);
    // For the first time mount, this will throw an error because Recapd object will
    // not be defined
    if(!window.hasOwnProperty('Recapd')) return;
    try {
      Recapd.updateSettings({
        eventID: Number(_recapdEventId),
        eventToken: _recapdEventToken || '',
        isCollapsed: false,
        isFullscreenModeEnabled: false,
        isIcons: false,
      });
      console.log('recapd after update settings', Recapd.getSettings());
    } catch (err) {
      console.error('recapd initRecapdStage error > ', err);
    }
  };

  const clearSyncWordsJs = () => {
    try {
      Recapd.remove();
    } catch (err) {
      console.error('Recapd remove---', err);
    }
    try {
      const recapdUpdateLanguageOld = document.getElementById(
        'recapdChangeLanguage',
      );
      if (recapdUpdateLanguageOld) {
        document.body.removeChild(recapdUpdateLanguageOld);
      }
      const jsonpScript = document.getElementById('fw-js-jsonp');
      if (jsonpScript) {
        document.body.removeChild(jsonpScript);
      }
      const jsOptionsScript = document.getElementById('fw-js-options');
      if (jsOptionsScript) {
        document.body.removeChild(jsOptionsScript);
      }
    } catch (err) {
      console.error('Recapd clearSyncWordScripts---', err);
    }
  };

  useEffect(() => {
    if (!isCaptionSupport) {
      return;
    }
    try {
      initRecapd();
    } catch (err) {
      console.error('Recapd Debug---', err);
    }
  }, [isCaptionSupport]);

  return {
    recapdLanguagesList,
    clearSyncWordsJs,
    fetchLanguages: updateRecapdLanguagesList,
  };
};

export default useSyncWords;
