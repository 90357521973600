import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const ScreenShareOn = (props: IIconProps) => {
  const { color = IContentColors.NEUTRAL_MID_1, size } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.516 22.3626L11.596 25.8826C11.34 26.2666 11.66 26.7786 12.172 26.7786H16.588H21.004C21.452 26.7786 21.772 26.2666 21.58 25.8826L19.724 22.3626C19.596 22.1706 19.404 22.0426 19.148 22.0426H14.092C13.836 22.0426 13.644 22.1706 13.516 22.3626Z"
        className={fillClassName}
      />
      <path
        d="M27.212 7.89865H6.09198C5.57998 7.89865 5.19598 8.28265 5.19598 8.79465V23.1306C5.19598 23.6426 5.57998 24.0266 6.09198 24.0266H27.212C27.724 24.0266 28.108 23.6426 28.108 23.1306V8.79465C28.108 8.28265 27.724 7.89865 27.212 7.89865ZM16.652 20.8906C13.9 20.8906 11.724 18.7147 11.724 15.9627C11.724 13.2107 13.9 11.0347 16.652 11.0347C19.404 11.0347 21.58 13.2107 21.58 15.9627C21.58 18.7147 19.404 20.8906 16.652 20.8906Z"
        className={fillClassName}
      />
      <path
        d="M14.4013 18.2126L18.9013 13.7126"
        stroke="#ffffff"
        strokeLinecap="round"
      />
      <path
        d="M18.9013 18.2126L14.4013 13.7126"
        stroke="#ffffff"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ScreenShareOn;
