import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const DragIcon = (props: IIconProps) => {
  const { color = IContentColors.WHITE } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      xmlns="http://www.w3.org/2000/svg"
      className={fillClassName}
    >
      <circle cx="2" cy="2.5" r="2" className={fillClassName} />
      <circle cx="2" cy="8.5" r="2" className={fillClassName} />
      <circle cx="2" cy="14.5" r="2" className={fillClassName} />
      <circle cx="8" cy="2.5" r="2" className={fillClassName} />
      <circle cx="8" cy="8.5" r="2" className={fillClassName} />
      <circle cx="8" cy="14.5" r="2" className={fillClassName} />
    </svg>
  );
};

export default DragIcon;
