import React from 'react';
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Compass = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 32 32',
      }}
    >
      <path
        d="M16.0004 25.6004C10.6983 25.6004 6.40039 21.3025 6.40039 16.0004C6.40039 10.6983 10.6983 6.40039 16.0004 6.40039C21.3025 6.40039 25.6004 10.6983 25.6004 16.0004C25.6004 21.3025 21.3025 25.6004 16.0004 25.6004ZM20.3204 11.6804L14.0804 14.0804L11.6804 20.3204L17.9204 17.9204L20.3204 11.6804ZM16.0004 16.9604C15.7458 16.9604 15.5016 16.8592 15.3216 16.6792C15.1415 16.4992 15.0404 16.255 15.0404 16.0004C15.0404 15.7458 15.1415 15.5016 15.3216 15.3216C15.5016 15.1415 15.7458 15.0404 16.0004 15.0404C16.255 15.0404 16.4992 15.1415 16.6792 15.3216C16.8592 15.5016 16.9604 15.7458 16.9604 16.0004C16.9604 16.255 16.8592 16.4992 16.6792 16.6792C16.4992 16.8592 16.255 16.9604 16.0004 16.9604Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default Compass;
