import { IStreamsDisplayMode } from "@/components/PublishedStreamDisplay/types";
import { IStageLayoutTemplateType } from '@/custom-config/types';


const template8: IStageLayoutTemplateType = {
    getDisplayMode: (numStreams, hasScreenshareStream) => {
        if (numStreams === 1) {
          return IStreamsDisplayMode.GRID;
        } else if (numStreams < 4) {
          return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
        } else {
          return IStreamsDisplayMode.SPOTLIGHT;
        }
      },
};

export default template8;