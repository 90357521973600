import React from 'react';
import produce from 'immer';
// components
import Label from '@/components/ui/content/Label';
// styles + types
import styles from './styles.module.scss';
import { IInputListFieldProps } from './types';

const InputListField = (props: IInputListFieldProps) => {
  const {
    fieldIndex,
    label,
    onChange,
    onClick,
    placeholder,
    widthStyles,
    value,
  } = props;
  let { styleClass } = props;

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      return false;
    }
    return true;
  };

  const handleSingleInputChange = (index, e) => {
    onChange(
      produce(value, draft => {
        draft[index] = e.target.value;
      }),
    );
  };

  const handleSingleInputAdd = e => {
    onChange(
      produce(value, draft => {
        draft.push(e.target.value);
      }),
    );
  };

  styleClass = !styleClass ? 'inputField' : styleClass;
  return (
    <div
      key={`${label}InputField`}
      className={`${styles.labelledContainer} ${styles[widthStyles]}`}
    >
      {label && label.length && <Label value={label} styleClass="inputField" />}
      {value &&
        value.map((v, index) => (
          <input
            className={`${styles.inputField}`}
            defaultValue={v}
            onBlur={e => handleSingleInputChange(index, e)}
            onClick={onClick}
            onKeyPress={handleKeyPress}
            placeholder={placeholder}
            tabIndex={fieldIndex}
            type="text"
          />
        ))}
      <input
        className={`${styles.inputField}`}
        defaultValue={''}
        onBlur={e => handleSingleInputAdd(e)}
        onClick={onClick}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        tabIndex={fieldIndex}
        type="text"
      />
    </div>
  );
};

export default React.memo(InputListField);
