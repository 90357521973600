import api from '@/api';
import { useDispatch } from 'react-redux';

export const useStageSettings = ({ stageId }) => {
  const dispatch = useDispatch();
  const updateStageRecordingToggle = status => {
    api.stage.updateStageRecordingStatus(stageId, status).catch(() => {
      dispatch({
        type: 'global/addDangerToast',
        payload: {
          description: `Failed to ${status ? 'enable' : 'disable'} recordings`,
        },
      });
    });
  };
  const updateDryRunToggle = status => {
    api.stage.updateDryRunStatus(stageId, status).catch(() => {
      dispatch({
        type: 'global/addDangerToast',
        payload: {
          description: `Failed to ${status ? 'enable' : 'disable'} dry run`,
        },
      });
    });
  };
  return { updateStageRecordingToggle, updateDryRunToggle };
};
