import React from 'react';
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const FeedV2 = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 32 32',
      }}
    >
      <path
        d="M22.0022 6.40039H9.99859C9.04445 6.40079 8.12943 6.78009 7.45477 7.45477C6.7801 8.12944 6.4008 9.04445 6.40039 9.99859V22.0022C6.40079 22.9563 6.78009 23.8714 7.45477 24.546C8.12944 25.2207 9.04445 25.6 9.99859 25.6004H22.0022C22.9563 25.6 23.8714 25.2207 24.546 24.546C25.2207 23.8713 25.6 22.9563 25.6004 22.0022V9.99859C25.6 9.04445 25.2207 8.12943 24.546 7.45477C23.8713 6.7801 22.9563 6.4008 22.0022 6.40039ZM9.99859 10.7182H9.99839C9.99658 10.5269 10.0719 10.3427 10.2072 10.2074C10.3425 10.0721 10.5267 9.99678 10.718 9.99859H14.2444C14.6413 9.9998 14.9628 10.3215 14.964 10.7182V14.2446C14.9578 14.6393 14.6391 14.958 14.2444 14.9642H10.718C10.3211 14.963 9.99957 14.6415 9.99839 14.2446L9.99859 10.7182ZM21.2826 22.0022L10.7182 22.0024C10.3209 22.0024 9.99859 21.6801 9.99859 21.2828C9.99859 20.8852 10.3209 20.5631 10.7182 20.5631H21.2826C21.6799 20.5631 22.0022 20.8852 22.0022 21.2828C22.0022 21.6801 21.6799 22.0024 21.2826 22.0024V22.0022ZM21.2826 18.4759L10.7182 18.4761C10.5269 18.4779 10.3427 18.4028 10.2074 18.2674C10.0721 18.1319 9.99678 17.9478 9.99859 17.7564C9.9998 17.3594 10.3213 17.038 10.7182 17.0368H21.2826C21.6795 17.038 22.001 17.3594 22.0022 17.7564C22.004 17.9478 21.9287 18.1319 21.7934 18.2674C21.658 18.4028 21.4739 18.4779 21.2826 18.4761V18.4759ZM21.2826 14.964L17.7562 14.9642C17.3588 14.9642 17.0366 14.6419 17.0366 14.2446C17.0366 13.8472 17.3588 13.5249 17.7562 13.5249H21.2826C21.6799 13.5249 22.0022 13.8472 22.0022 14.2446C22.0022 14.6419 21.6799 14.9642 21.2826 14.9642V14.964ZM21.2826 11.4377L17.7562 11.4379C17.3588 11.4379 17.0366 11.1158 17.0366 10.7182C17.0366 10.3209 17.3588 9.99859 17.7562 9.99859H21.2826C21.6799 9.99859 22.0022 10.3209 22.0022 10.7182C22.0022 11.1158 21.6799 11.4379 21.2826 11.4379V11.4377Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default FeedV2;
