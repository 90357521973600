import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const MicOffCurved = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      className={fillClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5312 21.2984C18.2126 22.0447 16.756 22.515 15.25 22.6809V27.7496H12.75V22.6809C10.2139 22.3966 7.84969 21.2589 6.04518 19.4544C4.24067 17.6499 3.10298 15.2857 2.81871 12.7496H5.33746C5.53234 14.0989 6.0397 15.384 6.81911 16.5025C7.59852 17.621 8.62842 18.5419 9.82674 19.192C11.0251 19.842 12.3586 20.2031 13.7212 20.2466C15.0838 20.29 16.4377 20.0145 17.675 19.4421L15.7362 17.5046C14.8052 17.7739 13.8243 17.8229 12.8711 17.6479C11.9179 17.4728 11.0184 17.0785 10.2438 16.496C9.46922 15.9136 8.84067 15.159 8.40785 14.2919C7.97503 13.4247 7.7498 12.4688 7.74996 11.4996V9.51714L0.741211 2.50964L2.50996 0.740891L27.2587 25.4909L25.49 27.2584L19.5312 21.2984ZM10.2912 12.0584L13.4412 15.2084C12.6486 15.0887 11.9151 14.7182 11.3482 14.1514C10.7814 13.5845 10.4109 12.8511 10.2912 12.0584ZM23.2187 17.9496L21.415 16.1471C22.0512 15.1346 22.485 13.9846 22.6612 12.7496H25.1812C24.9749 14.6205 24.2999 16.409 23.2187 17.9496ZM19.5812 14.3134L17.6462 12.3784C17.7137 12.0959 17.75 11.8034 17.75 11.4996V6.49964C17.7508 5.61802 17.4411 4.76428 16.8751 4.08835C16.309 3.41242 15.523 2.95752 14.6549 2.80354C13.7868 2.64957 12.8923 2.80635 12.1283 3.24637C11.3644 3.68639 10.7799 4.38151 10.4775 5.20964L8.60746 3.33839C9.30134 2.15416 10.3658 1.23127 11.6364 0.712211C12.907 0.193149 14.3131 0.106781 15.6377 0.466441C16.9623 0.8261 18.1316 1.61179 18.9652 2.7022C19.7988 3.79261 20.2503 5.1271 20.25 6.49964V11.4996C20.2514 12.477 20.0228 13.4409 19.5825 14.3134H19.5812Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default MicOffCurved;
