import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const VideoWithDownArrowIcon = (props: IIconProps) => {
  const { className = '', color = IContentColors.WHITE, size = 16 } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2.662C2 2.29667 2.29667 2 2.662 2H13.338C13.7033 2 14 2.29667 14 2.662V13.338C13.9998 13.5135 13.93 13.6818 13.8059 13.8059C13.6818 13.93 13.5135 13.9998 13.338 14H2.662C2.48648 13.9998 2.3182 13.93 2.19409 13.8059C2.06998 13.6818 2.00018 13.5135 2 13.338V2.662ZM7.08133 5.61C7.04121 5.58323 6.99458 5.56784 6.9464 5.56548C6.89823 5.56312 6.85032 5.57386 6.80776 5.59657C6.76521 5.61929 6.72962 5.65311 6.70477 5.69445C6.67993 5.73579 6.66676 5.7831 6.66667 5.83133V10.1687C6.66676 10.2169 6.67993 10.2642 6.70477 10.3055C6.72962 10.3469 6.76521 10.3807 6.80776 10.4034C6.85032 10.4261 6.89823 10.4369 6.9464 10.4345C6.99458 10.4322 7.04121 10.4168 7.08133 10.39L10.334 8.222C10.3706 8.19766 10.4006 8.16465 10.4213 8.12592C10.4421 8.08719 10.4529 8.04394 10.4529 8C10.4529 7.95606 10.4421 7.91281 10.4213 7.87408C10.4006 7.83535 10.3706 7.80234 10.334 7.778L7.08067 5.61H7.08133Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default VideoWithDownArrowIcon;
