import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';
import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';

const aspenTechConfig = {
  name: 'aspenTechConfig',
  includeZones: ['lobby', 'stages', 'expo', 'discussions', 'networking'],

  expoConfig: ManualLayoutConfig.ASPENTECH_OUTER_EXPO,

  // EXPO Config
  boothConfig: {
  },
};

export default aspenTechConfig;
