import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const tidalSB = {
  name: 'tidalSB',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.Tidal_EXPO_OUTER1,
  boothConfig: {
    '3c48b31f-2c83-496b-9e80-e25e0b6f71e1': ManualLayoutConfig.TidalSB_PHOTOBOOTH,
 },


};

export default tidalSB;
