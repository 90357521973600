import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';
import { IStageLayoutTemplateType } from '@/custom-config/types';

const template3: IStageLayoutTemplateType = {
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (numStreams > 6) {
      return IStreamsDisplayMode.GRID;
    }
    return IStreamsDisplayMode.RAZORPAY;
  },
};
export default template3;
