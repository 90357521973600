import React, { useState } from 'react';
import classnames from 'classnames';
// components
import TabMenuContents from './TabMenuContents';
import TabMenuOptions from './TabMenuOptions';
// styles
import styles from './styles.module.scss';
// types
import { ITabMenuProps, ITabMenuTypes } from './types';
import useIsPageEmbedded from '@/hooks/use-is-page-embedded';

const TabMenu = (props: ITabMenuProps) => {
  const {
    children,
    menuType = ITabMenuTypes.DEFAULT,
    options,
    selectedTabIndex,
    defaultTabIndex = 0,
    setCurrentTab,
    isPollsMenu,
    isWebinar,
    tabContent,
    hasBanner = false,
    isStudioStage,
  } = props;

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(
    defaultTabIndex,
  );

  const isEmbedded = useIsPageEmbedded();

  const handleTabClick = (index: number) => {
    setSelectedOptionIndex(index);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setCurrentTab && setCurrentTab(index);
  };

  return (
    <div
      className={classnames(styles[`${menuType}TabMenu`], {
        [styles.noBgTabMenuForEmbed]:
          isEmbedded && tabContent === 'hostChatTabMenu',
        [styles.hasCTABanner]: hasBanner && !isEmbedded && !isStudioStage && !isWebinar,
      })}
    >
      {/* Hide Zone name for Embedded SidePanel */}
      {!(
        (isEmbedded || isWebinar) &&
        tabContent === 'eventAndChannelSidePanel'
      ) && (
        <TabMenuOptions
          menuType={menuType}
          onClick={handleTabClick}
          options={options}
          selectedOptionIndex={
            selectedTabIndex != null ? selectedTabIndex : selectedOptionIndex
          }
          isPublishedPollTab={isPollsMenu}
          hasBanner={hasBanner}
          isWebinar={isWebinar}
        />
      )}
      <TabMenuContents
        menuType={menuType}
        selectedOptionIndex={
          selectedTabIndex != null ? selectedTabIndex : selectedOptionIndex
        }
      >
        {children}
      </TabMenuContents>
    </div>
  );
};

export { ITabMenuTypes };
export default TabMenu;
