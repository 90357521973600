import React, { useEffect, useState } from 'react';
import SelectFieldExternal from 'react-select';
// components
import Button from '@/components/ui/buttons/Button';
import IconButton, {
  IIconButtonBGColors,
} from '@/components/ui/buttons/IconButton';
import Loader from '@/components/ui/Loader';
import Modal from '@/components/ui/modals/Modal';
import NewLabel, {
  INewLabelColors,
  INewLabelMargins,
  INewLabelSizes,
  INewLabelStyles,
} from '@/components/ui/content/NewLabel';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
// helpers
import { handleCustomVideoStreamPlay } from '@/utils/helpers';
// styles + types
import classnames from 'classnames';
import styles from './styles.module.scss';
import {
  IStreamPreviewModalProps,
  MediaPermissionTypes,
  ModalContentType,
} from './types';
import { IButtonTypes } from '@/components/ui/buttons/Button/types';
import { IIconButtonTypes } from '@/components/ui/buttons/IconButton/types';
import { IContentColors, IUIIcons } from '@/types';
import { IModalTypes } from '@/components/ui/modals/Modal/types';
// services
import useDevices from '@/services/video/useDevices';
import { useVideoContext } from '@/services/video/video-context';
import IconTextButton, {
  IIconTextButtonMarginTypes,
} from '../../buttons/IconTextButton';
import useMute from '@/services/video/useMute';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentProfile, selectNetworkQuality } from '@/models/global';
import { getNetworkStatus } from './utils';
import { _isEmpty } from '@/utils/jsHelpers/obj';
import Text, { ITextColors, ITextStyles } from '../../content/Text';
import { IStageParent } from '@/components/Stage/types';
import { useParams } from 'react-router';
import {
  HOVER_NAV_ICON_SIZE,
  STANDARD_ICON_BUTTON_SIZE,
} from '@/styles/iconSizes';
import { getCssVar } from '@/utils/cssVars';
import ProfilePicture from '../../ProfilePicture';
import { IMAGE_URL, STUDIO_URL } from '@/config';
import { selectCurrentUser } from '@/models/account';
import { PicSizes } from '@/components/PublishedStreamDisplay/SingleStreamPlayer/StreamActiveUser/types';
import api from '@/api';
import { useUpdateEffect } from 'react-use';
import { usePusherState } from '@/services/messaging/pusher/pusher-context';
import PusherErrorModal from './PusherErrorModal';
import { useMediaQuery } from 'react-responsive';
import { makeSelectIsAttendee, makeSelectRoomByRoomId } from '@/models/event';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { getDevicesFailedContent } from './config';
import { useChannelContext } from '@/services/video/channel-context/channel-context';
import { isIOS13, isMobile } from 'react-device-detect';
import { Microphone as MicrophoneIcon } from '@styled-icons/boxicons-solid';
import useLocalMediaPermissions from '@/services/video/useLocalMediaPermissions';
import { useShowVirtualBgSetting } from '@/hooks/use-virtual-background';

const {
  NEW_MIC_OFF,
  NEW_MIC_ON,
  STREAM_JOIN,
  NEW_VIDEO_ON,
  NEW_VIDEO_OFF,
  VOLUME_ON,
  VIRTUAL_BACKGROUND_ON,
  VIRTUAL_BACKGROUND_OFF,
} = IUIIcons;

const selectFieldStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: getCssVar(IContentColors.MONOCHROME_CONTRAST),
  }),
  control: provided => ({
    ...provided,
    backgroundColor: getCssVar(IContentColors.NEUTRAL_BASE_2),
    border: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    color: getCssVar(IContentColors.MONOCHROME_CONTRAST),
    fontSize: '14px',
  }),
  option: provided => ({
    ...provided,
    background: getCssVar(IContentColors.NEUTRAL_BASE_2),
    color: getCssVar(IContentColors.MONOCHROME_CONTRAST),
    fontSize: '14px',
  }),
  menuList: provided => ({
    ...provided,
    // kill the white space on first and last option
    padding: 0,
  }),
};

const STREAM_ID = 'preview-stream';

const StreamPreviewModal = (props: IStreamPreviewModalProps) => {
  const { eventId, discussionTableId } = useParams<{
    eventId: string;
    discussionTableId: string;
  }>();
  const [stateCtx, mutationCtx] = useVideoContext() as any;
  const [modalContentState, setModalContentState] = useState<{
    state: ModalContentType;
    info: string;
    hints: string[];
  }>({
    state: ModalContentType.LOADING,
    info: '',
    hints: [],
  });
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const {
    defaultOpen = false,
    ignoreButtonMargin = false,
    localClient,
    localStream,
    setBroadcastPermissions: setParentBroadcastPermissions,
    showIconButton = false,
    showIconTextButton = true,
    parent,
    hideTriggerButton = false,
    disableClose = false,
    hideMuteOptions = false,
    tempStreamConfig = {},
    onModalClose,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { virtualBackground } = useChannelContext();
  const currentVideoProfile = useSelector(selectCurrentProfile);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const currentUser = useSelector(selectCurrentUser) as any;
  const networkQuality = useSelector(selectNetworkQuality);
  const isAttendee = useMemoizedSelector(makeSelectIsAttendee, eventId);
  const discussionTableInfo = useMemoizedSelector(makeSelectRoomByRoomId, {
    eventId,
    discussionTableId,
  });

  const [hasMutedAudioByDefault, setHasMutedAudioByDefault] = useState(false);
  const [
    hasAppliedVirtualBgByDefault,
    setHasAppliedVirtualBgByDefault,
  ] = useState(false);

  const [networkQualityIcon, setNetworkQualityIcon] = useState<JSX.Element>();
  const [networkQualityLabel, setNetworkQualityLabel] = useState<JSX.Element>();
  const [volumeLevelPercentage, setVolumeLevelPercentage] = useState<number>();
  const [isPlayingSampleSound, setIsPlayingSampleSound] = useState(false);

  const isRooms = parent === IStageParent.LIVE_DISCUSSION;
  const { handleTempStream, handleTempStreamConfirm } = tempStreamConfig;
  const createTempStream = !!handleTempStream;
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const pusherCtx = usePusherState();
  const [showPusherErrorModal, setShowPusherErrorModal] = useState(false);
  const isDesktopLikeView = useMediaQuery({ query: '(min-width: 1025px)' });
  const [isDevicesListFetching, setIsDevicesListFetching] = useState(false);

  const getDevicesFailedCallback = error => {
    setIsDevicesListFetching(false);
    setModalContentState(getDevicesFailedContent(error));
  };

  const { localMediaPermissions } = useLocalMediaPermissions();

  useEffect(() => {
    const { audio, video } = localMediaPermissions;
    let modalContentType = ModalContentType.LOADING;
    if (
      audio === MediaPermissionTypes.PROMPT ||
      video === MediaPermissionTypes.PROMPT
    ) {
      modalContentType = ModalContentType.ASK_PERMISSIONS;
    } else if (
      audio === MediaPermissionTypes.DENIED ||
      video === MediaPermissionTypes.DENIED
    ) {
      modalContentType = ModalContentType.DENIED_PERMISSIONS;
    } else if (
      audio === MediaPermissionTypes.GRANTED &&
      video === MediaPermissionTypes.GRANTED
    ) {
      modalContentType = ModalContentType.NORMAL;
    }
    setModalContentState({
      state: modalContentType,
      info: '',
      hints: [],
    });
  }, [localMediaPermissions]);

  const [shouldTrackMediaDevices, setShouldTrackMediaDevices] = useState(false);
  useEffect(() => {
    setShouldTrackMediaDevices(
      isModalOpen &&
        localMediaPermissions.audio === MediaPermissionTypes.GRANTED &&
        localMediaPermissions.video === MediaPermissionTypes.GRANTED,
    );
  }, [isModalOpen, localMediaPermissions]);

  const [reloadDevicesDeps, setReloadDevicesDeps] = useState([new Date()]);
  const [
    cameraList,
    microphoneList,
    speakerList,
    selectedCameraId,
    selectedMicrophoneId,
    selectedSpeakerId,
  ] = useDevices(
    shouldTrackMediaDevices,
    reloadDevicesDeps,
    getDevicesFailedCallback,
  );

  useEffect(() => {
    if (isDesktopLikeView) {
      setIsSettingsOpen(true);
    } else {
      setIsSettingsOpen(false);
    }
  }, [isDesktopLikeView]);

  // React effect hook that ignores the first invocation (e.g. on mount).
  // The signature is exactly the same as the useEffect hook.
  useUpdateEffect(() => {
    if (cameraList.length && microphoneList.length) {
      setModalContentState({
        state: ModalContentType.NORMAL,
        info: '',
        hints: [],
      });
    } else {
      // Agora's getDevices doesn't get into an error state if camera or microphone is not found
      // Setting permission video state explicitly, we notify the user in this state
      setModalContentState({
        state: ModalContentType.DENIED_PERMISSIONS,
        info: 'We could not connect to your camera/microphone',
        hints: [],
      });
    }
  }, [cameraList, microphoneList]);

  useEffect(() => {
    try {
      localClient.switchMicrophone(selectedMicrophoneId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [selectedMicrophoneId]);

  useEffect(() => {
    try {
      localClient.switchCamera(selectedCameraId);
    } catch (error) {
      console.error(error);
    }
  }, [selectedCameraId]);

  const {
    audioEnabled,
    videoEnabled,
    onAudioToggle,
    onVideoToggle,
    muteAudio,
    disableVideo,
  } = useMute({
    localClient,
  });

  useEffect(() => {
    if (
      !isAttendee ||
      !discussionTableInfo ||
      !localClient ||
      !audioEnabled ||
      hasMutedAudioByDefault
    )
      return;

    if (isRooms && discussionTableInfo.capacity >= 10) {
      muteAudio();
      setHasMutedAudioByDefault(true);
      dispatch({
        type: 'global/addInfoToast',
        payload: {
          description:
            'Your microphone has been turned off. Please unmute to speak.',
        },
      });
    }
  }, [isAttendee, isRooms, discussionTableInfo, localClient, audioEnabled]);

  const localStreamVideoTrack = localStream?.videoTrack;

  useEffect(() => {
    if (!isModalOpen || !localStreamVideoTrack) {
      return;
    }
    handleCustomVideoStreamPlay(localStreamVideoTrack, STREAM_ID, {
      hideVideoStreams: !videoEnabled,
    });
  }, [localStreamVideoTrack, isModalOpen, videoEnabled, parent]);

  useEffect(() => {
    if (!localClient) {
      return;
    }

    if (!isModalOpen) {
      return;
    }

    if (!selectedCameraId || !selectedMicrophoneId) {
      return;
    }

    if (
      _isEmpty(tempStreamConfig) &&
      (!stateCtx.mainReady || !stateCtx.greenRoomReady)
    ) {
      return;
    }

    if (localStream) {
      return;
    }

    const streamConfig = {
      cameraId: selectedCameraId,
      microphoneId: selectedMicrophoneId,
      resolution: currentVideoProfile.main,
    };
    (async () => {
      try {
        if (handleTempStream) {
          await api.channel.getConfigByRef(eventId).then(async ({ data }) => {
            streamConfig.channelConfig = data?.viewerChannel;
          });

          const [audio, video] = await localClient.initTempStream(streamConfig);

          handleTempStream({ audio, video });
          return;
        }

        console.error('debugResolution > StreamPreviewModal > ', streamConfig);
        await localClient.initMainStream(streamConfig);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('debug > StreamPreviewModal error', err);
        setModalContentState({
          state: ModalContentType.ERROR,
          info: 'We could not connect to your camera/microphone',
          hints: [
            'Close any other applications that may be using your camera/mic (like Webex, Skype, Teams, Zoom, etc.)',
            'Check if your built-in/external camera or microphone is enabled in your device',
            'Refresh your browser',
            // eslint-disable-next-line quotes
            "If this didn't work, contact us with the code: NotFoundError",
          ],
        });
      }
    })();
  }, [
    localClient,
    stateCtx.mainReady,
    stateCtx.greenRoomReady,
    selectedCameraId,
    selectedMicrophoneId,
    isModalOpen,
  ]);

  const saveSelectedDevices = () => {
    localStorage.setItem('selectedCamera', selectedCameraId);
    localStorage.setItem('selectedMicrophone', selectedMicrophoneId);
    localStorage.setItem('selectedPlaybackDevice', selectedSpeakerId);
  };

  const handleJoinStream = async (setShow: any) => {
    setShow(false);
    saveSelectedDevices();

    if (
      parent === IStageParent.LIVE_BACKSTAGE &&
      (!pusherCtx.pusher ||
        !pusherCtx.pusher.connection ||
        pusherCtx.pusher.connection.state !== 'connected')
    ) {
      // pusher checks for only backstage join stream
      setShowPusherErrorModal(true);
      return;
    }

    if (!cameraList.length && !microphoneList.length) {
      await muteAudio();
      await disableVideo();
      const streamConfig = {
        cameraId: null,
        microphoneId: null,
        resolution: currentVideoProfile.main,
      };
      if (handleTempStream) {
        await api.channel.getConfigByRef(eventId).then(async ({ data }) => {
          streamConfig.channelConfig = data?.viewerChannel;
        });
        const [audio, video] = await localClient.initTempStream(streamConfig);
        handleTempStream({ audio, video });
      } else {
        await localClient.initMainStream(streamConfig);
      }
    }

    if (handleTempStream) {
      handleTempStreamConfirm();
      return;
    }

    if (localStream && !localStreamVideoTrack) {
      await disableVideo();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setParentBroadcastPermissions && setParentBroadcastPermissions(true);
    const { makeHost, makeGreenroomHost, joinBeforePublish } = (() => {
      let mh = false;
      let mgh = false;
      let jbp = false;

      if (parent === IStageParent.LIVE_BACKSTAGE) mgh = true;
      if (parent === IStageParent.BOOTH || parent === IStageParent.BOOTH_OWNER)
        mh = true;
      // Making user a host for rooms because a 100 people room is a "live" and not a "rtc" channel
      if (parent === IStageParent.LIVE_DISCUSSION) {
        mh = true;
      }
      if (parent === IStageParent.CONTRIBUTOR_SLOT_AUDIO) mh = true;

      return {
        makeHost: mh,
        makeGreenroomHost: mgh,
        joinBeforePublish: jbp,
      };
    })();
    await localClient.publishMain({
      makeGreenroomHost,
      makeHost,
      joinBeforePublish,
    });
  };

  const closeLocalStream = async () => {
    if (handleTempStream) {
      return;
    }

    await localClient.unpublishMain({ close: true });
    setParentBroadcastPermissions(false);
  };

  const cancelJoinStream = setShow => async () => {
    setShow(false);
    await closeLocalStream();
  };

  const handleSelectCamera = camera => {
    mutationCtx.switchCamera(camera.value);
  };

  const handleSelectMicrophone = microphone => {
    mutationCtx.switchMicrophone(microphone.value);
  };

  const handleSelectSpeaker = speaker => {
    mutationCtx.switchSpeaker(speaker.value);
    dispatch({
      type: 'global/setAudioPlaybackDeviceId',
      payload: speaker.value,
    });
  };

  const clientWaiting =
    _isEmpty(tempStreamConfig) &&
    (!stateCtx.mainReady || !stateCtx.greenRoomReady);
  const waiting = !localStream || clientWaiting || stateCtx.loading;

  const joinStreamText = (() => {
    if (parent === IStageParent.BOOTH) {
      return 'Join Booth';
    }

    if (parent === IStageParent.BOOTH_OWNER) {
      return 'Join Booth';
    }

    if (parent === IStageParent.LIVE_DISCUSSION) {
      return 'Join Room';
    }

    if (parent === IStageParent.NETWORKING) {
      return 'Start Networking';
    }

    if (parent === IStageParent.LIVE_STAGE) {
      return 'Join Stage';
    }

    if (parent === IStageParent.LIVE_BACKSTAGE) {
      return 'Join Backstage';
    }

    return 'Join';
  })();

  const handleModalClose = async () => {
    if (parent === IStageParent.LIVE_DISCUSSION) {
      const { parentRef = 'discussions' } = searchParams;
      if (parentRef?.includes('studio/')) {
        window.location.assign(`${STUDIO_URL}/${parentRef}`);
        return;
      }
      history.push(`/l/event/${eventId}/${parentRef}`);
    }
    if (parent === IStageParent.NETWORKING) {
      history.push(`/l/event/${eventId}/networking`);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onModalClose && onModalClose();
  };

  useEffect(() => {
    const { label, icon } = getNetworkStatus(networkQuality);

    setNetworkQualityLabel(label);
    setNetworkQualityIcon(icon);
  }, [networkQuality]);

  useEffect(() => {
    if (!audioEnabled) {
      setVolumeLevelPercentage(0);
      return;
    }

    const volumeInterval = setInterval(() => {
      const audioVolLevel = localStream?.audioTrack?.getVolumeLevel();

      setVolumeLevelPercentage(audioVolLevel * 100);
    }, 250);
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(volumeInterval);
    };
  }, [audioEnabled, localStream]);

  const playTestSound = () => {
    const sampleAudio: any = new Audio(
      'https://phoenixlive.imgix.net/zuddl-vo/uplifting-sample-audio.mp3',
    );
    sampleAudio?.load();

    const audioListenerCallback = event => {
      console.info(
        'debugStreamPreviewModal > sampleAudio > canplaythrough',
        event,
      );
      /* the audio is now playable; play it if permissions allow */
      try {
        sampleAudio.setSinkId(selectedSpeakerId);
        sampleAudio.play();
      } catch {
        sampleAudio.play();
      }
    };

    // SetSinkId has minimal compatibility (https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId)
    sampleAudio.addEventListener('canplaythrough', audioListenerCallback);
    setIsPlayingSampleSound(true);

    // Handle when audio is finished playing
    sampleAudio.onended = () => {
      setIsPlayingSampleSound(false);
      sampleAudio.removeEventListener('canplaythrough', audioListenerCallback);
    };
  };

  const getModelStyleClass = (() => {
    return IModalTypes.STREAM_PREVIEW;
  })();

  const handlePusherErrorModalClose = () => {
    setShowPusherErrorModal(false);
    handleModalClose();
  };

  const { showVirtualBgSetting } = useShowVirtualBgSetting();

  useEffect(() => {
    // Handle hardcoded virtual bgs (for them to be enabled on stage)
    if (
      !localStreamVideoTrack ||
      !showVirtualBgSetting ||
      virtualBackground?.isEnabled ||
      hasAppliedVirtualBgByDefault
    )
      return;

    if (localStreamVideoTrack && videoEnabled) {
      virtualBackground?.enable();
      setHasAppliedVirtualBgByDefault(true);
    }
  }, [eventId, localStreamVideoTrack, showVirtualBgSetting, videoEnabled]);

  const handleAskPermissions = () => {
    setIsDevicesListFetching(true);
    if (!shouldTrackMediaDevices) {
      setShouldTrackMediaDevices(true);
      return;
    }
    setReloadDevicesDeps([new Date()]);
  };

  const handleTryAgainPermissions = async () => {
    if (isMobile || isIOS13) {
      window.location.reload();
      return;
    }
    handleAskPermissions();
  };

  const getPermissionView = (state: ModalContentType, setShow: any) => {
    const title =
      state === ModalContentType.ASK_PERMISSIONS
        ? 'Allow Zuddl to access your camera and microphone'
        : 'Cannot access your camera or microphone';
    const subTitle =
      state === ModalContentType.ASK_PERMISSIONS
        ? 'Click “Allow” in your browser notification to grant device permissions.'
        : 'Grant permissions from your browser’s address bar and refresh this page.';
    return (
      <>
        <div
          className={classnames(styles.videoPreviewContainer)}
        >
          <div className={styles.networkQualityIcon}>{networkQualityIcon}</div>
          <div className={styles.actionButtonsRow}>
            <IconButton
              defaultIcon={NEW_MIC_OFF}
              showDefault
              onClick={
                state === ModalContentType.ASK_PERMISSIONS
                ? handleAskPermissions
                : handleTryAgainPermissions
              }
              type={IIconButtonTypes.SQUARE_NEUTRAL_BASE_2}
              iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
              iconSize={HOVER_NAV_ICON_SIZE}
              tooltipText="Request permissions"
              className={styles.streamPreviewActionButtonsIcons}
            />
              <IconButton
                defaultIcon={NEW_VIDEO_OFF}
                showDefault
                onClick={
                  state === ModalContentType.ASK_PERMISSIONS
                    ? handleAskPermissions
                    : handleTryAgainPermissions
                }
                type={IIconButtonTypes.SQUARE_NEUTRAL_BASE_2}
                iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
                iconSize={HOVER_NAV_ICON_SIZE}
                tooltipText="Request permissions"
                className={styles.streamPreviewActionButtonsIcons}
              />
          </div>
        </div>
        <NewLabel
          text={title}
          labelColor={INewLabelColors.MONOCHROME_BASE}
          labelSize={INewLabelSizes.MLARGE}
          labelMargin={INewLabelMargins.SMALL_MARGIN_BOTTOM}
          labelStyle={INewLabelStyles.BOLDED}
        />
        <NewLabel
          text={subTitle}
          labelColor={INewLabelColors.MONOCHROME_BASE}
          labelSize={INewLabelSizes.XSMALL}
          labelMargin={INewLabelMargins.SMALL_MARGIN_BOTTOM}
          labelStyle={INewLabelStyles.REGULAR}
          style={{padding:'0 30px'}}
        />
        <div className={styles.permissionActionRowStyles}>
          {state === ModalContentType.ASK_PERMISSIONS && (
            <Button
              className={styles.permissionButtonStyles}
              label="Request permissions"
              onClick={() => handleAskPermissions()}
              styleClass={IButtonTypes.PRIMARY_SQUARE_FULL}
              textStyle={ITextStyles.BOLDED}
              type="button"
              loading={isDevicesListFetching}
            />
          )}
          {state === ModalContentType.DENIED_PERMISSIONS && (
            <>
              <Button
                className={styles.permissionButtonStyles}
                label="Try again"
                onClick={() => handleTryAgainPermissions()}
                styleClass={IButtonTypes.PRIMARY_SQUARE_FULL}
                textStyle={ITextStyles.BOLDED}
                type="button"
                loading={isDevicesListFetching}
              />
              <Button
                className={styles.permissionButtonStyles}
                label="Continue without permissions"
                onClick={() => handleJoinStream(setShow)}
                styleClass={IButtonTypes.NO_BG}
                textStyle={ITextStyles.REGULAR}
                type="button"
              />
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        title={joinStreamText}
        styleClass={getModelStyleClass}
        noHeader={true}
        className={styles.modal}
        bodyHeight={isSettingsOpen ? null : '530px'}
        autoTrigger={defaultOpen && !stateCtx.loading}
        onShowChange={setModalIsOpen}
        disableClose={disableClose}
        onModalClose={handleModalClose}
        modalHeaderClass="header"
        noBodyPadding
        trigger={({ setShow }) => {
          if (hideTriggerButton) {
            return null;
          }
          if (showIconButton) {
            return (
              <IconButton
                disabled={stateCtx.loading}
                activeIcon={STREAM_JOIN}
                defaultIcon={STREAM_JOIN}
                onClick={() => {
                  setShow(true);
                }}
                showDefault={false}
                type={IIconButtonTypes.ROUND_WHITE_CONTRAST}
              />
            );
          }
          if (showIconTextButton) {
            return (
              <IconTextButton
                disabled={stateCtx.loading}
                activeIcon={STREAM_JOIN}
                activeLabel={joinStreamText}
                defaultLabel={joinStreamText}
                defaultIcon={STREAM_JOIN}
                showDefault={false}
                onClick={() => {
                  setShow(true);
                }}
                buttonType={IButtonTypes.NEW_SECONDARY}
                buttonMargin={
                  ignoreButtonMargin
                    ? IIconTextButtonMarginTypes.NO_MARGIN
                    : IIconTextButtonMarginTypes.MEDIUM_MARGIN_BOTTOM
                }
              />
            );
          }

          return (
            <IconTextButton
              disabled={stateCtx.loading}
              activeLabel={joinStreamText}
              defaultLabel={joinStreamText}
              onClick={() => {
                setShow(true);
              }}
              buttonType={IButtonTypes.NEW_SECONDARY}
              buttonMargin={
                ignoreButtonMargin
                  ? IIconTextButtonMarginTypes.NO_MARGIN
                  : IIconTextButtonMarginTypes.MEDIUM_MARGIN_BOTTOM
              }
            />
          );
        }}
        render={({ setShow }) => (
          <div className={styles.mainContainer}>
            {modalContentState.state === ModalContentType.LOADING && (
              <div className={styles.streamPreviewLoader}>
                <Loader styleClass="streamPreviewModalLoader" />
              </div>
            )}

            {modalContentState.state === ModalContentType.ASK_PERMISSIONS && (
              <>{getPermissionView(modalContentState.state, setShow)}</>
            )}
            {modalContentState.state ===
              ModalContentType.DENIED_PERMISSIONS && (
              <>{getPermissionView(modalContentState.state, setShow)}</>
            )}
            {modalContentState.state === ModalContentType.NORMAL && (
              <>
                <div>
                  <div className={styles.streamPreviewModalVideoPreview}>
                    {/* If localStream is not initialized, show waiting content */}
                    {!localStream && (
                      <div className={styles.streamPreviewWaitingContent}>
                        <Loader />
                      </div>
                    )}
                    {/* If localStream is initialized & videoTrack is absent, show error text */}
                    {localStream && !localStreamVideoTrack && (
                      <div className={styles.videoTrackError}>
                        <Text
                          textAlign="center"
                          text="We are having trouble connecting your video. Don't worry you can join with just audio."
                        />
                      </div>
                    )}
                    {/* If localStream is initialized, videoTrack is enabled & video is enabled, show stream */}
                    {localStream && localStreamVideoTrack && videoEnabled && (
                      <div
                        className={styles.streamPreviewVideoPreview}
                        id={STREAM_ID}
                        key={localStream.uid}
                      />
                    )}
                    {/* If localStream is initialized, videoTrack is enabled but video is not enabled, show profilePic */}
                    {localStream && localStreamVideoTrack && !videoEnabled && (
                      <div className={styles.streamPreviewDisabledVideo}>
                        <div className={classnames(styles.streamUserFrame)}>
                          <ProfilePicture
                            imageUrl={
                              currentUser && currentUser.picUrl
                                ? `${IMAGE_URL}${currentUser.picUrl}`
                                : undefined
                            }
                            name={
                              currentUser &&
                              `${currentUser.firstName} ${currentUser.lastName}`
                            }
                            className={classnames(styles.profilePicture)}
                            hexColor={ITextColors.DEFAULT}
                            disableTooltip={true}
                            styleClass={`streamProfilePic${PicSizes.Small}`}
                            isLive={false}
                          />
                        </div>
                      </div>
                    )}
                    {!hideMuteOptions &&
                      modalContentState.state === ModalContentType.NORMAL && (
                      <div className={styles.streamPreviewActionButtonsRow}>
                        <IconButton
                          defaultIcon={NEW_VIDEO_ON}
                          disabled={!localStream}
                          activeIcon={NEW_VIDEO_OFF}
                          showDefault={videoEnabled}
                          onClick={onVideoToggle}
                          type={IIconButtonTypes.SQUARE_NEUTRAL_BASE_2}
                          iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
                          iconSize={HOVER_NAV_ICON_SIZE}
                          className={styles.streamPreviewActionButtonsIcons}
                        />
                        <IconButton
                          defaultIcon={NEW_MIC_ON}
                          disabled={!localStream}
                          activeIcon={NEW_MIC_OFF}
                          showDefault={audioEnabled}
                          onClick={onAudioToggle}
                          type={IIconButtonTypes.SQUARE_NEUTRAL_BASE_2}
                          iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
                          iconSize={HOVER_NAV_ICON_SIZE}
                          className={styles.streamPreviewActionButtonsIcons}
                        />
                        {showVirtualBgSetting && (
                          <IconButton
                            defaultIcon={VIRTUAL_BACKGROUND_ON}
                            disabled={!localStream}
                            activeIcon={VIRTUAL_BACKGROUND_OFF}
                            showDefault={virtualBackground?.isEnabled}
                            onClick={virtualBackground?.toggle}
                            type={IIconButtonTypes.SQUARE_NEUTRAL_BASE_2}
                            iconBG={IIconButtonBGColors.NEUTRAL_LIGHT_3}
                            iconSize={HOVER_NAV_ICON_SIZE}
                            className={styles.streamPreviewActionButtonsIcons}
                          />
                        )}
                      </div>
                    )}
                    <div className={styles.networkQualityIcon}>
                      {networkQualityIcon}
                    </div>
                  </div>
                  <div className={styles.streamPreviewMicVolContainer}>
                    <div className={styles.streamPreviewMicIcon}>
                      <MicrophoneIcon color={IContentColors.WHITE} size={18} />
                    </div>
                    <div className={styles.gadget}>
                      <div className={styles.bar}>
                        <div
                          className={styles.gauge}
                          style={{ width: `${volumeLevelPercentage}%` }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.streamPreviewModalOptionsContainer}>
                  <div
                    data-testid="select_camera"
                    className={styles.selectField}
                  >
                    <NewLabel
                      labelColor={INewLabelColors.NEUTRAL_MEDIUM}
                      labelSize={INewLabelSizes.XSMALL}
                      text="Select camera"
                    />
                    <SelectFieldExternal
                      name="selectCamera"
                      options={cameraList}
                      onChange={handleSelectCamera}
                      value={cameraList.find(
                        camera => camera.value === selectedCameraId,
                      )}
                      styles={selectFieldStyles}
                      maxMenuHeight={85}
                      menuPlacement="top"
                      isSearchable={false}
                    />
                  </div>
                  <div
                    data-testid="select_microphone"
                    className={styles.selectField}
                  >
                    <NewLabel
                      labelColor={INewLabelColors.NEUTRAL_MEDIUM}
                      labelMargin={INewLabelMargins.MEDIUM_MARGIN_BOTTOM}
                      labelSize={INewLabelSizes.XSMALL}
                      text="Select microphone"
                    />
                    <SelectFieldExternal
                      name="selectMicrophone"
                      options={microphoneList}
                      onChange={handleSelectMicrophone}
                      value={microphoneList.find(
                        microphone => microphone.value === selectedMicrophoneId,
                      )}
                      styles={selectFieldStyles}
                      maxMenuHeight={85}
                      menuPlacement={isMobile ? 'top' : 'auto'}
                      isSearchable={false}
                    />
                  </div>
                  <NewLabel
                    labelColor={INewLabelColors.NEUTRAL_MEDIUM}
                    labelMargin={INewLabelMargins.MEDIUM_MARGIN_BOTTOM}
                    labelSize={INewLabelSizes.XSMALL}
                    text="Select speaker"
                  />
                  <div
                    data-testid="select_speaker"
                    className={styles.selectSpeakerContainer}
                  >
                    <div className={styles.speakerSelectField}>
                      <SelectFieldExternal
                        name="selectSpeaker"
                        options={speakerList}
                        onChange={handleSelectSpeaker}
                        value={speakerList.find(
                          speaker => speaker.value === selectedSpeakerId,
                        )}
                        styles={selectFieldStyles}
                        menuPlacement="top"
                        maxMenuHeight={85}
                      />
                    </div>
                    <div className={styles.testSoundButton}>
                      <IconTextButton
                        style={{ height: '38px' }}
                        activeLabel="Playing"
                        defaultLabel="Test"
                        showDefault={!isPlayingSampleSound}
                        defaultIcon={VOLUME_ON}
                        disabled={isPlayingSampleSound}
                        onClick={playTestSound}
                        buttonType={IButtonTypes.COLOURS_NEUTRAL_BASE_3}
                        iconSize={STANDARD_ICON_BUTTON_SIZE}
                      />
                    </div>
                  </div>
                </div>
                <div
                  data-testid={joinStreamText}
                  className={styles.joinStreamButton}
                >
                  <Button
                    className={styles.joinStreamButtonStyles}
                    disabled={!localStream || stateCtx.loading}
                    label={joinStreamText}
                    onClick={() => handleJoinStream(setShow)}
                    styleClass={IButtonTypes.PRIMARY_SQUARE_FULL}
                    textStyle={ITextStyles.BOLDED}
                    type="button"
                  >
                    {joinStreamText}
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      />
      {showPusherErrorModal && (
        <PusherErrorModal handleCloseModal={handlePusherErrorModalClose} />
      )}
    </div>
  );
};

export default StreamPreviewModal;
