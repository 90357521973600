import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull';
import ReactPlayer from 'react-player';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.scss';

import { EPlayerType, EVideoViewEvent } from '../types';
import {
  isIOS,
  isMobileOnly,
  isMobileSafari,
  isSafari,
} from 'react-device-detect';
import mux from 'mux-embed';
import { MUX_DATA_ENV_KEY } from '@/config';
import VideoTimerView from './videoTimerView';
import videoAPI from '@/api/video';
import { IReactPlayerWrapperProps } from './types';

const ReactPlayerWrapper = forwardRef(
  (props: IReactPlayerWrapperProps, ref) => {
    const {
      url: initUrl,
      loop: initLoop,
      controls: initControls,
      muted: dynamicMuted,
      autoplay = true,
      startTime = 0,
      playsinline,
      onPlay,
      onPause,
      onLoad,
      onEnded,
      onError,
      onReady,
      seekToEndWhenEnded,
      shouldTrack,
      trackingMetadata = {},
      showTimer,
      overrideFileConfig = {},
    } = props;

    const [state, setState] = useState({
      url: initUrl,
      pip: false,
      playing: autoplay,
      controls: initControls || false,
      light: false,
      volume: 0.8,
      muted: dynamicMuted || false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: initLoop || false,
      playedSeconds: 0,
    });

    const viewId = useRef(uuidv4());
    const seeked = useRef(false);
    const replayStarted = useRef(false);
    const reactPlayerId = useRef(uuidv4());
    const playerRef = useRef();
    const [sendTimeUpdate, setSendTimeUpdate] = useState(true);
    const vidoeLocationUrl = window.location.href;

    useEffect(() => {
      setState(state => {
        return {
          ...state,
          muted: dynamicMuted,
        };
      });
    }, [dynamicMuted]);

    useEffect(() => {
      setState(state => {
        return {
          ...state,
          playing: autoplay,
        };
      });
    }, [autoplay]);

    const playedSecondsRef = useRef(0);

    // Effect for cleanup on component unmount
    useEffect(() => {
      return () => {
        sendVideoViewEvent(EVideoViewEvent.VIEW_END, playedSecondsRef.current);
      };
    }, []);

    useEffect(() => {
      const onBeforeUnload = e => {
        sendVideoViewEvent(EVideoViewEvent.VIEW_END, playedSecondsRef.current);
      };
      window.addEventListener('beforeunload', onBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
      };
    }, []);

    useEffect(() => {
      if (sendTimeUpdate && state.playing) {
        sendVideoViewEvent(EVideoViewEvent.TIME_UPDATE, state.playedSeconds);
      }
      setSendTimeUpdate(false);
    }, [sendTimeUpdate]);

    useEffect(() => {
      const sendEvent = () => {
        setSendTimeUpdate(true);
      };

      const intervalId = setInterval(sendEvent, 60000);
      return () => clearInterval(intervalId);
    }, []);

    const handlePlayPause = () => {
      setState(state => {
        return { ...state, playing: !state.playing };
      });
    };

    const handleStop = () => {
      playerRef.current.seekTo(0);
      setState(state => {
        return { ...state, played: 0, playing: false };
      });
    };

    const handleToggleLight = () => {
      setState(state => {
        return { ...state, light: !state.light };
      });
    };

    const handleToggleLoop = () => {
      setState(state => {
        return { ...state, loop: !state.loop };
      });
    };

    const handleVolumeChange = e => {
      setState(state => {
        return { ...state, volume: parseFloat(e.target.value) };
      });
    };

    const handleToggleMuted = () => {
      setState(state => {
        return { ...state, muted: !state.muted };
      });
    };

    const handleSetPlaybackRate = e => {
      setState(state => {
        return { ...state, playbackRate: parseFloat(e.target.value) };
      });
    };

    const handleTogglePIP = () => {
      setState(state => {
        return { ...state, pip: !state.pip };
      });
    };

    const handlePlay = e => {
      setState(state => {
        return { ...state, playing: true };
      });
      onPlay && onPlay(state.played);
      sendVideoViewEvent(
        EVideoViewEvent.PLAY,
        playerRef?.current?.getCurrentTime(),
      );
    };

    const handleEnablePIP = () => {
      setState(state => {
        return { ...state, pip: true };
      });
    };

    const handleDisablePIP = () => {
      setState(state => {
        return { ...state, pip: false };
      });
    };

    const handlePause = e => {
      setState(state => {
        return { ...state, playing: false };
      });
      onPause && onPause();
      sendVideoViewEvent(EVideoViewEvent.PAUSE, state.playedSeconds);
    };

    const handleSeekMouseDown = e => {
      setState(state => {
        return { ...state, seeking: true };
      });
    };

    const handleSeekChange = e => {
      setState(state => {
        return { ...state, played: parseFloat(e.target.value) };
      });
    };

    const handleSeekMouseUp = e => {
      setState(state => {
        return { ...state, seeking: false };
      });
      playerRef.current.seekTo(parseFloat(e.target.value));
    };

    const handleUnmount = e => {
      sendVideoViewEvent(EVideoViewEvent.VIEW_END, state.playedSeconds);
    };

    const handleProgress = progressState => {
      if (seeked.current) {
        sendVideoViewEvent(
          EVideoViewEvent.SEEK_END,
          playerRef?.current?.getCurrentTime(),
        );
        seeked.current = false;
      }

      if (replayStarted.current) {
        sendVideoViewEvent(
          EVideoViewEvent.VIEW_START,
          playerRef?.current?.getCurrentTime(),
        );
        replayStarted.current = false;
      }
      // We only want to update time slider if we are not currently seeking
      if (!progressState.seeking) {
        setState(state => {
          return { ...state, ...progressState };
        });
        playedSecondsRef.current = playerRef?.current?.getCurrentTime();
      }
    };

    const handleReady = () => {
      sendVideoViewEvent(EVideoViewEvent.VIEW_START, state.playedSeconds);
      onReady && onReady();
      if (shouldTrack && playerRef && playerRef.current) {
        const hls = playerRef.current.getInternalPlayer('hls');
        if (hls) {
          mux.monitor(hls.media, {
            debug: false,
            hlsjs: hls,
            data: {
              env_key: MUX_DATA_ENV_KEY,
              // trackingMetadata fields
              player_init_time: Date.now(),
              custom_1: `${trackingMetadata.eventId}|${trackingMetadata.stageId}`,
              custom_2: window.location.host,
            },
          });
        }
      }
    };

    const handleEnded = () => {
      setState(state => {
        return { ...state };
      });
      sendVideoViewEvent(EVideoViewEvent.VIEW_END, state.playedSeconds);

      if (!state.loop) {
        onEnded && onEnded();
      }
    };

    const handleSeeking = () => {
      if (state.playedSeconds > state.duration - 5 && loop) {
        replayStarted.current = true;
        sendVideoViewEvent(EVideoViewEvent.VIEW_END, state.playedSeconds);
      } else {
        seeked.current = true;
        sendVideoViewEvent(EVideoViewEvent.SEEK_START, state.playedSeconds);
      }
    };

    const handleDuration = duration => {
      // setting video duration to state
      setState(state => {
        return { ...state, duration };
      });

      if (startTime > duration) {
        if (seekToEndWhenEnded) {
          // If start time is passed the video duration, we seek it to the end so it pauses at the last frame.
          // This is a workaround for not marking the video completed when it is ended.
          // Also, subtract just enough time so it sticks to the last frame. Prevents BUG-1440
          playerRef?.current?.seekTo(duration - 0.01);
        }
        onEnded && onEnded();
        return;
      }

      if (startTime > 1) {
        const played = parseFloat(startTime / duration);
        setState(state => {
          return { ...state, played };
        });
        playerRef.current.seekTo(played);
      }
    };

    const handleClickFullscreen = () => {
      try {
        const videoContainer = document.getElementById(
          `react-player-${reactPlayerId.current}`,
        );
        const videoList = videoContainer?.getElementsByTagName('video');
        const video = videoList && videoList[0];
        if ((isSafari || isMobileSafari) && isIOS && isMobileOnly) {
          if (video?.webkitEnterFullScreen) {
            // Toggle fullscreen in Safari for mobile
            video.webkitEnterFullScreen();
            return;
          }
        }
        screenfull.request(findDOMNode(playerRef.current));
      } catch (e) {
        console.error('debugScreenfull > ', e);
      }
    };

    const handleUnmute = () => {
      setState(state => ({ ...state, muted: false }));
      playerRef.current?.player?.player.unmute();
    };

    useImperativeHandle(ref, () => ({
      handleUnmute,
    }));

    const sendVideoViewEvent = (
      videoViewEvent: EVideoViewEvent,
      playedSeconds: number,
    ) => {
      if (
        trackingMetadata?.isAnalyticsEnabled &&
        trackingMetadata?.eventId &&
        trackingMetadata?.videoId
      ) {
        videoAPI.insertVideoViewLogs(trackingMetadata?.eventId, {
          viewId: viewId.current,
          videoId: trackingMetadata.videoId,
          videoType: trackingMetadata.videoType || 'RECORDING',
          videoViewEventType: videoViewEvent,
          viewerId: trackingMetadata.accountId || '',
          viewerType: trackingMetadata.accountId ? 'LOGGED_IN' : 'ANONYMOUS',
          playedSeconds: playedSeconds,
          viewTimestamp: new Date(),
          eventId: trackingMetadata.eventId,
          viewLocation: trackingMetadata.viewLocation,
          locationUrl: vidoeLocationUrl,
          createdAt: new Date(),
          playerType: EPlayerType.REACT,
        });
      }
    };

    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      pip,
    } = state;
    // here onContextMenu={e => e.preventDefault()} for disable right click on video player
    return (
      <div
        className={styles.containerVideo}
        onDoubleClick={handleClickFullscreen}
        onContextMenu={e => e.preventDefault()}
        id={`react-player-${reactPlayerId.current}`}
      >
        <ReactPlayer
          ref={playerRef}
          className="react-player"
          width="100%"
          height="100%"
          url={url}
          pip={pip}
          playing={playing}
          controls={controls}
          light={light}
          loop={loop}
          playsinline={playsinline}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onReady={handleReady}
          // onStart={() => console.log('onStart')}
          onPlay={handlePlay}
          onEnablePIP={handleEnablePIP}
          onDisablePIP={handleDisablePIP}
          onPause={handlePause}
          onBuffer={onLoad}
          // onSeek={e => console.log('onSeek', e)}
          onEnded={handleEnded}
          onError={onError}
          onProgress={handleProgress}
          onDuration={handleDuration}
          onSeek={handleSeeking}
          config={{
            file: {
              attributes: {
                disablePictureInPicture: true,
                controlsList: 'nodownload',
                autoPlay: autoplay,
              },
              ...overrideFileConfig,
            },
          }}
        />
        {showTimer && (
          <VideoTimerView
            duration={duration}
            playedSeconds={state?.playedSeconds}
          />
        )}
      </div>
    );
  },
);

export default ReactPlayerWrapper;
