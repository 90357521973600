import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const RefreshCircle = (props: IIconProps) => {
  const { color = IContentColors.NEUTRAL_MID_2, size } = props;
  const fillClassName = styles[`fill-${color}`];
  return (<svg width={size} height={size} viewBox="0 0 16 16" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
<path d="M12.9028 13.6752C11.5418 14.8545 9.80076 15.5025 8 15.5C3.85775 15.5 0.5 12.1423 0.5 8C0.5 3.85775 3.85775 0.5 8 0.5C12.1423 0.5 15.5 3.85775 15.5 8C15.5 9.602 14.9975 11.087 14.1425 12.305L11.75 8H14C13.9999 6.61716 13.5221 5.2768 12.6475 4.20567C11.7729 3.13454 10.5551 2.39839 9.20025 2.12177C7.84536 1.84514 6.4365 2.04501 5.21201 2.68757C3.98752 3.33013 3.02257 4.37593 2.48039 5.64806C1.93822 6.92019 1.8521 8.34054 2.23662 9.66885C2.62113 10.9972 3.45267 12.1519 4.59056 12.9377C5.72845 13.7235 7.10284 14.0921 8.48123 13.9812C9.85962 13.8703 11.1574 13.2866 12.155 12.329L12.9028 13.6752Z" className={fillClassName} />
</svg>
);
};

export default RefreshCircle;
