import React from 'react';
import classnames from 'classnames';
// styles + types
import { ICheckBoxFieldProps } from './types';
import styles from './styles.module.scss';

const CheckBoxField = (props: ICheckBoxFieldProps) => {
  const {
    checked = false,
    className = '',
    onChange,
    label = '',
    onCheckBoxToggle,
    checkboxClassName,
    labelClassName,
    disabled = false,
  } = props;
  return (
    <div
      className={classnames(styles.checkBoxField, className)}
      onChange={onChange}
    >
      <input
        className={classnames(styles.checkBox, checkboxClassName)}
        type="checkbox"
        checked={checked}
        onClick={onCheckBoxToggle}
        disabled={disabled}
      />
      {label.length > 0 && (
        <span className={classnames(styles.checkBoxFieldLabel, labelClassName)}>
          {label}
        </span>
      )}
    </div>
  );
};

export default CheckBoxField;
