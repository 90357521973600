import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const Finsec2022 = {
  name: 'Finsec2022',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.Finsec2022_Expo,
  boothConfig: {
    '689be19b-2e87-478d-bf08-74e8d8dfea61': ManualLayoutConfig.DSCIFinsec_PHOTOBOOTH,
 },


};

export default Finsec2022;
