import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

const useEllipsisCheck = () => {
  const ref: any = React.useRef();
  const id = uuidv4();
  const [isEllipsis, setIsEllipsis] = React.useState(false);

  const checkEllipsisized = () => {
    if (ref?.current?.offsetWidth < ref?.current?.scrollWidth) {
      setIsEllipsis(true);
    } else {
      setIsEllipsis(false);
    }
  };

  React.useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      checkEllipsisized();
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
    // clean up function
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [ref]);

  React.useLayoutEffect(() => {
    checkEllipsisized();
  }, [ref.current]);

  return { id, isEllipsis, ref };
};

export default useEllipsisCheck;
