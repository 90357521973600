import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const KeyFilled = (props: IIconProps) => {
  const { color = IContentColors.GRAY_BLUE, size = 20 } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.59414 9.63815L15.2108 3.02148L16.9783 4.78898L15.8 5.96732L17.5675 7.73482L14.6216 10.6815L12.8541 8.91398L10.3625 11.4057C10.8213 12.2884 10.9492 13.3061 10.7232 14.275C10.4972 15.2438 9.9321 16.0999 9.13005 16.6885C8.32801 17.2771 7.34189 17.5594 6.34986 17.4845C5.35783 17.4095 4.42532 16.9822 3.7208 16.2798C3.01593 15.5761 2.58648 14.6431 2.51027 13.65C2.43406 12.6569 2.71613 11.6693 3.30537 10.8663C3.89461 10.0632 4.75203 9.4978 5.72226 9.2725C6.69248 9.0472 7.71132 9.17692 8.59414 9.63815ZM7.84497 14.5115C8.00415 14.3577 8.13112 14.1738 8.21847 13.9705C8.30582 13.7671 8.3518 13.5484 8.35372 13.3272C8.35564 13.1059 8.31347 12.8864 8.22967 12.6816C8.14587 12.4767 8.02212 12.2906 7.86563 12.1342C7.70914 11.9777 7.52305 11.8539 7.31823 11.7701C7.1134 11.6863 6.89393 11.6441 6.67263 11.6461C6.45134 11.648 6.23264 11.694 6.0293 11.7813C5.82596 11.8687 5.64205 11.9956 5.4883 12.1548C5.18471 12.4692 5.01672 12.8902 5.02051 13.3272C5.02431 13.7641 5.19959 14.1822 5.50861 14.4912C5.81762 14.8002 6.23564 14.9755 6.67263 14.9793C7.10963 14.9831 7.53063 14.8151 7.84497 14.5115Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default KeyFilled;
