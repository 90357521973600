import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const AddCircleFilled = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 21 } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_641_2726)">
        <path
          d="M10.4988 19.3339C5.89552 19.3339 2.16406 15.6024 2.16406 10.9992C2.16406 6.39589 5.89552 2.66443 10.4988 2.66443C15.1021 2.66443 18.8335 6.39589 18.8335 10.9992C18.8335 15.6024 15.1021 19.3339 10.4988 19.3339ZM9.66532 10.1657H6.33143V11.8326H9.66532V15.1665H11.3323V11.8326H14.6662V10.1657H11.3323V6.83179H9.66532V10.1657Z"
          className={fillClassName}
        />
      </g>
      <defs>
        <clipPath id="clip0_641_2726">
          <rect
            width="20.0033"
            height="20.0033"
            className={fillClassName}
            transform="translate(0.496582 0.997681)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddCircleFilled;
