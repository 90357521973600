import { IContentColors } from '@/types';
import React from 'react';
import styles from '../styles.module.scss';
import { IIconProps } from '../types';

const RaisedHandV2 = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0697 8.96854C15.2995 8.6278 14.1992 9.08454 13.4207 10.1887C12.9094 10.9137 12.7588 11.946 12.0872 12.0536C11.8307 12.0949 11.6914 11.9388 11.6189 11.714C11.5425 11.3348 11.5592 10.7486 11.7008 9.92887L13.2288 3.06108C13.3504 2.53629 13.0655 2.02827 12.3795 1.86264C11.6936 1.69701 11.1777 2.03326 11.0556 2.55805L9.69153 8.78508C9.4166 10.1603 8.90686 9.91045 9.0301 8.35287V8.35231L9.42103 1.0512C9.45282 0.486842 9.01894 0.0256439 8.37761 0.00110935C7.73629 -0.023426 7.274 0.361339 7.24108 0.925159L6.86855 8.40306C6.7888 9.91269 6.24842 9.34385 6.1313 8.25025L5.26467 2.20057C5.17601 1.63008 4.64118 1.23578 3.99986 1.33841C3.35854 1.44159 2.97097 1.97194 3.05963 2.54243L3.89336 8.07124C4.22406 10.182 3.79183 10.7665 3.08024 8.47445L2.19133 5.11443C2.04633 4.57514 1.51487 4.24057 0.847327 4.42793C0.179786 4.61532 -0.107985 5.15234 0.0364482 5.69163L0.905314 8.84977C1.26837 11.1234 1.26837 12.0207 1.26837 13.5861C1.26837 15.1516 1.98835 20 7.4954 20C13.0025 20 14.1351 14.4058 14.1875 14.0518C14.1875 14.0518 14.2561 13.2264 14.8322 12.454C15.6514 11.3559 16.2264 10.694 16.575 10.298C16.7517 10.0978 16.9235 9.3461 16.0697 8.96854Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default RaisedHandV2;
