import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const VolumeOn = (props: IIconProps) => {
  const { color = IContentColors.NEUTRAL_MID_1, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.90751 14H1.66668C1.44566 14 1.2337 13.9122 1.07742 13.7559C0.921141 13.5997 0.833344 13.3877 0.833344 13.1667V8.16668C0.833344 7.94567 0.921141 7.73371 1.07742 7.57743C1.2337 7.42115 1.44566 7.33335 1.66668 7.33335H4.90751L9.31918 3.72335C9.38022 3.67331 9.45422 3.64163 9.53257 3.63201C9.61091 3.62239 9.69038 3.63523 9.76171 3.66902C9.83305 3.70281 9.89332 3.75616 9.93551 3.82288C9.97769 3.88959 10.0001 3.96692 10 4.04585V17.2875C10.0001 17.3664 9.97769 17.4438 9.93551 17.5105C9.89332 17.5772 9.83305 17.6306 9.76171 17.6644C9.69038 17.6981 9.61091 17.711 9.53257 17.7014C9.45422 17.6917 9.38022 17.6601 9.31918 17.61L4.90834 14H4.90751ZM16.1717 17.445L14.9917 16.265C15.7817 15.5621 16.4137 14.6997 16.846 13.7348C17.2784 12.7698 17.5013 11.7241 17.5 10.6667C17.501 9.55509 17.2545 8.45723 16.7782 7.45283C16.302 6.44843 15.608 5.56271 14.7467 4.86002L15.93 3.67668C16.9454 4.53631 17.761 5.60709 18.3201 6.81435C18.8791 8.02161 19.168 9.33628 19.1667 10.6667C19.1667 13.3525 18.0117 15.7684 16.1717 17.445ZM13.2192 14.4925L12.0342 13.3075C12.4387 12.9964 12.7663 12.5965 12.9916 12.1385C13.2169 11.6806 13.3338 11.177 13.3333 10.6667C13.3333 9.47502 12.7083 8.42918 11.7667 7.84002L12.9658 6.64085C13.5971 7.10518 14.1101 7.71156 14.4635 8.41095C14.8169 9.11034 15.0007 9.88309 15 10.6667C15 12.2017 14.3083 13.575 13.2192 14.4925Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default VolumeOn;
