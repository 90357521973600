import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const Veeco3config = {
  name: 'Veeco3config',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.Veeco3_Expo,


};

export default Veeco3config;
