/* eslint-disable indent */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import classnames from 'classnames';
// components
import Label from '../../content/Label';
// styles+types
import styles from './styles.module.scss';
import { IEventSelectionFieldProps } from './types';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
// helpers
import {
  getGMTValueFromTimeZone,
  removeAsteriskFromLabel,
} from '@/utils/helpers';
import { IFormFieldTypes } from '@/types/form';
import { CheckBoxesField } from '../../NewFormComponents/CheckBoxesField';
import moment from 'moment';
import api from '@/api';
import { useParams } from 'react-router-dom';

export const EventSelectionField = (props: IEventSelectionFieldProps) => {
  const {
    error,
    isLastElement,
    fieldType,
    fieldName,
    onChange,
    submitFailed,
    name,
    value,
    widthStyles = '',
    validate,
    placeholder,
    selectStyleClass,
    labelStyleClass,
    titleLabelContainerClass,
    isMandatory = false,
    isSponsorLandingPage = false,
  } = props;

  const { eventId } = useParams() as any;
  const [eventSelectionOptions, setEventSelectionOptions] = useState<any[]>();
  const [seriesEventsData, setSeriesEventsData] = useState<any[]>();

  useEffect(() => {
    if (!eventId) {
      return;
    }
    api.event
      .getSeriesLiveAndUpcomingEvents(eventId)
      .then(res => {
        setSeriesEventsData(res.data);
      })
      .catch(err => console.error(err));
  }, [eventId]);

  useEffect(() => {
    if (!seriesEventsData) {
      return;
    }
    let options: any[] = [
      {
        label: 'Select All',
        value: 'SELECT_ALL',
      },
    ];
    options = options.concat(
      seriesEventsData?.map(item => ({
        label: item.title,
        description: (() => {
          const localStartTime = moment(item.startDateTime)
            .local()
            .format('MMM D, h:mm A');
          const localEndTime = moment(item.endDateTime)
            .local()
            .format('MMM D, h:mm A');
          const localTimeZone = getGMTValueFromTimeZone(
            Intl.DateTimeFormat().resolvedOptions().timeZone,
          ); // Get user's local time zone identifier
          return `${localStartTime} - ${localEndTime} (${localTimeZone})`;
        })(),
        value: item.eventId,
      })),
    );
    setEventSelectionOptions(options);
  }, [seriesEventsData]);

  const validationError = submitFailed && error;
  const SELECT_ALL = 'SELECT_ALL';

  const updateAnswers = (event: { value: any }) => {
    const currentValue = event?.value;
    const currentAnswers = value || [];
    let updatedAnswers: any[] | undefined = [];
    let includeSelectAll = false;

    if (!currentValue) {
      return [];
    }

    if (currentAnswers.includes(SELECT_ALL)) {
      // If "SELECT_ALL" is already selected
      if (currentValue === SELECT_ALL) {
        // Toggle off "SELECT_ALL" and remove all options
        updatedAnswers = [];
      } else {
        // Toggle off specific option and remove "SELECT_ALL" if other options remain
        updatedAnswers = eventSelectionOptions
          ?.map(o => o?.value)
          .filter(item => item !== SELECT_ALL && item !== currentValue);
      }
    } else {
      // If "SELECT_ALL" is not selected
      // eslint-disable-next-line no-lonely-if
      if (currentValue === SELECT_ALL) {
        // Toggle on "SELECT_ALL" and remove all other options
        updatedAnswers = [SELECT_ALL];
      } else {
        // Toggle on specific option
        if (currentAnswers.includes(currentValue)) {
          updatedAnswers = currentAnswers.filter(
            (item: any) => item !== currentValue,
          );
        } else {
          updatedAnswers = [...currentAnswers, currentValue];
        }
        // Check if "SELECT_ALL" needs to be included
        if (
          updatedAnswers?.length ===
          (eventSelectionOptions?.length ?? 0) - 1
        ) {
          includeSelectAll = true;
        }
      }
    }

    // Add "SELECT_ALL" if needed
    if (includeSelectAll) {
      updatedAnswers = [SELECT_ALL];
    }

    return updatedAnswers;
  };

  const handleOnChangeMultiSelect = (event: any) => {
    const updatedAnswers = updateAnswers(event);
    onChange?.(updatedAnswers);
  };

  const handleOnChangeCheckBoxes = (event: any) => {
    const updatedAnswers = updateAnswers(event);
    onChange?.(updatedAnswers);
  };

  const isChecked = (option: { value: string }) =>
    value &&
    (value.some((item: string) => item === option.value) ||
      value.find((item: string) => item === SELECT_ALL));

  // set up necessary style classes
  const fullContainerWidth = `${styles[widthStyles]}`;
  const marginRightClass = `${styles.addMarginRight}`;
  const containerWithValidationMessage = classnames(fullContainerWidth, {
    [marginRightClass]: !isLastElement,
  });

  const fullContainerPositioning = `${styles.container}`;
  const validationErrorBorder = `${styles.validationError}`;
  const innerContainerWithValidationBorder = classnames(
    fullContainerPositioning,
    { [validationErrorBorder]: !isSponsorLandingPage && validationError },
  );

  const selectFieldClass = `${styles.selectField}`;

  const checkBoxesContainer = `${styles.checkBoxesContainer}`;

  const customValueRenderer = (
    selected: { label: string; value: string }[],
  ) => {
    if (selected[0]?.label) {
      const trimmedName =
        selected[0].label.length > 35
          ? `${selected[0].label.substring(0, 35)}...`
          : selected[0].label;
      return selected.length > 1 ? (
        <>
          <span className={styles.unselectedStyle}>
            {trimmedName} + {selected.length - 1}
          </span>
        </>
      ) : (
        <span className={styles.unselectedStyle}>{trimmedName}</span>
      );
    }
    return (
      <div className={styles.placeHolder}>
        {removeAsteriskFromLabel(placeholder)}
      </div>
    );
  };

  const customItemRenderer = ({ option }) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
    <div
      key={option?.value}
      className={classnames(styles.itemContainer)}
      role="button"
      onClick={e => {
        e.preventDefault();
        handleOnChangeMultiSelect(option);
      }}
    >
      <div className={styles.inputContainer}>
        <input
          className={styles.input}
          type="checkbox"
          checked={isChecked(option)}
        />
        <span className={styles.checkbox} />
      </div>
      <div className={styles.labelContainer}>
        <div className={styles.itemLabel}>{option?.label}</div>
        {option?.description && (
          <div className={styles.itemDescription}>{option?.description}</div>
        )}
      </div>
    </div>
  );

  const customCheckboxLabel = (option: any) => (
    <div className={styles.labelContainer}>
      <div className={styles.itemLabel}>{option?.label}</div>
      {option?.description && (
        <div className={styles.itemDescription}>{option?.description}</div>
      )}
    </div>
  );

  return eventSelectionOptions && eventSelectionOptions.length > 1 ? (
    <div
      className={classnames(
        containerWithValidationMessage,
        'eventSelectionContainer',
      )}
    >
      {fieldType === IFormFieldTypes.MULTISELECT && (
        <div
          className={classnames(styles.titleLabelContainer, {
            [styles.ticketMappingLabelWidth]: titleLabelContainerClass,
            [styles.titleLabelContainerSponsorLandingPage]: isSponsorLandingPage,
          })}
        >
          {fieldName && fieldName.length && (
            <Label
              value={fieldName}
              styleClass="fieldSelectLabelTitleTextSponsorLandingPage"
              isMandatory={validate || isMandatory}
              isSponsorLandingPage={isSponsorLandingPage}
            />
          )}
          {fieldName && placeholder && (
            <Label
              value={removeAsteriskFromLabel(placeholder)}
              styleClass={
                // eslint-disable-next-line no-nested-ternary
                isSponsorLandingPage
                  ? 'fieldSelectLabelTitleTextSponsorLandingPage'
                  : value && value.length
                  ? 'fieldSelectLabelTitleTextFocus'
                  : 'fieldSelectLabelTitleTextWithoutFocus'
              }
              isMandatory={validate || isMandatory}
              isSponsorLandingPage={isSponsorLandingPage}
            />
          )}
        </div>
      )}
      {fieldType === IFormFieldTypes.MULTISELECT && (
        <div className={innerContainerWithValidationBorder}>
          <MultiSelect
            labelledBy={name}
            className={classnames(selectFieldClass, {
              [selectStyleClass]: selectStyleClass,
              inputFieldError: validationError,
              sponsorLandingPage: isSponsorLandingPage,
            })}
            options={eventSelectionOptions ?? []}
            value={
              eventSelectionOptions?.filter(o => value?.includes(o.value)) ?? []
            }
            onChange={handleOnChangeMultiSelect}
            hasSelectAll={false}
            ItemRenderer={customItemRenderer}
            valueRenderer={customValueRenderer}
            disableSearch={true}
          />
          {validationError && <FormValidationErrorTooltip value={error} />}
        </div>
      )}
      {fieldType === IFormFieldTypes.CHECKBOXES && (
        <div className={checkBoxesContainer}>
          <div
            className={classnames(styles.titleLabelContainer, {
              [styles.ticketMappingLabelWidth]: titleLabelContainerClass,
              [styles.titleLabelContainerSponsorLandingPage]: isSponsorLandingPage,
            })}
          >
            {fieldName && fieldName.length && (
              <Label
                value={
                  validate
                    ? `${removeAsteriskFromLabel(fieldName)}`
                    : removeAsteriskFromLabel(fieldName)
                }
                styleClass={
                  labelStyleClass || isSponsorLandingPage
                    ? 'fieldLabelTitleTextMaxWidth100'
                    : 'fieldSelectLabelTitleTextSponsorLandingPage'
                }
                isMandatory={validate || isMandatory}
              />
            )}
          </div>
          <div className={innerContainerWithValidationBorder}>
            <CheckBoxesField
              id="checkboxes"
              label="Checkboxes"
              styleClass={styles.checkBox}
              options={eventSelectionOptions ?? []}
              customLabel={customCheckboxLabel}
              onChange={handleOnChangeCheckBoxes}
              isChecked={isChecked}
            />
            {validationError && <FormValidationErrorTooltip value={error} />}
          </div>
        </div>
      )}
    </div>
  ) : null;
};
