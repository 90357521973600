const umdDemo = {
    name: 'iotPLNG',
    auth: {
      magicLinks: true,
    },
    zoneNames: {
      expo: 'Discover',
    },  
  };
  
  export default umdDemo;