import { APOLLO_FOUR_WHEELER_BOOTH3, QUALCOMM_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';

const qualcomm = {
    name: 'qualcomm',
    auth: {
      magicLinks: true,
    },
    boothConfig: {
      '15f7a329-5412-422b-b91b-f35de4212ad5': QUALCOMM_PHOTOBOOTH,
      '46f75aa1-16b3-4391-81d0-012c6e8a6e3c': APOLLO_FOUR_WHEELER_BOOTH3,
      //staging
      '37a18b11-2bee-4c3d-bdf8-d847e0c52931': APOLLO_FOUR_WHEELER_BOOTH3,
      'ad209531-2037-4a95-87bb-260f465a6b70': QUALCOMM_PHOTOBOOTH,
      // local
      '928548f3-6859-4807-887b-7e9aa9ec11ad': QUALCOMM_PHOTOBOOTH,
      '9effdeb7-a387-4980-9cff-2b76f73eba62': APOLLO_FOUR_WHEELER_BOOTH3,
    },
    
  };


  export default qualcomm;