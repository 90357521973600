import React, { useState } from 'react';
import classnames from 'classnames';
// components
import Label from '@/components/ui/content/Label';
import SelectFieldExternal from 'react-select';
// helpers
import {
  generateSelectFieldOptionsFromStringArray,
  removeAsteriskFromLabel,
} from '@/utils/helpers';
// styles + types
import styles from './styles.module.scss';
import { ISelectFieldOptionsArray } from '@/types/selectField';
import { ISelectFieldProps } from './types';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';
import isTieConEvent from '@/hooks/is-tiecon-event';
import { useLocation, useParams } from 'react-router-dom';
import { getCssVar } from '@/utils/cssVars';
import { IContentColors } from '@/types';

const SelectField = (props: ISelectFieldProps) => {
  const [showShadow, setShowShadow] = useState(false);

  const {
    allowMultiSelect = false,
    data,
    error,
    isLastElement,
    label,
    onChange,
    readOnly,
    submitFailed,
    value,
    widthStyles = '',
    validate,
    tooltip,
    placeholder,
    selectStyles = {},
    selectStyleClass = '',
    isClearable = true,
    components = {},
    containerStyles = '',
    labelStyles = '',
    isSearchable = false,
    isSponsorLandingPage = false,
  } = props;

  let selectFieldOptions = data as ISelectFieldOptionsArray;
  let selectedOption = null as any;

  const { eventId = '' } = useParams();
  const location = useLocation();

  const isTieconEvent = isTieConEvent(eventId, location.pathname);

  const validationError = submitFailed && error;

  if (data && typeof data[0] === 'string') {
    selectFieldOptions = generateSelectFieldOptionsFromStringArray(data);
  }

  if (data && data[0] && typeof data[0].value === 'boolean') {
    const selectedOptionIndex = selectFieldOptions.findIndex(
      obj => obj.value === value,
    );
    selectedOption = selectFieldOptions[selectedOptionIndex];
  }

  if (value) {
    if (Array.isArray(value)) {
      selectedOption =
        selectFieldOptions &&
        selectFieldOptions.filter(so => {
          return value.includes(so.value);
        });
    } else {
      const selectedOptionIndex = selectFieldOptions.findIndex(
        obj => obj.value === value,
      );
      selectedOption = selectFieldOptions[selectedOptionIndex];
    }
  }

  const handleOnChange = (input: any) => {
    if (allowMultiSelect) {
      const iA =
        input &&
        input.map(i => {
          return i.value;
        });
      onChange(iA);
    } else {
      console.log('onChange', input?.value);
      onChange(input?.value);
    }
  };
  // set up necessary style classes
  const fullContainerWidth = `${styles[widthStyles]}`;
  const marginRightClass = `${styles.addMarginRight}`;
  const landingPageSelect = `${styles[selectStyleClass]}`;
  const containerWithValidationMessage = classnames(
    fullContainerWidth,
    { [marginRightClass]: !isLastElement },
    { [landingPageSelect]: selectStyleClass },
    { [styles.maxWidth]: isTieconEvent },
  );

  const fullContainerPositioning = `${styles.container}`;
  const validationErrorBorder = `${styles.validationError}`;
  const validationErrorBorderLP2 = `${styles.errorState}`;
  const showFocusShadowClass = `${styles.focusShadow}`;
  const innerContainerWithValidationBorder = classnames(
    fullContainerPositioning,
    { [validationErrorBorder]: !isSponsorLandingPage && validationError },
    { [validationErrorBorderLP2]: validationError },
    { [showFocusShadowClass]: showShadow },
    { [styles[containerStyles]]: !!containerStyles },
  );

  const selectFieldClass = `${styles.selectField}`;
  // Forcefully add focus on the field since it's a div
  const handleContainerClick = () => !showShadow && setShowShadow(true);
  const handleContainerBlur = () => showShadow && setShowShadow(false);

  const selectStylesSponsorLandingPage = {
    control: provided => ({
      ...provided,
      border: validationError ? '2px solid #ff6b6b' : '1px solid #bccbdc',
      fontSize: '12px',
      marginTop: '-10px',
      marginBottom: '-10px',
    }),
    menu: provided => ({
      ...provided,
      zIndex: 999999,
    }),
    singleValue: () => ({
      paddingTop: '10px',
      paddingBottom: '10px',
    }),
    placeholder: () => ({
      color: getCssVar(IContentColors.NEUTRAL_MID_2),
      position: 'absolute',
    }),
    valueContainer: provided => ({
      ...provided,
      paddingLeft: '12px',
      paddingRight: '12px',
      paddingTop: '0px',
      paddingBottom: '0px',
      whiteSpace: "nowrap"
    }),
  };

  return (
    <div
      className={containerWithValidationMessage}
      onClick={handleContainerClick}
      onBlur={handleContainerBlur}
      tabIndex={0}
    >
      <div className={innerContainerWithValidationBorder}>
        <div
          className={classnames(styles.titleLabelContainer, {
            [styles.maxWidth]: isTieconEvent,
          })}
        >
          {label && label.length ? (
            <Label
              value={removeAsteriskFromLabel(label)}
              hasTooltip={isTieconEvent}
              styleClass={
                labelStyles || isSponsorLandingPage
                  ? 'fieldSelectLabelTitleTextSponsorLandingPage'
                  : isTieconEvent
                  ? 'fieldLabelTitleTextMaxWidth100'
                  : 'fieldLabelTitleText'
              }
              wrapperClass={isTieconEvent && 'tieconWrapperClass'}
              isMandatory={!!validate}
              isSponsorLandingPage={isSponsorLandingPage}
            />
          ) : (
            <>
              {/* if placeholder and no label, we use placeholder as both placeholder and label */}
              {!label && placeholder && (
                <Label
                  value={removeAsteriskFromLabel(placeholder)}
                  styleClass={
                    isSponsorLandingPage
                      ? 'fieldSelectLabelTitleTextSponsorLandingPage'
                      : value && value.length
                      ? 'fieldSelectLabelTitleTextFocus'
                      : 'fieldSelectLabelTitleTextWithoutFocus'
                  }
                  isMandatory={!!validate}
                  isSponsorLandingPage={isSponsorLandingPage}
                />
              )}
            </>
          )}
          {label && label.length && tooltip ? (
            <TooltipDiv
              dataFor={`tooltip_select_${label}`}
              tooltipText={tooltip}
            />
          ) : null}
        </div>
        <div className={validationError ? styles['validationErrorBorder'] : ''}>
          <SelectFieldExternal
            placeholder={isSponsorLandingPage ? removeAsteriskFromLabel(placeholder) : placeholder}
            className={selectFieldClass}
            defaultValue={selectedOption}
            value={selectedOption}
            isMulti={allowMultiSelect}
            onChange={handleOnChange}
            options={selectFieldOptions}
            isSearchable={isSearchable}
            isDisabled={readOnly}
            tabIndex={0}
            styles={
              isSponsorLandingPage
                ? selectStylesSponsorLandingPage
                : selectStyles
            }
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
              },
            })}
            isClearable={isClearable}
            components={components}
          />
        </div>
      </div>
      {validationError && <FormValidationErrorTooltip value={error} />}
    </div>
  );
};

export default SelectField;
