import React from 'react';
import { useDispatch } from 'react-redux';
// components
import { Upload, XCircle } from 'react-feather';
// styles + types
import styles from './styles.module.scss';

const FileUploadField = (props) => {
  const { 
    label = "Select a file from your computer", 
    onChange, 
    value, 
    filePlaceHolder= 'Drag a file here', 
    maxFileSize = 4,
    isFileSizeConstraint = true
  } = props;
  const dispatch = useDispatch();

  const onFileChange = (e) => {
    e.preventDefault();
    const chosenFile = e.target.files[0];
    if(!chosenFile){
      dispatch({ type: 'global/addDangerToast', payload: { description: `Please select valid csv file` } });
      return
    }
    if (isFileSizeConstraint && (chosenFile.size / Math.pow(1024, 2)) > maxFileSize) {
      dispatch({ type: 'global/addDangerToast', payload: { description: `Please select csv file below ${maxFileSize} MB.` } });
      return
    }
    onChange(e.target.files[0]);
  };

  const deleteFile = () => {
    onChange(null);
  }

  return (
    <div className={styles.fileUploadContainer}>
      {
        value ? (
          <>
            <div className={styles.selectedFile}>
              {value.name}
              <XCircle onClick={deleteFile} />
            </div>
          </>
        )
        : (
          <>
            <Upload />
            <div className={styles.infoContainer}>
              <div className={styles.placeHolderContainer}>
                <label className={styles.labelText}>{`${filePlaceHolder} or`}</label>
                <label className={styles.labelBrowseText}>{`browse`}</label>
              </div>
              <label className={styles.labelHintText}>
                Supports: csv{ isFileSizeConstraint ? `; upto ${maxFileSize}Mb` : ""}</label>
            </div>
            <input className={styles.fileInput} type="file" onChange={onFileChange} />
          </>
        )
      }
    </div>
  );
};

export default FileUploadField;
