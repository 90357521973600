import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';
import { IEventCustomConfigType } from '@/custom-config/types';

const acxiom: IEventCustomConfigType = {
  name: 'acxiom',
  auth: {
    magicLinks: true,
  },
  zoneNames: {
    lobby: 'Homepage',
    stages: 'Sessions',
  },
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      if (numStreams <= 3) {
        return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
      } else {
        return IStreamsDisplayMode.SPOTLIGHT;
      }
    }

    if (numStreams <= 3) {
      return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
    } else {
      return IStreamsDisplayMode.CONDENSED;
    }
  },
};
export default acxiom;
