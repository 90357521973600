import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const PinIcon = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 9 } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      className={fillClassName}
      width={size}
      height={size}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.36751 3.81409L7.83688 4.34434L7.57176 4.07921L5.98101 5.66996L5.71588 6.99596L5.18526 7.52621L3.59451 5.93508L1.73826 7.79133L1.20801 7.26108L3.06426 5.40483L1.47313 3.81409L2.00338 3.28346L3.32976 3.01834L4.92051 1.42759L4.65538 1.16246L5.18563 0.631836L8.36751 3.81409Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default PinIcon;
