import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const PeopleIcon = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 50 50',
      }}
    >
      <path
        d="M16.4642 12.7671L17.3282 9.88714C17.4242 9.50314 17.1362 9.11914 16.7522 9.11914H14.2082C13.4882 10.0311 12.4322 10.6551 11.1362 10.6551C9.84024 10.6551 8.73624 10.0791 8.06424 9.11914H5.56824C5.18424 9.11914 4.89624 9.50314 4.99224 9.88714L6.91224 16.4631C7.00824 16.7031 7.20024 16.8951 7.48824 16.8951H11.1362C11.1362 16.7511 11.1362 16.6551 11.1362 16.5111C11.1362 14.3031 12.9122 12.5271 15.1202 12.5271C15.6002 12.5271 16.0322 12.6231 16.4642 12.7671Z"
        fill="white"
        className={fillClassName}
      />
      <path d="M9.40771 9.11925C9.88771 9.50325 10.4637 9.69525 11.1357 9.69525C11.8077 9.69525 12.3837 9.50325 12.8637 9.11925C13.5357 8.59125 13.9677 7.77525 13.9677 6.86325C13.9677 5.27925 12.7197 4.03125 11.1357 4.03125C9.55171 4.03125 8.30371 5.27925 8.30371 6.86325C8.30371 7.77525 8.73571 8.59125 9.40771 9.11925Z"
        fill="white"
        className={fillClassName}
      />
      <path d="M17.9528 15.5511C18.0488 15.4551 18.0008 15.2631 17.8568 15.2631L16.2248 15.0231C16.1768 15.0231 16.1288 14.9751 16.0808 14.9271L15.9368 14.6391L15.3608 13.4391C15.2648 13.2951 15.0728 13.2951 15.0248 13.4391L14.3048 14.9271C14.2568 14.9751 14.2088 15.0231 14.1608 15.0231L12.5288 15.2631C12.3848 15.2631 12.3368 15.4551 12.4328 15.5511L13.5848 16.7031C13.6328 16.7511 13.6328 16.7991 13.6328 16.8471V16.8951L13.3448 18.4791C13.2968 18.6231 13.4408 18.7191 13.5848 18.6711L15.0248 17.9031C15.0728 17.8551 15.1688 17.8551 15.3608 17.9031L16.8008 18.6711C16.8968 18.7191 17.0888 18.6231 17.0408 18.4791L16.7528 16.8471C16.7528 16.7991 16.7528 16.7511 16.8008 16.7031L17.9528 15.5511Z"
        fill="white"
        className={fillClassName}
      />
    </svg>

  );
};

export default PeopleIcon;
