
export enum EVideoViewEvent {
    VIEW_START = 'VIEW_START',
    VIEW_END = 'VIEW_END',
    PLAY = 'PLAY',
    PAUSE = 'PAUSE',
    SEEK_START = 'SEEK_START',
    SEEK_END = 'SEEK_END',
    TIME_UPDATE = 'TIME_UPDATE',
    PLAY_ERROR = 'PLAY_ERROR',
  }
  
  export enum EViewLocation {
    LANDING_PAGE= 'LANDING_PAGE' ,  
    EVENT_HUB_WIDGET = 'EVENT_HUB_WIDGET', 
    SCHEDULE = 'SCHEDULE'
  }
  
  export enum EPlayerType {
    REACT ='REACT',
    MUX = 'MUX'
  }