import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const VideoOn = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.48 11.904L20.544 9.91999C20.544 9.40799 20.16 9.02399 19.648 9.02399H6.71997C6.20797 9.02399 5.82397 9.40799 5.82397 9.91999V22.144C5.82397 22.656 6.20797 23.04 6.71997 23.04H19.648C20.096 23.04 20.544 22.656 20.544 22.144V19.84L20.416 19.776C19.84 19.392 19.52 18.816 19.52 18.176V13.568C19.52 12.864 19.84 12.224 20.48 11.904ZM9.91997 20.416C9.27997 20.416 8.70397 19.904 8.70397 19.2C8.70397 18.56 9.21597 17.984 9.91997 17.984C10.624 17.984 11.136 18.496 11.136 19.2C11.2 19.84 10.624 20.416 9.91997 20.416Z"
        className={fillClassName}
      />
      <path
        d="M26.24 11.136V20.8C26.24 21.184 25.92 21.504 25.6 21.44C25.216 21.376 21.056 18.624 21.056 18.624C20.864 18.496 20.8 18.304 20.8 18.112V13.504C20.8 13.312 20.928 13.056 21.12 12.992C21.12 12.992 25.28 10.496 25.6 10.496C25.856 10.496 26.24 10.816 26.24 11.136Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default VideoOn;
