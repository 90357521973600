import { AISS2021_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';
import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';

const aissConfig = {
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      return IStreamsDisplayMode.SPOTLIGHT;
    }

    switch (numStreams) {
      case 1:
      case 2:
        return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
      default:
        return IStreamsDisplayMode.GRID;
    }
  },
  containerPadding: {
    leftPercentage: 16.5,
    rightPercentage: 16.5,
    topPercentage: 3,
    bottomPercentage: 21.2,
  },
  boothConfig: {
    '2e63dabd-81a8-4eff-be1d-bfa8e76beb96': AISS2021_PHOTOBOOTH,
    // local
    'bc3d5727-4a21-4e97-a991-a22e841c7bfd': AISS2021_PHOTOBOOTH,
  },
};

export default aissConfig;
