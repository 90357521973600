import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const RecordingIcon = (props: IIconProps) => {
  const { color = IContentColors.DANGER, size } = props;
  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 9 10"
      className={fillClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5C9 7.48528 6.98528 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C6.98528 0.5 9 2.51472 9 5ZM0.9 5C0.9 6.98822 2.51177 8.6 4.5 8.6C6.48822 8.6 8.1 6.98822 8.1 5C8.1 3.01177 6.48822 1.4 4.5 1.4C2.51177 1.4 0.9 3.01177 0.9 5Z"
        fill="white"
      />
      <circle cx="4.5" cy="5" r="2.8125" className={fillClassName} />
    </svg>
  );
};

export default RecordingIcon;
