import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const TimerLine = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 11 12" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M8.54285 2.73266L9.32989 1.94562L10.0958 2.71154L9.30877 3.49858C10.0865 4.47205 10.4619 5.70635 10.358 6.94799C10.2541 8.18962 9.67873 9.34435 8.75003 10.175C7.82134 11.0057 6.60984 11.4492 5.36434 11.4145C4.11885 11.3799 2.9339 10.8696 2.05286 9.98857C1.17182 9.10753 0.661562 7.92259 0.62689 6.67709C0.592217 5.43159 1.03576 4.22009 1.86642 3.2914C2.69708 2.36271 3.85181 1.78732 5.09345 1.68341C6.33509 1.5795 7.56939 1.95496 8.54285 2.73266ZM5.49977 10.3333C5.9977 10.3333 6.49075 10.2353 6.95078 10.0447C7.4108 9.85416 7.82879 9.57487 8.18088 9.22278C8.53297 8.87069 8.81226 8.4527 9.00281 7.99267C9.19336 7.53265 9.29143 7.03959 9.29143 6.54166C9.29143 6.04374 9.19336 5.55068 9.00281 5.09066C8.81226 4.63063 8.53297 4.21264 8.18088 3.86055C7.82879 3.50846 7.4108 3.22917 6.95078 3.03862C6.49075 2.84807 5.9977 2.75 5.49977 2.75C4.49416 2.75 3.52973 3.14948 2.81865 3.86055C2.10758 4.57163 1.7081 5.53605 1.7081 6.54166C1.7081 7.54728 2.10758 8.5117 2.81865 9.22278C3.52973 9.93385 4.49416 10.3333 5.49977 10.3333ZM4.9581 3.83333H6.04143V7.08333H4.9581V3.83333ZM3.3331 0.0416641H7.66643V1.125H3.3331V0.0416641Z" className={fillClassName} />
    </svg>
  );
};

export default TimerLine;
