import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const StopCircle = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 13 12" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M6.49984 11.8332C3.27809 11.8332 0.666504 9.22159 0.666504 5.99984C0.666504 2.77809 3.27809 0.166504 6.49984 0.166504C9.72159 0.166504 12.3332 2.77809 12.3332 5.99984C12.3332 9.22159 9.72159 11.8332 6.49984 11.8332ZM4.74984 4.24984V7.74984H8.24984V4.24984H4.74984Z" className={fillClassName} />
    </svg>
  );
};

export default StopCircle;
