import React from 'react';
import { Field } from 'react-final-form';
// config
import { COMPONENT_MAP } from './config';
// styles + types
import { IFormFieldProps } from './types';
import { IFormFieldCategory, IFormFieldTypes } from '@/types/form';
import { EventSelectionField } from '../EventSelectionField';
const { INPUT, MULTISELECT } = IFormFieldTypes;

const FormField = (props: IFormFieldProps) => {
  const {
    field,
    formValues,
    index,
    readOnly,
    totalLength,
    isSponsorLandingPage = false,
    isEditProfileSection = false,
  } = props;
  let { name, fieldType, fieldCategory, widthRatio, validate } = field;

  const widthStyles = `flexGrow${widthRatio}`;
  // TODO: remove this hack later
  fieldType = fieldType in COMPONENT_MAP ? fieldType : INPUT;
  const FieldElement =
    fieldCategory === IFormFieldCategory.EVENT_SELECTION
      ? EventSelectionField
      : COMPONENT_MAP[fieldType];

  const renderField = ({ input, meta }) => {
    const extraProps = {} as any;
    if (fieldType === MULTISELECT) {
      extraProps.allowMultiSelect = true;
    }
    extraProps.isLastElement =
      totalLength > 1 ? index === totalLength - 1 : true;

    const metaKeys = (({
      error,
      submitFailed,
      submitError,
      touched,
      dirtySinceLastSubmit,
    }) => ({
      error,
      submitFailed,
      submitError,
      dirtySinceLastSubmit,
      touched,
    }))(meta);

    return (
      <FieldElement
        key={`${name}${fieldType}Field`}
        {...extraProps}
        {...field}
        {...input}
        {...metaKeys}
        readOnly={readOnly}
        widthStyles={widthStyles}
        fieldType={fieldType}
        value={formValues[name]}
        isSponsorLandingPage={isSponsorLandingPage}
        isEditProfileSection={isEditProfileSection}
        required={!!field?.validate}
        isMandatory={field.isMandatory || !!field?.validate}
        showDatePickerOnly={true}
      />
    );
  };

  return (
    <Field key={index} name={name} validate={validate} render={renderField} />
  );
};

export default FormField;
