import React from 'react';
// styles + types
import { IIconProps } from '../types';
import styles from '../styles.module.scss';

const CopyIcon = (props: IIconProps) => {
  const { color = '#2E425A', size, onClick } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      className={fillClassName}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M15.3033 12.9467L14.125 11.7667L15.3033 10.5883C15.693 10.202 16.0026 9.74258 16.2142 9.23633C16.4259 8.73008 16.5354 8.18701 16.5366 7.6383C16.5378 7.08959 16.4307 6.54605 16.2212 6.03888C16.0118 5.53171 15.7043 5.0709 15.3163 4.6829C14.9283 4.2949 14.4675 3.98736 13.9603 3.77794C13.4531 3.56851 12.9096 3.46132 12.3609 3.46252C11.8122 3.46372 11.2691 3.57329 10.7628 3.78494C10.2566 3.99658 9.79713 4.30614 9.41083 4.69583L8.2325 5.875L7.05333 4.69666L8.23333 3.51833C9.32735 2.42431 10.8112 1.8097 12.3583 1.8097C13.9055 1.8097 15.3893 2.42431 16.4833 3.51833C17.5774 4.61235 18.192 6.09616 18.192 7.64333C18.192 9.19051 17.5774 10.6743 16.4833 11.7683L15.3042 12.9467H15.3033ZM12.9467 15.3033L11.7675 16.4817C10.6735 17.5757 9.18967 18.1903 7.6425 18.1903C6.09532 18.1903 4.61152 17.5757 3.5175 16.4817C2.42348 15.3876 1.80887 13.9038 1.80887 12.3567C1.80887 10.8095 2.42348 9.32568 3.5175 8.23166L4.69667 7.05333L5.875 8.23333L4.69667 9.41166C4.30697 9.79796 3.99742 10.2574 3.78577 10.7637C3.57413 11.2699 3.46456 11.813 3.46336 12.3617C3.46215 12.9104 3.56934 13.4539 3.77877 13.9611C3.9882 14.4683 4.29574 14.9291 4.68373 15.3171C5.07173 15.7051 5.53254 16.0126 6.03971 16.2221C6.54689 16.4315 7.09043 16.5387 7.63914 16.5375C8.18784 16.5363 8.73091 16.4267 9.23716 16.2151C9.74341 16.0034 10.2029 15.6939 10.5892 15.3042L11.7675 14.1258L12.9467 15.3042V15.3033ZM12.3567 6.46416L13.5358 7.64333L7.64333 13.535L6.46417 12.3567L12.3567 6.465V6.46416Z"
        fill={color}
        className={fillClassName}
      />
    </svg>
  );
};

export default CopyIcon;
