import { useEffect } from 'react';
import { HostChannelEvent } from '@/types/messaging';
import { useDispatch } from 'react-redux';

const useSessionOrderChanges = ({ hostChannel, eventId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hostChannel) {
      return;
    }

    const sessionOrderChangeListener = () => {
      dispatch({
        type: 'event/getEventPendingSegmentList',
        payload: {
          eventId,
        },
      });
    };

    hostChannel.bind(
      HostChannelEvent.STAGE_SESSIONS_UPDATE,
      sessionOrderChangeListener,
    );

    // eslint-disable-next-line consistent-return
    return () => {
      hostChannel.unbind(
        HostChannelEvent.STAGE_SESSIONS_UPDATE,
        sessionOrderChangeListener,
      );
    };
  }, [hostChannel]);
};

export default useSessionOrderChanges;
