import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
// components
import Label from '../../content/Label';
// styles + types
import styles from './styles.module.scss';
import { IInputFieldProps } from './types';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';
import { Eye, EyeOff } from 'react-feather';
import { getCssVar } from '@/utils/cssVars';
import { IContentColors } from '@/types';

const InputField = (props: IInputFieldProps) => {
  const {
    className = '',
    error,
    isLastElement,
    label,
    onChange,
    onBlur,
    placeholder = '',
    readOnly,
    styleClass = '',
    submitFailed,
    value,
    widthStyles = '',
    marginStyles = '',
    validate,
    tooltip,
    maxLength,
    type,
    maskInputFeature = false,
    forceShowPlaceholder = false,
    hideAsterisk = false,
    dataTestId,
  } = props;

  const [showMaxLengthExceededError, setShowMaxLengthExceededError] = useState(false);
  const fieldRef = useRef();

  useEffect(() => {
    if (!fieldRef.current) return;
    // To reflect data changes into the field text value.
    if (value === undefined) {
      fieldRef.current.value = '';
      return
    }
    if (fieldRef.current.value !== value) {
      fieldRef.current.value = value;
    }
  }, [value]);

  const validationError = (() => {
    if (error && submitFailed === undefined) {
      // with out Form Section
      return true;
    } else if (error && submitFailed === true) {
      // with Form Section
      return true;
    } else {
      return false;
    }
  })();

  // set up necessary style classes
  const fullContainerWidth = `${styles[widthStyles]}`;
  const fullContainerPositioning = `${styles.container}`;
  const marginRightClass = `${styles.addMarginRight}`;
  const containerWithValidationMessage = classnames(
    fullContainerWidth,
    fullContainerPositioning,
    { [marginRightClass]: !isLastElement },
  );

  const validationErrorBorder = `${styles.validationError}`;
  const marginClass = `${styles[marginStyles]}`;
  const baseClass = 'inputField';
  const inputFieldBaseClass = classnames(
    `${styles[label ? `${baseClass}` : `${baseClass}NoLabel`]}`,
    { [validationErrorBorder]: validationError },
    { [validationErrorBorder]: showMaxLengthExceededError },
  );
  const styleClassExists = !!styleClass;
  const inputFieldClass = classnames(
    { [inputFieldBaseClass]: !styleClassExists },
    { [`${styles[styleClass]}`]: styleClassExists },
    { [className]: className },
    marginClass,
  );

  const handleChangeInput = (event) => {
    const { value } = event.target;
    setShowMaxLengthExceededError(maxLength !== undefined && maxLength === value.length);
    onChange && onChange(event);
  }

  const [showText, setShowText] = useState(true);
  useEffect(() => {
    if (maskInputFeature || type == 'password') {
      setShowText(false);
    }
  }, [maskInputFeature, type])
  const handleMaskInputToggle = () => {
    setShowText(!showText);
  }
  const EyeIcon = showText ? (
    <Eye color={getCssVar(IContentColors.BLACK)} size={15} onClick={handleMaskInputToggle} />
  ) : (
    <EyeOff
      color={getCssVar(IContentColors.BLACK)}
      size={15}
      onClick={handleMaskInputToggle}
    />
  );

  return (
    <div className={containerWithValidationMessage}>
      {label && label.length && <div className={styles.titleLabelContainer}>
        {
          label && label.length && (
            <Label
              value={
                validate && !hideAsterisk
                  ? `${label}*`
                  : label
              }
              styleClass="fieldLabelTitleText"
            />
          )
        }
     
        {/* max length will show only when label was set */}
        {
          label && label.length && maxLength &&
          <Label value={`(Max ${maxLength} characters)`} styleClass="fieldLabelTitleTextLength" />
        }
        {/* tooltip will show only when label was set */}
        {
          label && label.length && tooltip && (
            <TooltipDiv dataFor={`tooltip_input_${label}`} tooltipText={tooltip} />
          )
        }
      </div>}
      <input
        data-testid={dataTestId}
        ref={fieldRef}
        className={inputFieldClass}
        defaultValue={value || ''}
        disabled={readOnly}
        onChange={handleChangeInput}
        onBlur={onBlur}
        placeholder={
          forceShowPlaceholder ? placeholder : label ? placeholder : ''
        }
        tabIndex={0}
        // type={(type && (type == 'password')) ? 'password' : "text"}
        type={showText ? 'text' : 'password'}
        maxLength={maxLength}
      />
      {maskInputFeature && <div className={styles.passwordVisibilityToggleContainer}>
        {EyeIcon}
      </div>}
      {
        (maxLength && !!value) &&
        <div className={styles.textLimit}>{value.length}/{maxLength} characters</div>
      }
      {validationError && !showMaxLengthExceededError && <FormValidationErrorTooltip value={error} />}
      {!validationError && showMaxLengthExceededError && <FormValidationErrorTooltip value='Max Character Limit Reached!' />}
    </div>
  );
};

export default React.memo(InputField);
