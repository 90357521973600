import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const FilterBar = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];
  return (<svg width={size} height={size} viewBox="0 0 15 10" className={fillClassName}  xmlns="http://www.w3.org/2000/svg">
      <path d="M5.91667 9.75H9.08333V8.16667H5.91667V9.75ZM0.375 0.25V1.83333H14.625V0.25H0.375ZM2.75 5.79167H12.25V4.20833H2.75V5.79167Z" />
    </svg>);
};

export default FilterBar;
