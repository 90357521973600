import { useRef, useState, useLayoutEffect, useEffect } from "react";

const useDimensions = (deps = [], onDimensionsChanged, page) => {
  const ref = useRef();

  const [dimensions, setDimensions] = useState({});

  useEffect(() => {
  }, [onDimensionsChanged]);

  const setDims = (dims) => {
    setDimensions(dims);
  }

  useEffect(() => {
  }, []);

  useEffect(() => {
    onDimensionsChanged && onDimensionsChanged(dimensions);
  }, [dimensions]);

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      if (ref.current) {
        setDims(ref.current.getBoundingClientRect().toJSON());
      }
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [ref]);

  useLayoutEffect(() => {
    if (ref.current) {
      if(typeof ref.current.getBoundingClientRect() === 'function') {
        setDims(ref.current.getBoundingClientRect().toJSON());
      } else {
        setDims(ref.current.getBoundingClientRect());
      }
    }
  }, [ref.current].concat(deps));

  return [ref, dimensions];
};

export default useDimensions;
