import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const MapPinRangeFill = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" className={fillClassName}  xmlns="http://www.w3.org/2000/svg">
        <path d="M5.79159 11.7062C4.36267 11.5243 3.05661 10.8053 2.13884 9.69505C1.22108 8.58485 0.760492 7.16684 0.850691 5.72923C0.940891 4.29162 1.57511 2.9423 2.62445 1.95552C3.67378 0.968734 5.05949 0.418535 6.49992 0.41675C7.94211 0.415676 9.33046 0.964531 10.3821 1.95148C11.4337 2.93843 12.0694 4.2892 12.1597 5.72857C12.25 7.16793 11.7881 8.58757 10.8681 9.69823C9.94812 10.8089 8.63927 11.527 7.20825 11.7062V13.1752C10.0033 13.2404 12.1666 13.6874 12.1666 14.2292C12.1666 14.8157 9.62934 15.2917 6.49992 15.2917C3.3705 15.2917 0.833252 14.8157 0.833252 14.2292C0.833252 13.6874 2.9965 13.2404 5.79159 13.1752V11.7062ZM6.49992 7.50008C6.87564 7.50008 7.23598 7.35083 7.50165 7.08515C7.76733 6.81947 7.91659 6.45914 7.91659 6.08342C7.91659 5.70769 7.76733 5.34736 7.50165 5.08168C7.23598 4.81601 6.87564 4.66675 6.49992 4.66675C6.1242 4.66675 5.76386 4.81601 5.49818 5.08168C5.23251 5.34736 5.08325 5.70769 5.08325 6.08342C5.08325 6.45914 5.23251 6.81947 5.49818 7.08515C5.76386 7.35083 6.1242 7.50008 6.49992 7.50008Z" className={fillClassName} />
    </svg>
  );
};

export default MapPinRangeFill;
