import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';
import { SHIFT_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';

const shift = {
  name: 'shift',
  logo: '',
  auth: {
    magicLinks: true,
  },
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
    }
    if (numStreams === 1) {
      return IStreamsDisplayMode.CENTER_STAGE;
    } else if (numStreams > 1 && numStreams <= 6) {
      return IStreamsDisplayMode.GRID;
    } else {
      return IStreamsDisplayMode.TWO_ROW_SPOTLIGHT;
    }
  },

  boothConfig: {

    'f4fc6575-5abc-4187-97e1-377f4827d409': SHIFT_PHOTOBOOTH,
    // local
    '928548f3-6859-4807-887b-7e9aa9ec11ad': SHIFT_PHOTOBOOTH,
  },
};

export default shift;