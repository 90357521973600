import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const avigonConfig = {
  name: 'avigonConfig',
  // Lobby
  lobbyBG: '',
  lobbyConfig: ManualLayoutConfig.DEMO_LOBBY,
};

export default avigonConfig;
// https://stream.mux.com/r2eY5HDf4VL1Tmt7tAPinkW4beN601JC02O5c2vt8rUg00.m3u8
