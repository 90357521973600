import React from 'react';
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const MessageFillAdd = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size = 16 } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      className={fillClassName}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3335 1.83325H2.66683C1.9315 1.83325 1.3335 2.43125 1.3335 3.16659V15.1666L4.00016 12.4999H13.3335C14.0688 12.4999 14.6668 11.9019 14.6668 11.1666V3.16659C14.6668 2.43125 14.0688 1.83325 13.3335 1.83325ZM11.3335 7.83325H8.66683V10.4999H7.3335V7.83325H4.66683V6.49992H7.3335V3.83325H8.66683V6.49992H11.3335V7.83325Z"
        className={fillClassName}
        fill={color}
      />
    </svg>
  );
};

export default MessageFillAdd;
