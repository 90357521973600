import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';

const SMLC2021 = {
  name: 'SMLC2021',
  auth: {
    magicLinks: true,
  },
  zoneNames: {
    lobby: 'Home',
    schedule: 'Agenda',
    stages: 'Presentations',
    expo: 'Donate',
  },

  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      if (numStreams <= 3) {
        return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
      } else {
        return IStreamsDisplayMode.SPOTLIGHT;
      }
    }

    if (numStreams <= 3) {
      return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
    } else {
      return IStreamsDisplayMode.CONDENSED;
    }
  },

};

export default SMLC2021;
