import React from 'react';
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Gif = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 0.666626L15.5 4.83329V16.4941C15.5 16.9575 15.1292 17.3333 14.6725 17.3333H1.3275C0.870833 17.3333 0.5 16.9541 0.5 16.5066V1.49329C0.5 1.03663 0.8725 0.666626 1.3325 0.666626H11.3333ZM10.5 2.33329H2.16667V15.6666H13.8333V5.66663H10.5V2.33329ZM8.83333 7.33329V11.5H8V7.33329H8.83333ZM7.16667 7.33329V8.16663H5.5C5.04 8.16663 4.66667 8.53996 4.66667 8.99996V9.83329C4.66667 10.2933 5.04 10.6666 5.5 10.6666H6.33333V9.83329H5.5V8.99996H7.16667V10.6666C7.16667 11.1266 6.79333 11.5 6.33333 11.5H5.5C4.57917 11.5 3.83333 10.7541 3.83333 9.83329V8.99996C3.83333 8.07913 4.57917 7.33329 5.5 7.33329H7.16667ZM12.1667 7.33329V8.16663H10.5V8.99996H12.1667V9.83329H10.5V11.5H9.66667V7.33329H12.1667Z"
        fill="white"
        className={fillClassName}
      />
    </svg>
  );
};

export default Gif;
