import React from 'react';

const KeynoteIcon = ({ size = 20, onClick, color = '#ffffff' }) => (
  <svg
    style={{ marginRight: '8px' }}
    onClick={onClick}
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.44008 8H17.5601L16.3601 2H3.64008L2.44008 8ZM11.0001 10V18H15.0001V20H5.00008V18H9.00008V10H0.992083C0.444083 10 0.0860828 9.57 0.195083 9.023L1.80508 0.977C1.91308 0.437 2.44508 0 3.00008 0H16.9981C17.5511 0 18.0851 0.43 18.1941 0.977L19.8041 9.023C19.9121 9.563 19.5441 10 19.0071 10H11.0001Z"
      fill={color}
    />
  </svg>
);

export default KeynoteIcon;
