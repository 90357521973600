import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const Veeco4config = {
  name: 'Veeco4config',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.Veeco4_Expo,


};

export default Veeco4config;
