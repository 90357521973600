import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const VideoRecording = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 13 10" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M8.66669 0.666687C8.96569 0.666687 9.20835 0.909354 9.20835 1.20835V3.48335L12.0321 1.50627C12.1545 1.42069 12.3235 1.45048 12.4096 1.57344C12.441 1.61894 12.4584 1.6731 12.4584 1.72835V8.27169C12.4584 8.42119 12.337 8.54252 12.1875 8.54252C12.1317 8.54252 12.0776 8.52519 12.0321 8.49377L9.20835 6.51669V8.79169C9.20835 9.09069 8.96569 9.33335 8.66669 9.33335H1.08335C0.784354 9.33335 0.541687 9.09069 0.541687 8.79169V1.20835C0.541687 0.909354 0.784354 0.666687 1.08335 0.666687H8.66669ZM8.12502 1.75002H1.62502V8.25002H8.12502V1.75002ZM4.87502 2.83335L7.04169 5.00002H5.41669V7.16669H4.33335V5.00002H2.70835L4.87502 2.83335ZM11.375 3.28889L9.20835 4.80556V5.19448L11.375 6.71114V3.28835V3.28889Z" className={fillClassName} />
    </svg>
  );
};

export default VideoRecording;
