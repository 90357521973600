import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const VideoRecordingAdd = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 11 8" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 0C7.776 0 8 0.224 8 0.5V2.6L10.6065 0.775C10.7195 0.696 10.8755 0.7235 10.955 0.837C10.984 0.879 11 0.929 11 0.98V7.02C11 7.158 10.888 7.27 10.75 7.27C10.6985 7.27 10.6485 7.254 10.6065 7.225L8 5.4V7.5C8 7.776 7.776 8 7.5 8H0.5C0.224 8 0 7.776 0 7.5V0.5C0 0.224 0.224 0 0.5 0H7.5ZM7 1H1V7H7V1ZM3.5 2H4.5V3.5H6V4.5H4.4995L4.5 6H3.5L3.4995 4.5H2V3.5H3.5V2ZM10 2.4205L8 3.8205V4.1795L10 5.5795V2.42V2.4205Z" className={fillClassName} />
    </svg>
  );
};

export default VideoRecordingAdd;
