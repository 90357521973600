import React from 'react';
import classnames from 'classnames';
// components
import NewLabel, {
  INewLabelColors,
  INewLabelMargins,
  INewLabelSizes,
} from '../../content/NewLabel';
// styles + types
import styles from './styles.module.scss';
import { IToggleFieldProps } from './types';
import { keyboardAccessible } from '@/utils/keyboard accessible';

const ToggleField = (props: IToggleFieldProps) => {
  const {
    enabled = false,
    label,
    onToggle,
    className,
    readOnly = false,
    dataTestId = "",
    tabIndex
  } = props;

  const togglerClassname = classnames(
    styles.toggleFieldButton,
    className,
    {
      [styles.toggleFieldButtonOn]: enabled,
    },
    {
      [styles.toggleFieldButtonOff]: !enabled,
    },
  );

  const Toggle = (
    <div
      className={classnames(styles.toggleFieldSwitch,{
        'elementAsButton': tabIndex !== undefined && tabIndex >= 0
      })}
      onClick={() => !readOnly && onToggle(!enabled)}
      aria-hidden={true}
      data-testid={dataTestId}
      tabIndex={tabIndex}
      {...(
        tabIndex !== undefined && tabIndex >= 0 ? {
          onKeyDown: (e: any) => keyboardAccessible(e, () => !readOnly && onToggle(!enabled))
        } :{}
      )}
    >
      <div className={togglerClassname} data-testid={`${dataTestId}-class`}></div>
    </div>
  );

  if (label) {
    return (
      <div className={styles.toggleWithLabel}>
        <NewLabel
          labelColor={INewLabelColors.NEUTRAL}
          labelMargin={INewLabelMargins.SMALL_MARGIN_RIGHT}
          labelSize={INewLabelSizes.MEDIUM}
          text={label}
        />
        {Toggle}
      </div>
    );
  }
  return Toggle;
};

export default ToggleField;
