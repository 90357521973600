import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const TicketIcon = (props: IIconProps) => {
  const { color = IContentColors.GRAY_BLUE, size = 20 } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4998 2.5C17.7209 2.5 17.9328 2.5878 18.0891 2.74408C18.2454 2.90036 18.3332 3.11232 18.3332 3.33333V7.91667C17.7806 7.91667 17.2507 8.13616 16.86 8.52686C16.4693 8.91756 16.2498 9.44747 16.2498 10C16.2498 10.5525 16.4693 11.0824 16.86 11.4731C17.2507 11.8638 17.7806 12.0833 18.3332 12.0833V16.6667C18.3332 16.8877 18.2454 17.0996 18.0891 17.2559C17.9328 17.4122 17.7209 17.5 17.4998 17.5H2.49984C2.27882 17.5 2.06686 17.4122 1.91058 17.2559C1.7543 17.0996 1.6665 16.8877 1.6665 16.6667V12.0833C2.21904 12.0833 2.74894 11.8638 3.13964 11.4731C3.53034 11.0824 3.74984 10.5525 3.74984 10C3.74984 9.44747 3.53034 8.91756 3.13964 8.52686C2.74894 8.13616 2.21904 7.91667 1.6665 7.91667V3.33333C1.6665 3.11232 1.7543 2.90036 1.91058 2.74408C2.06686 2.5878 2.27882 2.5 2.49984 2.5H17.4998ZM16.6665 4.16667H3.33317V6.64L3.46317 6.7075C4.02844 7.01587 4.50478 7.4646 4.84631 8.01047C5.18784 8.55634 5.38306 9.18096 5.41317 9.82417L5.4165 10C5.41661 10.6735 5.23527 11.3347 4.89155 11.9139C4.54782 12.4931 4.05441 12.969 3.46317 13.2917L3.33317 13.36V15.8333H16.6665V13.3592L16.5365 13.2925C15.9712 12.9841 15.4949 12.5354 15.1534 11.9895C14.8118 11.4437 14.6166 10.819 14.5865 10.1758L14.5832 10C14.5832 8.58 15.3723 7.34417 16.5365 6.70833L16.6665 6.63917V4.16667ZM13.3332 7.5V12.5H6.6665V7.5H13.3332Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default TicketIcon;
