import React, {useState, useRef,useEffect } from 'react';
import classnames from 'classnames';
// components
import Label from '@/components/ui/content/Label';
// styles + types
import styles from './styles.module.scss';
import { ITextAreaFieldProps } from './types';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';

const TextAreaField = (props: ITextAreaFieldProps) => {
  const {
    error,
    isLastElement,
    label,
    onChange,
    onBlur,
    onClick,
    placeholder,
    readOnly,
    styleClass = '',
    submitFailed,
    value,
    widthStyles = '',
    maxLength,
    validate,
    tooltip,
    dataTestId
  } = props;

  const [showMaxLengthExceededError, setShowMaxLengthExceededError] = useState(false);
  const fieldRef = useRef();
  useEffect(() => {
    if (!fieldRef.current) return;
    // To reflect data changes into the field text value.
    if (value === undefined){
      fieldRef.current.value = '';
      return
    }
    if (fieldRef.current.value !== value) {
      fieldRef.current.value = value;
    }
  }, [value])
  

  const validationError = submitFailed && error;
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      return false;
    }
    return true;
  };

  // set up necessary style classes
  const fullContainerWidth = `${styles[widthStyles]}`;
  const fullContainerPositioning = `${styles.container}`;
  const marginRightClass = `${styles.addMarginRight}`;
  const containerWithValidationMessage = classnames(
    fullContainerWidth,
    fullContainerPositioning,
    { [marginRightClass]: !isLastElement }
  );

  const validationErrorBorder = `${styles.validationError}`;
  const textAreaBaseClass = classnames(
    `${styles.textAreaField}`,
    { [validationErrorBorder]: validationError },
  );
  const styleClassExists = !!styleClass;
  const textAreaClass = classnames(
    { [textAreaBaseClass]: !styleClassExists },
    { [`${styles[styleClass]}`]: styleClassExists }
  );

  const handleChangeInput = (event) => {
    const { value } = event.target;
    setShowMaxLengthExceededError(maxLength !== undefined && maxLength === value.length);
    onChange && onChange(event);
  }

  // showing validation error works without inner div around textarea
  // because label is positioned absolutely
  return (
    <div className={containerWithValidationMessage}>
       <div className={styles.titleLabelContainer}>
        {
          label && label.length ? (
            <Label
              value={
                validate
                  ? `${label}*`
                  : label
              }
              styleClass="fieldLabelTitleText"
            />
          ) : null
        }
         {
          maxLength &&
          <Label value={`(Max ${maxLength} characters)`} styleClass="fieldLabelTitleTextLength" />
        }
        {
          label && label.length && tooltip ? (
            <TooltipDiv dataFor={`tooltip_text_area_${label}`} tooltipText={tooltip}/>
          ) : null
        }
      </div>
      <textarea
        ref={fieldRef}
        className={textAreaClass}
        defaultValue={value}
        disabled={readOnly}
        onChange={handleChangeInput}
        onBlur={onBlur}
        onClick={onClick}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        tabIndex={0}
        maxLength={maxLength}
        data-testid={dataTestId}
      />
      {
        (maxLength && !!value) &&
          <div className={styles.textLimit}>{value.length}/{maxLength} characters</div>
      }
      <br/>
      {validationError && <FormValidationErrorTooltip value={error} />}
      {/* {showMaxLengthExceededError && <Label value={'Max Character Limit Reached!'} styleClass={"maxLimitExceededText"} />} */}
    </div>
  );
};

export default React.memo(TextAreaField);
