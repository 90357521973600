import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';
import { IEventCustomConfigType } from '@/custom-config/types';

const doveRecordingScenario: IEventCustomConfigType = {
  name: 'doveRecordingScenario',
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      if (numStreams === 2) {
        return IStreamsDisplayMode.CONDENSED;
      }
      return IStreamsDisplayMode.SPOTLIGHT;
    }
    return IStreamsDisplayMode.CONDENSED;
  },
};

export default doveRecordingScenario;
