import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const VeecoLL2config = {
  name: 'VeecoLL2config',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.VeecoLL2_Expo,


};

export default VeecoLL2config;

