import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Feed = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 11 12" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M1.37501 0.375H15.625C15.835 0.375 16.0363 0.458407 16.1848 0.606874C16.3333 0.75534 16.4167 0.956704 16.4167 1.16667V13.8333C16.4167 14.0433 16.3333 14.2447 16.1848 14.3931C16.0363 14.5416 15.835 14.625 15.625 14.625H1.37501C1.16505 14.625 0.963684 14.5416 0.815217 14.3931C0.666751 14.2447 0.583344 14.0433 0.583344 13.8333V1.16667C0.583344 0.956704 0.666751 0.75534 0.815217 0.606874C0.963684 0.458407 1.16505 0.375 1.37501 0.375ZM6.12501 7.5C6.12501 8.12989 6.37523 8.73398 6.82063 9.17938C7.26603 9.62478 7.87012 9.875 8.50001 9.875C9.1299 9.875 9.73399 9.62478 10.1794 9.17938C10.6248 8.73398 10.875 8.12989 10.875 7.5H14.8333V1.95833H2.16668V7.5H6.12501Z" className={fillClassName} />
    </svg>
  );
};

export default Feed;
