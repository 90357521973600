import { IEventCustomConfigType } from '@/custom-config/types';

const salespreConfig: IEventCustomConfigType = {
  name: 'salespre',
  zoneNames: {
    lobby: 'Reception',
    expo: 'Schools',
    stages: 'Auditorium',
  },
};
export default salespreConfig;
