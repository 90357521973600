import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const indiaMobileCongress = {
  name: 'IndiaMobileCongress',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.INDIAMOBILECONGRESS_EXPO_OUTER,

};

export default indiaMobileCongress;
