import { IStreamsDisplayMode } from "@/components/PublishedStreamDisplay/types";
import { IEventCustomConfigType } from "./types";

const IndoPacificBusinessFormETP: IEventCustomConfigType = {
  name: 'SMLC2021',
  auth: {
    magicLinks: true,
  },
  zoneNames: {
    schedule: 'Agenda',
  },
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      if (numStreams <= 3) {
        return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
      } else {
        return IStreamsDisplayMode.SPOTLIGHT;
      }
    }

    if (numStreams <= 3) {
      return IStreamsDisplayMode.BOTTOM_SPONSOR_LAYOUT;
    } else {
      return IStreamsDisplayMode.CONDENSED;
    }
  },
};

export default IndoPacificBusinessFormETP;