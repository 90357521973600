import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const WifiPoor = (props: IIconProps) => {
  const { color = IContentColors.DANGER, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99983 12C7.5423 11.9995 7.09853 12.1565 6.74316 12.4447C6.74316 12.4447 6.99987 14 7.99983 14C8.99987 14 9.2565 12.444 9.2565 12.444C8.91316 12.1667 8.47583 12 7.99983 12Z"
        className={fillClassName}
      />
      <path
        d="M7.99977 8.667C6.77976 8.66526 5.59633 9.08359 4.64844 9.85166L5.9051 11.407C6.49764 10.9271 7.23729 10.6658 7.99977 10.667C8.76225 10.6658 9.50191 10.9271 10.0944 11.407L11.3511 9.851C10.4351 9.11033 9.2691 8.667 7.99977 8.667Z"
        className={fillClassName}
      />
      <path
        d="M7.99971 5.33302C6.0172 5.33004 4.09411 6.00968 2.55371 7.25768L3.81038 8.81302C4.95571 7.88768 6.41304 7.33302 7.99971 7.33302C9.52469 7.33054 11.004 7.85316 12.189 8.81302L13.4457 7.25702C11.957 6.05368 10.0624 5.33302 7.99971 5.33302Z"
        fill="#808080"
      />
      <path
        d="M0.459961 4.66468C2.59269 2.93689 5.25519 1.99595 7.99996 2.00001C10.8566 2.00001 13.48 2.99801 15.54 4.66468L14.2833 6.22001C12.506 4.78037 10.2872 3.99645 7.99996 4.00001C5.61996 4.00001 3.43329 4.83201 1.71663 6.22001L0.459961 4.66468Z"
        fill="#808080"
      />
    </svg>
  );
};

export default WifiPoor;
