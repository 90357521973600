import { useState, useEffect } from 'react';
import api from '@/api';
import { useMemoizedSelector } from "./use-memoized-selector";
import { makeSelectEventUserRole } from "@/models/event";
import { IEventRoleOptions } from '@/models/event/types';
import { useUserAccessGroups } from './use-user-access-groups';
import { EFeatures, ESubFeatures } from '@/config/accessGroupsConfig';
import { IAccountRoleTypeOptions } from '@/models/account/types';

const useComputeBackstageAccess = (eventId, stageId, eventRole) => {
  const [hasBackstageAccess, setHasBackstageAccess] = useState(false);
  const currentUserRole = useMemoizedSelector(makeSelectEventUserRole, eventId);
  const [showJoinBackstageView, setShowJoinBackstageView] = useState(false);
  const { hasSubFeatureAccess } = useUserAccessGroups();
  useEffect(() => {
    if (!eventId || !stageId || !currentUserRole) {
      return;
    }

    if (
      currentUserRole === IEventRoleOptions.ORGANIZER ||
      currentUserRole === IEventRoleOptions.MODERATOR
    ) {
      setHasBackstageAccess(true);
      return;
    }

    if (
      currentUserRole === IEventRoleOptions.ATTENDEE ||
      currentUserRole === IEventRoleOptions.SPONSOR
    ) {
      setHasBackstageAccess(false);
      return;
    }

    api.event
      .hasBackStageAccess(eventId, stageId)
      .then(({ data }) => {
        setHasBackstageAccess(data);
      })
      .catch(err => {
        console.error(
          `Failed to compute back stage access for user with err ${err}`,
        );
        setHasBackstageAccess(false);
      });
  }, [eventId, stageId, currentUserRole]);

  // hasBackstageAccess using for checking user tagged to any session in stage or not only.
  // below conditions for check allow all stages and allow tagged stages only in user access group
  useEffect(() => {
    if (!eventRole || eventRole?.role === IAccountRoleTypeOptions.ATTENDEE) {
      setShowJoinBackstageView(false);
      return;
    }
    const hasAllStageAccess = hasSubFeatureAccess(
      EFeatures.BACKSTAGE,
      ESubFeatures.ALLOW_ALL_STAGES,
    );
    if (hasAllStageAccess) {
      setShowJoinBackstageView(true);
      return;
    }
    const hasTaggedStageAccess = hasSubFeatureAccess(
      EFeatures.BACKSTAGE,
      ESubFeatures.ALLOW_TAGGED_STAGES_ONLY,
    );
    if (hasBackstageAccess && hasTaggedStageAccess) {
      setShowJoinBackstageView(true);
    }
  }, [hasBackstageAccess, eventRole]);

  return showJoinBackstageView;
};

export default useComputeBackstageAccess;
