import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const MultilingualIcon = (props: IIconProps) => {
  const { color = IContentColors.NEUTRAL_BASE_2, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={fillClassName}
    >
      <path
        d="M5 15V17C4.99984 17.5046 5.19041 17.9906 5.5335 18.3605C5.87659 18.7305 6.34684 18.9572 6.85 18.995L7 19H10V21H7C5.93913 21 4.92172 20.5786 4.17157 19.8284C3.42143 19.0783 3 18.0609 3 17V15H5ZM18 10L22.4 21H20.245L19.044 18H14.954L13.755 21H11.601L16 10H18ZM17 12.885L15.753 16H18.245L17 12.885ZM8 2V4H12V11H8V14H6V11H2V4H6V2H8ZM17 3C18.0609 3 19.0783 3.42143 19.8284 4.17157C20.5786 4.92172 21 5.93913 21 7V9H19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H14V3H17ZM6 6H4V9H6V6ZM10 6H8V9H10V6Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default MultilingualIcon;
