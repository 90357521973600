import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const artsgrowscSB = {
  name: 'artsgrowscSB',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.ARTSGROWSCSB_EXPO,


};

export default artsgrowscSB;
