import { IEventCustomConfigType } from '@/custom-config/types';

const adot2021Event: IEventCustomConfigType = {
    name: 'adot2021Event',
    zoneNames: {
      discussions: 'Meeting Rooms',
      expo:'Exhibit Hall',
      stages: 'Ballroom',
    },
}
export default adot2021Event;