import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const AddIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.94643 4.16085H7.33928V11.1251H5.94643V4.16085ZM8.73214 6.94657H10.125V11.1251H8.73214V6.94657ZM3.16071 8.33943H4.55357V11.1251H3.16071V8.33943ZM8.73214 2.07157H1.76786V13.2144H11.5179V4.85728H8.73214V2.07157ZM0.375 1.36957C0.375 0.987925 0.686304 0.678711 1.07073 0.678711H9.42857L12.9107 4.16085V13.906C12.9114 13.9974 12.894 14.0881 12.8596 14.1729C12.8252 14.2576 12.7744 14.3347 12.7102 14.3999C12.646 14.465 12.5696 14.5168 12.4853 14.5524C12.4011 14.588 12.3106 14.6066 12.2192 14.6073H1.06655C0.883656 14.606 0.708601 14.5328 0.579205 14.4036C0.449809 14.2743 0.376459 14.0993 0.375 13.9164V1.36957Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default AddIcon;
