import React from 'react';
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const MessageFillRemove = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size = 16 } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      className={fillClassName}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3335 1.83325H2.66683C1.9315 1.83325 1.3335 2.43125 1.3335 3.16659V15.1666L4.00016 12.4999H13.3335C14.0688 12.4999 14.6668 11.9019 14.6668 11.1666V3.16659C14.6668 2.43125 14.0688 1.83325 13.3335 1.83325ZM11.1375 9.52859L10.1948 10.4713L7.9995 8.27659L5.80483 10.4713L4.86216 9.52859L7.05683 7.33392L4.86216 5.13859L5.80483 4.19592L7.9995 6.39059L10.1948 4.19592L11.1375 5.13859L8.94283 7.33392L11.1375 9.52859Z"
        className={fillClassName}
        fill={color}
      />
    </svg>
  );
};

export default MessageFillRemove;
