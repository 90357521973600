import { IStreamsDisplayMode } from '@/components/PublishedStreamDisplay/types';
import { IStageLayoutTemplateType } from '@/custom-config/types';

const template2: IStageLayoutTemplateType = {
  getDisplayMode: (numStreams, hasScreenshareStream) => {
    if (hasScreenshareStream) {
      if (numStreams === 2) {
        return IStreamsDisplayMode.CONDENSED;
      }
      return IStreamsDisplayMode.SPOTLIGHT;
    }
    return IStreamsDisplayMode.CONDENSED;
  },
};
export default template2;
