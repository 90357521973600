/* eslint-disable quotes */
import { ModalContentType } from './types';

export const getDevicesFailedContent = error => {
  if (error && !error.message) {
    return {
      state: ModalContentType.DENIED_PERMISSIONS,
      info: 'We could not connect to your camera/microphone',
      hints: [
        'Close any other applications that may be using your camera/mic (like Webex, Skype, Teams, Zoom, etc.)',
        'Check browser settings for device permissions: tap the icon next to the site address',
        'Refresh your browser',
        // Keep in the end
        "If this didn't work, contact us with the code: UnknownError",
      ],
    };
  }
  if (error.message.includes('AbortError')) {
    return {
      state: ModalContentType.DENIED_PERMISSIONS,
      info: 'We could not connect to your camera/microphone',
      hints: [
        'Close any other applications that may be using your camera/mic (like Webex, Skype, Teams, Zoom, etc.)',
        'Refresh your browser',
        // Keep in the end
        "If this didn't work, contact us with the code: AbortError",
      ],
    };
  }
  if (error.message.includes('NotAllowedError')) {
    return {
      state: ModalContentType.DENIED_PERMISSIONS,
      info: 'We could not connect to your camera/microphone',
      hints: [
        'Close any other applications that may be using your camera/mic (like Webex, Skype, Teams, Zoom, etc.)',
        'Check browser settings for device permissions: tap the icon next to the site address',
        'Refresh your browser',
        "If this didn't work, contact us with the code: NotAllowedError",
      ],
    };
  }
  if (error.message.includes('NotFoundError')) {
    return {
      state: ModalContentType.ERROR,
      info: 'We could not connect to your camera/microphone',
      hints: [
        'Close any other applications that may be using your camera/mic (like Webex, Skype, Teams, Zoom, etc.)',
        'Refresh your browser',
        "If this didn't work, contact us with the code: NotFoundError",
      ],
    };
  }
  if (error.message.includes('NotReadableError')) {
    return {
      state: ModalContentType.DENIED_PERMISSIONS,
      info: 'We could not connect to your camera/microphone',
      hints: [
        'Close any other applications that may be using your camera/mic (like Webex, Skype, Teams, Zoom, etc.)',
        'Refresh your browser',
        'If you still face issues, restart your PC if possible',
        "If this didn't work, contact us with the code: NotReadableError",
      ],
    };
  }
  if (error.message.includes('OverconstrainedError')) {
    return {
      state: ModalContentType.DENIED_PERMISSIONS,
      info: 'We could not connect to your camera/microphone',
      hints: [
        'Close any other applications that may be using your camera/mic (like Webex, Skype, Teams, Zoom, etc.)',
        'Try using the latest version of Chrome or Edge browser',
        'Refresh your browser',
        "If this didn't work, contact us with the code: OverconstrainedError",
      ],
    };
  }
  if (error.message.includes('SecurityError')) {
    return {
      state: ModalContentType.DENIED_PERMISSIONS,
      info:
        'Camera/microphone access may be blocked by your organization or network',
      hints: [
        'Try using the latest version of Chrome or Edge browser',
        'Contact your administrator',
        "If this didn't work, contact us with the code: SecurityError",
      ],
    };
  }
  if (error.message.includes('TypeError')) {
    return {
      state: ModalContentType.DENIED_PERMISSIONS,
      info: 'We could not connect to your camera/microphone',
      hints: [
        'Close any other applications that may be using your camera/mic (like Webex, Skype, Teams, Zoom, etc.)',
        'Check browser settings for device permissions: tap the lock icon next to the site address',
        'Refresh your browser',
        "If this didn't work, contact us with the code: TypeError",
      ],
    };
  }

  return {
    state: ModalContentType.DENIED_PERMISSIONS,
    info: 'We could not connect to your camera/microphone',
    hints: [
      'Close any other applications that may be using your camera/mic (like Webex, Skype, Teams, Zoom, etc.)',
      'Check if your built-in/external camera or microphone is enabled in your device',
      'Check browser settings for permissions: tap the icon next to the site address',
      'Refresh your browser',
      "If this didn't work, contact us with the code: UnknownError",
    ],
  };
};
