import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
// api + models
import videoAPI from '@/api/video';
// components
import MediaVideo from '@/components/ui/media/Video';
import Label from '@/components/ui/content/Label';
import Loader from '@/components/ui/Loader';
// styles + types + utils + configs
import styles from './styles.module.scss';
import { IVideoUploadFieldProps } from './types';
import { fileWithURL } from '@/utils/helpers';
import * as UpChunk from '@mux/upchunk';
import { IVideoStatusType } from '@/types/video';
import { getVideoMuxUrl } from '@/utils/helpers';
import { IContentColors } from '@/types';
import BinIcon from '@/components/ui/new-icons/Bin';
import DeleteConfirmPopup from '@/components/DeleteConfirmPopup';

const VideoUploadField = (props: IVideoUploadFieldProps) => {
  let { value, resizeProps } = props;
  const { error, label, onChange, submitFailed, readOnly, isLastElement, deleteModalTitle, dataTestId } = props;
  let { fileType = "mp4", maxFileSize = 6000, minWidth, maxWidth, minHeight, maxHeight } = props;
  const [uploadedVideoFileName, setUploadedVideoFileName] = useState(value || '');
  const [videoUploading, setVideoUploading] = useState(false);
  const [videoReadyToDisplay, setVideoReadyToDisplay] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [videoStatus, setVideoStatus] = useState('');
  const [upChunkStatus, setUpChunkStatus] = useState('');
  const [loaderLabelText, setLoaderLabelText] = useState('');
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const validationError = submitFailed && error;

  if (value === null) {
    value = '';
  }

  useEffect(() => {
    setUploadedVideoFileName(value);
  }, [value]);


  const handleVideoLoad = () => {
    setVideoReadyToDisplay(true);
    setVideoUploading(false);
  };

  const resetState = () => {
    setVideoReadyToDisplay(false);
    setVideoUploading(false);
    setUploadedVideoFileName('');
    onChange('');
    setVideoId('');
    setVideoStatus('');
    setUpChunkStatus('');
  }

  const getMuxUrl = async () => {
    var response = await videoAPI.getMuxUrl(eventId).catch(function (error) {
      return null;
    });
    if (!response) {
      return null;
    }
    return response.data;
  }

  useEffect(() => {
    const getVideoStatus = async () => {
      if (!videoId) {
        return false;
      }
      const { data } = await videoAPI.getVideoById(videoId);
      setVideoStatus(data.status);
      if (data.status === IVideoStatusType.READY) {
        setUploadedVideoFileName(getVideoMuxUrl(data.muxPlaybackId));
        onChange(getVideoMuxUrl(data.muxPlaybackId));
        setLoaderLabelText("");
        setVideoUploading(false);
        return true;
      }
      if (data.status === IVideoStatusType.ERRORED || data.status === IVideoStatusType.CANCELLED) {
        resetState();
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Intro Video Upload Failed' }
        });
        return true;
      }
      return false;
    }

    const asyncInterval = async (callback, ms) => {
      return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
          if (await callback()) {
            resolve();
            clearInterval(interval);
          }
        }, ms);
      });
    }

    const checkVideoStatus = async () => {
      try {
        await asyncInterval(getVideoStatus, 5 * 1000);
      } catch (e) {
        console.log('error handling');
      }
      console.log("Done!");
    }

    if (upChunkStatus === 'success' && videoId !== '' && videoStatus === IVideoStatusType.UPLOADING) {
      checkVideoStatus();
    }
  }, [videoId, upChunkStatus, videoStatus]);


  const handleFileChoice = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    var fileUpload = e.target;
    if (typeof (fileUpload.files) === "undefined") {
      dispatch({ type: 'global/addDangerToast', payload: { description: 'This browser does not support HTML5.' } });
      return;
    }
    let fileTypes = fileType.split(',');
    const chosenFile = fileUpload.files[0];
    const { name: chosenFileName } = chosenFile;
    const lastDotIndex = chosenFileName.lastIndexOf('.');
    const fileExtension = chosenFileName.substring(lastDotIndex + 1);

    if (!fileTypes.includes(fileExtension.toLocaleLowerCase().trim())) {
      dispatch({ type: 'global/addDangerToast', payload: { description: 'Please select a valid video file.' } });
      return;
    }

    if ((chosenFile.size / Math.pow(1024, 2)) > maxFileSize) {
      let fileSizeFormat = `${maxFileSize} MB`;
      if(maxFileSize >= 1000){
        fileSizeFormat = maxFileSize/1000 + ' GB';
      }
      dispatch({ type: 'global/addDangerToast', payload: { description: `Please select video file below ${fileSizeFormat}` } });
      return
    }

    var video = document.createElement('video');
    video.onloadedmetadata = async () => {
      let videoWidth = video.videoWidth;
      let videoHeight = video.videoHeight;

      if (minWidth && videoWidth < minWidth) {
        dispatch({ type: 'global/addDangerToast', payload: { description: `Video frame minimum width should be ${minWidth} pixels` } });
        return;
      }
      if (minHeight && videoHeight < minHeight) {
        dispatch({ type: 'global/addDangerToast', payload: { description: `Video frame minimum height should be ${minHeight} pixels` } });
        return;
      }
      if (maxWidth && videoWidth > maxWidth) {
        dispatch({ type: 'global/addDangerToast', payload: { description: `Video frame maximum width should be ${maxWidth} pixels` } });
        return;
      }
      if (maxHeight && videoHeight > maxHeight) {
        dispatch({ type: 'global/addDangerToast', payload: { description: `Video frame maximum height should be ${maxHeight} pixels` } });
        return;
      }

      setVideoUploading(true);
      setLoaderLabelText("0%");

      const uploadResponse = await getMuxUrl();
      if (!uploadResponse) {
        setVideoUploading(false);
        setLoaderLabelText("");
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Video Upload Failed' }
        });
        return;
      }

      setVideoId(uploadResponse.video_id);
      setVideoStatus(IVideoStatusType.UPLOADING);
      setUpChunkStatus('uploading');

      const upload = UpChunk.createUpload({
        endpoint: uploadResponse.mux_upload_url,
        file: chosenFile,
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });

      // subscribe to events
      upload.on('error', err => {
        console.error('💥 🙀', err.detail);
        resetState();
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Video Upload Failed' }
        });
      });

      upload.on('progress', progress => {
        console.log(`So far we've uploaded ${progress.detail}% of this file.`);
        setLoaderLabelText(parseInt(progress.detail) < 100 ? `${parseInt(progress.detail)}%` : '100%');
        setUpChunkStatus(parseInt(progress.detail) < 100 ? 'progress' : 'success');
      });

      upload.on('success', () => {
        console.log("Wrap it up, we're done here. 👋");
        setLoaderLabelText("100%");
        setUpChunkStatus('success');
      });
    };
    video.onerror = () => {
      dispatch({ type: 'global/addDangerToast', payload: { description: 'Please select a valid video file.' } });
      resetState();
      return;
    };
    video.src = URL.createObjectURL(chosenFile);
  }

  const uploadedVideoFilenameExists = uploadedVideoFileName && uploadedVideoFileName.length > 0;

  const getVideoUploadButtonClass = () => {
    const baseClass = 'uploadContainer';
    if (videoUploading || !uploadedVideoFilenameExists) {
      return baseClass;
    } else {
      return `${baseClass}Hidden`;
    }
  }

  const CloudUploadImage = () => (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9999 10.586L16.2429 14.828L14.8279 16.243L12.9999 14.415V20H10.9999V14.413L9.17189 16.243L7.75689 14.828L11.9999 10.586ZM11.9999 2.2216e-08C13.7169 8.17698e-05 15.374 0.631114 16.6561 1.77312C17.9382 2.91512 18.756 4.48846 18.9539 6.194C20.1982 6.53332 21.2836 7.2991 22.0206 8.35753C22.7575 9.41595 23.099 10.6997 22.9855 11.9844C22.872 13.2691 22.3106 14.473 21.3995 15.3858C20.4883 16.2986 19.2854 16.8622 18.0009 16.978V14.964C18.4611 14.8983 18.9035 14.7416 19.3025 14.503C19.7014 14.2644 20.0488 13.9487 20.3245 13.5744C20.6001 13.2001 20.7984 12.7746 20.9078 12.3228C21.0172 11.8711 21.0355 11.402 20.9616 10.9431C20.8878 10.4841 20.7232 10.0445 20.4776 9.64985C20.232 9.25519 19.9102 8.91342 19.5311 8.64447C19.152 8.37552 18.7231 8.18479 18.2694 8.08341C17.8158 7.98203 17.3465 7.97203 16.8889 8.054C17.0455 7.32489 17.037 6.56997 16.8641 5.84455C16.6912 5.11913 16.3582 4.44158 15.8895 3.86153C15.4208 3.28147 14.8283 2.81361 14.1554 2.49219C13.4824 2.17078 12.7461 2.00397 12.0004 2.00397C11.2546 2.00397 10.5183 2.17078 9.84542 2.49219C9.1725 2.81361 8.57999 3.28147 8.1113 3.86153C7.64261 4.44158 7.3096 5.11913 7.13668 5.84455C6.96375 6.56997 6.95528 7.32489 7.11189 8.054C6.19955 7.88267 5.25651 8.08079 4.49024 8.60476C3.72396 9.12874 3.19722 9.93565 3.02589 10.848C2.85456 11.7603 3.05268 12.7034 3.57666 13.4697C4.10063 14.2359 4.90755 14.7627 5.81989 14.934L5.99989 14.964V16.978C4.71534 16.8623 3.5123 16.2989 2.60103 15.3862C1.68976 14.4735 1.12822 13.2696 1.01457 11.9848C0.900915 10.7001 1.24237 9.41628 1.97926 8.35777C2.71615 7.29926 3.8016 6.53339 5.04589 6.194C5.2436 4.48838 6.0613 2.91491 7.34347 1.77287C8.62565 0.63082 10.2829 -0.000136703 11.9999 2.2216e-08Z" fill="#2E425A" />
    </svg>
  )

  const ImagePulsIcon = () => (
    <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.5 16V20H29.5V22.6667H25.5V26.6667H22.8333V22.6667H18.8333V20H22.8333V16H25.5ZM25.5106 0C26.2413 0 26.8333 0.593333 26.8333 1.324V13.3333H24.1666V2.66667H2.83329V21.332L16.1666 8L20.1666 12V15.772L16.1666 11.772L6.60263 21.3333H16.1666V24H1.48929C1.13838 23.9996 0.801955 23.86 0.553945 23.6117C0.305935 23.3635 0.166626 23.0269 0.166626 22.676V1.324C0.169066 0.973837 0.30916 0.63869 0.556643 0.390957C0.804127 0.143224 1.13913 0.00279287 1.48929 0H25.5106ZM8.16663 5.33333C8.87387 5.33333 9.55215 5.61428 10.0522 6.11438C10.5523 6.61448 10.8333 7.29276 10.8333 8C10.8333 8.70724 10.5523 9.38552 10.0522 9.88562C9.55215 10.3857 8.87387 10.6667 8.16663 10.6667C7.45938 10.6667 6.78111 10.3857 6.28101 9.88562C5.78091 9.38552 5.49996 8.70724 5.49996 8C5.49996 7.29276 5.78091 6.61448 6.28101 6.11438C6.78111 5.61428 7.45938 5.33333 8.16663 5.33333Z" fill="#03053D" />
    </svg>
  )

  const videoUploadButton = (
    <div className={styles[`${getVideoUploadButtonClass()}`]} style={resizeProps && { height: resizeProps.height / 10 + 'vh' }}>
      <div>
        {videoUploading
          ? <Loader styleClass="videoUploadField" />
          : <ImagePulsIcon />
        }
        {
          videoUploading
            ? <label className={styles.uploadProgressText}>{`${loaderLabelText} `}</label>
            :
            <div className={styles.infoContainer}>
              <div className={styles.placeHolderContainer}>
                <label className={styles.labelText}>{`Drop your video here or`}</label>
                <label className={styles.labelBrowseText}>{`browse`}</label>
              </div>
              <label className={styles.labelHintText}>{`Supports: ${fileType.replaceAll(',', ', ')}; upto ${maxFileSize >= 1000 ? (maxFileSize/1000)+'Gb' : maxFileSize+'Mb'}`}</label>
            </div>
        }
      </div>
      <input
        className={`${styles.fileChoiceInputField}`}
        onChange={e => handleFileChoice(e)}
        type="file"
        accept="video/*"
        disabled={readOnly}
        data-testid={dataTestId}
      />
    </div>
  );

  const marginRightClass = `${styles.addMarginRight}`;
  const errorContainer = `${styles.errorContainer}`;
  const containerStyles = classnames(
    errorContainer,
    { [marginRightClass]: !isLastElement }
  );

  const handleVideoDelete = () => ({setShow}) => {
    setShowDeletePopup(false);
    setShow(false);
    resetState();
  };

  const handleDeleteCancel = () => ({setShow}) => {
    setShowDeletePopup(false);
    setShow(false);
  }

  return (
    <div className={containerStyles}>
      <div
        className={classnames(`${styles.videoUploadContainer}`, {
          [`${styles.validationError}`]: validationError,
        }, { [`${styles.videoUploadContainerLoader}`]: videoUploading }
        )}
        style={resizeProps && { background: 'var(--neutral-base-1)' }}
      >
        {label ? <Label value={label} styleClass="videoUploadField" /> : null}
        {videoUploadButton}
        {
          uploadedVideoFilenameExists &&
          <MediaVideo
            onLoad={handleVideoLoad}
            styleClass="videoUploadField"
            loop={true}
            url={fileWithURL(uploadedVideoFileName)}
            autoplay={false}
            playsinline={true}
            controls={true}
            showLoader={true}
            muted={false}
            deletable={true}
            resizeProps={resizeProps}
          />
        }
        {
          uploadedVideoFilenameExists &&
          <div onClick={e => { e.stopPropagation(); setShowDeletePopup(true); }} className={styles.videoDelete}>
            <BinIcon size={16} color={IContentColors.WHITE} />
            <Label className={styles.deleteText} value={'Delete'} />
          </div>
        }
        <DeleteConfirmPopup showPopup={showDeletePopup} handleDeleteCancel={handleDeleteCancel} handleDeleteConfirm={handleVideoDelete} title={deleteModalTitle} />
      </div>
      {validationError && (
        <Label value={error} styleClass="fieldValidationError" />
      )}
      {
        videoUploading &&
        <Label styleClass="fieldVideoWarningText" value={'(Please stay on this tab till video is uploaded. Moving out will cancel the upload)'} />
      }
    </div>
  );
}

export default VideoUploadField;
