import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const PlayCircle = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M5.99984 11.8332C2.77809 11.8332 0.166504 9.22159 0.166504 5.99984C0.166504 2.77809 2.77809 0.166504 5.99984 0.166504C9.22159 0.166504 11.8332 2.77809 11.8332 5.99984C11.8332 9.22159 9.22159 11.8332 5.99984 11.8332ZM5.196 3.90859C5.1609 3.88516 5.12009 3.8717 5.07794 3.86963C5.03579 3.86756 4.99386 3.87697 4.95663 3.89684C4.9194 3.91671 4.88825 3.94631 4.86651 3.98248C4.84477 4.01866 4.83325 4.06005 4.83317 4.10225V7.89742C4.83325 7.93962 4.84477 7.98102 4.86651 8.01719C4.88825 8.05336 4.9194 8.08296 4.95663 8.10284C4.99386 8.12271 5.03579 8.13211 5.07794 8.13004C5.12009 8.12797 5.1609 8.11451 5.196 8.09109L8.04209 6.19409C8.07409 6.17279 8.10034 6.14391 8.11849 6.11002C8.13665 6.07613 8.14615 6.03828 8.14615 5.99984C8.14615 5.96139 8.13665 5.92354 8.11849 5.88965C8.10034 5.85576 8.07409 5.82689 8.04209 5.80559L5.19542 3.90859H5.196Z" className={fillClassName} />
    </svg>
  );
};

export default PlayCircle;
