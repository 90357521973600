import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Edit = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 10 } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      className={fillClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.38963 3.66174L5.68263 2.95474L1.02563 7.61174V8.31874H1.73263L6.38963 3.66174ZM7.09663 2.95474L7.80363 2.24774L7.09663 1.54074L6.38963 2.24774L7.09663 2.95474ZM2.14663 9.31874H0.0256348V7.19724L6.74313 0.479737C6.8369 0.386001 6.96405 0.333344 7.09663 0.333344C7.22922 0.333344 7.35637 0.386001 7.45014 0.479737L8.86463 1.89424C8.95837 1.988 9.01103 2.11515 9.01103 2.24774C9.01103 2.38032 8.95837 2.50747 8.86463 2.60124L2.14713 9.31874H2.14663Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default Edit;
