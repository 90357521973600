import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const kpmgch2023 = {
  name: 'kpmgch2023',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.KPMGCH2023_Expo,
  boothConfig: {
    'ccd453b8-80e9-45b1-9a4e-19b54aba5871': ManualLayoutConfig.KPMGCH2023_PHOTOBOOTH,
 },


};

export default kpmgch2023;