import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const presalebpm2022copy = {
  name: 'presalebpm2022copy',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.PreSaleBPM2022Copy_Expo,
  boothConfig: {
    '37659aad-26c3-4e8f-8f8b-1b8a1999dd63': ManualLayoutConfig.BPM2022_PHOTOBOOTH,
 },


};

export default presalebpm2022copy;

