const nymburuCulturalEvent = {
    name: 'nymburuCulturalEvent',
    auth: {
      magicLinks: true,
    },
    zoneNames: {
      expo: 'Donate',
      discussions: 'Watch Party'
    },
  };
  
  export default nymburuCulturalEvent;
