import React from 'react';
import { SheetRef } from 'react-modal-sheet';
import { useMediaQuery } from 'react-responsive';
import Modal from '../Modal';
import styles from './styles.module.scss';
import Text, { ITextColors, ITextSizes, ITextStyles } from '../../content/Text';
import CloseLine from '../../new-icons/CloseLine';
import { IContentColors } from '../../ExpandableContent';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import BottomSheet from '../../BottomSheet';
import classnames from 'classnames';

const PusherErrorModal = ({ handleCloseModal }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const ref = React.useRef<SheetRef>();

  return (
    <>
      {!isMobile && (
        <Modal
          styleClass="streamPreviewPusherError"
          autoTrigger={true}
          disableClose={true}
          modalHeaderClass="header"
          noHeader={true}
          render={({ setShow }) => (
            <div className={styles.pusherContainer}>
              <div className={styles.header}>
                <Text
                  textColor={ITextColors.MONOCHROME_BASE}
                  text="We couldn’t connect to you"
                  textSize={ITextSizes.MEDIUM}
                  textStyle={ITextStyles.BOLDED}
                />
                <div
                  className={styles.close}
                  onClick={() => {
                    setShow(false);
                    handleCloseModal();
                  }}
                >
                  <CloseLine
                    color={IContentColors.NEUTRAL_CONTRAST_2}
                    size={10}
                  />
                </div>
              </div>
              <Text
                textColor={ITextColors.MONOCHROME_CONTRAST}
                text="Turn off any ad blocker or firewall and reload.
                Or try joining with a different browser."
                textSize={ITextSizes.XSMALL}
              />
              <div className={styles.reloadButton}>
                <Button
                  className={styles.reloadButtonStyles}
                  label="Reload"
                  onClick={() => location.reload()}
                  styleClass={IButtonTypes.PRIMARY_SQUARE_FULL}
                  textStyle={ITextStyles.BOLDED}
                  type="button"
                >
                  Reload
                </Button>
              </div>
            </div>
          )}
        />
      )}
      {isMobile && (
        <BottomSheet
          ref={ref}
          isOpen={true}
          initialSnap={0}
          snapPoints={[0.25, 0]}
          onBackdropTouch={handleCloseModal}
          id="pusherErrorModalBottomSheet"
          showCloseButton={false}
          showDragIndicator={false}
        >
          <div
            className={classnames(
              styles.pusherContainer,
              styles.pusherBottomContainer,
            )}
          >
            <div className={styles.header}>
              <Text
                textColor={ITextColors.WHITE}
                text="We couldn’t connect to you"
                textSize={ITextSizes.MEDIUM}
                textStyle={ITextStyles.BOLDED}
              />
              <div
                className={styles.close}
                onClick={handleCloseModal}
              >
                <CloseLine
                  color={IContentColors.NEUTRAL_CONTRAST_2}
                  size={10}
                />
              </div>
            </div>
            <Text
              textColor={ITextColors.MONOCHROME_CONTRAST}
              text="Turn off any ad blocker or firewall and reload.
              Or try joining with a different browser."
              textSize={ITextSizes.XSMALL}
            />
            <div className={styles.reloadButton}>
              <Button
                className={styles.reloadButtonStyles}
                label="Reload"
                onClick={() => location.reload()}
                styleClass={IButtonTypes.PRIMARY_SQUARE_FULL}
                textStyle={ITextStyles.BOLDED}
                type="button"
              >
                Refresh
              </Button>
            </div>
          </div>
        </BottomSheet>
      )}
    </>
  );
};

export default PusherErrorModal;
