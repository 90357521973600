import { IContentColors } from '@/types';
import React from 'react';
import styles from '../styles.module.scss';
import { IIconProps } from '../types';

const SmileEmoji = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM6 11C6 12.0609 6.42143 13.0783 7.17157 13.8284C7.92172 14.5786 8.93913 15 10 15C11.0609 15 12.0783 14.5786 12.8284 13.8284C13.5786 13.0783 14 12.0609 14 11H6ZM6 9C6.39782 9 6.77936 8.84196 7.06066 8.56066C7.34196 8.27936 7.5 7.89782 7.5 7.5C7.5 7.10218 7.34196 6.72064 7.06066 6.43934C6.77936 6.15804 6.39782 6 6 6C5.60218 6 5.22064 6.15804 4.93934 6.43934C4.65804 6.72064 4.5 7.10218 4.5 7.5C4.5 7.89782 4.65804 8.27936 4.93934 8.56066C5.22064 8.84196 5.60218 9 6 9ZM14 9C14.3978 9 14.7794 8.84196 15.0607 8.56066C15.342 8.27936 15.5 7.89782 15.5 7.5C15.5 7.10218 15.342 6.72064 15.0607 6.43934C14.7794 6.15804 14.3978 6 14 6C13.6022 6 13.2206 6.15804 12.9393 6.43934C12.658 6.72064 12.5 7.10218 12.5 7.5C12.5 7.89782 12.658 8.27936 12.9393 8.56066C13.2206 8.84196 13.6022 9 14 9Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default SmileEmoji;
