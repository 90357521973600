import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const SendIcon = (props: IIconProps) => {
  const { color = IContentColors.SILVER_CHALICE, size = 21 } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.946082 7.31501C0.424082 7.14101 0.419082 6.86001 0.956082 6.68101L20.0431 0.31901C20.5721 0.14301 20.8751 0.43901 20.7271 0.95701L15.2731 20.043C15.1231 20.572 14.8181 20.59 14.5941 20.088L11.0001 12L17.0001 4.00001L9.00008 10L0.946082 7.31501Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default SendIcon;
