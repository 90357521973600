import { useLocation, useParams } from 'react-router-dom';

export const useNavbarVisibility = () => {
  const { search } = useLocation();
  // this param coming from mobile apps for showing lobby and expo without navbar and side panel. If param not exists or not false means nav bar will be shows ion top.
  const showNavBarParam =
    new URLSearchParams(search).get('show_nav_bar') || 'true';
    
  const paramValue = showNavBarParam.toLowerCase().trim()
    
  const showNavbar = paramValue === 'true';
  const hideNavbar = paramValue === 'false';

  return { showNavbar, hideNavbar }
};
