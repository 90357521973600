import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const ShuffleIcon = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 15 } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 14"
      xmlns="http://www.w3.org/2000/svg"
      className={fillClassName}
    >
      <path
        d="M11 10.9219V9.66659L14.3333 11.6666L11 13.6666V12.2733C9.98097 12.1273 9.01662 11.7217 8.19968 11.0953C7.38275 10.4689 6.74073 9.64284 6.33534 8.69659L6.33334 8.69325L6.33134 8.69725C5.86873 9.77642 5.09966 10.6961 4.11941 11.3424C3.13916 11.9888 1.99082 12.3333 0.816677 12.3333H0.333344V10.9999H0.816677C1.73 10.9999 2.62323 10.7319 3.38569 10.2291C4.14814 9.72624 4.74629 9.01075 5.10601 8.17125L5.60801 6.99992L5.10601 5.82859C4.74629 4.98909 4.14814 4.2736 3.38569 3.77078C2.62323 3.26797 1.73 2.99994 0.816677 2.99992H0.333344V1.66659H0.816677C1.99089 1.66664 3.13928 2.01124 4.11954 2.65767C5.0998 3.30411 5.86883 4.22396 6.33134 5.30325L6.33334 5.30659L6.33534 5.30259C6.74082 4.35645 7.38288 3.53055 8.19981 2.90428C9.01673 2.27801 9.98104 1.87245 11 1.72659V0.333252L14.3333 2.33325L11 4.33325V3.07792C10.2446 3.21788 9.53544 3.54218 8.93546 4.02202C8.33548 4.50187 7.86325 5.12241 7.56068 5.82859L7.05868 6.99992L7.56068 8.17125C7.86325 8.87742 8.33548 9.49797 8.93546 9.97782C9.53544 10.4577 10.2446 10.782 11 10.9219Z"
        className={fillClassName}
      ></path>
    </svg>
  );
};

export default ShuffleIcon;
