import ngis from './ngis';
import pharmacoConfig from './pharmaco';
import aspenTechConfig from './aspenTechDemo';
import tikTok from './tikTok';
import saasInsider from './saasInsider';
import toyInsider from './toyInsider';
import qualcomm from './qualcomm';
import changeHealthcare from './changeHealthcare';
import bombaLive from './bombaLive';
import IndiaMobileCongress from './indiaMobileCongress';
import adobeAlchemy from './adobeAlchemy';
import umdDemo from './umdDemo';
import template1 from './baseTemplates/template1';
import template2 from './baseTemplates/template2';
import template3 from './baseTemplates/template3';
import template4 from './baseTemplates/template4';
import template5 from './baseTemplates/template5';
import template6 from './baseTemplates/template6';
import zuddlPhotoBooth from './zuddlPhotoBooth';
import aop from './aop';
import shift from './shift';
import dsci from './dsci';
import vmworld from './vmworld';
import kellogg from './kellogg';
import tigerAnalytics from './tigerAnalytics';
import llegoogle from './llegoogle';
import abpa from './abpa';
import aznet from './aznet';
import SMLC2021 from './SMLC2021';
import IndoPacificBusinessFormETP from './IndoPacificBusinessFormETP';
import umdETP from '@/custom-config/umdETP';
import nymburuCulturalEvent from './nymburuCulturalEvent';
import doveRecordingScenario from '@/custom-config/doveRecordingScenario';
import foodBuy from './foodBuy';
import acxiom from './acxiom';
import nacdgala from './nacdGala';
import pcma2021 from './pcma2021';
import plpsvrp from './plpsvrp';
import seri2021Event from './seri2021Event';
import dsciaissConfig from './dsciaiss';
import adot2021Event from './adot2021Event';
import uawVirtualMeetingConfig from './uawVirtualMeeting';
import razorpayFtxConfig from './razorpayFtx';
import indiaMobileCongressNew from './indiaMobileCongressNew';
import aiss from './aiss';
import sandbox1 from './sandbox1';
import salespreConfig from './salespre';
import acxiomss from './acxiomss';
import manipalJFConfig from './manipalJF';
import OTICONNEXT from './OTICONNEXT';
import Bayerconfig from './Bayerconfig';
import GoogleSBconfig from './GoogleSBconfig';
import RASBconfig from './RASBconfig';
import worldbankSB from './worldbankSB';
import Finsec2022 from './Finsec2022';
import SandboxMSconfig from './SandboxMSconfig';
import Veeco1config from './Veeco1config';
import Veeco2config from './Veeco2config';
import Veeco3config from './Veeco3config';
import Veeco4config from './Veeco4config';
import VeecoLL2config from './VeecoLL2config';
import SandboxMS2config from './SandboxMS2config';
import JuneteenthExpo from './JuneteenthExpo';
import tidalSB from './tidalSB';
import ipc22 from './ipc22';
import satairSB from './satairSB';
import artsgrowscSB from './artsgrowscSB';
import bpm2022 from './bpm2022';
import AlphaWaveSB from './AlphaWaveSB';
import presalebpm2022copy from './presalebpm2022copy';
import PresaleFinsec2022Copy from './PresaleFinsec2022Copy';
import serviceNow from './serviceNow';
import indiaMobileCongressNew2 from './indiaMobileCongressNew2';
import neomsb from './neomsb';
import reveneraExpo from './reveneraExpo';
import neomrenewed from './neomrenewed';
import kpmgch2023 from './kpmgch2023';

const configByEvent = {
  // app
  'dbbf6ec0-0005-4668-b478-71f974ffface': ngis,
  'cc9c2419-efe9-41a0-9522-983ea5adcddf': ngis,
  'fc51eef5-6aa6-4895-a1d4-9817760ed140': ngis,
  '16c7b24c-fb88-4f12-931b-874e192b8dbb': ngis,
  'b6fbec52-6df1-4c32-bb3d-bb2030b2b8a3': pcma2021,
  'c8a98321-0a41-49d8-9010-33a4cdf6ed2c': doveRecordingScenario,
  '5097020d-4805-4f6c-9cd5-efa08dfae56f': ngis,
  '319cf682-2ce2-428a-a71f-ab6ccc663798': ngis,
  'ac0e3387-34f8-4dec-8f3b-334bd2ec7298': aop,
  '259dc5c5-2b78-4e95-b127-a31ebfcb71ee': ngis,
  '59ef733f-63f7-4cd1-8d00-8714d785a627': ngis,
  '684bf931-2349-40e9-819e-cfe13c43c1d4': ngis,
  'd7f03dc6-62b4-4481-87ea-439af427ae74': ngis,
  '63ebecfe-15e0-45c1-9736-606f78d78f03': ngis,
  'f7b1e700-e90e-4588-83cd-fb382b74cd3a': ngis,
  'bee05b94-0d35-44b5-b1fd-3a4090adce9e': ngis,
  'dc26153e-f8a0-4f47-9a2a-69580f0bfa0b': ngis,
  '884761d6-a661-4e72-9d31-30ff689a46f9': ngis,
  '0cdae855-8eed-477a-b897-e3f7b333a666': ngis,
  'd6424ecb-3ffc-4ff8-9f8f-a30ea0d50142': ngis,
  'eac0f02a-1de3-43ac-898b-c4e6c6066031': ngis,
  '824d641e-94cf-47ea-8bb5-66dc7314abd7': ngis,
  '8f35264b-11b9-4fa9-9167-b239f8b54f5c': ngis,
  'e2726016-3d82-41ee-880e-ee9d169915ab': ngis,
  '8c3ff25e-2818-4982-bd66-b2cf2095093e': dsci,
  '35821cd9-daea-4ba3-89aa-4fdacb7c5d7c': aspenTechConfig,
  'e1b863df-5eaa-43fc-adb5-1d0a99719a03': ngis,
  '431a51b3-38b5-4838-a103-2deaa147843e': ngis,
  '1283546f-ec4c-45a7-a87e-3ebdc7c40083': tikTok,
  '4fdf1c5f-e79c-4895-a8ea-f2f76d0bb9fb': ngis,
  'ff6cb288-1623-4911-ba85-e0f3ec197032': saasInsider,
  '7b6b3c5b-0766-49e9-a0b4-a69ace723ce6': toyInsider,
  '40c17af3-a031-423f-9331-d0aa3ab4f819': qualcomm,
  '870572bf-5b39-4111-abf7-5269d5655d10': changeHealthcare,
  'e4b3cb43-6d48-470d-8b6b-c43abdb35e6a': ngis,
  'adbdb1b3-e792-427c-8585-dfe127e76223': bombaLive,
  'b0a45946-b2da-4f77-aa78-bcf6b92e45e4': IndiaMobileCongress,
  'dff9b16b-d9ae-47aa-ad68-468b6158d3f2': Bayerconfig,
  '85e00e4d-786c-40d2-8ae7-32fc3c7d535c': RASBconfig,
  '218f910f-bc34-4ac7-a259-e08d29b987b9': umdDemo,
  'c016fb78-e254-4365-8a91-9082734b6292': adobeAlchemy,
  '5cad28ba-f094-43be-815c-166e5fc5c2c7': ngis,
  'b35653a1-9f52-4c6c-a9c3-9e90b7cc1123': zuddlPhotoBooth,
  '87bcdd48-654e-43a4-9c70-67d92b6d3c6e': zuddlPhotoBooth,
  'd71f75f7-7989-4a8c-bce3-eee4e6911045': toyInsider,
  'c0fad185-454b-497d-898a-df6778034680': aop,
  '8fdb7d0b-081f-44af-9456-42a4bde99040': ngis,
  'dc6f8d12-04a8-4307-bac4-3e9700d5dbc2': shift,
  '34d06311-780f-40b5-b181-3886ed9b66a0': ngis,
  '70149f1d-3f2a-446f-abab-b9d6881b8c55': ngis,
  '7e036563-a97a-4b46-a6c2-65ccd5ec6a25': dsci,
  'b1b76766-a798-44d4-a4be-678e6eeaf330': aop,
  '3b9107c6-2c9f-4f8e-848c-40002393dcef': ngis,
  'dbd80361-0049-4a95-b8fc-d332e2eb91f3': aop,
  'eedc5b57-3aa5-455a-96ae-1ee1c2e1834a': ngis,
  '5d2f2959-321a-4687-8c81-6af19e00de4f': aop,
  '55bb16a1-2a67-4cef-a822-203ecf7c169f': aop,
  '91c7cf83-c7f9-4d3a-b424-7465871d50b8': aop,
  '3ef5bcb3-bb28-4dd5-92cc-e3e8e674846f': aop,
  '28203457-defd-4ee5-b6fb-ce217d60f3c8': ngis,
  'fe0bcbb5-1fb7-41bc-9a12-dc6d2e8b5359': aop,
  '8e676b7d-4a49-42d2-a5a2-6b1de51ecc79': aop,
  'd78506c9-381a-4fbf-beda-f23310d9691d': ngis,
  '154a3b0c-4a9d-4998-9230-bfbb1df7f0fd': aop,
  'b9502e75-538f-4714-8209-f9afa6d5051f': aop,
  '1b503506-4e59-4bc5-a240-9da1101d9c4b': aop,
  '222318b6-007b-4588-962d-e4930ae2d5bd': ngis,
  'b835ac7c-e875-4dca-a370-a7b7bbafb9c0': ngis,
  'da141173-6acd-48d3-9ee4-7501491f8b14': ngis,
  '8cb747ea-93b9-4007-91c5-8fe9ef7fe503': aop,
  '20d0dbb9-a76e-487d-ac81-81bcdf835879': vmworld,
  'edbb04cf-847b-4810-9219-89f15591bf2e': kellogg,
  '48d9aca6-59b3-4eb9-8b3d-a83efd37805b': ngis,
  '3c24be08-989d-4bb0-8e09-c32e47347855': ngis,
  '8287fd47-39d4-4029-895c-83870db8a6c3': ngis,
  '71cb629e-9f38-4f39-af98-53f58262cb83': tigerAnalytics,
  'b77607f4-a1c5-4a8e-81c6-ad8b8a70176e': aznet,
  'e354db3d-a325-4914-91ed-5e82151b10e8': llegoogle,
  '140b5599-5437-4c7b-baa1-f1c9461daaf1': abpa,
  'eaec2fc4-86b9-4bc2-9f9d-85e7759096ed': zuddlPhotoBooth,
  'acecf84e-3d6c-4575-a1df-8caa29398135': SMLC2021,
  'bbe3a852-e573-4e7d-b808-0e8d5b958c97': IndoPacificBusinessFormETP,
  '0b868c93-9c09-41cb-9850-be2f645fa93e': dsci,
  'bae825cf-10f6-496e-9eb2-96c0a8a7cf88': umdETP,
  'ec62711e-124c-4778-9784-4bb6f4ca3d64': ngis,
  '48d8a85c-29da-421b-8f03-8006dc98af03': aznet,
  'becde82a-05b2-4094-bde7-65718b4dde0f': foodBuy,
  '58027ec6-3392-4b4e-a782-de7af02ae8b6': acxiom,
  '17e9e971-81d9-4203-a395-f4ed48f8239c': nacdgala,
  '17e109aa-bddb-4ebc-97cc-0614fb88a9e1': ngis,
  'f878f1a3-4151-4187-a1e7-648015d02e20': plpsvrp,
  '9cd7a170-a840-4636-9404-548dfb0571b5': indiaMobileCongressNew,
  'b11d01db-86d8-47c0-8352-4197560b412a': zuddlPhotoBooth,
  'e2fdaa82-ec9d-41d6-aee9-80c923f4eddb': aiss,
  '85769d81-af57-4309-8500-b74a9a7fcc73': sandbox1,
  '6d1d02b9-51a5-4cb9-9d8a-0ec9fde9a0be': zuddlPhotoBooth,
  '9dc7720a-55de-43e6-a421-dc9c4ff9e8bc': zuddlPhotoBooth,
  '1d92d48a-e54e-4daf-9334-e4dab238fb2c': zuddlPhotoBooth,
  'f4126a55-5a8d-497e-871d-181e2ed749c3': zuddlPhotoBooth,
  '8bce1600-b461-49dc-9284-33fb442bd8f5': zuddlPhotoBooth,
  '5edd2acd-b442-4246-983f-19cdd49b0521': zuddlPhotoBooth,
  '4104a535-9383-4669-a306-06897eb2d4ab': zuddlPhotoBooth,
  'c50be1ed-6c05-480a-8ec8-d76fb587438c': worldbankSB,
  'a52fc575-a987-402f-b297-cb30b878564a': Finsec2022,
  '6f3a5f3d-f69c-4e33-bd8c-06fe8ded6464': zuddlPhotoBooth,
  'f71433cd-f536-4765-a200-53abef9139fa': zuddlPhotoBooth,
  'b77080cc-14a5-4551-ab3f-729effc3e4a7': zuddlPhotoBooth,
  '517f00cd-e0a5-4921-9013-abfca1076d81': Veeco1config,
  'e6b0a88b-525f-4063-90e1-e932b8a66e52': Veeco2config,
  '427591fb-72eb-4468-b172-c74f14ec9edc': Veeco3config,
  '8e2af682-bdb1-4371-9c81-80cba84695de': Veeco4config,
  '993b8ef4-e393-43d2-9cb3-cd44160c0f53': VeecoLL2config,
  '900af8ea-7b78-432d-bcff-9af18c781610': zuddlPhotoBooth,
  '0bf37f5a-817d-4c2a-8cb2-8a4c0b076c35': zuddlPhotoBooth,
  '7d3407bf-6a74-45bf-87a0-979a111b1871': zuddlPhotoBooth,
  'df3f0b1a-4692-4db4-b137-4ed5390b11c5': zuddlPhotoBooth,
  '2589e487-81c5-41c3-bbf7-5d14f346b22d': zuddlPhotoBooth,
  '0cbf999a-9259-4f07-984e-1b744b3897c0': zuddlPhotoBooth,
  '26c81316-82ad-4181-b05f-29e37210d1a7': zuddlPhotoBooth,
  '16c118f9-3904-41b1-a871-014bd79b8f53': zuddlPhotoBooth,
  'c027a24f-e7bd-4750-9855-b2455a28bde5': zuddlPhotoBooth,
  '757cb9c4-c896-474f-b79d-4870c193d230': zuddlPhotoBooth,
  'ee57a307-297f-48d5-ace5-b3466f91a696': ipc22,
  '77edf186-afc9-452b-ae86-a0e3a2e3c450': bpm2022,
  '816baa72-84b0-402c-87af-bc44f7da3c11': zuddlPhotoBooth,
  '08c340f3-da91-47b2-a3a5-953293266df1': serviceNow,
  'dcb86a06-8266-4ec1-bc39-29526f79856c': indiaMobileCongressNew2,
  '50a8c214-5da2-4fce-9a5d-3481b4f85d23': zuddlPhotoBooth,
  'e3eaf89d-90ac-4fd6-a590-2475fb3c6078': kpmgch2023,
  '6f66228c-d98f-4edc-93c3-98ddebff65a3': zuddlPhotoBooth,
  '7036fd1d-63d8-4231-a244-8f877e8e92df': zuddlPhotoBooth,
  'c2697a57-5a67-4c78-acfe-184ce55103c2': zuddlPhotoBooth,
  '57856fb4-c140-4aad-9251-5ecb752f565d' : zuddlPhotoBooth,
  
  // demo
  'f632dd87-2818-43d3-85cf-739f924cbfac': nymburuCulturalEvent,
  'ca586e61-20dc-408a-9c9a-2c1d5e48835f': seri2021Event,
  'e10a7024-899a-418d-870a-48d59ffa017b': adot2021Event,
  'e08619f6-af53-4031-a357-570550e1f011': OTICONNEXT,
  'f1e15d76-4dad-4c9b-aade-eee3341dfa51': uawVirtualMeetingConfig,
  'ac5da688-c8ee-46c9-b5a7-6daa6ecc1f53': razorpayFtxConfig,
  '8fe1fcbe-842a-4fe4-807d-2fb1fee092b6': salespreConfig,
  '4d212610-17b8-4aa0-9d5f-33b154dc26b8': acxiomss,
  'efb52ddb-4f48-4571-8622-25dc9ea9de7d': manipalJFConfig,
  '4c7e8776-9583-4f2a-b9f6-931b2a2bcfc3': zuddlPhotoBooth,
  '6733ffbe-0e58-4840-88fd-7256e128056d': zuddlPhotoBooth,
  '0a4a440f-ff2e-4f0f-82c2-a0f8c79e5c1c': zuddlPhotoBooth,
  '95923550-1576-4bf4-8376-2019229a3478': zuddlPhotoBooth,
  'd01a68e9-648f-48e5-93d2-242e5475517c': zuddlPhotoBooth,
  '2108482f-ee59-4e2a-a668-19788c003c9a': zuddlPhotoBooth,
  '0edfb219-2be9-4cf7-9d01-a06eb745b851': zuddlPhotoBooth,
  'fc541742-7f06-41d0-8f64-8df0b75d3f1b': GoogleSBconfig,
  'e545121f-5169-45a3-a8f3-2f5af8ab7fb7': zuddlPhotoBooth,
  'dff9780a-6441-4b3d-b4d6-bd432d8dbad7': zuddlPhotoBooth,
  '839a1049-41c8-4af7-96aa-d1de2d4a16bf': zuddlPhotoBooth,
  '118cb53b-2775-4696-b08d-a81a274b6bf5': zuddlPhotoBooth,
  '3381e525-546c-47e4-859e-80a559c38f0f': zuddlPhotoBooth,
  'ab187ce6-2810-4b91-9303-6e5b4f2e24a8': zuddlPhotoBooth,
  'e2368e50-8368-486c-848f-20c82bd0677b': zuddlPhotoBooth,
  '9e9c3c73-b4a0-4169-95d2-043b5efcc69c': zuddlPhotoBooth,
  '50607458-708f-4339-9fda-f6be37236501': zuddlPhotoBooth,
  '420a25db-a35e-4447-9165-5499b9a6c9bf': zuddlPhotoBooth,
  '5b3184be-8215-4266-a12e-728419692967': SandboxMSconfig,
  'c5662ed5-54fd-42ed-9b6e-de5ad9d15146': SandboxMS2config,
  '4cfc0c7d-55fa-441c-ab43-298e3b2bd501': zuddlPhotoBooth,
  'f7eaa029-9a3c-4ff9-a88e-4b821dad69eb': zuddlPhotoBooth,
  'f4b5bc05-f923-40bc-abcb-0ae473083a8a': zuddlPhotoBooth,
  //'127b7f67-3209-4435-99cf-a72ddba9a50e': JuneteenthExpo,
  '6e3fbf1e-f74c-4877-9291-e5eaac897c8a': tidalSB,
  '1b0eb9fc-dcb5-45eb-be23-f65705e55384': zuddlPhotoBooth,
  'd8d4517a-b810-4504-b422-35444a766a04': satairSB,
  'bc0f12a0-ea3a-4599-b8e2-dea3adb3991d': artsgrowscSB,
  '3170de70-148e-4d49-91e7-c2ef289f3a3a': zuddlPhotoBooth,
  'b0e27c5d-a126-4b6c-8972-9e1b831cc19f': zuddlPhotoBooth,
  '43a8b94e-22e2-44ff-a3e7-160d21d123ef': zuddlPhotoBooth,
  '34e604ce-8aeb-414e-b1be-5e3f5cd06b48': AlphaWaveSB,
  '9c17f658-864e-431e-9246-9a33b0fb099f': zuddlPhotoBooth,
  'dc99d545-a506-4941-8f4d-9d78f842bb27': zuddlPhotoBooth,
  '3c993792-debe-4e37-951e-537a9cb52dfc': presalebpm2022copy,
  'c882f9cc-6b4f-4c53-ab62-e2b9a527485e': zuddlPhotoBooth,
  'bf23cd6c-c0d3-47e5-88ae-8043799c484a': PresaleFinsec2022Copy,
  '32bdd659-0259-41a4-9a0d-573f6711feb1': zuddlPhotoBooth,
  '7e83cd21-1fe6-4bd2-b00d-3adaa2551e99': zuddlPhotoBooth,
  'b00d7875-bff5-4388-971c-340bb15ebe99': zuddlPhotoBooth,
  '8423c9a9-89af-412d-9002-80d56a4baa72': zuddlPhotoBooth,
  '29b0367f-090d-416c-b612-a3970614d697': zuddlPhotoBooth,
  'b2ca1cda-18ee-4299-918e-f6e147edb115': neomsb,
  'da285dd3-1f92-4871-981b-496c589bea37': reveneraExpo,
  '7d8d7dd6-4075-4902-a3df-2c4ff0f7531b': neomrenewed,
  'adb1d9c8-548b-4758-bcd5-8e3ece55e0af': zuddlPhotoBooth,
  'c19c105d-de1f-4276-af31-8d8575d87557': zuddlPhotoBooth,
  '286ea898-6fea-482b-a7e6-463bac8b2bec': zuddlPhotoBooth,
  '72bc434f-8996-41de-9835-4b96081f3796': zuddlPhotoBooth,
  '073e685f-bf42-4c13-9312-311bd4763096': zuddlPhotoBooth,
  '8cf8afd2-a22f-43d3-bebd-40e3d02a572a' : zuddlPhotoBooth,
  '9bb7d022-8f66-4c52-9a40-8d95e528ee87' : zuddlPhotoBooth,
  // Staging
  'f339a49f-e906-4585-975c-bbc7d21fd112': qualcomm,
  '46e7d23f-54ad-427d-ab39-170bbffc70e5': zuddlPhotoBooth,
  'b25bf442-4e03-4b40-9c26-f111be5928fd': zuddlPhotoBooth,

  // local
  'f0907055-9f1d-499e-945a-27f0ac3db3f2': dsci,
  'e10aab48-5f16-493f-a3ec-ea38954a8796': aop,
  // satish
  '7287577a-ba2b-437e-95ad-152bd0c9fd8b': tigerAnalytics,
  '52ee0702-287a-4cfe-b5da-781c533d0d79': acxiomss,
  // samrat
  '6cfdf753-2ce9-4923-8007-aa1ca5c89912': llegoogle,
  // suraj
  //'0f988d41-c18f-4ea1-921d-f35b675e9183': zuddlPhotoBooth,
  default: pharmacoConfig,
};

const configByStage = {
  '55b9daf2-3787-4c8c-9ff9-b7dfc171c3d0': template1,
  '7b393cb6-4b03-4069-8962-c6f36b1de4d9': template2,
  '7dee8a7e-b4bb-42a5-9a62-a65a3071fad5': template3,
  'bae58a22-580f-4422-b93b-6d9146c42f9d': template4,
  '6aaac68e-9f3e-4dfe-8f4f-25ee412d878d': template5,
  '021cbb93-bffd-48b0-8fa4-3524d3495f69': template6,
  '523acc4f-24e0-489a-befa-214e1795cbc5': template1,
  'a028023a-fac5-4bd9-9f7e-c00609bca3a6': template2,
  'bbe89b65-f9f1-4842-bcea-e639bc374d8f': template3,
  '6aac0d8f-0f98-47ad-8aa5-a61d78b89b0c': template4,
  'ddb83e25-800d-406b-bec2-d0d038a75505': template5,
  '8937aad1-3aef-4e94-b505-cc57ee957171': template6,
  '54a32bba-a0b7-456c-8cdd-f04d7194ec41': template3,
  '294f6885-4bcc-4eb3-bc10-e938f956cc00': dsciaissConfig, // On pre-prod for testing
};

export { configByStage };
export default configByEvent;
