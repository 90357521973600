import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const SignalTower = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 11 12" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M2.313 10.3805C1.62185 9.87825 1.05948 9.21948 0.671959 8.45809C0.284436 7.69669 0.082777 6.85433 0.083498 6C0.083498 3.00837 2.50854 0.583328 5.50016 0.583328C8.49179 0.583328 10.9168 3.00837 10.9168 6C10.9176 6.85433 10.7159 7.69669 10.3284 8.45809C9.94085 9.21948 9.37848 9.87825 8.68733 10.3805L8.13754 9.43849C8.856 8.88739 9.38378 8.12507 9.64674 7.25861C9.90971 6.39215 9.89463 5.46508 9.60365 4.60763C9.31266 3.75017 8.76038 3.00541 8.02439 2.47795C7.28839 1.95049 6.40565 1.66684 5.50016 1.66684C4.59468 1.66684 3.71194 1.95049 2.97594 2.47795C2.23995 3.00541 1.68767 3.75017 1.39668 4.60763C1.1057 5.46508 1.09063 6.39215 1.35359 7.25861C1.61655 8.12507 2.14433 8.88739 2.86279 9.43849L2.313 10.3805ZM3.41475 8.49274C2.90289 8.06452 2.53527 7.48909 2.36183 6.84465C2.1884 6.20021 2.21756 5.518 2.44535 4.89072C2.67315 4.26343 3.08853 3.72147 3.63507 3.33849C4.1816 2.9555 4.8328 2.75005 5.50016 2.75005C6.16753 2.75005 6.81872 2.9555 7.36526 3.33849C7.9118 3.72147 8.32718 4.26343 8.55498 4.89072C8.78277 5.518 8.81193 6.20021 8.6385 6.84465C8.46507 7.48909 8.09744 8.06452 7.58558 8.49274L7.02767 7.53616C7.33197 7.23366 7.5396 6.84762 7.62425 6.42698C7.7089 6.00634 7.66675 5.57003 7.50314 5.17338C7.33953 4.77672 7.06183 4.43757 6.70524 4.19892C6.34865 3.96028 5.92924 3.83288 5.50016 3.83288C5.07109 3.83288 4.65168 3.96028 4.29509 4.19892C3.9385 4.43757 3.6608 4.77672 3.49719 5.17338C3.33358 5.57003 3.29143 6.00634 3.37608 6.42698C3.46073 6.84762 3.66836 7.23366 3.97266 7.53616L3.41475 8.49274ZM4.9585 6.54166H6.04183V11.4167H4.9585V6.54166Z" className={fillClassName} />
    </svg>
  );
};

export default SignalTower;
