import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
// components
import Label from '../../content/Label';
// styles + types
import styles from './styles.module.scss';
import { IInputFieldProps } from './types';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';
import { STANDARD_ICON_BUTTON_SIZE } from '@/styles/iconSizes';
import { getCssVar } from '@/utils/cssVars';
import { Eye, EyeOff } from 'react-feather';
import { IContentColors } from '@/types';

const InputField = (props: IInputFieldProps) => {
  const {
    className = '',
    error,
    isLastElement,
    label,
    onChange,
    onBlur,
    placeholder,
    readOnly,
    styleClass = '',
    submitFailed,
    value,
    widthStyles = '',
    validate,
    tooltip,
    maxLength,
    hideAsterisk = false,
  } = props;
  const [showText, setShowText] = useState(false);
  const [showMaxLengthExceededError, setShowMaxLengthExceededError] = useState(false);
  const fieldRef = useRef();
  // Add icon settings + determine the correct icon to show
  const iconColor = IContentColors.NEUTRAL_MID_2;
  const size = STANDARD_ICON_BUTTON_SIZE;
  const handleIconClick = () => setShowText(!showText);
  const EyeIcon = showText ? (
    <Eye color={getCssVar(iconColor)} size={size} onClick={handleIconClick} />
  ) : (
    <EyeOff
      color={getCssVar(iconColor)}
      size={size}
      onClick={handleIconClick}
    />
  );

  useEffect(() => {
    if (value === undefined) return;
    if (!fieldRef.current) return;
    // To reflect data changes into the field text value.
    if (fieldRef.current.value !== value) {
      fieldRef.current.value = value;
    }
  }, [value]);

  const validationError = submitFailed && error;

  // set up necessary style classes
  const fullContainerWidth = `${styles[widthStyles]}`;
  const fullContainerPositioning = `${styles.container}`;
  const marginRightClass = `${styles.addMarginRight}`;
  const containerWithValidationMessage = classnames(
    fullContainerWidth,
    fullContainerPositioning,
    { [marginRightClass]: !isLastElement },
  );

  const validationErrorBorder = `${styles.validationError}`;
  const baseClass = 'inputField';
  const inputFieldBaseClass = classnames(
    `${styles[label ? `${baseClass}` : `${baseClass}NoLabel`]}`,
    { [validationErrorBorder]: validationError },
  );
  const styleClassExists = !!styleClass;
  const inputFieldClass = classnames(
    { [inputFieldBaseClass]: !styleClassExists },
    { [`${styles[styleClass]}`]: styleClassExists },
    { [className]: className },
  );

  const handleChangeInput = (event) => {
    const { value } = event.target;
    setShowMaxLengthExceededError(maxLength !== undefined && maxLength === value.length);
    onChange && onChange(event);
  }

  return (
    <div className={containerWithValidationMessage}>
      <div className={styles.titleLabelContainer}>
        {
          label && label.length ? (
            <Label
              value={
                validate && !hideAsterisk
                  ? `${label}*`
                  : label
              }
              styleClass="fieldLabelTitleText"
            />
          ) : null
        }
        {
          maxLength &&
          <Label value={`(${maxLength} characters)`} styleClass="fieldLabelTitleTextLength" />
        }
        {
          label && label.length && tooltip ? (
            <TooltipDiv dataFor={`tooltip_input_${label}`} tooltipText={tooltip}/>
          ) : null
        }
      </div>
      <input
        ref={fieldRef}
        className={inputFieldClass}
        defaultValue={value || ''}
        disabled={readOnly}
        onChange={handleChangeInput}
        onBlur={onBlur}
        placeholder={placeholder}
        tabIndex={0}
        type={showText ? 'text' : 'password'}
        maxLength={maxLength}
        data-private
      />
      <span className={styles.toggleEye}>
             {EyeIcon}
             </span>
      {
        (maxLength && !!value) &&
          <div className={styles.textLimit}>{value.length}/{maxLength} characters</div>
      }
      {validationError && <FormValidationErrorTooltip value={error} />}
      {/* {showMaxLengthExceededError && <Label value={'Max Character Limit Reached!'} styleClass={"maxLimitExceededText"} />} */}
    </div>
  );
};

export default React.memo(InputField);
