import { IEventCustomConfigType } from '@/custom-config/types';
import { PCMA_RE_UNITE_PHOTOBOOTH } from '@/components/custom/layouts/ManualLayout/config';
const pcma2021: IEventCustomConfigType = {
  name: 'pcma2021',
  zoneNames: {
    expo: 'Sponsors',
  },

  boothConfig: {
    '555a99d6-7b82-47f6-9af4-9bedea0287ed': PCMA_RE_UNITE_PHOTOBOOTH,
  },
};
export default pcma2021;
