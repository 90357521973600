import React from 'react';
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const FeedV3 = (props: IIconProps) => {
  const { className = '', color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 32 32',
      }}
    >
      <path
        d="M24.6776 5.66222H7.29674C6.80286 5.66222 6.40039 6.07008 6.40039 6.56956V25.4317C6.40039 25.9359 6.8033 26.339 7.29674 26.339H24.704C25.1979 26.339 25.6004 25.9312 25.6004 25.4317L25.6002 6.60011C25.6002 6.08278 25.1847 5.66211 24.6778 5.66211L24.6776 5.66222ZM14.8804 8.37474H18.6264C19.0313 8.37474 19.3596 8.70705 19.3596 9.11684C19.3596 9.52662 19.0313 9.85894 18.6264 9.85894H14.8804C14.4756 9.85894 14.1473 9.52664 14.1473 9.11684C14.1473 8.70706 14.4756 8.37474 14.8804 8.37474ZM9.10083 9.11714C9.10083 8.70737 9.42911 8.37504 9.83395 8.37504H12.0452C12.45 8.37504 12.7783 8.70735 12.7783 9.11714V11.7102C12.7783 12.12 12.45 12.4523 12.0452 12.4523H9.83395C9.42913 12.4523 9.10083 12.12 9.10083 11.7102V9.11714ZM22.8992 22.8841C22.8992 23.2939 22.5709 23.6262 22.1661 23.6262H9.83395C9.42913 23.6262 9.10083 23.2939 9.10083 22.8841V15.4797C9.10083 15.0699 9.42911 14.7376 9.83395 14.7376H22.1661C22.5709 14.7376 22.8992 15.0699 22.8992 15.4797V22.8841ZM22.1661 12.4536H14.8804C14.4756 12.4536 14.1473 12.1213 14.1473 11.7115C14.1473 11.3017 14.4756 10.9694 14.8804 10.9694H22.1661C22.5709 10.9694 22.8992 11.3017 22.8992 11.7115C22.8992 12.1213 22.5709 12.4536 22.1661 12.4536Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default FeedV3;
