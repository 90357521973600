import { IStageParent } from '@/components/Stage';

export interface IStreamPreviewModalProps {
  setBroadcastPermissions: (bool: boolean) => void;
  ignoreButtonMargin?: boolean;
  localClient: any;
  localStream: any;
  showIconButton?: boolean;
  showIconTextButton: boolean;
  defaultOpen?: boolean;
  parent: IStageParent;
  hideTriggerButton?: boolean;
  disableClose?: boolean;
  hideMuteOptions?: boolean;
  tempStreamConfig?: any;
  onModalClose?: any;
}

export enum ModalContentType {
  ASK_PERMISSIONS = 'ASK_PERMISSIONS',
  DENIED_PERMISSIONS = 'DENIED_PERMISSIONS',
  LOADING = 'LOADING',
  NORMAL = 'NORMAL',
  ERROR = 'ERROR',
}

export enum EAgoraNetworkQualityMetric {
  UNKNOWN = 0,
  EXCELLENT = 1,
  GOOD = 2,
  SLIGHTLY_IMPAIRED = 3,
  NOT_SMOOTH = 4,
  POOR = 5,
  DISCONNECTED = 6,
}

export enum MediaPermissionTypes {
  PROMPT = 'prompt',
  GRANTED = 'granted',
  DENIED = 'denied',
}
