import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const CaptionIcon = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={fillClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2C14.368 2 14.6666 2.29867 14.6666 2.66667V13.3333C14.6666 13.7013 14.368 14 14 14H1.99998C1.63198 14 1.33331 13.7013 1.33331 13.3333V2.66667C1.33331 2.29867 1.63198 2 1.99998 2H14ZM5.99998 5.33333C4.52798 5.33333 3.33331 6.528 3.33331 8C3.33331 9.472 4.52798 10.6667 5.99998 10.6667C6.73331 10.6667 7.39998 10.3667 7.88531 9.88533L6.94265 8.94267C6.70198 9.184 6.36865 9.33333 5.99998 9.33333C5.26331 9.33333 4.66665 8.73667 4.66665 8C4.66665 7.26333 5.26331 6.66667 5.99998 6.66667C6.36665 6.66667 6.69865 6.81333 6.94331 7.058L7.88598 6.11533C7.40331 5.632 6.73665 5.33333 5.99998 5.33333ZM10.6666 5.33333C9.19465 5.33333 7.99998 6.528 7.99998 8C7.99998 9.472 9.19465 10.6667 10.6666 10.6667C11.4026 10.6667 12.0693 10.368 12.552 9.88533L11.6093 8.94267C11.368 9.184 11.0346 9.33333 10.6666 9.33333C9.92998 9.33333 9.33331 8.73667 9.33331 8C9.33331 7.26333 9.92998 6.66667 10.6666 6.66667C11.0353 6.66667 11.3686 6.816 11.61 7.058L12.5526 6.11533C12.07 5.632 11.4033 5.33333 10.6666 5.33333Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default CaptionIcon;
