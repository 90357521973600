import React from 'react';
import { EAgoraNetworkQualityMetric } from './types';

import NewLabel, {
  INewLabelColors,
  INewLabelMargins,
  INewLabelSizes,
} from '@/components/ui/content/NewLabel';
import { IContentColors } from '@/types';
import { getCssVar } from '@/utils/cssVars';
import { Wifi } from 'react-feather';
import WifiAvg from '../../new-icons/WifiAvg';
import WifiPoor from '../../new-icons/WifiPoor';
import WifiOffline from '../../new-icons/WifiOffline';

export const getMediaDeviceBrowserData = async () => {
  const { navigator } = window;
  let stream: any = null;
  stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: true,
  });

  return {
    video: stream.getVideoTracks().length > 0,
    audio: stream.getAudioTracks().length > 0,
  };
};

// Docs: https://docs.agora.io/en/Video/API%20Reference/web_ng/interfaces/networkquality.html
export const getNetworkStatus = (networkQuality: any) => {
  if (
    networkQuality.averageUplink === EAgoraNetworkQualityMetric.EXCELLENT ||
    networkQuality.averageUplink === EAgoraNetworkQualityMetric.GOOD
  ) {
    return {
      label: (
        <NewLabel
          labelColor={INewLabelColors.SUCCESS}
          labelMargin={INewLabelMargins.XSMALL_MARGIN_LEFT}
          labelSize={INewLabelSizes.XSMALL}
          text="GOOD"
        />
      ),
      icon: <Wifi color={getCssVar(IContentColors.SUCCESS)} size={16} />,
    };
  }

  if (
    networkQuality.averageUplink ===
      EAgoraNetworkQualityMetric.SLIGHTLY_IMPAIRED ||
    networkQuality.averageUplink === EAgoraNetworkQualityMetric.NOT_SMOOTH
  ) {
    return {
      label: (
        <NewLabel
          labelColor={INewLabelColors.WARNING}
          labelMargin={INewLabelMargins.XSMALL_MARGIN_LEFT}
          labelSize={INewLabelSizes.XSMALL}
          text="FAIR"
        />
      ),
      icon: <WifiAvg color={getCssVar(IContentColors.WARNING)} size={16} />,
    };
  }

  if (networkQuality.averageUplink === EAgoraNetworkQualityMetric.POOR) {
    return {
      label: (
        <NewLabel
          labelColor={INewLabelColors.DANGER}
          labelMargin={INewLabelMargins.XSMALL_MARGIN_LEFT}
          labelSize={INewLabelSizes.XSMALL}
          text="POOR"
        />
      ),
      icon: <WifiPoor color={getCssVar(IContentColors.DANGER)} size={16} />,
    };
  }

  if (
    networkQuality.averageUplink === EAgoraNetworkQualityMetric.DISCONNECTED
  ) {
    return {
      label: (
        <NewLabel
          labelColor={INewLabelColors.DANGER}
          labelMargin={INewLabelMargins.XSMALL_MARGIN_LEFT}
          labelSize={INewLabelSizes.XSMALL}
          text="OFFLINE"
        />
      ),
      icon: <WifiOffline size={16} />,
    };
  }

  // Default when SDK is reporting 0 - unknown
  return {
    label: (
      <NewLabel
        labelColor={INewLabelColors.MONOCHROME_CONTRAST}
        labelMargin={INewLabelMargins.XSMALL_MARGIN_LEFT}
        labelSize={INewLabelSizes.XSMALL}
        text="Checking..."
      />
    ),
    icon: (
      <Wifi color={getCssVar(IContentColors.MONOCHROME_CONTRAST)} size={16} />
    ),
  };
};
