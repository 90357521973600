import { ManualLayoutConfig } from '@/components/custom/layouts/ManualLayout/config';

const PresaleFinsec2022Copy = {
  name: 'PresaleFinsec2022Copy',
  logo: '',
  auth: {
    magicLinks: true,
  },


  expoConfig: ManualLayoutConfig.PresaleFinsec2022Copy_Expo,
  boothConfig: {
    '3187fc98-f826-4359-8c35-eb8a9efc16e1': ManualLayoutConfig.DSCIFinsec_PHOTOBOOTH,
 },


};

export default PresaleFinsec2022Copy;